import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  MediaCard,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalSettings } from '../LocalSettings'

function AppNotEnabledBanner({ appEmbedUrl, appEnabled, loading }) {
  const { t } = useTranslation()
  const breakPoints = useBreakpoints()
  const { saveSettings, settings } = useLocalSettings()

  const watchTutAction = {
    content: t('AppNotEnabled.mediaCard.watchTutcta'),
    onClick: async () => {
      document.getElementById('tut-modal').show()
    },
  }

  const enableAppAction = {
    content: t('AppNotEnabled.mediaCard.enableAppCta'),
    url: appEmbedUrl,
  }

  if (settings.hideAppEmbed || appEnabled || loading) {
    return null
  }

  const Content = () => (
    <InlineGrid columns={breakPoints.lgUp ? 'auto 1fr' : '1fr'}>
      <div
        alt=""
        width="100%"
        style={{
          width: getImageWidth(breakPoints),
          height: getImageHeight(breakPoints),
          borderRadius: breakPoints.lgUp
            ? 'var(--p-border-radius-300) 0 0 var(--p-border-radius-300)'
            : null,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          style={{
            width: '80px',
          }}
          viewBox="0 0 80 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M79.724.603L54.981.521c-7.857 0-14.796 3.513-17.828 9.986C34.121 4.034 27.18.52 19.326.52 8.776.521-.127 6.85-.127 18.188c0 11.34 8.903 17.667 19.453 17.667 7.854 0 14.795-3.509 17.828-9.985 3.031 6.476 9.97 9.985 17.827 9.985 10.55 0 19.45-6.328 19.45-17.667 0-4.046-1.14-7.451-3.077-10.163l8.37-7.422zm-60.416 26.38c-5.276 0-8.903-3.617-8.903-8.795 0-5.092 3.627-8.79 8.903-8.79 5.275 0 8.887 3.697 8.887 8.79 0 5.178-3.612 8.795-8.887 8.795zm35.654 0c-5.275 0-8.902-3.617-8.902-8.795 0-5.092 3.627-8.79 8.902-8.79 5.276 0 8.9 3.709 8.9 8.79 0 5.178-3.624 8.795-8.881 8.795h-.019z"
            fill="rgb(255, 159, 138)"
          ></path>
        </svg>
      </div>
      <Box padding={'400'}>
        <BlockStack gap={'100'}>
          <Text as="strong" variant="headingMd">
            {t('AppNotEnabled.mediaCard.heading')}
          </Text>
          <Text>{t('AppNotEnabled.mediaCard.subHeading')}</Text>
          <div
            style={{
              padding: 'var(--p-spacing-100)',
            }}
          />
          <InlineStack gap={'200'} align='end'>
            <Button size="slim" {...watchTutAction}>
              {watchTutAction.content}
            </Button>
            <Button size="slim" variant="primary" url={enableAppAction.url}>
              {enableAppAction.content}
            </Button>
          </InlineStack>
        </BlockStack>
      </Box>
    </InlineGrid>
  )

  return (
    <Banner
      title={t('AppNotEnabled.title')}
      tone="critical"
      onDismiss={() => {
        saveSettings({
          ...settings,
          hideAppEmbed: true,
        })
      }}
    >
      <Box>
        <Content />
      </Box>
    </Banner>
  )
}

export default AppNotEnabledBanner

function getImageHeight(breakPoints) {
  if (breakPoints.lgUp) {
    return '100px'
  }
  return '300px'
}

function getImageWidth(breakPoints) {
  if (breakPoints.lgUp) {
    return '100px'
  }
  return '100%'
}
