import React, { useEffect, useState, useRef } from 'react'
import {
  Banner,
  BlockStack,
  Box,
  Card,
  Divider,
  InlineGrid,
  Text,
} from '@shopify/polaris'
import { CartDiscountIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import RecommendationsProductSelector, {
  ProductSelectionPopover,
} from '../../../../../../../../components/RecommendationsProductSelector'

const MAX_PRODUCTS = 10

function ProductSelector({ form }) {
  const [selectedProducts, setSelectedProducts] = useState([])
  const { t } = useTranslation()

  const recProductsHandlePopoverChange = async (value) => {
    let prods = [],
      copy = [...form.fields.products.value]
    if (value.aiRec) {
      Array.from({ length: value.aiRec }).forEach((_, index) => {
        prods.push({
          id: `ai-${parseInt(Math.random() * (10e13).toFixed(0))}`,
          aiGenerated: true,
          title: t(
            'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.card.aiGenerate',
          ),
          images: [
            {
              originalSrc: CartDiscountIcon,
            },
          ],
          discount: null,
        })
      })
    } else if (value.specificProducts) {
      const resourcePickerPayload = {
        multiple:
          MAX_PRODUCTS -
          form.fields.products.value.reduce(
            (acc, p) => acc + (p.aiGenerated ? 1 : 0),
            0,
          ),
        type: 'product',
        selectedIds: form.fields.products.value
          .filter(({ aiGenerated }) => !aiGenerated)
          .map((p) => 'gid://shopify/Product/' + p.id),
        filter: {
          variants: false,
        },
      }
      try {
        const selected = await shopify.resourcePicker(resourcePickerPayload)
        if (selected) {
          selected.forEach((product) => {
            prods.push({
              id: parseInt(product.id.replace('gid://shopify/Product/', '')),
              title: product.title,
              url: product.url,
              images: product.images,
              aiGenerated: false,
              discount: null,
              vendor: product.vendor,
            })
          })
        } else {
          prods
        }
      } catch (e) {
        window.shopify.toast.show(t('noProductUpdate'), {
          isError: true,
        })
      }
    }
    const set = new Set([...copy.map((p) => p.id), ...prods.map((p) => p.id)])
    const idMap = [...copy, ...prods].reduce((acc, p) => {
      acc[p.id] = p
      return acc
    }, {})
    const productArr = Array.from(set).map((id) => idMap[id]).sort((a, b) => {
      if (a.aiGenerated && !b.aiGenerated) {
        return 1
      } else if (!a.aiGenerated && b.aiGenerated) {
        return -1
      } else {
        return 0
      }
    })
    form.fields.products.onChange(productArr)
  }

  return (
    <>
      <Card padding={'0'}>
        <Box padding={'400'}>
          <BlockStack gap={'300'}>
            {form.fields.products.value.length >= MAX_PRODUCTS && (
              <Banner
                tone="warning"
                title={t(
                  'CheckoutUpsell.CampaignDetail.TriggerCard.productSelector.maxProducts',
                  {
                    maxProducts: MAX_PRODUCTS,
                  },
                )}
                status="critical"
              />
            )}
            <InlineGrid columns={'1fr auto'} gap={'200'}>
              <BlockStack gap={'100'}>
                <Text variant="headingMd">
                  {t(
                    'CheckoutUpsell.CampaignDetail.TriggerCard.productSelector.title',
                  )}
                </Text>
                <Text>
                  {t(
                    'CheckoutUpsell.CampaignDetail.TriggerCard.productSelector.description',
                  )}
                </Text>
              </BlockStack>
              <Box>
                <ProductSelectionPopover
                  disabled={false}
                  primaryVariant={true}
                  onChange={recProductsHandlePopoverChange}
                />
              </Box>
            </InlineGrid>
          </BlockStack>
        </Box>
        <Divider />
        {/* Add component here */}
        <RecommendationsProductSelector
          {...{
            selectedItems: selectedProducts,
            setSelectedItems: setSelectedProducts,
            form,
            productsOnChange: (item) => {
              const copy = [...form.fields.products.value]
              const index = copy.findIndex((p) => p.id === item.id)
              copy[index] = item
              form.fields.products.onChange(copy)
            },
            handlePopoverChange: recProductsHandlePopoverChange,
            skipPopover: true,
          }}
        />
      </Card>
    </>
  )
}

export default ProductSelector
