import {
  Banner,
  BlockStack,
  Box,
  Button,
  InlineStack,
  Link,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import ContactUsLink from '../ContactUsLink'
import { areFeaturesEnabled } from '../../utils/features'
import { FEATURES } from '../../constants/features'

export default function UsageSurpassedBanner({
  show,
  dashboardData,
  condensed = false,
  onUpgrade = () => {},
  cta = null
}) {
  const { t } = useTranslation()
  const isLiveSupportEnabled = areFeaturesEnabled(
    [FEATURES.LIVE_SUPPORT],
    dashboardData,
  )

  if (!show) {
    return null
  }

  if (condensed) {
    return (
      <Banner tone="critical">
        <p>
          {t('SettingsPage.Sections.Account.usageSurpassedDescription')}{' '}
          {dashboardData?.shopPlan?.onAnnual ||
          dashboardData?.shopPlan?.isExtendedUsageEnabled ? (
            <Link onClick={onUpgrade}>
              {' '}
              {cta || t('SettingsPage.Sections.Account.upgradeBtnCta')}
            </Link>
          ) : (
            <ContactUsLink showLiveSupport={isLiveSupportEnabled} />
          )}
        </p>
      </Banner>
    )
  }
  return (
    <Banner
      tone="critical"
      title={t('SettingsPage.Sections.Account.usageSurpassedTitle')}
    >
      <BlockStack gap={'200'}>
        <p>{t('SettingsPage.Sections.Account.usageSurpassedDescription')}</p>
        <Box>
          {dashboardData?.shopPlan?.onAnnual ||
          dashboardData?.shopPlan?.isExtendedUsageEnabled ? (
            <Button variant="primary" onClick={onUpgrade}>
              {' '}
              {cta || t('SettingsPage.Sections.Account.upgradeBtnCta')}
            </Button>
          ) : (
            <ContactUsLink type="button" />
          )}
        </Box>
      </BlockStack>
    </Banner>
  )
}
