import { Button, Card, InlineGrid, Text, Tooltip } from "@shopify/polaris"
import { SettingsIcon } from "@shopify/polaris-icons"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

export default function RecommendationSettingCard({
    title,
    description,
    onClick,
    btnText,
    disabled,
  }) {
    const { t } = useTranslation()
    const Wrapper = disabled ? Tooltip : Fragment
    return (
      <Wrapper
        {...(disabled
          ? { content: disabled ? t('DefaultText.settingNotAvailable') : null }
          : {})}
      >
        <div
          style={{
            opacity: disabled ? 0.6 : 1,
            height: '100%',
          }}
        >
          <Card>
            <InlineGrid columns={'1fr auto'} alignItems="start">
              <div>
                <Text as="strong">{title}</Text>
                <Text>{description}</Text>
              </div>
              <Button onClick={onClick} disabled={disabled} icon={SettingsIcon}>
                {btnText}
              </Button>
            </InlineGrid>
          </Card>
        </div>
      </Wrapper>
    )
  }