import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";

export function useRedirect() {
  const app = useAppBridge();
  const redirect = Redirect.create(app);
  function redirectToLink({ external = false, url }) {
    if (!external) {
      redirect.dispatch(Redirect.Action.APP, url);
    } else {
      redirect.dispatch(Redirect.Action.REMOTE, url);
    }
  }
  return {
    redirectToLink,
    redirect,
    app,
  };
}