import {
  BlockStack,
  Card,
  Layout,
  Link,
  Select,
  SkeletonDisplayText,
  Text,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export default function BasicDetailsSection({ form, templateOptions, templateLoading }) {
  const { t } = useTranslation()

  return (
    <Card>
      <BlockStack gap={'100'}>
        <Text variant="headingSm">{t('BundlesUpsert.Heading.details')}</Text>
        <BlockStack gap={'300'}>
          <TextField
            {...{
              label: t('BundlesUpsert.BasicDetails.form.title.label'),
              ...form.fields.title,
              type: 'text',
              placeholder: t('BundlesUpsert.BasicDetails.form.title.label'),
            }}
          />
          {templateLoading ? (
            <SkeletonDisplayText maxWidth="100%" />
          ) : (
            <Select
              options={templateOptions}
              {...form.fields.shopTemplate}
              label={t('BundlesUpsert.BasicDetails.form.shopTemplate.label')}
              placeholder={t(
                'BundlesUpsert.BasicDetails.form.template.placeholder',
              )}
              helpText={t(
                'BundlesUpsert.BasicDetails.form.shopTemplate.helpText',
                {
                  link: (
                    <Link url="/settings/templates/create">
                      {t('BundlesUpsert.BasicDetails.form.shopTemplate.cta')}
                    </Link>
                  ),
                },
              )}
            />
          )}
          {/* <TextField
            {...{
              label: t('BundlesUpsert.BasicDetails.form.description.label'),
              ...form.fields.description,
              type: 'text',
              placeholder: t(
                'BundlesUpsert.BasicDetails.form.description.label',
              ),
              multiline: 3,
            }}
          /> */}
        </BlockStack>
      </BlockStack>
    </Card>
  )
}
