import getApiClient from '.'

export async function getIntegrations() {
  try {
    const client = await getApiClient()
    const res = await client.get('/api/integration')
    return {
      data: res.data.integrations,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data ?? e.message,
    }
  }
}

export async function updateIntegration(data) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/integration', data)
    return {
      data: res.data.integrations,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data ?? e.message,
    }
  }
}