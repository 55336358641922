import React, { useCallback, useEffect, useState } from 'react'
import {
  BlockStack,
  ChoiceList,
  Filters,
} from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import MetricsCards from './MetricsCards/index.jsx'
import SectionUsageChart from './SectionUsageChart/index.jsx'
import RevenueChart from './RevenueChart/index.jsx'
import { WIDGET_TYPES } from '../../../../constants/widgets/index.js'
import { PAGE_TYPES } from '../../../../constants/page.js'
import { getCharts, getStats } from '../../../../apis/analytics.js'
import { useShopDetails } from '../../../../hooks/useShopDetails.js'
import { useLocalSettings } from '../../../../components/LocalSettings'
import { currencyFormatter } from '../../../../utils/formater.js'
import SectionPerformanceChart from './SectionPerformanceChart/index.jsx'


function AnalyticsSections() {
  const { t } = useTranslation()
  const { data: shopData, isLoading: shopLoading } = useShopDetails()
  const { settings, saveSettings } = useLocalSettings()
  const [durationFilter, setDurationFilter] = useState(settings.durationFilter)

  const [sectionFilter, setSectionFilter] = useState(undefined)
  const [pageFilter, setPageFilter] = useState(undefined)

  const handleDurationFilterChange = useCallback(
    (value) => setDurationFilter(value),
    [],
  )
  const handleSectionFilterChange = useCallback(
    (value) => setSectionFilter(value),
    [],
  )
  const handlePageFilterChange = useCallback(
    (value) => setPageFilter(value),
    [],
  )

  const handleDurationFilterRemove = useCallback(
    () => setDurationFilter(undefined),
    [],
  )
  const handleSectionFilterRemove = useCallback(
    () => setSectionFilter(undefined),
    [],
  )
  const handlePageFilterRemove = useCallback(() => setPageFilter(undefined), [])
  const handleFiltersClearAll = useCallback(() => {
    handleSectionFilterRemove()
    handleDurationFilterRemove()
    handlePageFilterRemove()
  }, [
    handleSectionFilterRemove,
    handleDurationFilterRemove,
    handlePageFilterRemove,
  ])

  const shopSections = shopData?.sections ?? []
  const shopPages = shopData?.pages ?? []

  useEffect(() => {
    saveSettings({
      ...settings,
      durationFilter,
    })
  }, [durationFilter])

  const filters = [
    {
      key: 'section',
      label: t('Analytics.Sections.Filter.section'),
      filter: (
        <ChoiceList
          title="Section"
          titleHidden
          disabled={shopLoading}
          choices={[
            {
              label: 'All Sections',
              value: 'all',
            },
            ...Object.entries(WIDGET_TYPES).map(([key, obj]) => ({
              label: obj.label,
              value: key,
            })),
          ]}
          selected={sectionFilter || []}
          onChange={handleSectionFilterChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'page',
      label: t('Analytics.Sections.Filter.page'),
      filter: (
        <ChoiceList
          title="Page"
          titleHidden
          disabled={shopLoading}
          choices={[
            {
              label: 'All Pages',
              value: 'all',
            },
            ...Object.values(PAGE_TYPES).map((page) => ({
              label: t(`Pages.${page.value}.label`),
              value: page.value,
            })),
          ]}
          selected={pageFilter || []}
          onChange={handlePageFilterChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ]

  const appliedFilters = []

  if (!isEmpty(sectionFilter)) {
    const key = 'section'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sectionFilter),
      onRemove: handleSectionFilterRemove,
    })
  }

  if (!isEmpty(pageFilter)) {
    const key = 'page'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, pageFilter),
      onRemove: handlePageFilterRemove,
    })
  }

  const pages = (pageFilter ?? [])
    .filter((page) => page != 'all')
    .map((page) => shopPages.find((p) => p.type === page))
    .filter((p) => Boolean(p))
    .map((p) => p.id)

  const statsQuery = useQuery({
    queryKey: ['stats', appliedFilters, durationFilter],
    queryFn: async () => {
      const sections = (sectionFilter ?? [])
        .map((section) =>
          shopSections.filter((s) => s.type === section).map((s) => s.id),
        )
        .flat()
        .filter((s) => Boolean(s))
      const { data, error } = await getStats({
        sections: sections.length > 0 ? sections : undefined,
        pages: pages.length > 0 ? pages : undefined,
        dateGte: new Date(durationFilter.start).valueOf() / 1000,
        dateLte: new Date(durationFilter.end).valueOf() / 1000,
      })
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const chartsQuery = useQuery({
    queryKey: ['charts', appliedFilters, durationFilter],
    queryFn: async () => {
      const sections = (sectionFilter ?? [])
        .map((section) =>
          shopSections.filter((s) => s.type === section).map((s) => s.id),
        )
        .flat()
        .filter((s) => Boolean(s))
      const { data, error } = await getCharts({
        sections: sections.length > 0 ? sections : undefined,
        pages: pages.length > 0 ? pages : undefined,
        dateGte: new Date(durationFilter.start).valueOf(),
        dateLte: new Date(durationFilter.end).valueOf(),
      })
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const aggregates = chartsQuery.data?.aggregates ?? []

  const [activeIdx, setActiveIdx] = useState(0)

  const TABS = [
    {
      content: t('Analytics.Sections.Charts.Revenue.Tabs.revenue'),
      id: 'revenue',
    },
    {
      content: t('Analytics.Sections.Charts.Revenue.Tabs.quantity'),
      id: 'quantity',
    },
  ]

  const revenueChartSeriesObject = geChartSeriesObject(aggregates, t)

  return (
    <BlockStack gap={'200'}>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        onClearAll={handleFiltersClearAll}
        hideQueryField
      />
      <MetricsCards
        loading={statsQuery.isLoading}
        metrics={statsQuery.data?.tiles ?? []}
        durationFilter={durationFilter}
        onDurationFilterChange={handleDurationFilterChange}
      />
      {Object.values(revenueChartSeriesObject).map((_, idx) => (
        <>
          {idx === activeIdx ? (
            <RevenueChart
              tabs={TABS}
              activeIdx={activeIdx}
              setActiveIdx={setActiveIdx}
              loading={chartsQuery.isLoading}
              data={revenueChartSeriesObject[TABS[activeIdx].id]}
            />
          ) : null}
        </>
      ))}
      <SectionUsageChart
        data={{
          labels: aggregates.map((agg) =>
            format(new Date(agg.date), 'dd LLL yy'),
          ),
          datasets: [
            {
              label: t('Analytics.Sections.Charts.Labels.pageViews'),
              data: aggregates.map((agg) => agg.ppv),
            },
            {
              label: t('Analytics.Sections.Charts.Labels.sectionServed'),
              data: aggregates.map((agg) => agg.served),
            },
          ],
        }}
      />
      <SectionPerformanceChart
        data={{
          labels: aggregates.map((agg) =>
            format(new Date(agg.date), 'dd LLL yy'),
          ),
          datasets: [
            {
              label: t('Analytics.Sections.Charts.Labels.viewRate'),
              data: aggregates.map((agg) => agg.viewRate * 100),
            },
            {
              label: t(
                'DashboardPage.PerformanceSection.cards.clickThroughRate.title',
              ),
              data: aggregates.map((agg) => agg.clickRate * 100),
            },
          ],
        }}
      />
    </BlockStack>
  )

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'section':
        return (
          'Section: ' +
          value
            ?.map(
              (val) =>
                `${
                  WIDGET_TYPES[val]?.label ??
                  val?.[0]?.toUpperCase() + val?.slice(1)
                }`,
            )
            .join(', ')
        )
      case 'duration':
        return value?.start?.toDateString() + ' - ' + value?.end?.toDateString()
      case 'page':
        return (
          'Page: ' +
          value
            ?.map(
              (val) =>
                `${
                  PAGE_TYPES[val]?.label ??
                  val?.[0]?.toUpperCase() + val?.slice(1)
                }`,
            )
            .join(', ')
        )
      default:
        return value
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0
    } else {
      return value === '' || value == null
    }
  }
}

export default AnalyticsSections

function geChartSeriesObject(aggregates, t) {
  return {
    revenue: {
      labels: aggregates.map((agg) => format(new Date(agg.date), 'dd LLL yy')),
      datasets: [
        {
          label: t('Analytics.Sections.Charts.Labels.attributedRevenue'),
          borderColor: '#F7DF00',
          data: aggregates.map((agg) => agg.revenue + agg.nativeRevenueByRk),
        },
        {
          label: t('Analytics.Sections.Charts.Labels.totalRevenue'),
          borderColor: '#f68DF6',
          data: aggregates.map(
            (agg) => agg.nativeRevenue + agg.nativeRevenue,
          ),
        },
      ],
      formatter: (value) => currencyFormatter(window.shopify.data.shop.currency, value),
    },
    quantity: {
      labels: aggregates.map((agg) => format(new Date(agg.date), 'dd LLL yy')),
      datasets: [
        {
          label: t('Analytics.Sections.Charts.Labels.attributedQuantity'),
          borderColor: '#11DF7B',
          data: aggregates.map(
            (agg) => agg.productsSoldCount + agg.productsSoldCountByRk,
          ),
        },
        {
          label: t('Analytics.Sections.Charts.Labels.totalQuantity'),
          borderColor: '#AA95FF',
          data: aggregates.map((agg) => agg.productsSoldCount),
        },
      ],
    },
  }
}
