import { useQuery } from 'react-query';

import { listBundles } from '../../../apis/bundles';

export function useListBundles({
    enabled = true,
} = {}){
    const query = useQuery({
        queryKey: ['bundles'],
        queryFn: async () => {
            const {data,error} = await listBundles();
            if(error){
                return Promise.reject(error);
            }
            return data;
        },
        enabled
    })

    return {
        bundles: query.data?.bundles ?? [],
        isLoading: query.isLoading || query.isFetching,
        error: query.error,
        refetch: query.refetch
    }
}