import React from 'react'
import { useTranslation } from 'react-i18next'
import { BlockStack, Card, Select, Text } from '@shopify/polaris'

import { CAMPAIGN_STATUSES } from '../../../../../../../../constants/checkoutUpsell'

export default function CampaignStatusSelector({ form }) {
  const { t } = useTranslation()
  const status = form.fields.status
  const handleChange = (value) => {
    status.onChange(value)
  }

  const options = [
    {
      label: t('CheckoutUpsell.CampaignDetail.status.active'),
      value: CAMPAIGN_STATUSES.ACTIVE,
    },
    {
      label: t('CheckoutUpsell.CampaignDetail.status.paused'),
      value: CAMPAIGN_STATUSES.PAUSED,
    },
    {
      label: t('CheckoutUpsell.CampaignDetail.status.draft'),
      value: CAMPAIGN_STATUSES.DRAFT,
    },
  ]

  return (
    <Card>
      <BlockStack gap={'100'}>
        <Text>{t('CheckoutUpsell.CampaignDetail.status.changeStatus')}</Text>
        <Select
          value={status.value}
          onChange={handleChange}
          options={options}
        />
      </BlockStack>
    </Card>
  )
}
