import React from 'react'
import { useForm, useField, notEmpty } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'
import {
  BlockStack,
  Box,
  Button,
  Card,
  Form,
  FormLayout,
  InlineError,
  InlineStack,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'

import CustomModal from '../CustomModal'

export default function FeedbackModal({
  open,
  setOpen,
  onSubmit,
  skipModal = false,
}) {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const form = useForm({
    fields: {
      rating: useField({
        value: null,
        validates: [notEmpty(t('FeedbackModal.form.rating.required'))],
      }),
      message: useField({
        value: '',
        validates: [notEmpty(t('FeedbackModal.form.message.required'))],
      }),
    },
    onSubmit,
  })

  const content = (
    <Form onSubmit={form.submit}>
      <FormLayout>
        <FormLayout>
          <BlockStack gap={'300'}>
            <Text as="h2" variant="headingMd">
              {t('FeedbackModal.form.rating.label')}
            </Text>
            <InlineStack gap={'150'} align={'start'} blockAlign="center">
              <Text as="strong">{t('FeedbackModal.form.rating.good')}</Text>
              {Array.from({ length: 10 }).map((_, index) => (
                <div
                  onClick={() => form.fields.rating.onChange(index + 1)}
                  style={{
                    padding: breakpoints.smUp ?'var(--p-space-200)' : 'var(--p-space-100)',
                    borderRadius: '50%',
                    backgroundColor:
                      form.fields.rating.value === index + 1
                        ? '#003b4c'
                        : 'var(--p-color-bg-surface-tertiary-active)',
                    color:
                      form.fields.rating.value === index + 1
                        ? 'var(--p-color-bg-fill)'
                        : 'var(--p-color-bg-fill-brand-hover)',
                    cursor: 'pointer',
                    width: breakpoints.smUp ? 36 : 24,
                    height: breakpoints.smUp ? 36: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {index + 1}
                </div>
              ))}
              <Text as="strong">{t('FeedbackModal.form.rating.love')}</Text>
            </InlineStack>
            {form.fields.rating.error && (
              <InlineError message={form.fields.rating.error} />
            )}
          </BlockStack>
        </FormLayout>
        <FormLayout>
          <TextField
            multiline={2}
            label={t('FeedbackModal.form.message.label')}
            placeholder={t('FeedbackModal.form.message.placeholder')}
            {...form.fields.message}
          />
        </FormLayout>
      </FormLayout>
      <div
        style={{
          height: 'var(--p-space-200)',
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'end',
        }}
      />
      <button
        type="submit"
        style={{
          background: '#F47A60',
          border: 'none',
          padding: 'var(--p-space-200) var(--p-space-300)',
          borderRadius: 'var(--p-border-radius-200)',
          color: 'white',
          fontSize: 'var(--p-font-size-200)',
          cursor: form.submitting ? 'progress' : 'pointer',
          opacity: form.submitting ? 0.5 : 1,
        }}
        disabled={form.submitting}
      >
        {t('FeedbackModal.submitCta')}
      </button>
    </Form>
  )

  if (skipModal) {
    return (
      <Card padding={'0'}>
        <BlockStack>
          <div
            style={{
              paddingLeft: 'var(--p-space-400)',
              paddingRight: 'var(--p-space-400)',
              paddingTop: 'var(--p-space-300)',
              paddingBottom: 'var(--p-space-300)',
              background: '#F47A60',
              color: 'white',
            }}
          >
            <Text variant="headingMd">{t('FeedbackModal.title')}</Text>
          </div>
          <div
            style={{
              padding: 'var(--p-space-400)',
              border: '1px solid #F47A60',
              borderTop: 'none',
              borderBottomLeftRadius: 'var(--p-border-radius-300)',
              borderBottomRightRadius: 'var(--p-border-radius-300)',
            }}
          >
            {content}
          </div>
        </BlockStack>
      </Card>
    )
  }

  return (
    <CustomModal
      variant="medium"
      show={open}
      setShow={setOpen}
      title={t('FeedbackModal.title')}
      maxWidth={'700px'}
      overlayZIndex={1000}
    >
      <Box padding={'400'}>{content}</Box>
    </CustomModal>
  )
}
