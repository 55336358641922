import {
  Banner,
  BlockStack,
  Box,
  Card,
  Layout,
  Link,
  SkeletonBodyText,
} from '@shopify/polaris'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { getProductRecommendationList } from '../../apis/recommendation'
import CustomModal from '../CustomModal'
import ProductRecommendationTable from '../ProductRecommendationTable'
import { CREATION_TYPE } from '../../constants/recommendations'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  SIMILAR_PRODUCTS,
} from '../../constants/widgets'
import GlobalRecommendation from '../../container/Recommendation/GlobalRecommendation'
import ProductRecommendationEdit from '../../container/Recommendation/ProductRecommendation/Details'
import RuleBasedRecommendation from '../../container/Recommendation/RuleBasedRecommendation'
import useProductList from '../../hooks/useProductList'
import { getProducts } from '../../apis/products'

export default function RecommendationSettingsModal({
  setting,
  dispatcher,
  widgetType,
  sectionId,
  currency = ''
}) {
  const { t } = useTranslation()

  const ComponentMap = useMemo(
    () => ({
      ruleBasedRecommendation: RuleBasedRecommendation,
      globalRecommendation: GlobalRecommendation,
      manualRecommendation: OnetoOneRecommendation,
    }),
    [],
  )

  const Component = ComponentMap[setting] ?? Fragment

  return (
    <CustomModal
      show={Boolean(setting)}
      setShow={() => {
        dispatcher(null)
      }}
      variant="large"
      title={t(`Recommendation.${setting}.title`)}
    >
      <Box padding={'400'}>
        <BlockStack gap={'400'}>
          <Component
            widgetId={sectionId}
            skipPage={true}
            widgetType={widgetType}
            currency={currency}
          />
        </BlockStack>
      </Box>
    </CustomModal>
  )
}

function OnetoOneRecommendation({ widgetType, currency }) {
  const [searchQuery, setSearchQuery] = useState('')
  const [activeItemId, setActiveItemId] = useState(null)
  const {
    products,
    isLoading: productsLoading,
    pagination,
  } = useProductList({
    widgetType,
    searchQuery,
    maxItems: 5, // This is to fetch product in one page
    key: [activeItemId],
  })
  const query = useQuery({
    queryKey: ['productRecommendation', { widgetType }, products, activeItemId],
    queryFn: async ({ queryKey }) => {
      const widgetType = queryKey[1].widgetType
      const products = queryKey[2]
      const productIds = products.map(({ id }) => id)
      const res = await getProductRecommendationList({
        widgetTypes: [widgetType],
        products: productIds,
      })
      if (res.error) return Promise.reject(res.error)
      const { recommendations } = res.data
      const processed = recommendations.map(({ product, recommendations }) => ({
        product: products.find(({ id }) =>
          id.toString().includes(product.toString()),
        ),
        similarProducts: (
          recommendations.filter(
            ({ recommendationType }) =>
              recommendationType === SIMILAR_PRODUCTS.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType }) => ({
          product: typeof recommendedProduct == 'string' ? parseInt(recommendedProduct) : recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
        })),
        frequentlyBoughtTogether: (
          recommendations.filter(
            ({ recommendationType }) =>
              recommendationType === BOUGHT_TOGETHER.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType }) => ({
          product: typeof recommendedProduct == 'string' ? parseInt(recommendedProduct) : recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
        })),
        crossSell: (
          recommendations.filter(
            ({ recommendationType }) => recommendationType === CROSS_SELL.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType }) => ({
          product: typeof recommendedProduct == 'string' ? parseInt(recommendedProduct) : recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
        })),
      }))

      const populated = await Promise.all(
        processed.map(async (rec) => {
          let similarProductsIds = rec.similarProducts.map(({ product }) =>
              typeof product === 'string' ? parseInt(product) : product,
            ),
            crossSellIds = rec.crossSell.map(({ product }) =>
              typeof product === 'string' ? parseInt(product) : product,
            ),
            frequentlyBoughtTogetherIds = rec.frequentlyBoughtTogether.map(
              ({ product }) =>
                typeof product === 'string' ? parseInt(product) : product,
            )

          const similarProducts = (
            await getProducts(similarProductsIds)
          ).data.products.map((product) => ({
            id: product.id,
            images: [product?.image?.src],
            title: product.title,
            vendor: product.vendor,
            handle: product.handle,
            aiGenerated: Boolean(
              rec.similarProducts.find((r) => r.product == product.id)
                ?.aiGenerated,
            ),
            productType: product.productType,
            price: getPriceStringFromVariants(product.variants, currency),
          }))
          const crossSell = (await getProducts(crossSellIds)).data.products.map(
            (product) => ({
              id: product.id,
              images: [product?.image?.src],
              title: product.title,
              vendor: product.vendor,
              handle: product.handle,
              aiGenerated: Boolean(
                rec.crossSell.find((r) => r.product == product.id)?.aiGenerated,
              ),
              productType: product.productType,
              price: getPriceStringFromVariants(product.variants, currency),
            }),
          )
          const frequentlyBoughtTogether = (
            await getProducts(frequentlyBoughtTogetherIds)
          ).data.products.map((product) => ({
            id: product.id,
            images: [product?.image?.src],
            title: product.title,
            vendor: product.vendor,
            handle: product.handle,
            aiGenerated: Boolean(
              rec.frequentlyBoughtTogether.find((r) => r.product == product.id)
                ?.aiGenerated,
            ),
            productType: product.productType,
            price: getPriceStringFromVariants(product.variants, currency),
          }))
          return {
            product: rec.product.id,
            similarProducts,
            crossSell,
            frequentlyBoughtTogether,
          }
        }),
      )
      return populated
    },
    refetchOnMount: true,
    staleTime: 0,
    enabled: products.length > 0,
  })
  const recommendations = query.data ?? []
  const recommendationsMap = recommendations.reduce((acc, rec) => {
    acc[rec.product] = {
      similarProducts: rec.similarProducts ?? [],
      crossSell: rec.crossSell ?? [],
      frequentlyBoughtTogether: rec.frequentlyBoughtTogether ?? [],
    }
    return acc
  }, {})
  const { t } = useTranslation()

  if (activeItemId && (query.isLoading || productsLoading)) {
    return (
      <Layout>
        <Layout.Section>
          <BlockStack gap={'300'}>
            <Card>
              <SkeletonBodyText />
            </Card>
            <Card>
              <SkeletonBodyText />
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    )
  }

  if (query.error) {
    return null
  }

  if (activeItemId)
    return (
      <ProductRecommendationEdit
        productId={activeItemId}
        widgetType={widgetType}
        onPageBack={() => setActiveItemId(null)}
        embedded={true}
        skipPage={true}
        hideOtherWidgets={true}
        title={t('RecommendationPage.ProductRecommendation.editTitleTemplate', {
          widgetType: t(`Widgets.${widgetType}.label`),
        })}
      />
    )

  return (
    <Box>
      <BlockStack gap={'300'}>
        <Banner>
          <p>
            {t(
              'RecommendationPage.ProductRecommendation.moreConfigBannerText',
              {
                link: (
                  <Link url={`/settings/recommendation/${widgetType}/product`}>
                    This Page
                  </Link>
                ),
              },
            )}
          </p>
        </Banner>
        <ProductRecommendationTable
          widgetType={widgetType}
          products={products.map((product) => ({
            ...(recommendationsMap[product.id] ?? {
              similarProducts: [],
              crossSell: [],
              frequentlyBoughtTogether: [],
            }),
            product: {
              ...product,
              id: product.id.toString(),
            },
          }))}
          isLoading={productsLoading}
          pagination={pagination}
          searchQueryState={[searchQuery, setSearchQuery]}
          recFetching={query.isLoading || query.isRefetching}
          productCorrespondingActions={products
            ?.map((item) => ({
              [item.id]: {
                onClick: () => {
                  const id = item.id
                  setActiveItemId(id)
                },
              },
            }))
            .reduce(
              (p, c) => ({ ...p, [Object.keys(c)[0]]: Object.values(c)[0] }),
              {},
            )}
        />
        <Box padding={'300'} />
      </BlockStack>
    </Box>
  )
}

function getPriceStringFromVariants(variants, currency) {
  let arr = variants
    .map((v) => v.price)
    .map((v) => parseFloat(v))
    .sort((a, b) => a - b)
  arr = [...new Set(arr)]

  if (arr.length === 1) return currency.replace('{{amount}}', arr[0])

  return `${currency.replace('{{amount}}', arr[0])} - ${currency.replace(arr[arr.length - 1])}`
}
