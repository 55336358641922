import React, { Fragment, useCallback, useId, useRef } from 'react'
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  Link,
  SkeletonBodyText,
  SkeletonDisplayText,
  Spinner,
  Tag,
  Text,
} from '@shopify/polaris'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DragHandleIcon,
  XIcon,
} from '@shopify/polaris-icons'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

import ProductImage from '../ProductImage'
import { useTranslation } from 'react-i18next'

function ProductSequenceEdit({
  items = [],
  onChange,
  onTitleClick,
  loading = false,
  emptyText = null,
  showSkeleton = false,
}) {
  const { t } = useTranslation()
  let aiGeneratedIndex = items.findIndex((item) => Boolean(item.aiGenerated))
  emptyText = emptyText ?? t('noProductsSelected')
  const handleDragEnd = useCallback(({ source, destination }) => {
    if (
      !destination ||
      (aiGeneratedIndex != -1 && destination.index >= aiGeneratedIndex)
    )
      return
    const newItems = items.slice()
    const [temp] = newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, temp)
    onChange(newItems)
  }, [])

  if (showSkeleton) {
    return <Skeleton />
  }

  if (items.length === 0) {
    return (
      <>
        <Divider />
        <Box padding={'400'} background="bg-fill">
          <Text as="strong" tone="subdued">
            {emptyText}
          </Text>
        </Box>
        <Divider />
      </>
    )
  }

  return (
    <BlockStack>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable isDropDisabled={loading} droppableId="root">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Fragment key={index}>
                    {index === 0 && <Divider />}
                    <ProductCard
                      key={index}
                      product={item}
                      index={index}
                      onRemove={(removed) => {
                        onChange(items.filter((item) => item.id !== removed.id))
                      }}
                      onTitleClick={onTitleClick}
                      loading={loading}
                    />
                    {index !== items.length - 1 && <Divider />}
                  </Fragment>
                ))}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    </BlockStack>
  )
}

export default ProductSequenceEdit

function ProductCard({ product, index, onRemove, onTitleClick, loading }) {
  const id = useId()
  const [collapsed, setCollapsed] = React.useState(true)
  const { t } = useTranslation()
  const titleClickHandler =
    typeof onTitleClick === 'function' ? () => onTitleClick(product) : undefined
  const tags = product.tags ?? []
  const productType = product.productType

  const imageSrc =
    product.images?.[0]?.originalSrc ??
    product.images?.[0] ??
    '/assets/collection-placeholder.webp'

  return (
    <Draggable
      isDragDisabled={loading || product.aiGenerated}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot, rubric) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={
              snapshot.isDragging
                ? { background: 'white', ...provided.draggableProps.style }
                : provided.draggableProps.style
            }
          >
            <div
              id={id}
              style={{
                padding:
                  'var(--p-space-200) var(--p-space-400) var(--p-space-200) var(--p-space-400)',
              }}
            >
              <InlineGrid
                alignItems="center"
                gap={'400'}
                columns={'auto auto auto 1fr auto auto auto'}
              >
                {!product.aiGenerated ? (
                  <div style={{}} {...provided.dragHandleProps}>
                    <Icon source={DragHandleIcon} />
                  </div>
                ) : (
                  <div />
                )}
                <div />
                <ProductImage
                  src={imageSrc}
                  style={{
                    width: 40,
                    height: 60,
                  }}
                />
                <div>
                  <InlineStack gap={'200'}>
                    {titleClickHandler ? (
                      <Link monochrome onClick={titleClickHandler}>
                        <Text as="strong">{product.title}</Text>
                      </Link>
                    ) : (
                      <Text as="strong">{product.title}</Text>
                    )}
                    <Badge tone="magic">
                      ID:{' '}
                      {typeof product.id === 'string'
                        ? product.id.replace('gid://shopify/Product/', '')
                        : product.id}
                    </Badge>
                    {product.aiGenerated && (
                      <Badge tone="warning">
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          AI Generated
                        </span>
                      </Badge>
                    )}
                  </InlineStack>
                  <Collapsible open={!collapsed}>
                    <Box padding={'050'} />
                    <InlineGrid columns={'1fr auto'} alignItems="start">
                      <BlockStack gap={'100'}>
                        <Text variant="bodyXs">
                          Product Type: {productType}
                        </Text>
                        <Text variant="bodyXs">Handle: {product.handle}</Text>
                        <Text variant="bodyXs">Vendor: {product.vendor}</Text>
                      </BlockStack>
                    </InlineGrid>
                    <Box padding={'100'} />
                    <InlineStack gap={'100'}>
                      {tags.map((tag) => (
                        <Tag key={tag}>{tag}</Tag>
                      ))}
                    </InlineStack>
                  </Collapsible>
                </div>
                <Button
                  icon={collapsed ? ChevronDownIcon : ChevronUpIcon}
                  variant="plain"
                  onClick={() => {
                    setCollapsed(!collapsed)
                  }}
                />
                {!product.aiGenerated && (
                  <>
                    <Button
                      icon={XIcon}
                      variant="plain"
                      onClick={() => {
                        onRemove(product)
                      }}
                      disabled={loading}
                    />
                  </>
                )}
              </InlineGrid>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

function Skeleton() {
  const dummyArrr = new Array(5).fill(0)
  return (
    <BlockStack>
      {dummyArrr.map((_, index) => (
        <>
          <div
            style={{
              padding:
                'var(--p-space-200) var(--p-space-400) var(--p-space-200) var(--p-space-400)',
            }}
          >
            <InlineGrid
              alignItems="center"
              gap={'400'}
              columns={'auto 1fr'}
            >
              <div
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 'var(--p-space-200)',
                  backgroundColor: 'var(--p-color-bg-surface-hover)',
                }}
              />
              <div>
                <BlockStack gap={'200'}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </BlockStack>
              </div>
            </InlineGrid>
          </div>
          {index + 1 !== dummyArrr.length && <Divider />}
        </>
      ))}
    </BlockStack>
  )
}
