import {
  Badge,
  BlockStack,
  Box,
  Button,
  InlineStack,
  Page,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { LockIcon, PlusIcon } from '@shopify/polaris-icons'

import WidgetTabChart from '../../components/WidgetTabChart'
import {
  getCheckoutUpsellAnalytics,
  getCheckoutUpsellConfig,
} from '../../apis/checkoutUpsell'
import CampaignManager from './components/CampaignManager'
import { PricingModal, usePricing } from '../../components/PricingModal'
import { areFeaturesEnabled } from '../../utils/features'
import { FEATURES } from '../../constants/features'
import { useLocalSettings } from '../../components/LocalSettings'
import DateRangePopover from '../../components/DateTimeRangePopover'
import { numberFormater } from '../../utils/formater'

export default function CheckoutUpsell() {
  const { t } = useTranslation()
  const { settings, saveSettings } = useLocalSettings()
  const [durationFilter, setDurationFilter] = useState(settings.durationFilter)

  const handleDurationFilterChange = useCallback(
    (value) => setDurationFilter(value),
    [],
  )

  const featureEnabled = areFeaturesEnabled(
    [FEATURES.CHECKOUT_SECTIONS], // change to actual feature key
    window.shopify.data,
  )

  const pricing = usePricing()
  const analyticsQuery = useQuery({
    queryKey: ['checkoutUpsellAnalytics', durationFilter],
    queryFn: async () => {
      const { data, error } = await getCheckoutUpsellAnalytics({
        gtle: durationFilter.start.valueOf() / 1000,
        lte: durationFilter.end.valueOf() / 1000,
      })

      if (error) {
        return Promise.reject(error)
      }

      return data
    },
  })

  const configQuery = useQuery({
    queryKey: ['checkoutUpsellConfig'],
    queryFn: async () => {
      const { data, error } = await getCheckoutUpsellConfig()
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const [status, setStatus] = useState(null) // initially null to avoid flickering
  const breakpoints = useBreakpoints()
  const analyticsAggregates = analyticsQuery.data ?? []

  const analyticsData = {
    options: Object.keys(CHART_KEYS).map((key) => ({
      label: t(`CheckoutUpsell.Chart.${key}.title`),
      value: formateAnalyticsValue(
        CHART_KEYS[key].totalCalc(
          analyticsAggregates.map((aggregate) => aggregate[key]),
        ),
        CHART_KEYS[key].type,
      ),
      yAxisFormatter: (val) => formateAnalyticsValue(val, CHART_KEYS[key].type),
    })),
    stats: Object.keys(CHART_KEYS).map((key) => ({
      data: analyticsAggregates.map((aggregate) => ({
        key: format(new Date(aggregate.date), 'MMM dd'),
        value: aggregate[key],
      })),
      name: t(`CheckoutUpsell.Chart.${key}.title`),
      yAxisFormatter: (val) => formateAnalyticsValue(val, CHART_KEYS[key].type),
    })),
  }

  useEffect(() => {
    if (configQuery.data) {
      setStatus(configQuery.data.recommendation)
    }
  }, [configQuery.data])

  useEffect(() => {
    saveSettings({
      ...settings,
      durationFilter,
    })
  }, [durationFilter])

  return (
    <Page
      fullWidth
      backAction={{
        url: '/',
      }}
      title={t('CheckoutUpsell.title')}
      additionalMetadata={t('CheckoutUpsell.description')}
      titleMetadata={
        <InlineStack>
          <div
            style={{
              height: '100%',
              display: 'grid',
              alignItems: 'center',
            }}
          >
            {!featureEnabled && (
              <Button
                icon={LockIcon}
                variant="monochromePlain"
                onClick={() => {
                  pricing.open({
                    features: [FEATURES.CHECKOUT_SECTIONS], // this will show contact support
                  })
                }}
              />
            )}
          </div>
          {status && featureEnabled && (
            <Badge tone={status === 'disabled' ? 'info' : 'success-strong'}>
              {t(`CheckoutUpsell.CalloutCard.statusOptions.${status}`)}
            </Badge>
          )}
        </InlineStack>
      }
      primaryAction={
        <Button
          variant="primary"
          icon={featureEnabled ? PlusIcon : LockIcon}
          url={featureEnabled && '/checkout-upsell/create'}
          onClick={() => {
            if (!featureEnabled) {
              pricing.open({
                features: [FEATURES.CHECKOUT_SECTIONS],
              })
            }
          }}
        >
          {t('CheckoutUpsell.CampaignManager.btnCta')}
        </Button>
      }
      secondaryActions={[
        {
          content: t('CheckoutUpsell.CalloutCard.btnCta'),
          url: '/checkout-upsell/configure',
        },
      ]}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <BlockStack gap={'400'}>
          <WidgetTabChart
            dateFilter={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {' '}
                <DateRangePopover
                  durationFilter={durationFilter}
                  handleDurationFilterChange={handleDurationFilterChange}
                />
              </div>
            }
            loading={analyticsQuery.isLoading}
            loadingBlocks={4}
            stats={analyticsData.options}
            data={analyticsData.stats}
            columns={{
              xs: 3,
              sm: 4,
              md: 5,
              lg: 5,
            }}
          />
          <CampaignManager
            refresh={analyticsQuery.refetch}
            timeFilter={{
              gtle: durationFilter.start.valueOf() / 1000,
              lte: durationFilter.end.valueOf() / 1000,
            }}
          />
        </BlockStack>
      </Box>
      <PricingModal modal={pricing} />
    </Page>
  )
}

const CHART_KEYS = {
  aov: {
    type: 'currency',
    totalCalc: (data) => data.reduce((acc, curr) => acc + curr, 0),
  },
  attributedRevenue: {
    type: 'currency',
    totalCalc: (data) => data.reduce((acc, curr) => acc + curr, 0),
  },
  conversion: {
    type: 'percentage',
    totalCalc: (data) => {
      const val = data.reduce((acc, curr) => acc + curr, 0) / data.length
      return Boolean(val) ? val : 0
    },
  },
  orderPlaced: {
    type: 'number',
    totalCalc: (data) => data.reduce((acc, curr) => acc + curr, 0),
  },
}

function formateAnalyticsValue(value, type) {
  switch (type) {
    case 'currency':
      return `$${value}`
    case 'percentage':
      return `${value * 100}%`
    default:
      return typeof value === 'number' ? numberFormater(value) : value
  }
}
