import React from 'react'
import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  Layout,
  Text,
  Tooltip,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { triggerProductSync, triggerShopifySync } from '../../../apis/sync'
import { openChat } from '../../../components/ExternalScripts'
import useAppState from '../../../hooks/useAppState'
import { differnceInWords, hoursAgo } from '../../../utils/time'

function SettingsSection() {
  const { t } = useTranslation()
  const [pSyncLoading, setPSyncLoading] = React.useState(false)
  const { data = {}, isLoading } = useAppState()

  const productSync = data?.products?.lastProductSyncedAt
    ? new Date(data.products.lastProductSyncedAt)
    : null

  const handleProductSync = async () => {
    setPSyncLoading(true)
    await triggerProductSync()
    setPSyncLoading(false)
  }

  const toolTipText =
    productSync && (hoursAgo(productSync) < 6)
      ? t('SettingsPage.Sections.Setting.section3.waitCta', {
          duration: differnceInWords(productSync, new Date()),
        })
      : null

  const BtnWrapper = ({ children }) => {
    if (!toolTipText) return children

    return (
      <Tooltip content={toolTipText} active>
        {children}
      </Tooltip>
    )
  }

  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Setting.title')}
      description={t('SettingsPage.Sections.Setting.description')}
    >
      <Card padding={'0'}>
        <BlockStack>
          <Box padding={'400'}>
            <Text as="strong">
              {t('SettingsPage.Sections.Setting.section2.title')}
            </Text>
            <InlineGrid columns={'1fr auto'} gap={'100'} alignItems="center">
              <Text>
                {t('SettingsPage.Sections.Setting.section2.description')}
              </Text>
              <Button onClick={openChat}>
                {t('SettingsPage.Sections.Setting.section2.btnCta')}
              </Button>
            </InlineGrid>
          </Box>
          <Divider />
          <Box padding={'400'}>
            <Text as="strong">
              {t('SettingsPage.Sections.Setting.section3.title')}
            </Text>
            <InlineGrid columns={'1fr auto'} gap={'100'} alignItems="center">
              <Text>
                {t('SettingsPage.Sections.Setting.section3.description', {
                  time: productSync
                    ? format(productSync, 'dd/MM/yyyy HH:mm')
                    : ' -',
                })}
              </Text>
              <BtnWrapper>
                <Button
                  disabled={toolTipText}
                  onClick={handleProductSync}
                  loading={pSyncLoading || isLoading}
                >
                  {t('SettingsPage.Sections.Setting.section3.btnCta')}
                </Button>
              </BtnWrapper>
            </InlineGrid>
          </Box>
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default SettingsSection
