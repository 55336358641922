import {
  Avatar,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
} from '@shopify/polaris'
import { EditIcon } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const PAGE_SIZE = 5

function ObjectRecommendation({ objects, onClick }) {
  const { t } = useTranslation()
  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <InlineGrid columns={'1fr auto'}>
          <BlockStack gap={'100'}>
            <Text as="strong">
              {t('Section.Details.ObjectRecommendation.title')}
            </Text>
            <Text>{t('Section.Details.ObjectRecommendation.description')}</Text>
          </BlockStack>
          <div>
            <Button icon={EditIcon} onClick={onClick}>
              {t('Section.Details.ObjectRecommendation.editCta')}
            </Button>
          </div>
        </InlineGrid>
      </Box>
      <Divider />
      {objects.map((object, index) => (
        <>
          <ObjectTable products={object.products} url={object.url} />
          <Divider />
        </>
      ))}
    </Card>
  )
}

export default ObjectRecommendation

function ObjectTable({ products, url }) {
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  products = products.filter(
    (product) =>
      !query || product.title.toLowerCase().includes(query.toLowerCase()),
  )

  return (
    <>
      <Box padding={'400'}>
        <BlockStack gap={'200'}>
          <TextField
            label={t('Section.Details.ObjectRecommendation.urlLabel')}
            value={url}
            readOnly
          />
          <TextField
            placeholder={t('Section.Details.ObjectRecommendation.placeholder')}
            value={query}
            onChange={setQuery}
          />
        </BlockStack>
      </Box>
      <Divider />
      <ResourceList
        resourceName={{
          singular: 'product',
          plural: 'products',
        }}
        items={products.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)}
        renderItem={renderItem}
        resolveItemId={resolveItemIds}
        pagination={{
          hasPrevious: page > 1,
          hasNext: products.length > page * PAGE_SIZE,
          onPrevious: () => setPage((prev) => prev - 1),
          onNext: () => setPage((prev) => prev + 1),
        }}
        emptyState={
          <Box padding={'400'}>
            <InlineStack alignment="center">
              <Text>
                {t('Section.Details.ObjectRecommendation.emptyState')}
              </Text>
            </InlineStack>
          </Box>
        }
      />
    </>
  )
}

function renderItem(product, _, index) {
  const media = (
    <Avatar
      size="sm"
      name={product.title}
      source={
        product.images?.[0] ||
        'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081'
      }
    />
  )

  return (
    <ResourceItem id={product.id} media={media} sortOrder={index}>
      <BlockStack>
        <Text as="strong">{product.title}</Text>
        <Text>ID: {product.id}</Text>
      </BlockStack>
    </ResourceItem>
  )
}

function resolveItemIds({ id }) {
  return id
}
