import React from 'react'
import { Helmet } from 'react-helmet'
import Intercom, { show, onHide, onShow, hide } from '@intercom/messenger-js-sdk'

import { useDashboardDetails } from '../../hooks/useDashboardDetails'
import { areFeaturesEnabled } from '../../utils/features'
import { FEATURES } from '../../constants/features'
import { usePricing } from '../PricingModal'

export default function ExternalScripts() {
  const { data: dashbaordData, isLoading: dashboardDataLoading } =
    useDashboardDetails()
  const planQuery = usePricing()

  const chatSupportEnabled = areFeaturesEnabled(
    [FEATURES.LIVE_SUPPORT],
    dashbaordData,
  )
  if (chatSupportEnabled && !dashboardDataLoading && !planQuery.isLoading) {
    const currentPlan = planQuery.plans.find(
      (plan) => plan.id === dashbaordData.shopPlan.plan,
    )
    const settings = {
      app_id: 's2m1k3bl',
      user_id: window.shopify.data.user?.id || null,
      name:
        (window.shopify.data.user?.firstName || '') +
        ' ' +
        (window.shopify.data.user?.lastName || ''),
      email: window.shopify.data.user?.email || null,
      created_at: window.shopify.data.user?.createdAt || null,
      hide_default_launcher: true,
      company: {
        company_id: dashbaordData.shop?.myshopifyDomain || null,
        created_at: dashbaordData.shop?.createdAt
          ? new Date(dashbaordData.shop.createdAt)
          : null,
        name: dashbaordData.shop?.name || null,
        plan: currentPlan?.name || null,
        website: dashbaordData.shop?.domain || null,
        country: dashbaordData.shop?.country || null,
        currency: dashbaordData.shop?.currency || null,
        city: dashbaordData.shop?.city || null,
        locale: dashbaordData.shop?.primaryLocale || null,
        isEnabled: dashbaordData.shop?.isEnabled || null,
        shopifyPlan: dashbaordData.shop?.shopifyPLan || null,
        monthly_spend: currentPlan?.price
          ? parseFloat(currentPlan.price)
          : null,
      },
    }
    Intercom(settings)
    onShow(() => {
      if (window.gloodExtra) {
        window.gloodExtra.intercomState = 'open'
      } else {
        window.gloodExtra = { intercomState: 'open' }
      }
    })
    onHide(() => {
      if (window.gloodExtra) {
        window.gloodExtra.intercomState = 'close'
      } else {
        window.gloodExtra = { intercomState: 'close' }
      }
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        {/* Start of Google Analytics Embed Code  */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-N222BV6R9S"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          
          gtag('config', 'G-N222BV6R9S');`}
        </script>
        {/* End of Google Analytics Embed Code  */}
      </Helmet>
    </React.Fragment>
  )
}

export function openChat() {
  const isFeatureEnabled = areFeaturesEnabled(
    [FEATURES.LIVE_SUPPORT],
    window.shopify.data,
  )

  if (!isFeatureEnabled) {
    return
  }

  if(window.gloodExtra?.intercomState === 'open') {
    hide()
  } else {
    show()
  }
}
