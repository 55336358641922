import { useEffect, useState } from 'react'
import { checkShopEligibleForBundles } from '../../../apis/shopify'

export default function useBundlesPermissible() {
  const [loading, setLoading] = useState(true)
  const [allowed, setAllowed] = useState(false)
  const [error, setError] = useState(null)
  const fetchBundlesPermissible = async () => {
    setLoading(true)
    const { data, error } = await checkShopEligibleForBundles()
    setLoading(false)
    if (error) {
      setError(error)
      return
    }
    setAllowed(data)
  }

  useEffect(() => {
    fetchBundlesPermissible()
  }, [])

  return {
    loading,
    allowed,
    error,
  }
}
