import React, { useEffect, useState } from 'react'
import {
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  InlineGrid,
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  Tooltip,
  useBreakpoints,
} from '@shopify/polaris'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'

Chart.register(CategoryScale)

function WidgetTabChart({
  stats = [],
  data = [],
  initiallyCollapsed = true,
  skipBorders = false,
  loading,
  loadingBlocks = 3,
  columns = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 6,
  },
  hideTabs = false,
  dateFilter = null,
}) {
  const [chartCollapse, setChartCollapse] = useState(initiallyCollapsed)
  const [activeStat, setActiveStat] = useState(0)
  const Wrapper = skipBorders ? Box : Card
  const breakPoints = useBreakpoints()
  const TooltipWrapper = ({ children, tooltipText }) => {
    if (!tooltipText) return children
    return <Tooltip content={tooltipText}>{children}</Tooltip>
  }

  return (
    <Wrapper padding="0">
      <InlineGrid columns={'1fr auto'}>
        <InlineGrid columns={columns}>
          {dateFilter}
          {!loading &&
            !hideTabs &&
            stats.map(({ label, value, tooltipText = null, total = null }, index) => (
              <TooltipWrapper tooltipText={tooltipText}>
                <div
                  style={{
                    borderRight:
                      breakPoints.lgUp && index + 1 != stats.length
                        ? '1px solid var(--p-color-border)'
                        : null,
                    padding: 'var(--p-space-200)',
                  }}
                >
                  <div
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor =
                        'var(--p-color-bg-surface-selected)'
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor =
                        activeStat === index && !chartCollapse
                          ? 'var(--p-color-bg-surface-selected)'
                          : 'transparent'
                    }}
                    onClick={() => {
                      setActiveStat(index)
                      setChartCollapse(false)
                    }}
                    style={{
                      padding: 'var(--p-space-200)',
                      borderRadius: 'var(--p-border-radius-200)',
                      cursor: 'pointer',
                      backgroundColor:
                        activeStat === index && !chartCollapse
                          ? 'var(--p-color-bg-surface-selected)'
                          : 'transparent',
                      transition: 'all 0.1s var(--p-motion-ease-in-out)',
                    }}
                  >
                    <BlockStack gap={'200'}>
                      <Text as="h4" variant="bodyLg">
                        <span
                          style={{
                            textDecoration: 'underline',
                            textDecorationStyle: 'dashed',
                            textDecorationColor:
                              'var(--p-color-bg-fill-selected)',
                            textDecorationThickness: '2px',
                          }}
                        >
                          {label}
                        </span>
                      </Text>
                      <InlineStack gap={'200'}>
                        <Text variant="headingMd" as="strong">
                          {value ?? 0}
                        </Text>
                        {total && (
                          <Text variant="subdued" as="span">
                            / {total}
                          </Text>
                        )}
                      </InlineStack>
                    </BlockStack>
                  </div>
                </div>
              </TooltipWrapper>
            ))}
          <Skeleton
            show={loading && !hideTabs}
            size={loadingBlocks}
            activeStat={activeStat}
            chartCollapse={chartCollapse}
          />
        </InlineGrid>
        <div
          style={{
            padding: 'var(--p-space-200)',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="plain"
            onClick={() => {
              setChartCollapse(!chartCollapse)
            }}
            icon={chartCollapse ? ChevronDownIcon : ChevronUpIcon}
          />
        </div>
      </InlineGrid>
      <Collapsible
        open={!chartCollapse}
        transition={{
          duration: '500ms',
          timingFunction: 'ease-in-out',
        }}
      >
        <div
          style={{
            borderTop: '1px solid var(--p-color-border)',
            padding: 'var(--p-space-400) var(--p-space-200)',
          }}
        >
          <AnalyticChart data={data[activeStat]} />
        </div>
      </Collapsible>
    </Wrapper>
  )
}

export default WidgetTabChart

function AnalyticChart({ data }) {
  const yAxisFormatter = data.yAxisFormatter || ((_) => _)
  const secondSeries = Boolean(data?.name2) && Boolean(data?.data2) ? [{
    label: data?.name2,
    data: (data?.data2 ?? []).map((_) => _.value)
  }] : []

  return (
    <div
      style={{
        height: '300px',
      }}
    >
      <Line
        data={{
          labels: data.data.map((_) => _.key),
          datasets: [
            {
              label: data.name,
              data: data.data.map((_) => _.value),
            },
            ...secondSeries
          ],
        }}
        width={'100%'}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.3,
              borderJoinStyle: 'bevel',
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'end',
            },
            tooltip: {
              mode: 'x',
              backgroundColor: 'white',
              titleColor: 'rgba(26, 26, 26, 1)',
              bodyColor: 'rgba(48, 48, 48, 1)',
              borderWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0.1)',
              intersect: false,
              callbacks: {
                label: (tooltipItem) => {
                  return (
                    tooltipItem.dataset.label +
                    ': ' +
                    data.tooltipFormatter(tooltipItem.parsed.y)
                  )
                },
              },
              usePointStyle: true,
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          tooltips: {
            mode: 'x',
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Month',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
              },
            ],
            y: {
              ticks: {
                callback: function (value, index, ticks) {
                  return yAxisFormatter(value)
                },
              },
            },
          },
        }}
      />
    </div>
  )
}

function Skeleton({ show, size, activeStat, chartCollapse }) {
  if (!show) return null
  return Array.from({
    length: size,
  }).map((_, index) => (
    <div
      style={{
        padding: 'var(--p-space-200)',
        borderRadius: 'var(--p-border-radius-200)',
        cursor: 'progress',
        backgroundColor:
          activeStat === index && !chartCollapse
            ? 'var(--p-color-bg-surface-selected)'
            : 'transparent',
        transition: 'all 0.1s var(--p-motion-ease-in-out)',
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--p-space-200)',
      }}
    >
      <SkeletonDisplayText size="small" maxWidth={'50px'} />
      <SkeletonBodyText lines={1} />
    </div>
  ))
}
