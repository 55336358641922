import getApiClient from '.'

export const getShopStatus = async () => {
  try {
    const client = await getApiClient()
    const response = await client.get('api/auth/status')
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error?.response?.data ?? error.message ?? error,
    }
  }
}
