import getApiClient from '.'

export async function createSection(data) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/setup/section', data)
    return {
      error: null,
      data: res.data,
    }
  } catch (error) {
    return {
      error: error.response.data,
      data: null,
    }
  }
}

export async function updateSection(id, data, token = null) {
  try {
    const client = await getApiClient(token)
    const res = await client.post(`/api/setup/section/${id}`, data)
    return {
      error: null,
      data: res.data,
    }
  } catch (error) {
    return {
      error: error.response.data,
      data: null,
    }
  }
}

export async function deleteSection(id) {
  try {
    const client = await getApiClient()
    const res = await client.delete(`/api/setup/section/${id}`)
    return {
      error: null,
      data: res.data,
    }
  } catch (error) {
    return {
      error: error.response.data,
      data: null,
    }
  }
}
