import { BUNDLE_SETTING_TYPE, BUNDLE_TYPE } from '../constants/bundles'

const CONJUCTOR = '___'

// function to filter variants based on common options
export function prepareListOfVariants(
  products,
  commonOptions = [],
  productIdMap,
) {
  const filteredVariants = []
  const allVariants = products.map((product) => product.variants).flat()
  if (commonOptions.length === 0) {
    return []
  }
  const commonValues = commonOptions.map((option) => option.values).flat()
  allVariants.forEach((variant) => {
    const { selectedOptions: options = [] } = variant
    let isPresent = false
    options.forEach((option) => {
      const { value } = option
      if (commonValues.includes(value)) {
        isPresent = true
      }
    })
    if (isPresent) {
      filteredVariants.push(variant)
    }
  })
  return filteredVariants
}

export function prepareProductIdMap(products = []) {
  return products.reduce((acc, product) => {
    acc[product.id] = product
    return acc
  }, {})
}

export function prepareProductIdVariantMap(variants = []) {
  return variants.reduce((acc, variant) => {
    if (acc[variant.product.id]) {
      acc[variant.product.id].push(variant)
    } else {
      acc[variant.product.id] = [variant]
    }
    return acc
  }, {})
}

export function isOptionEditable(type) {
  switch (type) {
    case BUNDLE_SETTING_TYPE.UNIQUE:
      return false
    case BUNDLE_SETTING_TYPE.PRODUCT:
      return false
    case BUNDLE_SETTING_TYPE.CUSTOM:
      return true
  }
}

export function getCommonOptions(products) {
  const optionsArray = products.map((product) =>
    product.options.map((option) => ({
      name: option.name,
      values: option.values,
    })),
  )
  const unionOptions = []
  optionsArray.forEach((options) => {
    options.forEach((option) => {
      const index = unionOptions.findIndex(
        (unionOption) => unionOption.name === option.name,
      )
      if (index === -1) {
        unionOptions.push(option)
      } else {
        unionOptions[index].values = Array.from(
          new Set([...unionOptions[index].values, ...option.values]),
        )
      }
    })
  })
  return unionOptions

  // below code is to find common options in products
  const optionArray = products.map((product) =>
    product.variants.map((variant) =>
      variant.selectedOptions
        .map((option) => option.value)
        .sort((a, b) => a.localeCompare(b))
        .join(CONJUCTOR),
    ),
  )

  let intersection = optionArray[0] ?? []
  for (let i = 1; i < optionArray.length; i++) {
    intersection = optionArray[i].filter((value) =>
      intersection.includes(value),
    )
  }
  // saperating values in intersection array
  intersection = intersection.map((value) => value.split(CONJUCTOR))
  const uniqueOptions = Array.from(new Set(intersection.flat()))
  const commonOptions = []
  products[0]?.options?.forEach((option) => {
    if (uniqueOptions.find((val) => option.values.includes(val))) {
      commonOptions.push({
        name: option.name,
        values: option.values.filter((value) => uniqueOptions.includes(value)),
      })
    }
  })

  return commonOptions.map((option) => ({
    ...option,
    id: (Math.random() * 1e10).toFixed(0),
  }))
}

export function getProductsFromCommonOptions(products, options) {
  const avaialableOptions = options.map((option) => option.values).flat()
  return products.map((product) => {
    const variants = (product?.variants ?? []).filter((variant) => {
      const { selectedOptions: options = [] } = variant
      const variantOptions = options.map((option) => option.value)
      return variantOptions.every((option) =>
        avaialableOptions.includes(option),
      )
    })
    return {
      ...product,
      variants,
    }
  })
}

export function createBundleVariants(products, options) {
  if(products.length === 0) {
    return []
  }
  const productVariants = []

  // filtering out product variants
  for (const option of options) {
    const product = products.find(
      (product) =>
        parseInt(product.id.replace('gid://shopify/Product/', '')) ===
        option.product,
    )
    const optionValues = option.options.map((option) => option.values).flat()
    const variants = (product?.variants ?? [])
      .filter((variant) => {
        const selectedOptionsValue = variant.selectedOptions.map(
          (option) => option.value,
        )
        return selectedOptionsValue.every((value) =>
          optionValues.includes(value),
        )
      })
      .map((variant) => ({
        ...variant,
        productTitle: product.title,
      }))
    productVariants.push(variants)
  }

  options.forEach((_, index) => {
    options[index].productVariants = productVariants[index]
  })

  const variants = combineArrays(
    ...options.map((option) => option.productVariants),
  )

  return  variants.map((pVariants) => ({
    name: pVariants.map((variant) => variant.title).join('-'),
    options: pVariants
      .map((variant, index) => ({
        name: variant.productTitle,
        value: variant.selectedOptions.map(({ value }) => value).join(' / '),
      }))
      .flat(),
    productVariants: pVariants.map((variant) => ({
      id: parseInt(variant.id.replace('gid://shopify/ProductVariant/', '')),
      price: parseInt(variant.price),
      productId: parseInt(
        variant.product.id.replace('gid://shopify/Product/', ''),
      ),
      compareAtPrice: parseInt(variant.compareAtPrice ?? variant.price),
    })),
  }))

  function combineArrays(...arrays) {
    if (arrays.length === 0) return [[]]

    const [first, ...rest] = arrays
    const combinationsWithoutFirst = combineArrays(...rest)
    const combinationsWithFirst = []

    for (const value of first) {
      for (const combination of combinationsWithoutFirst) {
        combinationsWithFirst.push([value, ...combination])
      }
    }

    return combinationsWithFirst
  }
}

export function generateBundleOptions(form, bundleType) {
  switch (bundleType) {
    case BUNDLE_TYPE.CUSTOM:
      return form.fields.options.value.map((option) => {
        const product = form.fields.products.value.find(
          (product) =>
            parseInt(product.id.replace('gid://shopify/Product/', '')) ===
            option.product,
        )
        return {
          name: product?.title,
          values: getSelectedOptionsList(product?.variants ?? [], option.options),
        }
      })
  }
}

// function to get selected options list
function getSelectedOptionsList(variants, options) {
  const optionValues = options.map((option) => option.values).flat()

  const selectedVariants = variants.filter((variant) => {
    const vOptions = variant.selectedOptions.map((option) => option.value)
    return vOptions.every((value) => optionValues.includes(value))
  })

  return selectedVariants.map((variant) => {
    const selectedVals = variant.selectedOptions.map((option) => option.value)
    return selectedVals.map((value) => `${value}`).join(' / ')
  })
}

export function calculatePrice(
  price,
  variantDiscounts,
  discountType,
  bundleVariantId,
  variantId,
  bundleDiscount
) {
  if (discountType === 'variantDiscount') {
    return variantDiscounts?.[bundleVariantId]?.[variantId] ? parseFloat(variantDiscounts[bundleVariantId][variantId]) : parseFloat(price)
  } else if(discountType === 'bundleDiscount') {
    return parseFloat((price * (1 - bundleDiscount / 100)).toFixed(2))
  }
  return price
}