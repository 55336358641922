import React from 'react'
import { BlockStack, Box, Button, InlineGrid, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

function ManualSyncOption({ config }) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        height: '500px',
      }}
    >
      <Box>
        <BlockStack gap={'100'}>
          <Text as="strong">
            {t('SettingsPage.Sections.Advanced.Section.sync.title')}
          </Text>
          <InlineGrid columns={'1fr auto'} gap={'100'}>
            <Text>
              {t('SettingsPage.Sections.Advanced.Section.sync.description', {
                time: config.lastTriggered,
              })}
            </Text>
            <Button variant="primary" disabled={config.wait}>
              {config.wait
                ? t('SettingsPage.Sections.Advanced.Section.sync.waitCta')
                : t('SettingsPage.Sections.Advanced.Section.sync.triggerCta')}
            </Button>
          </InlineGrid>
        </BlockStack>
      </Box>
    </div>
  )
}

export default ManualSyncOption
