export const SHOP_TYPES = [
  { label: 'Fashion', value: 'fashion' },
  { label: 'Fashion Accessories', value: 'fashion_accessories' },
  { label: 'Jewellry', value: 'jewellry' },
]

export const REASONS_TO_INSTALL = [
  {
    label: 'Improve Conversion',
    value: 'improve_conversion',
  },
  {
    label: 'Improve AoV',
    value: 'improve_aov',
  },
  {
    label: 'Improve User Experience',
    value: 'improve_user_experience',
  },
  {
    label: 'Checkout Upsell',
    value: 'checkout_upsell',
  },
  {
    label: 'Cart Upsell',
    value: 'cart_upsell',
  },
  {
    label: 'Homepage Personalization',
    value: 'homepage_personalization',
  },
  {
    label: 'Product Page Personalization',
    value: 'product_page_personalization',
  },
  {
    label: 'Others',
    value: 'others',
  },
]

export const STORE_SUBCATEGORY = [
    { label: 'Electronics', value: 'electronics' },
    { label: 'Home Decor', value: 'home_decor' },
    { label: 'Beauty', value: 'beauty' },
    { label: 'Toys', value: 'toys' },
    { label: 'Books', value: 'books' },
    { label: 'Sports', value: 'sports' },
    { label: 'Food & Beverage', value: 'food_beverage' },
    { label: 'Health & Wellness', value: 'health_wellness' },
]

export const CHANNELS = [
    {
        label: 'Shopify App Store',
        value: 'shopify_app_store',
    },
    {
        label: 'Referred by another Store',
        value: 'referred_by_store',
    },
    {
        label: 'Referred by Agency',
        value: 'referred_by_agency',
    },
    {
        label: 'Social Media',
        value: 'social_media',
    },
    {
        label: 'Facebook Groups',
        value: 'facebook_groups',
    },
    {
        label: 'Google / Search Engines',
        value: 'search_engines',
    },
]