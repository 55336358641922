import {
  Autocomplete,
  Avatar,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  Layout,
  ResourceItem,
  ResourceList,
  Select,
  Tag,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris'
import { DeleteIcon, InfoIcon, PlusIcon } from '@shopify/polaris-icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const INIT_TAGS = ['bundle']

export default function ProductOperations({ form, productTypes }) {
  const { t } = useTranslation()

  // for product tags
  const [inputValue, setInputValue] = useState('')
  const deselectedOptions = Array.from(
    new Set([
      ...form.fields.products.value.map((product) => product.tags).flat(),
    ]),
  )
    .filter(Boolean)
    .map((tag) => ({ value: tag, label: tag }))
  const [options, setOptions] = useState(deselectedOptions)

  const updateText = useCallback(
    (value) => {
      setInputValue(value)
      if (value === '') {
        setOptions(deselectedOptions)
        return
      }

      const filterRegex = new RegExp(value, 'i')
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      )

      setOptions(resultOptions)
    },
    [deselectedOptions],
  )

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...form.fields.tags.value]
      options.splice(options.indexOf(tag), 1)
      form.fields.tags.onChange(options)
    },
    [form.fields.tags.value],
  )

  const verticalContentMarkup =
    form.fields.tags.value.length > 0 ? (
      <InlineStack gap={'100'}>
        {form.fields.tags.value.map((option) => {
          let tagLabel = ''
          tagLabel = option?.replace('_', ' ')
          tagLabel = titleCase(tagLabel)
          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tagLabel}
            </Tag>
          )
        })}
      </InlineStack>
    ) : null

  useEffect(() => {
    const tags = Array.from(
      new Set([
        ...form.fields.products.value.map((product) => product.tags).flat(),
      ]),
    ).filter(Boolean)
    form.fields.tags.onChange([...INIT_TAGS, ...tags])
  }, [form.fields.products.value])

  return (
    <BlockStack gap={'300'}>
      {form.fields.collections.error && (
        <Banner title={form.fields.collections.error} tone="critical" />
      )}
      <Card padding={'0'}>
        <Box padding={'400'}>
          <BlockStack gap={'100'}>
            <Text variant="headingSm">
              {t('BundlesUpsert.Heading.productOrganization')}
            </Text>
            <BlockStack gap={'300'}>
              <BlockStack gap={'100'}>
                <InlineStack gap={'100'}>
                  <Text>
                    {t('BundlesUpsert.ProductDetails.productTags.label')}
                  </Text>
                  <Tooltip
                    content={t('BundlesUpsert.ProductOperations.tagsHelpText')}
                  >
                    <Icon source={InfoIcon} />
                  </Tooltip>
                </InlineStack>
                <InlineGrid
                  alignItems="start"
                  gap={'200'}
                  columns={{
                    sm: '1fr',
                    md: '1fr',
                    lg: '1fr auto',
                    xl: '1fr auto',
                  }}
                >
                  <TextField
                    onChange={updateText}
                    value={inputValue}
                    placeholder={t(
                      'BundlesUpsert.ProductDetails.productTags.placeholder',
                    )}
                    autoComplete="off"
                  />
                  <Box>
                    <Button
                      icon={PlusIcon}
                      onClick={() => {
                        form.fields.tags.onChange([
                          ...form.fields.tags.value,
                          ...inputValue.split(',').map((tag) => tag.trim()),
                        ])
                        setInputValue('')
                      }}
                    >
                      {t('BundlesUpsert.ProductOperations.addTagCta')}
                    </Button>
                  </Box>
                </InlineGrid>
                <InlineStack gap={'100'}>{verticalContentMarkup}</InlineStack>
              </BlockStack>
              <ProductOptionSelector productTypes={productTypes} form={form} />
              <InlineGrid
                columns={{
                  sm: '1fr',
                  md: '1fr',
                  lg: '1fr auto',
                  xl: '1fr auto',
                }}
              >
                <BlockStack gap={'50'}>
                  <Text>
                    {t('BundlesUpsert.ProductDetails.collection.label')}
                  </Text>
                  <Text>
                    {t('BundlesUpsert.ProductDetails.collection.description')}
                  </Text>
                </BlockStack>
                <Box>
                  <Button
                    icon={PlusIcon}
                    onClick={async () => {
                      try {
                        const collections = await shopify.resourcePicker({
                          type: 'collection',
                          action: 'add',
                          multiple: 5,
                          selectionIds: form.fields.collections.value.map(
                            (collection) => ({
                              id: collection.id,
                            }),
                          ),
                        })
                        if (collections) {
                          form.fields.collections.onChange(collections)
                        }
                      } catch (e) {
                        window.shopify.toast.show(t('noProductUpdate'), {
                          isError: true,
                        })
                      }
                    }}
                  >
                    {t('BundlesUpsert.ProductDetails.collection.addCta')}
                  </Button>
                </Box>
              </InlineGrid>
              <InlineStack gap={'300'}>
                {form.fields.collections.value.map((collection) => {
                  return (
                    <Tag
                      size="large"
                      key={collection.id}
                      onRemove={() => {
                        form.fields.collections.onChange(
                          form.fields.collections.value.filter(
                            (col) => col.id !== collection.id,
                          ),
                        )
                      }}
                    >
                      {collection.title}
                    </Tag>
                  )
                })}
              </InlineStack>
            </BlockStack>
          </BlockStack>
        </Box>
      </Card>
    </BlockStack>
  )

  function titleCase(string) {
    if (!string) return ''
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join('')
  }

  function renderItem(item) {
    const media = (
      <Avatar customer size="md" source={item?.image?.originalSrc} />
    )

    return (
      <ResourceItem id={item.id} media={media}>
        <BlockStack>
          <Text as="strong">{item.title}</Text>
          <Text>{item.handle}</Text>
        </BlockStack>
      </ResourceItem>
    )
  }
}

function ProductOptionSelector({ form, productTypes }) {
  const { t } = useTranslation()
  const deselectedOptions = useMemo(
    () =>
      productTypes.map((pType) => ({
        value: pType,
        label: pType,
      })),
    [],
  )
  const [selectedOptions, setSelectedOptions] = useState([])
  const [inputValue, setInputValue] = [
    form.fields.productType.value,
    form.fields.productType.onChange,
  ]
  const [options, setOptions] = useState(deselectedOptions)

  const updateText = useCallback(
    (value) => {
      setInputValue(value)

      if (value === '') {
        setOptions(deselectedOptions)
        return
      }

      const filterRegex = new RegExp(value, 'i')
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      )
      setOptions(resultOptions)
    },
    [deselectedOptions],
  )

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem)
        })
        return matchedOption && matchedOption.label
      })

      setSelectedOptions(selected)
      setInputValue(selectedValue[0] || '')
    },
    [options],
  )

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={t('BundlesUpsert.ProductDetails.productType.label')}
      value={inputValue}
      placeholder={t('BundlesUpsert.ProductDetails.productType.placeholder')}
      autoComplete="off"
    />
  )

  return (
    <Autocomplete
      options={options}
      selected={selectedOptions}
      onSelect={updateSelection}
      textField={textField}
    />
  )
}
