import { useState } from 'react'
import { useQuery } from 'react-query'
import { currencyFormatter } from '../utils/formater'

export default function useProductList({
  widgetType,
  searchQuery,
  maxItems = null,
  key = [],
}) {
  const [cursor, setCursor] = useState(null)
  const productQuery = useQuery({
    queryKey: ['products', widgetType, cursor, searchQuery, ...key],
    queryFn: async ({ queryKey }) => {
      const cursor = Boolean(searchQuery) ? null : queryKey?.[2] ?? null
      let queryVal = ''
      searchQuery = searchQuery.trim()
      if (searchQuery.length > 0) {
        searchQuery = searchQuery
          .split(' ')
          .map((st) => st.replace(/\s+/g, ''))
          .filter(Boolean)
          .join(' AND ')
        queryVal = `, query: "title:${searchQuery} OR ${searchQuery}"`
      }
      const query = `
              {
                products(first: ${maxItems || 10} ${cursor ? `, after: "${cursor}",` : ''}${queryVal}, sortKey:PUBLISHED_AT) {
                  edges {
                    node {
                      id
                      title
                      handle
                      vendor
                      featuredImage {
                        url
                        id
                      }
                      handle
                      productType
                      priceRangeV2{
                        maxVariantPrice{
                          amount
                          currencyCode
                        }
                        minVariantPrice{
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                  pageInfo{
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                  }
                }
              }
            `
      try {
        const res = await fetch('shopify:admin/api/graphql.json', {
          method: 'POST',
          body: JSON.stringify({
            query,
          }),
        })
        const { data } = await res.json()
        return {
          products: data.products.edges.map(({ node, cursor }) => ({
            id: parseInt(node.id.replace('gid://shopify/Product/', '')),
            images: node.featuredImage?.url ? [node.featuredImage?.url] : [],
            title: node.title,
            vendor: node.vendor,
            handle: node.handle,
            productType: node.productType,
            price: `${currencyFormatter(node.priceRangeV2.minVariantPrice.currencyCode, node.priceRangeV2.minVariantPrice.amount)}`,
          })),
          pagination: data.products.pageInfo,
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },
    staleTime: 0,
  })

  const startCursor = productQuery.data?.pagination.startCursor
  const endCursor = productQuery.data?.pagination.endCursor

  return {
    products: productQuery.data?.products ?? [],
    isLoading: productQuery.isLoading || productQuery.isRefetching,
    pagination: {
      onNext: () => {
        setCursor(endCursor)
      },
      onPrevious: () => {
        setCursor(startCursor)
      },
      hasNext: productQuery.data?.pagination.hasNextPage,
      hasPrevious: productQuery.data?.pagination.hasPreviousPage,
    },
  }
}
