import {
  Banner,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormLayout,
  Layout,
  Select,
  TextField,
  BlockStack,
  List,
  Link,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { PAGE_TYPES } from '../../../../constants/page'
import { AMAZON_BOUGHT_TOGETHER } from '../../../../constants/layouts'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'

const TOTAL_IMPLICATIONS = 4 // This is the total implications specified in locale JSON file (Section.Edit.Sections.BundleConfig.Implications)

export default function BundleConfig({ form, pricing, dashbaordData }) {
  const { t } = useTranslation()
  const featureAvalaible = areFeaturesEnabled(
    [FEATURES.DISCOUNTS],
    dashbaordData,
  )

  form.fields.enableBundleDiscounts = form.fields.enableBundleDiscounts || {}
  form.fields.discountType = form.fields.discountType || {}
  form.fields.discountValue = form.fields.discountValue || {}
  form.fields.cartPageMsg = form.fields.cartPageMsg || {}
  form.fields.discountMessage = form.fields.discountMessage || {}

  const discountAvalaible =
    form.fields.page.value === PAGE_TYPES.product_page.value &&
    form.fields.layout.value === AMAZON_BOUGHT_TOGETHER.value

  const disableAll = !discountAvalaible || !featureAvalaible

  return (
    <Layout.AnnotatedSection
      id="bundle"
      description={t('Section.Edit.Sections.BundleConfig.description')}
      title={t('Section.Edit.Sections.BundleConfig.title')}
    >
      <Card padding={'0'}>
        <Box padding={'400'}>
          {!featureAvalaible && (
            <Banner
              title={t('Features.notAvailableInPlanText', {
                feature: t('Features.labels.discounts'),
                cta: (
                  <Button
                    variant="plain"
                    onClick={() => {
                      pricing.open({
                        features: [FEATURES.DISCOUNTS],
                      })
                    }}
                  >
                    {t('DefaultText.upgradeText')}
                  </Button>
                ),
              })}
              status="info"
            />
          )}
          <BlockStack gap={'200'}>
            <Text as="h2" variant="headingSm">
              {t('Section.Edit.Sections.BundleConfig.Implications.title')}
            </Text>
            <List>
              {Array.from({ length: TOTAL_IMPLICATIONS }).map((_, index) => (
                <List.Item key={index}>
                  <Text>
                    {t(
                      `Section.Edit.Sections.BundleConfig.Implications.Points.point${
                        index + 1
                      }`,
                      {
                        LINK: <Link monochrome>get in touch with us</Link>,
                      },
                    )}
                  </Text>
                </List.Item>
              ))}
            </List>
            <FormLayout>
              <FormLayout>
                <Checkbox
                  disabled={!discountAvalaible || !featureAvalaible}
                  {...form.fields.enableBundleDiscounts}
                  label={t(
                    'Section.Edit.Sections.DiscountConfig.form.enabled.label',
                  )}
                />
              </FormLayout>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    disabled={disableAll}
                    options={DISCOUNT_TYPE}
                    {...form.fields.discountType}
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.type.label',
                    )}
                  />
                  <TextField
                    disabled={!form.fields.discountType.value || disableAll}
                    type="number"
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.value.label',
                    )}
                    {...form.fields.discountValue}
                    placeholder={t(
                      'Section.Edit.Sections.DiscountConfig.form.value.placeholder',
                    )}
                  />
                </FormLayout.Group>
                <FormLayout>
                  <TextField
                    disabled={disableAll}
                    {...form.fields.cartPageMsg}
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.cartMessage.label',
                    )}
                    placeholder={t(
                      'Section.Edit.Sections.DiscountConfig.form.cartMessage.placeholder',
                    )}
                  />
                </FormLayout>
                <FormLayout>
                  <TextField
                    disabled={disableAll}
                    {...form.fields.discountMessage}
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.widgetMessage.label',
                    )}
                    placeholder={t(
                      'Section.Edit.Sections.DiscountConfig.form.widgetMessage.placeholder',
                    )}
                  />
                </FormLayout>
              </FormLayout>
            </FormLayout>
          </BlockStack>
        </Box>
        {!discountAvalaible && featureAvalaible && (
          <>
            <Divider />
            <Box padding={'400'}>
              <Banner
                tone="info"
                title={t(
                  'Section.Edit.Sections.DiscountConfig.notAvailableText',
                )}
              />
            </Box>
          </>
        )}
      </Card>
    </Layout.AnnotatedSection>
  )
}

const DISCOUNT_TYPE = [
  { label: 'Amount', value: 'AMOUNT' },
  { label: 'Percentage', value: 'PERCENTAGE' },
]
