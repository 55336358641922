import React, { useState } from 'react'
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  Layout,
  Link,
  ProgressBar,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris'
import { format, parse } from 'date-fns'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import BillingModal from '../../../components/BillingModal'
import UsageSurpassedBanner from '../../../components/UsageSurpassedBanner'

function AccountSection({
  config,
  billingCycle,
  shopPlan,
  dashboardData,
  pricing,
  loading,
}) {
  const { t } = useTranslation()
  const [bmOpen, setBMOpen] = useState(false)
  const showUsageBasedAmount =
    shopPlan.isUnlimited || shopPlan.isExtendedUsageEnabled
  let usageBasedCharge
  let usageBasedChargeRule
  const maxPrice = parseFloat(
    (
      (parseFloat(shopPlan.recommendationServesBatchPrice) *
        (parseInt(shopPlan.recommendationsServedHardLimit) -
          parseInt(shopPlan.maximumRecommendationsServed))) /
      parseInt(shopPlan.recommendationServesBatchSize)
    ).toFixed(2),
  )
  if (showUsageBasedAmount) {
    usageBasedCharge = numeral(
      shopPlan.isUnlimited
        ? Math.min(
            parseFloat(billingCycle.billingPrice),
            parseFloat(billingCycle.maximumPrice),
          )
        : Math.min(
          parseFloat(billingCycle.billingPrice),
          maxPrice
        ),
    ).format('0.00')

    const extraUsage = Math.max(
      0,
      billingCycle.currentUsage - shopPlan.maximumRecommendationsServed,
    )
    usageBasedChargeRule = shopPlan.isUnlimited
      ? t('SettingsPage.Sections.Account.Card.Usage.usageChargeRulePercent', {
          percent: `${billingCycle.revenuePercent}%`,
        })
      : t('SettingsPage.Sections.Account.Card.Usage.usageChargeRuleAdditonal', {
          usage: extraUsage > 0 ? extraUsage : '',
          price: shopPlan.recommendationServesBatchPrice,
          batchSize: shopPlan.recommendationServesBatchSize,
        })
  }

  if (loading) {
    return <Skeleton />
  }

  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Account.title')}
      description={t('SettingsPage.Sections.Account.description')}
    >
      <BlockStack gap={'500'}>
        <BlockStack>
          {billingCycle.isTrialPeriod && (
            <Banner tone="warning">
              <p>
                {t('SettingsPage.Sections.Account.trialTemplates', {
                  time: (
                    <strong>
                      {format(
                        parse(billingCycle.endingOn, 'yyyy-MM-dd', new Date()),
                        'd MMMM, yyyy',
                      )}
                    </strong>
                  ),
                })}
              </p>
            </Banner>
          )}
          {config.usageAboutToSurpass && (
            <Banner tone="warning">
              <p>
                {t('SettingsPage.Sections.Account.usageAboutToSurpass', {
                  cta: (
                    <Button
                      onClick={() => {
                        pricing.open({
                          abovePlans: dashboardData.shopPlan,
                        })
                      }}
                    >
                      {t('SettingsPage.Sections.Account.upgradeBtnCta')}
                    </Button>
                  ),
                })}
              </p>
            </Banner>
          )}
        </BlockStack>
        <Card padding={'0'}>
          <Box padding={'400'}>
            <BlockStack gap={'300'}>
              <UsageSurpassedBanner
                dashboardData={dashboardData}
                condensed
                show={config.usageSurpassed}
                onUpgrade={() => {
                  pricing.open({
                    abovePlans: dashboardData.shopPlan,
                  })
                }}
              />
              <InlineGrid
                columns={'1fr auto auto'}
                gap={'300'}
                alignItems="center"
              >
                <BlockStack>
                  <Text as="strong">
                    {t(
                      'SettingsPage.Sections.Account.Card.Usage.titleTemplate',
                      {
                        planName: config.planName,
                      },
                    )}
                  </Text>
                  <Text as="p">
                    {t('SettingsPage.Sections.Account.Card.Usage.description')}
                  </Text>
                </BlockStack>
                {config.upgradeRequired && (
                  <div>
                    <Button url="/pricing">
                      {t('SettingsPage.Sections.Account.upgradeBtnCta')}
                    </Button>
                  </div>
                )}
                {config.onPaidPlan && (
                  <Link monochrome url="/pricing">
                    {t('DefaultText.downgradeText')}
                  </Link>
                )}
              </InlineGrid>
            </BlockStack>
          </Box>
          <Divider />
          <Box padding={'400'}>
            <InlineGrid columns={'1fr 1fr 1fr'} gap={'200'}>
              {!shopPlan.onAnnual && (
                <BlockStack gap={'100'}>
                  <Text>
                    {t(
                      'SettingsPage.Sections.Account.Card.Usage.monthlyCharge',
                    )}
                  </Text>
                  <Text as="strong">{config.monthlyCharge}</Text>
                </BlockStack>
              )}
              {shopPlan.onAnnual && (
                <BlockStack gap={'100'}>
                  <Text>
                    {t('SettingsPage.Sections.Account.Card.Usage.annualCharge')}
                  </Text>
                  <Text as="strong">{config.annualCharge}</Text>
                </BlockStack>
              )}
              {!shopPlan.isUnlimited && !shopPlan.onAnnual && (
                <BlockStack gap={'100'}>
                  <Text>
                    {t(
                      'SettingsPage.Sections.Account.Card.Usage.additionalUsageCharge',
                    )}
                  </Text>
                  <Text as="strong">${usageBasedCharge}</Text>
                </BlockStack>
              )}
              {!billingCycle.isTrialPeriod && (
                <BlockStack gap={'100'}>
                  <Text>
                    {t(
                      'SettingsPage.Sections.Account.Card.Usage.billingPeriod',
                    )}
                  </Text>
                  <Text as="strong">{config.billingPeriod}</Text>
                </BlockStack>
              )}
            </InlineGrid>
          </Box>
          <Divider />
          <Box padding={'400'}>
            <BlockStack gap={'300'}>
              <Box>
                <Text as="strong">
                  {' '}
                  {t('SettingsPage.Sections.Account.Card.Usage.consumption', {
                    consumedPercentage:
                      (config.widgetsConsumed * 100).toFixed(0) + '%',
                    consumed: config.consumedWidgets,
                    total: config.maxPostWidgets,
                  })}
                </Text>
                {shopPlan.isUsageBased ? (
                  <Text>
                    {t('SettingsPage.Sections.Account.Card.Usage.postCharge', {
                      limit: config.maxPostWidgets,
                      total: config.totalWidgets,
                    })}
                  </Text>
                ) : (
                  <Text>
                    {t('SettingsPage.Sections.Account.Card.Usage.recStop')}
                  </Text>
                )}
              </Box>
              <ProgressBar
                size="small"
                tone="primary"
                progress={config.widgetsConsumed * 100}
              />
            </BlockStack>
          </Box>
          <Divider />
          <Box padding={'400'}>
            <BlockStack>
              <Text as='strong'>
                {t('SettingsPage.Sections.Account.Card.Billing.title')}
              </Text>
              <InlineGrid columns={'1fr auto'} gap={'100'}>
                <Text>
                  {t('SettingsPage.Sections.Account.Card.Billing.description')}
                </Text>
                <Button
                  onClick={() => {
                    setBMOpen(true)
                  }}
                >
                  {t('SettingsPage.Sections.Account.Card.Billing.btnCta')}
                </Button>
              </InlineGrid>
            </BlockStack>
          </Box>
        </Card>
      </BlockStack>
      <BillingModal open={bmOpen} setOpen={setBMOpen} />
    </Layout.AnnotatedSection>
  )
}

export default AccountSection

function Skeleton() {
  const {t} = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Account.title')}
      description={t('SettingsPage.Sections.Account.description')}
    >
      <BlockStack gap={'500'}>
        <Card padding={'0'}>
          <Box padding={'400'}>
            <BlockStack gap={'300'}>
              <InlineGrid
                columns={'1fr auto auto'}
                gap={'300'}
                alignItems="center"
              >
                <BlockStack>
                  <SkeletonDisplayText size='small'/>
                  <Text as="p">
                    {t('SettingsPage.Sections.Account.Card.Usage.description')}
                  </Text>
                </BlockStack>
              </InlineGrid>
            </BlockStack>
          </Box>
          <Divider />
          <Box padding={'400'}>
            <BlockStack gap={'300'}>
              <BlockStack gap={'200'}>
                <SkeletonDisplayText size='small'/>
                <SkeletonBodyText lines={1}/>
              </BlockStack>
              <ProgressBar
                size="small"
                tone="primary"
                progress={0}
              />
            </BlockStack>
          </Box>
          <Divider />
          <Box padding={'400'}>
            <BlockStack>
              <Text as='strong'>
                {t('SettingsPage.Sections.Account.Card.Billing.title')}
              </Text>
              <InlineGrid columns={'1fr auto'} gap={'100'}>
                <Text>
                  {t('SettingsPage.Sections.Account.Card.Billing.description')}
                </Text>
                <Button
                  disabled
                >
                  {t('SettingsPage.Sections.Account.Card.Billing.btnCta')}
                </Button>
              </InlineGrid>
            </BlockStack>
          </Box>
        </Card>
      </BlockStack>
    </Layout.AnnotatedSection>
  )
}
