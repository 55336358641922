import {
    Bleed,
    BlockStack,
    Card,
    Collapsible,
    Divider,
    Scrollable,
    Text,
    Icon,
    InlineGrid,

} from '@shopify/polaris'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'
import React, { useState, } from 'react'

function NoErrorIndicator() {
    return (
        <div style={{
            margin: 'var(--p-space-500)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            No Error
        </div>
    )
}

export default function CollapsableErrorWindow({error}){
    const [isErrorCollapsed, setErrorCollapsed] = useState(true)
    return (
        <div
        style={{
            width: '300px',
            position: 'absolute',
            bottom: 'var(--p-space-500)',
            right: 'var(--p-space-500)',
        }}
    >
        <Card>
            <BlockStack gap={200}>
                <div style={{
                    cursor: 'pointer'
                }} onClick={() => { setErrorCollapsed(!isErrorCollapsed) }}>
                    <InlineGrid alignItems='center' columns={'auto 1fr auto'}>
                        <Text as='h6' fontWeight='semibold' >Error</Text>
                        <div></div>
                        <Icon source={!isErrorCollapsed ? ChevronUpIcon : ChevronDownIcon} />
                    </InlineGrid>
                </div>

                <Collapsible
                    open={!isErrorCollapsed}
                    id="basic-collapsible"
                    expandOnPrint
                >
                    <Bleed marginInline="400">
                        <Divider />
                    </Bleed>
                    <Scrollable scrollbarWidth='thin'>
                        {
                            error == '' ? <NoErrorIndicator /> :
                                <p>
                                    {error}
                                </p>
                        }

                    </Scrollable>
                </Collapsible>
            </BlockStack>
        </Card>
    </div>
    )
}