import React, { useState } from 'react'
import {
  BlockStack,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Layout,
  Tag,
  Text,
  ResourceList,
  ResourceItem,
  Avatar,
  FormLayout,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { EditIcon } from '@shopify/polaris-icons'

import { SEGMENTS } from '../../../../constants/segmentation'

function SegmentConfiguration({ onClick, segementationType }) {
  const { t } = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.SegmentationConfig.title')}
      description={t('Section.Details.SegmentationConfig.description')}
    >
      <Card>
        <BlockStack gap={'400'}>
          <InlineGrid columns={'1fr auto'}>
            <div>
              <Text as="h4" variant="bodyMd">
                {t(
                  'Section.Details.SegmentationConfig.FormFields.Selection.label',
                )}
              </Text>
              <Text variant="headingMd" as="strong">
                {
                  SEGMENTS.find(({ value }) => value === segementationType)
                    .label
                }
              </Text>
            </div>
            <div>
              <Button size="slim" icon={EditIcon} onClick={onClick}>
                {t('Section.Details.SegmentationConfig.btnText')}
              </Button>
            </div>
          </InlineGrid>
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default SegmentConfiguration
