export const BUNDLE_SETTING_TYPE = {
  UNIQUE: 'unique_option',
  PRODUCT: 'product',
  CUSTOM: 'custom',
}

export const BUNDLE_TYPE = {
  CUSTOM: 'custom',
  PACK_OF_N: 'pack_of_n',
  BUY_X_GET_Y: 'buy_x_get_y',
  MIX_MATCH: 'mix_match',
  UPSELL: 'upsell',
  GIFT: 'gift',
}

export const BUNDLE_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
}
