import { useTranslation } from 'react-i18next'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { getFromStorage, saveToStorage } from '../../utils/storage'

const LocalSettingsContext = createContext({
  settings: {},
  saveSettings: (newSettings) => {},
})

export default function LocalSettingsProvider({ children }) {
  const shop = window.shopify.config.shop
  const locale = window.shopify.config.locale.split('-')[0]
  const { i18n } = useTranslation()

  async function changeDashboardLocale(locale) {
    const res = await i18n.changeLanguage(locale)
  }

  const [settings, setSettings] = useState({
    durationFilter: {
      start: new Date(new Date().setDate(new Date().getMonth() - 7)),
      end: new Date(),
    },
    locale: locale,
    onboardinStepsCollapsed: false,
  })

  useEffect(() => {
    const localSettings = getFromStorage(shop)
    setSettings({
      durationFilter: {
        start: localSettings?.durationFilter?.start
          ? new Date(localSettings.durationFilter.start)
          : new Date(new Date().setDate(new Date().getMonth() - 7)),
        end: localSettings?.durationFilter?.end
          ? new Date(localSettings.durationFilter.end)
          : new Date(),
      },
      locale: localSettings?.locale || locale,
      onboardinStepsCollapsed: Boolean(localSettings?.onboardinStepsCollapsed),
      hideAppEmbed: Boolean(localSettings?.hideAppEmbed),
    })
    changeDashboardLocale(localSettings?.locale || locale)
  }, [])

  const saveSettings = useCallback(
    (newSettings) => {
      setSettings(newSettings)
      saveToStorage(shop, newSettings)
    },
    [setSettings],
  )

  return (
    <LocalSettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </LocalSettingsContext.Provider>
  )
}

export function useLocalSettings() {
  return useContext(LocalSettingsContext)
}
