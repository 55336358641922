import { BoughtTogether, Carousel, HorizontalGrid } from '../assets'

const AMAZON_BOUGHT_TOGETHER = {
  value: 'amazon_bought_together',
  label: 'Amazon like bought together',
  image: BoughtTogether,
  description:
    'You have a great product. Now, it’s time to test your idea to see if it can become a business. ',
}

const CAROUSEL = {
  value: 'carousel',
  label: 'Carousel',
  image: Carousel,
  description:
    'You have a great product. Now, it’s time to test your idea to see if it can become a business. ',
}

const HORIZONTAL_GRID = {
  value: 'horizontal_grid',
  label: 'Horizontal Grid',
  image: HorizontalGrid,
  description:
    'You have a great product. Now, it’s time to test your idea to see if it can become a business. ',
}

export { AMAZON_BOUGHT_TOGETHER, CAROUSEL, HORIZONTAL_GRID }
