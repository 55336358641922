import {
  lengthLessThan,
  lengthMoreThan,
  notEmpty,
  useField,
} from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

const getValue = (currentState, path, type, suffix, defaultValue) => {
  if (path.length == 0) {
    return undefined
  }
  let value = currentState
  for (let x of path) {
    if (x === '{{layout}}') {
      x = currentState.widget.layout
    }
    value = value?.[x]
  }
  if (suffix && value?.length > 2) {
    value = value.replace(suffix, '')
  }
  if (typeof value === 'string' && value.includes('undefined')) {
    value = defaultValue
  }
  return value ? value : defaultValue
}

function getValidators(item,t) {
  const validatorArr = item.validators.map((validator) => {
    if (validator.type === 'notEmpty') {
      return notEmpty(`${item.label} ${t('VisualEditorForm.errors.notEmpty')}`)
    }
    if (validator.type === 'positiveIntegerString') {
      return (val) => {
        const regex = /^[1-9][0-9]*$/
        if (!regex.test(val)) {
          return `${item.label} ${t('VisualEditorForm.errors.positiveIntegerString')}`
        }
        return null
      }
    }
    if (validator.type === 'positiveNumericString') {
      return (val) => {
        const regex = /^[1-9]\d*(\.\d+)?$/
        if (!regex.test(val)) {
          return `${item.label} ${t('VisualEditorForm.errors.positiveIntegerString')}`
        }
      }
    }
    if (validator.type === 'minLength') {
      return lengthMoreThan(
        validator.length - 1,
        `${item.label} ${t('VisualEditorForm.errors.minLength',{
          min: validator.length})}`,
      )
    }
    if (validator.type === 'maxLength') {
      return lengthLessThan(
        validator.length + 1,
        `${item.label} ${t('VisualEditorForm.errors.maxLength',{
          min: validator.length})}`,
      )
    }
    if (validator.type === 'aspectRatio') {
      return (val) => {
        const regex = /^\d+:\d+$/
        if (!regex.test(val)) {
          return t('VisualEditorForm.errors.aspectRatio')
        }
        return null
      }
    }
  })
  return validatorArr
}

export const useFieldsObject = (array, currentState, onChange) => {
  const initialValue = {}
  const {t} = useTranslation()
  const x = array.reduce((obj, item) => {
    return {
      ...obj,
      [item['name']]: useField({
        value: getValue(
          currentState,
          item.path,
          item.type,
          item.suffix,
          item.defaultValue,
        ),
        validates: getValidators(item,t),
      }),
    }
  }, initialValue)
  return {
    ...x,
    onChange: (value) => {
      onChange()
      x.onChange(value)
    },
  }
}

// This function will delete the fields that are dependent on other fields and are not shown
export const usePrepareFieldsFromSchema = (formFields, schemaArray, layout) => {
  schemaArray.forEach(({ sections }) => {
    sections.forEach(({ rows }) => {
      rows.forEach(({ dependency = {}, fields }) => {
        let show = true
        Object.keys(dependency).forEach((key) => {
          show = show && formFields[key]?.value === dependency[key]
        })
        if (!show) {
          fields.forEach((field) => {
            delete formFields[field.name]
          })
        }
      })
    })
  })
  return formFields
}
