import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  Icon,
  IndexFilters,
  InlineStack,
  Page,
  Tooltip,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  IndexTable,
  Avatar,
  BlockStack,
  SkeletonBodyText,
  SkeletonPage,
  SkeletonDisplayText,
} from '@shopify/polaris'
import { InfoIcon, UploadIcon } from '@shopify/polaris-icons'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import { getDatasetDetails } from '../../../apis/recommendation.js'
import Loader from '../../../components/Loader/index.jsx'
import { WIDGET_TYPES } from '../../../constants/widgets/index.js'

const WINDOW_SIZE = 6

export default function RecommendationDataset() {
  const { t } = useTranslation()
  const { datasetId } = useParams()
  const StatusMap = {
    success: t('RecommendationPage.Components.Dataset.Status.success'),
    error: t('RecommendationPage.Components.Dataset.Status.error'),
    in_progress: t('RecommendationPage.Components.Dataset.Status.inprogress'),
    failed: t('RecommendationPage.Components.Dataset.Status.failed'),
  }

  const { data, isLoading } = useQuery({
    queryFn: () => getDatasetDetails(),
    queryKey: ['datasetDetails', { id: datasetId }],
    // enabled: !!datasetId,
    refetchOnWindowFocus: false,
  })

  if (isLoading) {
    return <Skeleton title={`${datasetId}.csv`} />
  }

  return (
    <Page
      backAction={{
        url: '/settings/recommendation/bulk-config',
      }}
      title={`${datasetId}.csv`}
      titleMetadata={
        <InlineStack gap="300">
          <Badge tone="info">
            {WIDGET_TYPES[data?.recommendationType]?.label}
          </Badge>
          <Badge tone={TagToneMap[data?.status]}>
            {StatusMap[data?.status]}
          </Badge>
          <Tooltip
            content={t(
              'RecommendationPage.Components.Dataset.Header.infoTooltop',
            )}
          >
            <Icon source={InfoIcon} tone="base" />
          </Tooltip>
        </InlineStack>
      }
      subtitle={`${t(
        'RecommendationPage.Components.Dataset.Header.createdAt',
      )} : ${new Date(data?.createdAt).toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })}  |  ${t(
        'RecommendationPage.Components.Dataset.Header.updatedAt',
      )}  : ${new Date(data?.updatedAt).toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })} `}
      compactTitle
      fullWidth
      primaryAction={
        <Button
          variant="secondary"
          icon={
            <div
              style={{
                rotate: '180deg',
              }}
            >
              <Icon source={UploadIcon} tone="base" />
            </div>
          }
        >
          {t('RecommendationPage.Components.Dataset.Action.download')}
        </Button>
      }
    >
      <Card>
        {/* <IndexFilters /> */}
        <MyTable products={data?.products} />
      </Card>
    </Page>
  )
}

function MyTable({ products }) {
  const { t } = useTranslation()
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const StatusMap = {
    success: t('RecommendationPage.Components.Dataset.Status.success'),
    error: t('RecommendationPage.Components.Dataset.Status.error'),
    in_progress: t('RecommendationPage.Components.Dataset.Status.inprogress'),
    failed: t('RecommendationPage.Components.Dataset.Status.failed'),
  }

  const itemStrings = [
    t('RecommendationPage.Components.Dataset.Tabs.all'),
    t('RecommendationPage.Components.Dataset.Tabs.success'),
    t('RecommendationPage.Components.Dataset.Tabs.failed'),
  ]
  const [selectedTab, setSelectedTab] = useState(0)

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {
      setSelectedTab(index)
    },
    id: `${item}-${index}`,
    isLocked: true,
  }))
  const { mode, setMode } = useSetIndexFiltersMode()

  const [queryValue, setQueryValue] = useState('')

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  )
  const onHandleSave = async () => {
    await sleep(1)
    return true
  }

  const primaryAction = {
    type: 'save',
    onAction: onHandleSave,
    disabled: false,
    loading: false,
  }

  const handleQueryValueRemove = useCallback(() => setQueryValue(''), [])
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove()
  }, [handleQueryValueRemove])

  const resourceName = {
    singular: 'product',
    plural: 'products',
  }

  const filteredProducts = products.filter(
    ({ parent, recommendation, status }) => {
      let showProduct = true
      if (queryValue) {
        let show = false
        const words = queryValue.toLowerCase().split(' ')
        let titleString = parent.title.toLowerCase()
        words.forEach((word) => {
          show = show || titleString.includes(word)
        })
        showProduct = show
      }
      if (
        itemStrings[selectedTab] ===
        t('RecommendationPage.Components.Dataset.Tabs.success')
      ) {
        showProduct = showProduct && status === 'success'
      }
      if (
        itemStrings[selectedTab] ===
        t('RecommendationPage.Components.Dataset.Tabs.failed')
      ) {
        showProduct = showProduct && status === 'failed'
      }
      return showProduct
    },
  )

  const [pageNumber, setPageNumber] = useState(0)

  const rowMarkup = filteredProducts
    .slice(pageNumber * WINDOW_SIZE, pageNumber * WINDOW_SIZE + WINDOW_SIZE)
    .map(({ parent, recommendation, status }, index) => {
      return (
        <IndexTable.Row id={index} key={index} position={index}>
          <IndexTable.Cell>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                margin: '5px 0',
              }}
            >
              <div style={{ minWidth: '2rem' }}>
                <Avatar
                  customer
                  size="lg"
                  source="/assets/collection-placeholder.webp"
                  // name={name}
                />
              </div>
              <div>
                <Text as="h3" variant="bodyMd" fontWeight="bold">
                  {parent.title}
                </Text>
                <div>
                  {parent.price} | ID: {parent.id} | Handle: {parent.handle}
                </div>
              </div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                margin: '5px 0',
              }}
            >
              <div style={{ minWidth: '2rem' }}>
                <Avatar
                  customer
                  size="lg"
                  source="/assets/collection-placeholder.webp"
                  // name={name}
                />
              </div>
              <div>
                <Text as="h3" variant="bodyMd" fontWeight="bold">
                  {recommendation.title}
                </Text>
                <div>
                  {recommendation.price} | ID: {recommendation.id} | Handle:{' '}
                  {recommendation.handle}
                </div>
              </div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge tone={TagToneMap[status]}>{StatusMap[status]}</Badge>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    })

  return (
    <>
      <IndexFilters
        queryValue={queryValue}
        queryPlaceholder={t(
          'RecommendationPage.Components.Dataset.Filters.queryPlaceholder',
        )}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => setQueryValue('')}
        primaryAction={primaryAction}
        cancelAction={{
          onAction: () => {},
          disabled: false,
          loading: false,
        }}
        tabs={tabs}
        appliedFilters={[]}
        filters={[]}
        selected={selectedTab}
        canCreateNewView={false}
        mode={mode}
        setMode={setMode}
      />
      <IndexTable
        resourceName={resourceName}
        itemCount={filteredProducts.length}
        pagination={{
          onNext: () => {
            setPageNumber(pageNumber + 1)
          },
          onPrevious: () => {
            setPageNumber(pageNumber - 1)
          },
          hasNext: filteredProducts.length > (pageNumber + 1) * WINDOW_SIZE,
          hasPrevious: pageNumber > 0,
        }}
        headings={[
          {
            title: t(
              'RecommendationPage.Components.Dataset.Table.Headers.parent',
            ),
          },
          {
            title: t(
              'RecommendationPage.Components.Dataset.Table.Headers.recommended',
            ),
          },
          {
            title: t(
              'RecommendationPage.Components.Dataset.Table.Headers.status',
            ),
          },
        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    </>
  )
}
const TagToneMap = {
  success: 'success',
  failed: 'critical',
  in_progress: 'info',
}

function Skeleton({ title }) {
  return (
    <SkeletonPage fullWidth title={title}>
      <BlockStack gap={'200'}>
        <SkeletonDisplayText />
        <SkeletonBodyText lines={6} />
      </BlockStack>
    </SkeletonPage>
  )
}
