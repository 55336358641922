import { useEffect, useState } from 'react'

import { getAppEmbedState } from '../apis/app'
import {
  SHOPIFY_GRAPHQL_API_ENDPOINT,
  THEME_SECTION_EXTENSION_ID,
} from '../config'

export function useAppEmbed() {
  const [loading, setLoading] = useState(true)
  const [isAppEmbedded, setIsAppEmbedded] = useState(false)
  const [activeThemeId, setActiveThemeId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function fetchState() {
      setLoading(true)
      const themeId = await getThemeSectionExtension()
      const res = await getAppEmbedState(themeId)
      setLoading(false)
      if (res.error) {
        setError(error)
        console.error(error)
        return
      }
      setIsAppEmbedded(res.data.appEnabled)
      setActiveThemeId(themeId)
    }
    fetchState()
  }, [])

  const appEmbedUrl = `https://admin.shopify.com/store/${window.shopify.config.shop.replace('.myshopify.com', '')}/themes/${activeThemeId}/editor?context=apps&appEmbed=${THEME_SECTION_EXTENSION_ID}/app-embed`

  return {
    isAppEmbedded,
    isLoading: loading,
    appEmbedUrl,
  }
}

async function getThemeSectionExtension() {
  const query = `
      {
        themes(first: 1, roles: [MAIN]) {
          edges {
            node {
              id
            }
          }
        }
    }`
  const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      query,
    }),
  })
  const data = await res.json()
  return data?.data?.themes?.edges[0]?.node?.id?.replace(
    'gid://shopify/OnlineStoreTheme/',
    '',
  )
}
