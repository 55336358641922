import humps from 'humps'
import getApiClient from '.'

export async function createExperience(data) {
  const client = await getApiClient()
  try {
    const res = await client.post('/api/setup/experience', data)
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function updateExperience(id, data) {
  const client = await getApiClient()
  try {
    const res = await client.post(`/api/setup/experience/${id}`, data)
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function deleteExperience(id) {
  const client = await getApiClient()
  try {
    const res = await client.delete(`/api/setup/experience/${id}`)
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}
