export function numberFormater(val) {
  if (typeof val !== 'number') return null
  return new Intl.NumberFormat('en', {
    maximumSignificantDigits: 3,
  }).format(val)
}

export function currencyFormatter(currencCode, value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencCode,
  }).format(value)
}

export function percentageFormatter(value) {
  return (value * 100).toFixed(2) + '%'
}

export function formatCurrency(format, price) {
  // Convert the price to a number if it is a string
  let amount = typeof price === 'string' ? parseFloat(price) : price

  // Based on the format type, determine how to format the amount
  let formattedAmount
  switch (true) {
    case format.includes('{{ amount_no_decimals_with_space_separator }}'):
      formattedAmount = Math.round(amount)
        .toLocaleString('fr-FR', { maximumFractionDigits: 0 })
        .replace(/,/g, ' ')
      break
    case format.includes('{{ amount_with_period_and_space_separator }}'):
      formattedAmount = amount
        .toLocaleString('de-DE', { minimumFractionDigits: 2 })
        .replace('.', ' ')
      break
    case format.includes('{{ amount_no_decimals_with_comma_separator }}'):
      formattedAmount = Math.round(amount).toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })
      break
    case format.includes('{{ amount_with_comma_separator }}'):
      formattedAmount = amount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
      break
    case format.includes('{{ amount_with_apostrophe_separator }}'):
      formattedAmount = amount
        .toLocaleString('fr-CH', { minimumFractionDigits: 2 })
        .replace(/,/g, "'")
      break
    case format.includes('{{ amount_no_decimals }}'):
      formattedAmount = Math.round(amount).toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })
      break
    case format.includes('{{ amount_with_space_separator }}'):
      formattedAmount = amount
        .toLocaleString('fr-FR', { minimumFractionDigits: 2 })
        .replace(/,/g, ' ')
      break
    default:
      // Default case: assume standard US locale formatting with decimals
      formattedAmount = amount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
  }

  // Replace the placeholder {{ amount }} or other placeholders in the format string
  return format.replace(/{{\s*amount.*\s*}}/, formattedAmount)
}
