import React from 'react'
import { Box, BlockStack, Text, InlineGrid, Button } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

function PersonalizedPageSection({ config, form }) {
  const { t } = useTranslation()
  return (
    <Box padding={'0'}>
      <BlockStack>
        <InlineGrid columns={'1fr auto'} gap={'200'}>
          <Text as='strong'>
            {t('SettingsPage.Sections.Advanced.Section.personalized.title')}
          </Text>
          <Button disabled={true} variant='primary'>
            {t('SettingsPage.Sections.Advanced.Section.personalized.btnCta')}
          </Button>
        </InlineGrid>
      </BlockStack>
    </Box>
  )
}

export default PersonalizedPageSection
