import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  DataTable,
  Form,
  FormLayout,
  InlineGrid,
  Layout,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import * as Pages from '../../../../constants/page'
import * as WidgetsList from '../../../../constants/widgets'
import SectionTranslations from '../components/translation'

export default function Details({
  form,
  page = 'create',
  dashboardData,
  pricing,
}) {
  const { t } = useTranslation()
  const shopifyPageList = Object.values(Pages).map((page) => ({
    value: page.value,
    label: page.label,
  }))
  const activeWidget = Object.values(WidgetsList)
    .map((widget) => ({
      value: widget.value,
      title: widget.label,
      subtitle: widget.description,
      icon: widget.icon,
    }))
    .filter((widget) => widget.value === form.fields.widgetType.value)[0]

  return (
    <Layout.AnnotatedSection
      id="details"
      variant="oneThird"
      title={t('Section.Create.Details.title')}
      description={t('Section.Create.Details.description')}
    >
      <Card>
        <FormLayout>
          <FormLayout>
            <InlineGrid alignItems='end' columns={page === 'create' ? '1fr' : '1fr auto'} gap={'300'}>
              <TextField
                autoComplete="text"
                type="text"
                minLength={3}
                {...form.fields.name}
                label={
                  page == 'create'
                    ? t('Section.Create.Form.Name.title')
                    : t('Section.Edit.Sections.Details.name')
                }
                placeholder={t('Section.Create.Form.Name.placeholder')}
              />
              {page != 'create' && (
                <Box>
                  <SectionTranslations
                  form={form}
                  dashboardData={dashboardData}
                  pricing={pricing}
                />
                </Box>
              )}
            </InlineGrid>
          </FormLayout>
          <FormLayout.Group>
            <TextField
              readOnly
              value={form.fields.experience.value}
              placeholder={t('Section.Create.Form.Experience.placeholder')}
              label={t('Section.Create.Form.Experience.title')}
            />
            <TextField
              readOnly
              value={
                shopifyPageList.find((p) => p.value === form.fields.page.value)
                  ?.label
              }
              label={t('Section.Create.Form.Page.title')}
              placeholder={t('Section.Create.Form.Page.placeholder')}
            />
          </FormLayout.Group>
          <FormLayout>
            <Card padding={'0'}>
              <DataTable
                columnContentTypes={['text']}
                headings={
                  page == 'create'
                    ? [t('Section.Create.Form.Recommendation.title')]
                    : [t('Section.Edit.Sections.Details.type')]
                }
                rows={[
                  [
                    <RecommendationTypeCard
                      {...activeWidget}
                      checked={true}
                      onClick={() => {}}
                    />,
                  ],
                ]}
              />
            </Card>
          </FormLayout>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  )
}

const RecommendationTypeCard = ({
  title,
  subtitle,
  checked,
  onClick,
  icon,
}) => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Checkbox checked={checked} onChange={onClick} />
        <img src={icon} width={32} height={32} alt="icon" />
        <div
          style={{
            display: 'flex',
            flex: '1 1 0%',
            paddingLeft: 'var(--p-space-400)',
          }}
        >
          <BlockStack>
            <Text as="h6" fontWeight="semibold">
              {title}
            </Text>
            <Text as="p">{subtitle}</Text>
          </BlockStack>
        </div>
      </div>
    </div>
  )
}
