import {
  BlockStack,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Select,
  Text,
} from '@shopify/polaris'
import { DeleteIcon } from '@shopify/polaris-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as WIDGETS from '../../../../../constants/widgets'
import { useDashboardDetails } from '../../../../../hooks/useDashboardDetails'

const MAX_SLOTS = 6

function SlotsRecommendation({ slots, onChange }) {
  const { t } = useTranslation()
  const [rec, setRec] = useState(null)

  const { data } = useDashboardDetails()
  const recommendationsEnabled = data?.shopPlan?.recommendationsEnabled ?? []
  const options = getOptions(t)

  return (
    <Card>
      <BlockStack gap={'300'}>
        <BlockStack gap={'100'}>
          <Text as="strong">
            {t(
              'Section.Edit.Sections.Recommendation.SlotsRecommendation.title',
            )}
          </Text>
          <Text>
            {t(
              'Section.Edit.Sections.Recommendation.SlotsRecommendation.description',
            )}
          </Text>
        </BlockStack>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 'var(--p-space-200)',
          }}
        >
          {slots.map((slot, index) => (
            <Grid.Cell>
              <SlotCard
                options={options}
                slot={slot}
                onDelete={() => {
                  const newSlots = slots.filter((_, i) => i !== index)
                  onChange(newSlots)
                }}
                onEdit={(slot) => {
                  const newSlots = slots.map((s, i) => (i === index ? slot : s))
                  onChange(newSlots)
                }}
                index={index}
              />
            </Grid.Cell>
          ))}
          {slots.length < MAX_SLOTS && (
            <Grid.Cell>
              <CustomCard>
                <BlockStack gap={'300'}>
                  <Select
                    options={getOptions(t)}
                    value={rec}
                    placeholder={t(
                      'Section.Edit.Sections.Recommendation.SlotsRecommendation.SlotCard.placeholder',
                    )}
                    onChange={(value) => {
                      setRec(value)
                    }}
                  />
                  <div>
                    <Button
                      variant="primary"
                      onClick={() => {
                        onChange([...slots, { recommendation: rec }])
                        setRec(null)
                      }}
                      disabled={!rec}
                    >
                      {t(
                        'Section.Edit.Sections.Recommendation.SlotsRecommendation.addSlotCTA',
                      )}
                    </Button>
                  </div>
                </BlockStack>
              </CustomCard>
            </Grid.Cell>
          )}
        </div>
      </BlockStack>
    </Card>
  )
  function getOptions(t) {
    return Object.values(WIDGETS)
      .filter((w) => Boolean(w?.value))
      .filter((w) => w.allowedInSlots)
      .map((widget) => ({
        label: t(`Widgets.${widget.value}.label`),
        value: widget.value,
        disabled: recommendationsEnabled.indexOf(widget.value) === -1,
      }))
      .sort((a, b) => a.disabled - b.disabled)
  }
}

export default SlotsRecommendation

function SlotCard({ slot, onDelete, onEdit, index, options }) {
  const [isEditing, setIsEditing] = useState(false)
  const { t } = useTranslation()
  return (
    <CustomCard>
      <BlockStack gap={'100'}>
        <Text>
          {t(
            'Section.Edit.Sections.Recommendation.SlotsRecommendation.SlotCard.title',
            { number: index + 1 },
          )}
        </Text>
        {isEditing ? (
          <Select
            options={options}
            value={slot.recommendation}
            onChange={(value) => {
              onEdit({
                ...slot,
                recommendation: value,
              })
              setIsEditing(false)
            }}
            placeholder={t(
              'Section.Edit.Sections.Recommendation.SlotsRecommendation.SlotCard.placeholder',
            )}
          />
        ) : (
          <Text as="strong">{t(`Widgets.${slot.recommendation}.label`)}</Text>
        )}
        <ButtonGroup gap="200">
          <Button
            onClick={() => {
              setIsEditing(true)
            }}
          >
            {t(
              'Section.Edit.Sections.Recommendation.SlotsRecommendation.SlotCard.cta',
            )}
          </Button>
          <Button icon={DeleteIcon} onClick={onDelete} />
        </ButtonGroup>
      </BlockStack>
    </CustomCard>
  )
}

function CustomCard({ children }) {
  return (
    <div
      style={{
        padding: 'var(--p-space-400)',
        borderRadius: 'var(--p-border-radius-400)',
        border:
          'var(--p-border-width-025) solid var(--p-color-border-tertiary)',
        height: '100%',
      }}
    >
      {children}
    </div>
  )
}
