import { Banner, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { updateSection } from '../../apis/section'
import queryClient from '../../utils/query'

function SectionDisabledBanner({ sectionId }) {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  return (
    <Banner
      tone="warning"
      title={t('Section.disableBanner.title')}
      action={{
        content: t('Section.disableBanner.enableCta'),
        onAction: async () => {
          setLoading(true)
          await updateSection(sectionId, {
            enabled: true,
          })
          setLoading(false)
          await queryClient.invalidateQueries(['shopDetails'])
          shopify.toast.show('Widget enabled!')
        },
        loading: loading,
      }}
    >
      <Text>{t('Section.disableBanner.description')}</Text>
    </Banner>
  )
}

export default SectionDisabledBanner
