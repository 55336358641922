import axios from 'axios'

const baseURL = window.location.origin

if (!baseURL) {
  throw new Error('Backend URL is not set')
}

export default async function getApiClient(idToken = null) {
  let token = null
  if (idToken) {
    token = idToken
  } else {
    token = await window.shopify.idToken()
  }
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-shop': shopify.config.shop,
    },
  })
}
