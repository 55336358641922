import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Divider, Layout, Tabs } from '@shopify/polaris'
import {
  notEmpty,
  useChoiceField,
  useField,
  useForm,
} from '@shopify/react-form'
import { merge } from 'lodash-es'

import { updateShopConfig } from '../../../../apis/dashbboard'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'

function AdvancedConfig({ config, pricing, loading }) {
  const components = pathToComponentArrayResolver(
    import.meta.globEager('./sections/**/!(*.test.[jt]sx)*.([jt]sx)'),
  )
  const [activeIdx, setActiveIdx] = useState(0)
  const { t } = useTranslation()

  const TABS = [
    {
      content: 'CSS',
      id: 'css',
    },
    {
      content: 'JavaScript',
      id: 'js',
    },
    // {
    //   content: 'Manual Sync',
    //   id: 'sync',
    // },
    {
      content: 'Advanced Config',
      id: 'advanced',
    },
  ]

  const Component = components.find(
    (component) => component.key === TABS[activeIdx].id,
  ).component

  const customCssAvailable = areFeaturesEnabled(
    [FEATURES.CUSTOM_CSS],
    window.shopify.data,
  )

  const fields = {
    skipParentProduct: useChoiceField(config.extra?.skipParentProduct || false),
    storeData: useField({
      value: config.storeData,
      validates: [
        notEmpty(
          t(
            'SettingsPage.Sections.Advanced.Section.advanced.StoreData.validateText',
          ),
        ),
        (val) => {
          try {
            JSON.parse(val)
            return null
          } catch (e) {
            return t(
              'SettingsPage.Sections.Advanced.Section.advanced.StoreData.invalidText',
            )
          }
        },
      ],
    }),
    js: useField(config.js),
  }

  if (customCssAvailable) {
    fields.css = useField(config.css)
  }

  const form = useForm({
    fields,
    onSubmit: async ({ storeData: data, css, js, skipParentProduct }) => {
      const storeData = JSON.parse(config.storeData ?? '{}')
      merge(storeData, JSON.parse(data))
      const res = await updateShopConfig({
        data: storeData,
        css,
        js,
        extra: {
          ...config.extra,
          skipParentProduct: skipParentProduct.checked
        },
      })
      if (res.data) {
        shopify.toast.show(
          t('DefaultText.updateSuccess', {
            text: t('DefaultText.settings'),
          }),
        )
      } else {
        console.log('Error updating shop config in AdvancedSection', res.error)
      }
      return {
        status: 'success',
      }
    },
  })

  useEffect(() => {
    if (form.submitErrors.length) {
      shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
      })
    }
  }, [form.submitErrors])

  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Advanced.title')}
      description={t('SettingsPage.Sections.Advanced.description')}
    >
      <Card padding={'0'}>
        <Box padding={'0'}>
          <Tabs disabled={loading} selected={activeIdx} onSelect={setActiveIdx} tabs={TABS} />
        </Box>
        <Divider />
        <Box padding={'400'}>
          <Component
            pricing={pricing}
            form={form}
            config={config}
            loading={loading}
          />
        </Box>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default AdvancedConfig

function pathToComponentArrayResolver(obj) {
  const arr = []
  for (const [key, value] of Object.entries(obj)) {
    const temp = key.split('/')
    arr.push({
      key: temp[temp.length - 1].replace('.jsx', ''),
      component: value.default,
    })
  }
  return arr
}
