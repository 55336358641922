import getApiClient from './index.js'

export async function getCheckoutUpsellAnalytics(filters) {
  try {
    const client = await getApiClient()
    const { data } = await client.post('/api/checkout/analytics', filters)
    return {
      error: null,
      data: data.aggregates,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function getCampaigns(filter) {
  try {
    const client = await getApiClient()
    const { data } = await client.post('/api/checkout/campaign/list', filter)
    return {
      error: null,
      data: data.campaigns,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function updateCampaign(id, payload) {
  try{
    const client = await getApiClient()
    const { data } = await client.post(`/api/checkout/campaign/${id}`, payload)
    return {
      error: null,
      data: data.campaign,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function createCampaign(payload) {
  try {
    const client = await getApiClient()
    const { data } = await client.post('/api/checkout/campaign', payload)
    return {
      error: null,
      data: data.campaign,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function getCampaignDetails(id) {
  const { data, error } = await getCampaigns()
  if (error) {
    return {
      error,
      data: null,
    }
  }
  return {
    error: null,
    data: data.find((campaign) => campaign.id === id) ?? null,
  }
}

export async function getCheckoutUpsellConfig() {
  try {
    const client = await getApiClient()
    const { data } = await client.get('/api/checkout')
    return {
      error: null,
      data: data.config,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function updateCheckoutUpsellConfig(payload) {
  try {
    const client = await getApiClient()
    const { data } = await client.post('/api/checkout', payload)
    return {
      error: null,
      data: data.config,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}
