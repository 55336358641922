import React from 'react'
import { Card, ExceptionList, Link, Button } from '@shopify/polaris'
import { AlertCircleIcon, LockIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'

function SearchAPI({
  dashboardData,
  pricing
}) {
  const { t } = useTranslation()
  const featureEnabled = areFeaturesEnabled([FEATURES.SEARCH],dashboardData)

  if (!featureEnabled) {
    return (
      <Card>
        <ExceptionList
          items={[
            {
              icon: LockIcon,
              description: t('Features.notAvailableInPlanText', {
                cta: (
                  <Button
                    loading={!featureEnabled && pricing.isLoading}
                    variant="plain"
                    onClick={() => {
                      pricing.open({
                        features: [FEATURES.SEARCH],
                      })
                    }}
                  >
                    {t('DefaultText.upgradeText')}
                  </Button>
                ),
                feature: t('Features.labels.search'),
              }),
            },
          ]}
        />
      </Card>
    )
  }

  return (
    <Card>
      <ExceptionList
        items={[
          {
            icon: AlertCircleIcon,
            description: t('DefaultText.noAccessPage', {
              link: <Link monochrome>Chat</Link>,
            }),
          },
        ]}
      />
    </Card>
  )
}

export default SearchAPI
