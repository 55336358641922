import {
  Button,
  Card,
  InlineError,
  Form,
  SkeletonBodyText,
  Box,
} from '@shopify/polaris'

import CodeEditor from '../../../../components/CodeEditor'
import CollapsableErrorWindow from '../../../../components/CollapsableErrorWindow'

function Liquid({ config, form }) {
  return (
    <Form onSubmit={form.submit}>
      <div
        style={{
          position: 'relative',
          padding: 'var(--p-space-400) var(--p-space-400) var(--p-space-400) 0',
        }}
      >
        {form.fields.liquidEditor.error && (
          <InlineError message={form.fields.liquidEditor.error} />
        )}
        <CodeEditor
          onChange={form.fields.liquidEditor.onChange}
          loading={
            <Box padding={'400'}>
              <SkeletonBodyText lines={20} />
            </Box>
          }
          value={form.fields.liquidEditor.value}
          mode={'liquid'}
          height={500}
        />

        <div
          style={{
            paddingLeft: 'var(--p-space-400)',
          }}
        >
          <Button
            submit
            loading={form.submitting}
            variant="primary"
            size="large"
          >
            Save
          </Button>
        </div>
        <CollapsableErrorWindow error={config.htmlError} />
      </div>
    </Form>
  )
}

export default Liquid
