import {
  BlockStack,
  Button,
  Page,
  EmptyState,
  Card,
  Layout,
  Box,
  InlineGrid,
  Text,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris'
import React, { Children, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { ArrowLeftIcon } from '@shopify/polaris-icons'

import ProductSelector from '../../../../components/ProductSelector'
import ConfigureProductRecommendation from '../Configure'
import {
  getProductRecommendationDetails,
  updateProductRecommendations,
} from '../../../../apis/recommendation'
import { useRedirect } from '../../../../hooks'
import { fetchProduct } from '../../../../apis/shopify'
import { CREATION_TYPE } from '../../../../constants/recommendations'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  SIMILAR_PRODUCTS,
} from '../../../../constants/widgets'
import queryClient from '../../../../utils/query'
import { getQueryParams } from '../../../../utils/router'
import { useDashboardDetails } from '../../../../hooks/useDashboardDetails'
import ErrorScreen from '../../../../components/ErrorScreen'

export default function ProductRecommendationEdit({
  productId: pId,
  widgetType: wt,
  skipPage = false,
  embedded = false,
  onPageBack,
  hideOtherWidgets = false,
  title,
}) {
  const { t } = useTranslation()
  /**
   *  0 -> Bought Together
   *  1 -> Similar Products
   *  2 -> Cross Sell
   */
  const { productId: productIdFromParams, widgetType: widgetTypeFromParams } =
    useParams()
  const [productAdding, setProductAdding] = useState(false)
  const [productId, widgetType] = [
    pId ?? productIdFromParams,
    wt ?? widgetTypeFromParams,
  ]
  const selectedTab = getTabIndex(widgetType)
  const { redirectToLink } = useRedirect()
  const productQuery = useQuery({
    queryFn: async () => {
      const res = await fetchProduct(productId, true)
      if (res.error) return Promise.reject(res.error)
      return { ...res.data, id: res.data.id }
    },
    queryKey: ['product', productId],
    refetchOnWindowFocus: false,
  })
  const dashboardQuery = useDashboardDetails()
  const params = getQueryParams()
  const recommendationQuery = useQuery({
    queryFn: async () => {
      const res = await getProductRecommendationDetails({
        productId,
        widgetTypes: [widgetType],
      })
      if (res.error) return Promise.reject(res.error)
      const { recommendations } = res.data
      const processed = {
        similarProducts: (
          recommendations.filter(
            ({ recommendationType }) =>
              recommendationType === SIMILAR_PRODUCTS.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType }) => ({
          product: recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
        })),
        frequentlyBoughtTogether: (
          recommendations.filter(
            ({ recommendationType }) =>
              recommendationType === BOUGHT_TOGETHER.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType }) => ({
          product: recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
        })),
        crossSell: (
          recommendations.filter(
            ({ recommendationType }) => recommendationType === CROSS_SELL.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType }) => ({
          product: recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
        })),
      }
      try {
        let err = null
        const populated = {
          similarProducts: await Promise.all(
            processed.similarProducts.map(
              async ({ product: id, aiGenerated }) => {
                const productResponse = await fetchProduct(id, true)
                if (productResponse.error) {
                  err = productResponse.error
                }
                const product = productResponse.data
                return {
                  ...product,
                  id: product.id,
                  aiGenerated,
                }
              },
            ),
          ),
          crossSell: await Promise.all(
            processed.crossSell.map(async ({ product: id, aiGenerated }) => {
              const productResponse = await fetchProduct(id, true)
              if (productResponse.error) {
                err = productResponse.error
              }
              const product = productResponse.data
              return {
                ...product,
                id: product.id,
                aiGenerated,
              }
            }),
          ),
          frequentlyBoughtTogether: await Promise.all(
            processed.frequentlyBoughtTogether.map(
              async ({ product: id, aiGenerated }) => {
                const productResponse = await fetchProduct(id, true)
                if (productResponse.error) {
                  err = productResponse.error
                }
                const product = productResponse.data
                return {
                  ...product,
                  aiGenerated,
                }
              },
            ),
          ),
        }
        if(err) {
          return Promise.reject(err)
        }
        return populated
      } catch (e) {
        return Promise.reject(e)
      }
    },
    queryKey: ['productRecommendationDetails', productId],
    refetchOnMount: false,
    staleTime: 0,
  })
  const [recommendations, setRecommendations] = useState([])
  const [activeProduct, setActiveProduct] = useState({})

  const product = productQuery.data
  const recommendationsData = recommendationQuery.data

  const handleRecommendationChange = async (products) => {
    let sectionType = ''
    switch (selectedTab) {
      case 0:
        sectionType = BOUGHT_TOGETHER.value
        break
      case 1:
        sectionType = SIMILAR_PRODUCTS.value
        break
      case 2:
        sectionType = CROSS_SELL.value
        break
    }
    const payload = {
      product: productId,
      recommendationIds: products
        .filter((p) => !p.aiGenerated)
        .map(({ id }) => parseInt(id.replace('gid://shopify/Product/', ''))),
      widgetType: sectionType,
    }
    setProductAdding(true)
    const res = await updateProductRecommendations(payload)
    setProductAdding(false)
    if (res.error) {
      console.log('error in updating recommendation sequence', res.error)
    }
    await queryClient.invalidateQueries([
      'productRecommendationDetails',
      productId,
    ])
    shopify.toast.show(t('DefaultText.recUpdatedText'))
  }

  const handleReverseProductChange = async (products) => {
    let sectionType = ''
    switch (selectedTab) {
      case 0:
        sectionType = BOUGHT_TOGETHER.value
        break
      case 1:
        sectionType = SIMILAR_PRODUCTS.value
        break
      case 2:
        sectionType = CROSS_SELL.value
        break
    }
    const payload = {
      product: productId,
      recommendationIds: products
        .filter((p) => !p.aiGenerated)
        .map(({ id }) => parseInt(id.replace('gid://shopify/Product/', ''))),
      widgetType: sectionType,
      reverseProducts: products
        .map(({ id }) => parseInt(id.replace('gid://shopify/Product/', '')))
        .filter(
          (id) =>
            recommendations.find((rec) => rec.id.includes(id)) === undefined,
        ),
    }
    setProductAdding(true)
    const res = await updateProductRecommendations(payload)
    setProductAdding(false)
    if (res.error) {
      console.log('error in updating recommendation sequence', res.error)
    }
    await queryClient.invalidateQueries([
      'productRecommendationDetails',
      productId,
    ])
    shopify.toast.show(t('DefaultText.recUpdatedText'))
  }

  useEffect(() => {
    if (recommendationsData) {
      let recommendations = []
      switch (selectedTab) {
        case 0:
          recommendations = recommendationsData?.frequentlyBoughtTogether ?? []
          break
        case 1:
          recommendations = recommendationsData?.similarProducts ?? []
          break
        case 2:
          recommendations = recommendationsData?.crossSell ?? []
          break
      }
      setRecommendations(recommendations)
    }
    if (product) {
      setActiveProduct(product)
    }
  }, [recommendationsData, product, selectedTab])

  const Wrapper = ({ children }) =>
    skipPage ? (
      children
    ) : (
      <Page
        backAction={{
          url: `/settings/recommendation/${widgetType}/product${params.back ? `?back=${params.back}` : ''}`,
        }}
        fullWidth
        title={t('RecommendationPage.NewProductRecommendation.title')}
        subtitle={t('RecommendationPage.NewProductRecommendation.description')}
      >
        {children}
      </Page>
    )

  if (!productQuery.isLoading && !product) {
    return (
      <Page
        fullWidth
        backAction={{
          onAction: () => {
            window.history.back()
          },
        }}
      >
          <Card>
            <EmptyState
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              heading={t('Section.NotFound.heading')}
            >
              <p>{t('Section.NotFound.description')}</p>
            </EmptyState>
          </Card>
      </Page>
    )
  }

  if(productQuery.error || recommendationQuery.error || dashboardQuery.error) {
    return <ErrorScreen />
  }

  if (
    recommendationQuery.isLoading ||
    productQuery.isLoading ||
    dashboardQuery.isLoading
  ) {
    return (
      <Skeleton
        title={
          skipPage
            ? null
            : t('RecommendationPage.NewProductRecommendation.title')
        }
      />
    )
  }


  return (
    <Wrapper>
      <BlockStack gap={'400'}>
        {skipPage && (
          <Box padding={'0'}>
            <InlineGrid columns={'auto 1fr'} gap={'300'} alignItems="start">
              <Button
                icon={ArrowLeftIcon}
                variant="plain"
                onClick={onPageBack}
              />
              <BlockStack>
                <Text as="strong">
                  {title ??
                    t('RecommendationPage.NewProductRecommendation.title')}
                </Text>
                <Text>
                  {t('RecommendationPage.NewProductRecommendation.description')}
                </Text>
              </BlockStack>
            </InlineGrid>
          </Box>
        )}
        <ProductSelector
          product={activeProduct}
          onChange={(product) => {
            const { id } = product
            const url = `/settings/recommendation/${widgetType}/product/${id.replace(
              'gid://shopify/Product/',
              '',
            )}`
            if (embedded) {
              setActiveProduct(product)
              return
            }
            redirectToLink({
              url,
              external: false,
            })
          }}
          currencyFormat={dashboardQuery.data?.config?.currencyFormat}
        />
        <ConfigureProductRecommendation
          hideOtherWidgets={hideOtherWidgets}
          widgetType={widgetType}
          selectedTab={selectedTab}
          selectedProducts={recommendations}
          setSelectedProducts={(products) => {
            handleRecommendationChange(products)
            setRecommendations(products)
          }}
          product={product}
          onProductTitleClick={(product) => {
            if (confirm('Are you sure you want to leave this page?')) {
              const id =
                typeof product.id === 'string'
                  ? product.id.replace('gid://shopify/Product/', '')
                  : product.id
              redirectToLink({
                url: `/settings/recommendation/${widgetType}/product/${id}`,
              })
            }
          }}
          loading={productAdding}
          currencyFormat={dashboardQuery.data?.config?.currencyFormat}
          onReverseProductChange={handleReverseProductChange}
        />
      </BlockStack>
      <div style={{ padding: 'var(--p-space-600)' }} />
    </Wrapper>
  )
}

function Skeleton({ title }) {
  return (
    <SkeletonPage primaryAction fullWidth title={title}>
      <Layout>
        <Layout.Section>
          <BlockStack gap={'400'}>
            <Card>
              <SkeletonBodyText />
            </Card>
            <Card>
              <BlockStack gap={'300'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

function getTabIndex(widgetType) {
  switch (widgetType) {
    case BOUGHT_TOGETHER.value:
      return 0
    case SIMILAR_PRODUCTS.value:
      return 1
    case CROSS_SELL.value:
      return 2
    default:
      return 0
  }
}
