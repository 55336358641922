import {
  useForm,
  useField,
  useChoiceField,
  notEmpty,
} from '@shopify/react-form'

export function useOnboardingForm({ onSubmit, currentValues = {} }) {
  const shopType = useField({
    value: '',
    validates: [notEmpty('Please select a shop type')],
  })
  const email = useField({
    value: currentValues.email,
    validates: [
      (val) => {
        if (!val) {
          return 'Email is required'
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(val)) {
          return 'Invalid email format'
        }
        return null
      },
    ],
  })
  const reasonsToInstall = useField({
    value: [],
    validates: [notEmpty('Please select atleast one reason')],
  })
  const reasonsToInstallOther = useField({
    value: '',
    validates: [notEmpty('Please specify the reason')],
  })
  const channel = useField({
    value: '',
    validates: [notEmpty('Please select a channel')],
  })
  const extraStores = useChoiceField(false)
  const shopSubType = useField({
    value: '',
    validates: [notEmpty('Please select atleast one sub category')],
  })
  const shopSubTypeOther = useField({
    value: '',
    validates: [notEmpty('Please specify the sub category')],
  })
  const numberOfStores = useField(
    {
      value: 1,
      validates: [
        (val) => {
          if (val === 0) {
            return 'Please enter number of stores'
          }
          return null
        },
      ],
    },
    [extraStores.checked],
  )
  const isAgency = useChoiceField(false)
  const agencyDetails = {
    name: useField(
      {
        value: '',
        validates: [
          (val) => {
            if (!val) {
              return 'Name is required'
            }
            return null
          },
        ],
      },
      [isAgency.checked],
    ),
    email: useField(
      {
        value: currentValues.email,
        validates: [
          (val) => {
            if (!val) {
              return 'Email is required'
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailRegex.test(val)) {
              return 'Invalid email format'
            }
            return null
          },
        ],
      },
      [isAgency.checked],
    ),
    phone: useField(
      {
        value: '',
        validates: [
          (val) => {
            if (!val) {
              return 'Phone number is required'
            }
            return null
          },
        ],
      },
      [isAgency.checked],
    ),
    address: useField(
      {
        value: '',
        validates: [
          (val) => {
            if (!val) {
              return 'Address is required'
            }
            return null
          },
        ],
      },
      [isAgency.checked],
    ),
    clients: useField(
      {
        value: '',
        validates: [
          (val) => {
            if (!val) {
              return 'Clients are required'
            }
            return null
          },
        ],
      },
      [isAgency.checked],
    ),
  }

  const fields = {
    shopType,
    email,
    reasonsToInstall,
    channel,
    extraStores,
    shopSubType,
    isAgency,
  }

  if (extraStores.checked) {
    fields.numberOfStores = numberOfStores
  }

  if (isAgency.checked) {
    fields.agencyDetails = agencyDetails
  }

  if (shopSubType.value === 'others') {
    fields.shopSubTypeOther = shopSubTypeOther
  }

  if (reasonsToInstall.value.includes('others')) {
    fields.reasonsToInstallOther = reasonsToInstallOther
  }

  return useForm({
    fields,
    onSubmit
  })
}
