import React, { useState } from 'react'
import {
  BlockStack,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Layout,
  Tag,
  Text,
  ResourceList,
  ResourceItem,
  Avatar,
  FormLayout,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { EditIcon } from '@shopify/polaris-icons'

import { DISCOUNT_TYPES } from '../../../../constants/discounts'

function BundleConfig({
  discountType,
  discountValue,
  discountMessage,
  cartMessage,
  onClick,
  enabled,
}) {
  const { t } = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.BundleConfig.title')}
      description={t('Section.Details.BundleConfig.description')}
    >
      <Card>
        <BlockStack gap={'400'}>
          <InlineGrid columns={'1fr auto'}>
            <div />
            <div>
              <Button icon={EditIcon} onClick={onClick}>
                {t('Section.Details.BundleConfig.btnText')}
              </Button>
            </div>
          </InlineGrid>
          {enabled ? (
            <>
              <InlineGrid columns={2} gap={'400'}>
                <div>
                  <Text as="h4" variant="bodyMd">
                    {t(
                      'Section.Details.BundleConfig.FormFields.DiscountType.label',
                    )}
                  </Text>
                  <Text variant="headingMd" as="strong">
                    {
                      Object.values(DISCOUNT_TYPES).find(
                        ({ value }) => value === discountType,
                      ).label
                    }
                  </Text>
                </div>
                <div>
                  <Text as="h4" variant="bodyMd">
                    {t(
                      'Section.Details.BundleConfig.FormFields.DiscountValue.label',
                    )}
                  </Text>
                  <Text variant="headingMd" as="strong">
                    {discountValue}
                  </Text>
                </div>
              </InlineGrid>
              <InlineGrid columns={2} gap={'400'}>
                <div>
                  <Text as="h4" variant="bodyMd">
                    {t(
                      'Section.Details.BundleConfig.FormFields.DiscountMessage.label',
                    )}
                  </Text>
                  <Text variant="headingMd" as="strong">
                    {discountMessage}
                  </Text>
                </div>
                <div>
                  <Text as="h4" variant="bodyMd">
                    {t(
                      'Section.Details.BundleConfig.FormFields.MsgOnCartPage.label',
                    )}
                  </Text>
                  <Text variant="headingMd" as="strong">
                    {cartMessage}
                  </Text>
                </div>
              </InlineGrid>
            </>
          ) : (
            <Text>{t('Section.Details.BundleConfig.disabled')}</Text>
          )}
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default BundleConfig
