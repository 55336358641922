import {
  BlockStack,
  ColorPicker,
  Text,
  hsbToHex,
  hexToRgb,
  rgbToHsb,
  Box,
  InlineStack,
  TextField,
  Popover,
  InlineGrid,
} from '@shopify/polaris'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function InputColor({
  label,
  value='',
  onChange,
  helpText,
  error
}) {
  const [popoverActive, setPopoverActive] = useState(false)
  const {t} = useTranslation()
  function isValidColor(color = '') {
    // Regular expression to match hex color code
    var hexRegex = /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/
    // Regular expression to match rgba color code
    var rgbaRegex = /^rgba?\(\s*(\d{1,3}\s*,\s*){2}\d{1,3}\s*(,\s*(0|1|0?\.\d+))?\)$/
    // Regular expression to match simple color names
    var colorNames = ['black', 'blue', 'red', 'green', 'yellow', 'purple', 'orange', 'pink', 'brown', 'gray', 'white']

    // Test if the input matches any of the patterns
    if (hexRegex.test(color)) {
      return true
    } else if (rgbaRegex.test(color)) {
      return true
    } else if (colorNames.includes(color.toLowerCase())) {
      return true
    }
    return false
  }
  return (
    <Popover
      activator={
        <>
          <InlineGrid gap="200" columns={'1fr auto'}>
            <TextField
              label={label}
              value={value}
              onChange={onChange}
              helpText={helpText}
              onFocus={() => setPopoverActive(true)}
              error={error ? error : !isValidColor(value) ? t('invalidColor') : undefined}
            />
            <div
              onClick={() => setPopoverActive(true)}
              style={{
                height: '2rem',
                width: '2rem',
                borderRadius: '100%',
                border: '1px solid',
                borderColor: 'black',
                backgroundColor: value,
                cursor: 'pointer',
                position: 'relative',
                transform: 'translateY(23px)',
              }}
            ></div>
          </InlineGrid>
        </>
      }
      active={popoverActive}
      autofocusTarget="first-node"
      onClose={() => setPopoverActive(false)}
    >
      <ColorPicker
        onChange={(val) => onChange(hsbToHex(val))}
        color={rgbToHsb(hexToRgb(value))}
      />
    </Popover>
  )
}
