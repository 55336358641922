import React from 'react'
import {
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { openChat } from '../../../../components/ExternalScripts'

function LocationSection({ form, defaultValues }) {
  const { t } = useTranslation()

  return (
    <Layout.AnnotatedSection
      id="translation"
      description={t('Section.Edit.Sections.LocationPosition.description')}
      title={t('Section.Edit.Sections.LocationPosition.title')}
    >
      <Card>
        <BlockStack gap={'200'}>
          <InlineGrid columns={'1fr auto auto'} gap={'200'}>
            <div />
            <Button
              onClick={openChat}
              url={
                !window.HubSpotConversations?.widget
                  ? 'mailto:support@glood.ai'
                  : null
              }
            >
              {t('DefaultText.support')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                document.getElementById('location-tut-modal').show()
              }}
            >
              {t('DefaultText.tutorial')}
            </Button>
          </InlineGrid>
          <TextField
            label={t(
              'Section.Edit.Sections.LocationPosition.FormFields.Location.label',
            )}
            helpText={t(
              'Section.Edit.Sections.LocationPosition.FormFields.Location.helpText',
            )}
            {...form.fields.location}
          />
          <TextField
            type="number"
            label={t(
              'Section.Edit.Sections.LocationPosition.FormFields.Position.label',
            )}
            helpText={t(
              'Section.Edit.Sections.LocationPosition.FormFields.Position.helpText',
            )}
            {...form.fields.position}
          />
        </BlockStack>
      </Card>
      <ui-modal id="location-tut-modal" variant="large">
        <div
          style={{
            padding: 'var(--p-space-400)',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          Instructions video will be uploaded soon here. For now, You can
          contact our support team for any help from{' '}
          <a onClick={openChat}>here</a>.
        </div>
        <ui-title-bar
          title={t(
            'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.insModalTitle',
          )}
        ></ui-title-bar>
      </ui-modal>
    </Layout.AnnotatedSection>
  )
}

export default LocationSection
