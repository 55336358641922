import React from 'react'
import {
  BlockStack,
  TextField,
  DatePicker as ShopifyDatePicker,
  Card,
  Popover,
  Box,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import OutClick from 'react-outclick'

export default function GeneralInfo({ form }) {
  const { t } = useTranslation()
  return (
    <Card>
      <BlockStack gap={'100'}>
        <Text variant="headingMd">
          {t('CheckoutUpsell.CampaignCreate.section.form.title')}
        </Text>
        <BlockStack gap={'100'}>
          <TextField
            {...form.fields.name}
            placeholder={t(
              'CheckoutUpsell.CampaignCreate.section.form.name.placeholder',
            )}
            label={t('CheckoutUpsell.CampaignCreate.section.form.name.label')}
          />
          <DatePicker
            selectedDates={form.fields.duration.value}
            setSelectedDates={form.fields.duration.onChange}
            label={t(
              'CheckoutUpsell.CampaignCreate.section.form.duration.label',
            )}
          />
        </BlockStack>
      </BlockStack>
    </Card>
  )
}

function DatePicker({ selectedDates, setSelectedDates, label }) {
  const [{ month, year }, setDate] = React.useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const [popoverActive, setPopoverActive] = React.useState(false)

  const handleMonthChange = React.useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  return (
    <OutClick
      onOutsideClick={() => {
        setPopoverActive(false)
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <Popover
          active={popoverActive}
          activator={
            <TextField
              label={label}
              placeholder="Select date range"
              value={
                selectedDates.start && selectedDates.end
                  ? format(new Date(selectedDates.start), 'dd LLL, yyyy') +
                    ' - ' +
                    format(new Date(selectedDates.end), 'dd LLL, yyyy')
                  : null
              }
              onFocus={() => {
                setPopoverActive(true)
              }}
            />
          }
        >
          <Box padding={'300'}>
            <ShopifyDatePicker
              month={month}
              year={year}
              allowRange
              onChange={(val) => {
                setSelectedDates(val)
              }}
              onMonthChange={handleMonthChange}
              selected={{
                start: selectedDates?.start ?? new Date(),
                end: selectedDates?.end ?? new Date(),
              }}
            />
          </Box>
        </Popover>
      </div>
    </OutClick>
  )
}
