import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BlockStack, Box, Card, Divider, Page, Tabs, useBreakpoints } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../hooks'
import AnalyticsSections from './screens/Sections'
import AnalyticsOrderAttribution from './screens/OrderAttribution'
import AnalyticsTopSelling from './screens/TopSelling'
import AnalyticsPostCheckout from './screens/PostCheckout'
import AnalyticsExperience from './screens/Experiences'

function Analytics() {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  // Id of a tab is mapped to its slug and corresponding component
  const TABS = [
    {
      content: t('Analytics.tabs.tab1.title'),
      id: 'section',
    },
    {
      content: t('Analytics.tabs.tab2.title'),
      id: 'order-attribution',
    },
    // {
    //   content: t('Analytics.tabs.tab3.title'),
    //   id: 'top-selling',
    // },
    // {
    // 	content: 'Post Checkout',
    // 	id: 'post-checkout',
    // },
    {
      content: t('Analytics.tabs.tab5.title'),
      id: 'experience',
    },
  ]
  const { activeAnalyticsSection = null } = useParams()
  const { redirectToLink } = useRedirect()

  const [activeIndex, setActiveIndex] = React.useState(
    TABS.map(({ id }) => id).indexOf(activeAnalyticsSection) < 0
      ? 0
      : TABS.map(({ id }) => id).indexOf(activeAnalyticsSection),
  )

  useEffect(() => {
    if (!activeAnalyticsSection) {
      redirectToLink({
        url: '/analytics/section',
        external: false,
      })
    }
    const ind = TABS.map(({ id }) => id).indexOf(activeAnalyticsSection)
    setActiveIndex(ind < 0 ? 0 : ind)
  }, [activeAnalyticsSection])

  const ActiveComponent = SlugToComponentMap[activeAnalyticsSection]

  return (
    <Page
    fullWidth
    backAction={{
      url:'/'
    }}
      title={t('Analytics.title')}
      subtitle={t('Analytics.description')}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <Card padding={'0'}>
          <BlockStack gap={'200'}>
            <Tabs
              tabs={TABS}
              selected={activeIndex}
              onSelect={(idx) => {
                const tab = TABS[idx]
                redirectToLink({
                  url: `/analytics/${tab.id}`,
                  external: false,
                })
              }}
            />
            <Box padding={'200'}>
              {ActiveComponent && <ActiveComponent />}
            </Box>
            {/* {SlugToComponentMap[activeAnalyticsSection]()} */}
          </BlockStack>
        </Card>
      </Box>
      <Box minHeight="var(--p-space-300)" width="100%"></Box>
    </Page>
  )
}

export default Analytics

const SlugToComponentMap = {
  section: AnalyticsSections,
  'order-attribution': AnalyticsOrderAttribution,
  'top-selling': AnalyticsTopSelling,
  // 'post-checkout': AnalyticsPostCheckout,
  experience: AnalyticsExperience,
}
