import {
  BlockStack,
  Button,
  Card,
  Grid,
  InlineGrid,
  Text,
} from '@shopify/polaris'
import { EditIcon } from '@shopify/polaris-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function SlotsRecommendation({ slots, onClick }) {
  const { t } = useTranslation()

  return (
    <Card>
      <BlockStack gap={'300'}>
        <InlineGrid columns={'1fr auto'} gap={'200'}>
          <BlockStack gap={'100'}>
            <Text as="strong">
              {t('Section.Details.SlotsRecommendation.title')}
            </Text>
            <Text>{t('Section.Details.SlotsRecommendation.description')}</Text>
          </BlockStack>
          <div>
            <Button icon={EditIcon} onClick={onClick}>
              {t('Section.Details.SlotsRecommendation.btnText')}
            </Button>
          </div>
        </InlineGrid>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 'var(--p-space-200)',
          }}
        >
          {slots.map((slot, index) => (
            <Grid.Cell>
              <SlotCard slot={slot} index={index} />
            </Grid.Cell>
          ))}
        </div>
      </BlockStack>
    </Card>
  )
}

export default SlotsRecommendation

function SlotCard({ slot, index }) {
  const [isEditing, setIsEditing] = useState(false)
  const { t } = useTranslation()
  return (
    <CustomCard>
      <BlockStack gap={'100'}>
        <Text>
          {t(
            'Section.Edit.Sections.Recommendation.SlotsRecommendation.SlotCard.title',
            { number: index + 1 },
          )}
        </Text>
        <Text as="strong">{t(`Widgets.${slot.recommendation}.label`)}</Text>
      </BlockStack>
    </CustomCard>
  )
}

function CustomCard({ children }) {
  return (
    <div
      style={{
        padding: 'var(--p-space-400)',
        borderRadius: 'var(--p-border-radius-400)',
        border:
          'var(--p-border-width-025) solid var(--p-color-border-tertiary)',
        height: '100%',
      }}
    >
      {children}
    </div>
  )
}
