import React, { useMemo } from 'react'
import {
  BlockStack,
  Box,
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useQuery } from 'react-query'

import AccountSection from './sections/account'
import SettingsSection from './sections/setting'
import IntegrationsSection from './sections/integrations'
import AdvancedConfig from './sections/AdvancedSection'
import { useDashboardDetails } from '../../hooks/useDashboardDetails'
import { numberFormater } from '../../utils/formater'
import TemplatesSection from './sections/templates'
import { getPlans } from '../../apis/plans'
import Recommendation from './sections/recommendations'
import { PricingModal, usePricing } from '../../components/PricingModal'
import DashboardSettings from './sections/dashboard'
import { getQueryParams } from '../../utils/router'

const SURPASS_THRESHOLD = 0.8

function Settings() {
  const { t } = useTranslation()

  const { data = {}, dashboardLoading } = useDashboardDetails()
  const pricing = usePricing()
  const breakpoints = useBreakpoints()
  const { isLoading: plansLoading, data: [plan] = [] } = useQuery({
    queryKey: ['plans'],
    queryFn: async () => {
      const {
        shopPlan: { planId },
      } = window.shopify.data
      const res = await getPlans({
        id: planId,
      })
      if (res.error) {
        return []
      }
      return res.data
    },
  })
  const { recSection = null } = getQueryParams()

  const settings = useMemo(
    () => ({
      account: {
        monthlyCharge: '$' + data?.shopPlan?.price,
        annualCharge: '$' + data?.shopPlan?.annualPrice,
        additionalUsageCharge: data?.shopPlan?.isExtendedUsageEnabled
          ? '$0.0'
          : null,
        billingPeriod: `${data.billingCycle?.startedOn && format(new Date(data.billingCycle?.startedOn), 'dd MMM, yyyy')} - ${data.billingCycle?.endingOn && format(new Date(data.billingCycle?.endingOn), 'dd MMM, yyyy')}`,
        widgetsConsumed: parseFloat(
          (
            data.billingCycle?.currentUsage /
            data.billingCycle?.recommendationsServedHardLimit
          ).toFixed(2) ?? 0,
        ),
        consumedWidgets: numberFormater(data.billingCycle?.currentUsage) ?? 0,
        totalWidgets: numberFormater(
          data.billingCycle?.maximumRecummendationsServed,
        ),
        maxPostWidgets: numberFormater(
          data.billingCycle?.recommendationsServedHardLimit,
        ),
        upgradeRequired: data?.shopPlan?.price === '0.00',
        planName: plan?.name,
        usageSurpassed:
          data.billingCycle?.currentUsage >=
          data.billingCycle?.recommendationsServedHardLimit,
        usageAboutToSurpass:
          data.billingCycle?.currentUsage /
            data.billingCycle?.recommendationsServedHardLimit >
            SURPASS_THRESHOLD &&
          data.billingCycle?.currentUsage <
            data.billingCycle?.recommendationsServedHardLimit,
        onPaidPlan: parseFloat(data.shopPlan?.price) !== 0,
      },
      advancedConfig: {
        css: data.config?.css,
        js: data.config?.js,
        storeData: JSON.stringify(data.config?.data, null, 2),
        extra: data.config?.extra ?? {},
        lastTriggered: data.config?.productEventsSyncedAt
          ? format(new Date(data.config?.productEventsSyncedAt), 'dd MMM, yyyy')
          : 'Never',
        wait:
          new Date() - new Date(data.config?.productEventsSyncedAt) <
          6 * 3600 * 1000, // 6 hours
      },
      isExtendedUsageEnabled: data.shopPlan?.isExtendedUsageEnabled,
      onAnnualPlan: data.billingCycle?.isAnnual,
    }),
    [data, plan],
  )

  return (
    <Page
      fullWidth
      title={t('SettingsPage.title')}
      backAction={{
        url: '/',
      }}
      subtitle={t('SettingsPage.description')}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <Layout>
          <AccountSection
            pricing={pricing}
            shopPlan={{
              ...(data?.shopPlan ?? {}),
              plan: plan ?? {},
            }}
            billingCycle={data?.billingCycle ?? {}}
            config={settings.account}
            dashboardData={data}
            loading={dashboardLoading || plansLoading}
          />
          <Recommendation selectedTab={recSection} />
          <SettingsSection />
          <TemplatesSection />
          <IntegrationsSection />
          <AdvancedConfig
            pricing={pricing}
            config={settings.advancedConfig}
            loading={dashboardLoading || plansLoading}
          />
          <DashboardSettings />
          <Box padding={'400'} />
        </Layout>
      </Box>
      <PricingModal modal={pricing} />
    </Page>
  )
}

export default Settings

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth title={t('SettingsPage.title')}>
      <Layout>
        <Layout.Section variant="oneThird"></Layout.Section>
        <Layout.Section>
          <Card>
            <BlockStack gap={'300'}>
              <SkeletonDisplayText />
              <SkeletonBodyText lines={4} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
