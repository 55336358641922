import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  InlineGrid,
  RadioButton,
  Select,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris'
import React, { useEffect } from 'react'
import { DeleteIcon, InfoIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import {
  CAMPAIGN_CONDITION_TYPE,
  CAMPAIGN_TRIGGER_CONDITION_OPTIONS,
  CAMPAIGN_TRIGGER_OPTIONS,
} from '../../../../../../../../constants/checkoutUpsell'

const MAX_PRODS = 10,
  MAX_CONDITIONS = 5

function TriggerCard({ form, dashboardData }) {
  const { t } = useTranslation()
  const [trigger] = [form.fields.triggerType.value]
  const [triggerConditions, setTriggerConditions, errors] = [
    form.fields.triggerConditions.value,
    form.fields.triggerConditions.onChange,
    form.fields.triggerConditions.error ??
      Array.from(
        {
          length: form.fields.triggerConditions.value.length,
        },
        () => null,
      ),
  ]
  console.log('triggerConditions', errors)
  const triggerOptions = [
    {
      label: t('CheckoutUpsell.CampaignDetail.TriggerCard.option.all'),
      value: CAMPAIGN_TRIGGER_OPTIONS.ALL,
      name: 'trigger',
    },
    {
      label: t('CheckoutUpsell.CampaignDetail.TriggerCard.option.conditional'),
      value: CAMPAIGN_TRIGGER_OPTIONS.CONDITIONAL,
      name: 'trigger',
    },
  ]

  const addDummyCondition = () => {
    const newCondition = {
      type: CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS,
      data: getDefaultValues(
        CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS,
        'specific_products',
      ),
    }
    setTriggerConditions([...triggerConditions, newCondition])
  }

  useEffect(() => {
    if (trigger === CAMPAIGN_TRIGGER_OPTIONS.CONDITIONAL) {
      if (triggerConditions.length === 0) {
        addDummyCondition()
      }
    }
  }, [form.fields.triggerType.value])

  return (
    <Card padding={'0'}>
      <BlockStack>
        <Box padding={'400'}>
          <BlockStack gap={'100'}>
            <Text variant="headingMd">
              {t('CheckoutUpsell.CampaignDetail.TriggerCard.title')}
            </Text>
            {triggerOptions.map((option) => (
              <RadioButton
                key={option.value}
                label={option.label}
                helpText={''}
                checked={option.value === trigger}
                name={option.name}
                onChange={(val) => {
                  if (val) {
                    form.fields.triggerType.onChange(option.value)
                  }
                }}
                id={'glood_' + option.value}
              />
            ))}
          </BlockStack>
        </Box>
        {trigger === CAMPAIGN_TRIGGER_OPTIONS.CONDITIONAL && (
          <>
            <Divider />
            <Box padding={'400'}>
              <BlockStack gap={'300'}>
                {triggerConditions.length >= MAX_CONDITIONS && (
                  <Banner tone="warning">
                    <Text>
                      {t(
                        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.maxConditionText',
                        {
                          count: MAX_CONDITIONS,
                        },
                      )}
                    </Text>
                  </Banner>
                )}
                <InlineGrid
                  gap={'100'}
                  columns={'1fr auto'}
                  alignItems="center"
                >
                  <Text variant="headingSm">
                    {t(
                      'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.condtion',
                    )}
                  </Text>
                  <div>
                    <InlineGrid
                      columns={'auto auto auto auto'}
                      alignItems="center"
                      gap={'300'}
                    >
                      <Text variant="bodySm">
                        {t(
                          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.condtion',
                        )}
                      </Text>
                      <Select
                        options={Object.values(CAMPAIGN_CONDITION_TYPE).map(
                          (val) => ({
                            label: t(
                              `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.conditionType.${val}`,
                            ),
                            value: val,
                          }),
                        )}
                        {...form.fields.triggerConditionType}
                      />
                      <Tooltip
                        content={t(
                          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.infoText',
                        )}
                      >
                        <Icon source={InfoIcon} />
                      </Tooltip>
                      <Button
                        disabled={triggerConditions.length >= MAX_CONDITIONS}
                        onClick={addDummyCondition}
                      >
                        {t(
                          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.cta',
                        )}
                      </Button>
                    </InlineGrid>
                  </div>
                </InlineGrid>
                {triggerConditions.map((condition, index) => (
                  <TriggerConditonCard
                    error={errors[index]}
                    condition={condition}
                    dashboardData={dashboardData}
                    onChange={(newCondition) => {
                      const newTriggerConditions = [...triggerConditions]
                      newTriggerConditions[index] = newCondition
                      setTriggerConditions(newTriggerConditions)
                    }}
                    deleteCondition={() => {
                      const newTriggerConditions = [...triggerConditions]
                      newTriggerConditions.splice(index, 1)
                      setTriggerConditions(newTriggerConditions)
                    }}
                    key={index}
                  />
                ))}
                {triggerConditions.length === 0 && (
                  <Banner>
                    <p>
                      {t(
                        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.emptyText',
                      )}
                    </p>
                  </Banner>
                )}
              </BlockStack>
            </Box>
          </>
        )}
      </BlockStack>
    </Card>
  )
}

export default TriggerCard

function TriggerConditonCard({
  condition,
  onChange,
  deleteCondition,
  dashboardData,
  error,
}) {
  const { type, data } = condition
  const { t } = useTranslation()

  return (
    <Card>
      <BlockStack gap={'300'}>
        <InlineGrid columns={'1fr auto'} alignItems="start">
          <Box>
            <BlockStack gap={'300'}>
              <InlineGrid columns={'auto 1fr auto'} alignItems="center">
                <div
                  style={{
                    display: 'flex',
                    gap: 'var(--p-space-200)',
                    alignItems: 'center',
                  }}
                >
                  <Text as="strong" variant="headingXs">
                    {t(
                      'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.if',
                    )}
                  </Text>
                  <Text as="span" variant="bodyXs">
                    {t(
                      'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.subtitle',
                    )}
                  </Text>
                </div>
                <div />
                <Button
                  icon={DeleteIcon}
                  onClick={deleteCondition}
                  size="slim"
                />
              </InlineGrid>
              <ConditionSelector
                type={type}
                data={data}
                dashboardData={dashboardData}
                onChange={({ type, data }) => {
                  onChange({ type, data })
                }}
              />
            </BlockStack>
          </Box>
        </InlineGrid>
        {error && (
          <Banner tone="critical">
            <Text>{error}</Text>
          </Banner>
        )}
      </BlockStack>
    </Card>
  )
}

function ConditionSelector({ type, data, onChange, dashboardData }) {
  const { t } = useTranslation()
  const subType = data.subType

  const selector = (
    <div
      style={{
        maxWidth: '100px',
      }}
    >
      <Select
        options={Object.values(CAMPAIGN_TRIGGER_CONDITION_OPTIONS).map(
          (option) => ({
            label: t(
              `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${option}.title`,
            ),
            value: option,
          }),
        )}
        value={type}
        onChange={(val) => {
          onChange({
            type: val,
            data: getDefaultValues(val),
          })
        }}
      />
    </div>
  )

  const subTypeSelector = (
    <Select
      options={Object.keys(schema[type]).map((key) => ({
        label: t(
          `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${type}.${key}.label`,
        ),
        value: key,
      }))}
      value={subType}
      onChange={(val) => {
        onChange({
          type,
          data: getDefaultValues(type, val),
        })
      }}
    />
  )

  return (
    <BlockStack>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 'var(--p-space-200)',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {selector}
        <Text>
          {t(
            'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.in',
          )}
        </Text>
        {subTypeSelector}
        <TriggerAction
          type={type}
          data={data}
          dashboardData={dashboardData}
          onChange={(data) => {
            onChange({
              type,
              data: {
                subType,
                ...data,
              },
            })
          }}
        />
      </div>
    </BlockStack>
  )
}

function TriggerAction({ type, data, dashboardData, onChange }) {
  const { t } = useTranslation()
  const { subType, ...remData } = data
  const actionSchema = schema[type][subType]

  const handleSelectorOnChange = (val) => {
    const data = getDefaultValues(type, subType)
    delete data.subType
    data.selected = val
    onChange(data)
  }

  const selector = actionSchema?.options ? (
    <Select
      options={actionSchema?.options.map((op) => ({
        value: op,
        label: t(
          `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${type}.${subType}.options.${op}`,
        ),
      }))}
      value={remData.selected}
      onChange={handleSelectorOnChange}
    />
  ) : null

  const productSelect = (
    <Button
      size="large"
      onClick={async () => {
        try {
          const selected = await shopify.resourcePicker({
            type: 'product',
            multiple: MAX_PRODS,
            selectionIds: remData.items.map((id) => ({
              id: 'gid://shopify/Product/' + id,
            })),
            filter: {
              variants: false,
            },
          })
          if (selected) {
            onChange({
              ...remData,
              items: selected.map((item) =>
                parseInt(item.id.replace('gid://shopify/Product/', '')),
              ),
            })
          }
        } catch (e) {
          window.shopify.toast.show(t('noProductUpdate'), {
            isError: true,
          })
        }
      }}
    >
      {t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.products.select',
        {
          count: remData.items?.length,
        },
      )}
    </Button>
  )

  const collectionSelect = (
    <Button
      size="large"
      onClick={async () => {
        try {
          const selected = await shopify.resourcePicker({
            type: 'collection',
            multiple: MAX_PRODS,
            selectionIds: remData.items.map((id) => ({
              id: 'gid://shopify/Collection/' + id,
            })),
          })
          if (selected) {
            onChange({
              ...remData,
              items: selected.map((item) =>
                parseInt(item.id.replace('gid://shopify/Collection/', '')),
              ),
            })
          }
        } catch (e) {
          window.shopify.toast.show(t('noProductUpdate'), {
            isError: true,
          })
        }
      }}
    >
      {t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.products.collection.select',
        {
          count: remData.items?.length,
        },
      )}
    </Button>
  )

  const getInputValue = () => {
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
      return remData.quantity.toString()
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART) {
      return remData.total.toString()
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT) {
      return remData.discount.toString()
    }
  }

  const handleInputChange = (val) => {
    let number = 0
    if (val) {
      number = parseInt(val)
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
      onChange({
        ...remData,
        quantity: number,
      })
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART) {
      onChange({
        ...remData,
        total: number,
      })
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT) {
      onChange({
        ...remData,
        discount: number,
      })
    }
  }

  const input = (
    <TextField
      prefix={
        actionSchema?.prefix ? actionSchema.prefix(dashboardData) : undefined
      }
      value={getInputValue()}
      onChange={handleInputChange}
      max={actionSchema.max}
      min={actionSchema.min}
    />
  )

  const localisedText = t(
    `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${type}.${subType}.text`,
    {
      selector,
      productSelect,
      collectionSelect,
      input,
    },
  )

  return (
    <div
      style={{
        display: 'flex',
        gap: 'var(--p-space-200)',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {localisedText}
    </div>
  )
}

function getDefaultValues(type, subType) {
  if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
    return {
      items: [],
      subType: subType ?? 'specific_products',
      quantity: 0,
      selected: !subType
        ? 'atleast'
        : subType === 'quantity'
          ? 'greater'
          : 'atleast',
    }
  }
  if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART) {
    return {
      total: 0,
      items: [],
      subType: subType ?? 'subtotal',
      subscription: true,
      selected: !subType
        ? 'greater'
        : subType === 'subscription'
          ? 'true'
          : 'greater',
    }
  }
  if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT) {
    return {
      discount: 0,
      subType: subType ?? 'value',
      selected: 'greater',
    }
  }
}

const schema = {
  products: {
    specific_products: {
      type: 'product',
      max: 5,
      options: ['atleast', 'excluded'],
    },
    collection: {
      type: 'collection',
      max: 5,
      options: ['atleast', 'excluded'],
    },
    quantity: {
      type: 'number',
      max: 20,
      options: ['greater', 'lesser'],
    },
  },
  cart: {
    subtotal: {
      type: 'number',
      options: ['greater', 'lesser'],
      max: 999999,
      prefix: (dashboardResponse) => dashboardResponse.shop.currency,
      bannerText: (t, dashboardResponse) => {
        return t(
          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.cart.subtotal.bannerText',
          {
            currency: dashboardResponse.shop.currency,
          },
        )
      },
    },
    quantity: {
      type: 'number',
      max: 20,
      min: 1,
      options: ['greater', 'lesser'],
    },
    // subscription: {
    //   label: (t) =>
    //     t(
    //       'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.cart.subscription.label',
    //     ),
    //   type: 'boolean',
    //   options: ['true', 'false'],
    // },
  },
  discount: {
    value: {
      type: 'number',
      options: ['greater', 'lesser'],
      max: 999999,
      min: 10,
      prefix: (dashboardResponse) => dashboardResponse.shop.currency,
      bannerText: (t, dashboardResponse) =>
        t(
          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.cart.subtotal.bannerText',
          {
            currency: dashboardResponse.shop.currency,
          },
        ),
    },
  },
}
