export const TEMPLATE_LAYOUTS = {
  CAROUSEL: 'carousel',
  FBT: 'amazon_bought_together',
  CUSTOM: 'custom',
  BUCKET: 'bucket',
  HORIZONTAL_GRID: 'horizontal_grid',
}

export const TEMPLATE_TYPES = {
  WIDGET: 'widget',
  BUNDLE_PAGE: 'bundle_page',
  BUNDLE_POPUP: 'bundle_popup',
  POPUP: 'popup',
  MINI_CART: 'mini_cart',
  BUNDLE_SECTION: 'bundle_section',
}
