import {
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  BlockStack,
  Card,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export default function CampaignSkeleton({ title }) {
  const { t } = useTranslation()
  return (
    <SkeletonPage
      title={title ?? t('CheckoutUpsell.CampaignCreate.title')}
    >
      <BlockStack gap={'400'}>
        <Card>
          <BlockStack gap={'300'}>
            <SkeletonDisplayText size="medium" />
            <SkeletonBodyText lines={3} />
          </BlockStack>
        </Card>
      </BlockStack>
    </SkeletonPage>
  )
}
