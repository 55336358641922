import { BlockStack, Layout } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { getCharts, getStats } from '../../../../apis/analytics'
import WidgetFunnelChart from '../../../../components/WidgetFunnelChart'
import WidgetTabChart from '../../../../components/WidgetTabChart'
import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../../utils/formater'
import { format } from 'date-fns'

function AnalyticsSection({ sectionId, filters, shop }) {
  const { t } = useTranslation()
  const statsQuery = useQuery({
    queryKey: ['widgetStats', sectionId, filters],
    queryFn: async () => {
      const { data, error } = await getCharts({
        sections: [sectionId],
        ...filters,
      })
      if (error) return Promise.reject(error)
      return data
    },
  })
  const tilesQuery = useQuery({
    queryKey: ['widgetTiles', sectionId, filters],
    queryFn: async () => {
      const { data, error } = await getStats({
        sections: [sectionId],
        ...filters,
        dateGte: filters.dateGte / 1000,
        dateLte: filters.dateLte / 1000,
      })
      if (error) return Promise.reject(error)
      return data
    },
  })

  let ctr =
    statsQuery.data?.aggregates?.reduce(
      (prev, curr) => prev + curr.clicked,
      0,
    ) /
      statsQuery.data?.aggregates?.reduce(
        (prev, curr) => prev + curr.viewed,
        0,
      ) ?? 1
  ctr = isNaN(ctr) ? 0.0 : ctr

  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.Analytics.title')}
      description={t('Section.Details.Analytics.description')}
    >
      <BlockStack gap={'300'}>
        <WidgetTabChart
          columns={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
          }}
          loading={statsQuery.isLoading || tilesQuery.isLoading}
          stats={[
            {
              label: t('PerformanceSection.WidgetTabChart.revenueByRk'),
              value: currencyFormatter(
                shop.currency,
                tilesQuery.data?.tiles?.[0]?.nativeValue,
              ),
              total: currencyFormatter(
                shop.currency,
                tilesQuery.data?.tiles?.[0]?.nativeTotal,
              ),
              tooltipText: tilesQuery.data?.tiles?.[0]?.description,
            },
            {
              label: t(
                'PerformanceSection.WidgetTabChart.productsSoldCountByRk',
              ),
              value: numberFormater(
                tilesQuery.data?.tiles?.[1]?.value,
              ),
              total: numberFormater(
                tilesQuery.data?.tiles?.[1]?.total,
              ),
              tooltipText: tilesQuery.data?.tiles?.[1]?.description,
            },
            {
              label: t('PerformanceSection.WidgetTabChart.clickRate'),
              value: percentageFormatter(tilesQuery.data?.tiles?.[2]?.value),
              tooltipText: tilesQuery.data?.tiles?.[2]?.description,
            },
          ]}
          data={[
            {
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.nativeRevenueByRk,
                })) ?? [],
              data2:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.nativeRevenue,
                })) ?? [],
              name: t('PerformanceSection.WidgetTabChart.revenueByRk'),
              name2: 'Total Revenue',
              tooltipFormatter: (value) =>
                currencyFormatter(shop.currency, value),
              yAxisFormatter: (value) =>
                currencyFormatter(shop.currency, value),
            },
            {
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.productsSoldCountByRk,
                })) ?? [],
              data2:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.productsSoldCount,
                })) ?? [],
              name: t(
                'PerformanceSection.WidgetTabChart.productsSoldCountByRk',
              ),
              name2: 'Total Products Sold Count',
              tooltipFormatter: (value) => numberFormater(value),
              yAxisFormatter: (value) => numberFormater(value),
            },
            {
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.clickRate,
                })) ?? [],
              name: t('PerformanceSection.WidgetTabChart.clickRate'),
              tooltipFormatter: (value) => percentageFormatter(value),
              yAxisFormatter: (value) => percentageFormatter(value),
            },
          ]}
        />
        <WidgetFunnelChart
          columns={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
          }}
          data={[
            {
              name: t('PerformanceSection.WidgetFunnelChart.pageViews'),
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.ppv,
                })) ?? [],
            },
            {
              name: t('PerformanceSection.WidgetFunnelChart.sectionServed'),
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.served,
                })) ?? [],
            },
            {
              name: t('PerformanceSection.WidgetFunnelChart.sectionViews'),
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.viewed,
                })) ?? [],
            },
            {
              name: t('PerformanceSection.WidgetFunnelChart.clicks'),
              data:
                statsQuery.data?.aggregates?.map((aggregate) => ({
                  key: format(new Date(aggregate.date), 'MMM dd'),
                  value: aggregate.clicked,
                })) ?? [],
            },
          ]}
          loading={statsQuery.isLoading}
          stats={[
            {
              label: t('PerformanceSection.WidgetFunnelChart.pageViews'),
              value: numberFormater(
                statsQuery.data?.aggregates?.reduce(
                  (prev, curr) => prev + curr.ppv,
                  0,
                ),
              ),
            },
            {
              label: t('PerformanceSection.WidgetFunnelChart.sectionServed'),
              value: numberFormater(
                statsQuery.data?.aggregates?.reduce(
                  (prev, curr) => prev + curr.served,
                  0,
                ),
              ),
            },
            {
              label: t('PerformanceSection.WidgetFunnelChart.sectionViews'),
              value: numberFormater(
                statsQuery.data?.aggregates?.reduce(
                  (prev, curr) => prev + curr.viewed,
                  0,
                ),
              ),
            },
            {
              label: t('PerformanceSection.WidgetFunnelChart.clicks'),
              value: numberFormater(
                statsQuery.data?.aggregates?.reduce(
                  (prev, curr) => prev + curr.clicked,
                  0,
                ),
              ),
            },
          ]}
        />
      </BlockStack>
    </Layout.AnnotatedSection>
  )
}

export default AnalyticsSection
