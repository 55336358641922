import React from 'react'
import { Box, Card, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { numberFormater } from '../../../../../utils/formater'

Chart.register(CategoryScale)

export default function SectionPerformanceChart({ data }) {
  const { t } = useTranslation()
  return (
    <Card>
      <Box paddingBlockEnd={'400'}>
        <Text>
          {t('DashboardPage.PerformanceSection.title')}
        </Text>
      </Box>
      <div
        style={{
          height: '300px',
        }}
      >
        <Line
          data={data}
          width={'100%'}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              line: {
                tension: 0.5,
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                align: 'end',
              },
              tooltip: {
                mode: 'x',
                backgroundColor: 'white',
                titleColor: 'rgba(26, 26, 26, 1)',
                bodyColor: 'rgba(48, 48, 48, 1)',
                borderWidth: 1,
                borderColor: 'rgba(0, 0, 0, 0.1)',
                intersect: false,
                callbacks: {
                  title: (tooltipItem) => {
                    return tooltipItem[0].label
                  },
                  label: (tooltipItem) => {
                    return (
                      tooltipItem.dataset.label +
                      ': ' +
                      tooltipItem.formattedValue + '%'
                    )
                  },
                },
                usePointStyle: true,
              },
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            tooltips: {
              mode: 'x',
              intersect: false,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Month',
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                  },
                },
              ],
              y: {
                ticks: {
                    callback: function(value, index, ticks) {
                        return value.toFixed(0) + '%'
                    }
                }
              }
            },
          }}
        />
      </div>
    </Card>
  )
}
