import {
  ActionList,
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  CalloutCard,
  Card,
  DatePicker,
  EmptyState,
  InlineGrid,
  InlineStack,
  Layout,
  Page,
  Popover,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import { EditIcon, MenuVerticalIcon } from '@shopify/polaris-icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { deleteSection, updateSection } from '../../../apis/section'
import * as WIDGET_LAYOUT from '../../../constants/layouts'
import * as PAGES from '../../../constants/page'
import * as WIDGET_LIST from '../../../constants/widgets'
import { useRedirect } from '../../../hooks'
import AdvanceConfig from './sections/advanceConfg'
import BundleConfig from './sections/bundle'
import DesignSection from './sections/designSection'
import Recommendation from './sections/recommendation'
import SegmentConfiguration from './sections/segment'
import queryClient from '../../../utils/query'
import WidgetLimitReachedBanner from '../../../components/WidgetLimiltReachedBanner'
import { useShopDetails } from '../../../hooks/useShopDetails'
import SectionDisabledBanner from '../../../components/SectionDisabledBanner'
import { fetchProduct } from '../../../apis/shopify'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { getPlans } from '../../../apis/plans'
import { getTemplates } from '../../../apis/template'
import AnalyticsSection from './sections/analytics'
import { getAutoRecommendations } from '../../../apis/recommendation'
import RecommendationSettingsModal from '../../../components/RecommendationSettingModal'

function SectionDetails() {
  const { sectionId = null } = useParams()
  const [token, setToken] = useState(null)
  const [clearingCache, setClearingCache] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(new Date() - 1000 * 60 * 60 * 24 * 30),
    end: new Date(),
  })
  const breakpoints = useBreakpoints()

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  const {
    data: { billingCycle, shopPlan, config: shopConfig, shop } = {},
    isLoading: dashboardLoading,
    invalidate: invalidateDashbaordData,
  } = useDashboardDetails()

  const { data: { templates = [] } = {}, isLoading: templatesLoading } =
    useQuery({
      queryKey: ['templates'],
      queryFn: async () => {
        const res = await getTemplates()
        if (res.error) {
          return Promise.reject(res.error)
        }
        return res.data
      },
      refetchOnWindowFocus: false,
    })

  const { data: plans = [], isLoading: plansLoading } = useQuery({
    queryFn: async () => {
      const res = await getPlans()
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: ['plans'],
  })
  const { data = {}, isLoading, isRefetching } = useShopDetails()
  const autoRecQuery = useQuery({
    queryKey: ['autoRecommendations', sectionId],
    queryFn: async () => {
      const { data, error } = await getAutoRecommendations({
        recommendationType: 'promoted',
        objectType: 'page',
        section: sectionId,
      })

      if (error) {
        return Promise.reject(error)
      }

      // populate products
      const objects = data.recommendations
      const populatedObjects = await Promise.all(
        objects.map(async (obj) => {
          const {
            data: { recommendations },
          } = obj
          const products = await Promise.all(
            recommendations.map(
              async ({ value }) => (await fetchProduct(value)).data,
            ),
          )
          return {
            products,
            id: obj.id,
            url: obj.objectId,
          }
        }),
      )
      return populatedObjects
    },
  })

  const sections = data.sections ?? []
  const section =
    sections.find((section) => section.id.toString() === sectionId) ?? {}
  const experience =
    (data.experiences ?? []).find((exp) => exp.id === section.experience) ?? {}
  const page = data.pages?.find((page) => page.id === section.page) ?? {}

  const productsQuery = useQuery({
    queryKey: ['products', sectionId, section.disabledProducts],
    queryFn: async () => {
      const disabledProducts = await Promise.all(
        section.disabledProducts.map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      const excludedProducts = await Promise.all(
        section.excludedProducts.map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      const whitelistedProducts = await Promise.all(
        section.whitelistedProducts.map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      return {
        disabledProducts,
        excludedProducts,
        whitelistedProducts,
      }
    },
    enabled: Boolean(section.id),
  })

  const products = productsQuery.data ?? {}

  const widget = useMemo(
    () => ({
      title: section.title,
      id: section.id,
      experience: {
        name: experience.name,
      },
      type: section.type,
      page: page.type,
      enabled: section.enabled,
      layout: section.layout,
      excludedTags: section.excludedTags,
      disabledTags: section.disabledTags,
      whitelistedTags: section.whitelistedTags,
      excludedProducts: products.excludedProducts,
      disabledProducts: products.disabledProducts,
      whitelistedProducts: products.whitelistedProducts,
      enableBundleDiscount: section.discountConfig?.enabled ?? false,
      bundleConfig: {
        discountType: section.discountConfig?.type,
        discountValue: section.discountConfig?.value,
        discountMessage: section.discountConfig?.widgetMessage,
        cartMessage: section.discountConfig?.cartMessage,
        enabled: section.discountConfig?.enabled,
      },
      segmentationType: section.segment,
      minimumPrice: parseInt(section.minPrice),
      maximumPrice: parseInt(section.maxPrice),
      pricePercentageThreshold:
        section?.type === WIDGET_LIST.BOUGHT_TOGETHER.value
          ? section.pricePercentageThreshold
          : null,
      productRankingCriteria: section.rankingCriteria,
      productFilterCriteria: section.filterCriteria,
      fallbackCriteria: section.fallbackCriteria,
      template: section.template,
      enabledUrls: section.enabledUrls,
    }),
    [section, isLoading, isRefetching, experience, products],
  )

  const pageName = useMemo(() => {
    return (
      Object.values(PAGES).find((p) => p.value === page.type)?.label ??
      'DUMMY_NAME'
    )
  }, [widget.page, page])

  const [popoverActive, setPopoverActive] = useState(false)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )

  const widgetName = useMemo(() => {
    return (
      Object.values(WIDGET_LIST).find((w) => w.value === widget.type)?.label ??
      'DUMMY_NAME'
    )
  }, [widget.type])

  const openVisualEditor = async () => {
    const token = await shopify.idToken()
    setToken(token)
    setTimeout(() => {
      document.getElementById('visual-editor-modal')?.show()
      invalidateDashbaordData()
    }, 300)
  }

  useEffect(() => {
    function handleEvent(ev) {
      if (ev?.data === 'GAI-close-visual-editor-modal') {
        document.getElementById('visual-editor-modal')?.hide()
      }
    }
    window.addEventListener('message', handleEvent)

    return () => window.removeEventListener('message', handleEvent)
  })

  if (
    isLoading ||
    productsQuery.isLoading ||
    dashboardLoading ||
    plansLoading ||
    templatesLoading ||
    autoRecQuery.isLoading
  ) {
    return <Skeleton />
  }

  if (!section.id) {
    return (
      <Page
        fullWidth
        backAction={{
          url: '/',
        }}
      >
        <Card>
          <EmptyState
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            heading={t('Section.NotFound.heading')}
          >
            <p>{t('Section.NotFound.description')}</p>
          </EmptyState>
        </Card>
      </Page>
    )
  }

  const updatedPlan = plans.find(
    (plan) => parseFloat(plan.price) > parseFloat(shopPlan.price),
  )

  return (
    <>
      <Page
        fullWidth
        backAction={{
          url: '/sections',
        }}
        primaryAction={
          <InlineGrid columns={'auto auto 1fr'} gap={'200'} alignItems="center">
            <WidgetStateChanger
              enabled={widget.enabled}
              updateHandler={async (enabled) => {
                await updateSection(sectionId, {
                  enabled: enabled,
                })
                await queryClient.invalidateQueries(['shopDetails'])
                shopify.toast.show(t('DefaultText.sectionStateUpdated'))
              }}
            />
            <Popover
              active={datePickerOpen}
              onClose={() => {
                setDatePickerOpen(false)
              }}
              activator={
                <Button onClick={() => setDatePickerOpen(true)}>
                  {selectedDates.start.toDateString() +
                    ' - ' +
                    selectedDates.end.toDateString()}
                </Button>
              }
            >
              <Box padding={'200'}>
                <DatePicker
                  month={month}
                  year={year}
                  onChange={setSelectedDates}
                  onMonthChange={handleMonthChange}
                  selected={selectedDates}
                  allowRange
                />
              </Box>
            </Popover>
            <Popover
              active={popoverActive}
              activator={
                <Button
                  icon={MenuVerticalIcon}
                  onClick={togglePopoverActive}
                  variant="plain"
                />
              }
              autofocusTarget="first-node"
              onClose={togglePopoverActive}
            >
              <div
                style={{
                  width: 120,
                }}
              >
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: 'Delete',
                      destructive: true,
                      onAction: () => {
                        toggleDeleteModal('open')
                      },
                    },
                    {
                      content: 'Clear Cache',
                      disabled: clearingCache,
                      onAction: async () => {
                        setClearingCache(true)
                        const res = await updateSection(sectionId, {})
                        setClearingCache(false)
                        if (res.error) {
                          shopify.toast.show(
                            t('Section.Details.clearingCacheError'),
                            {
                              isError: true,
                            },
                          )
                        } else {
                          await queryClient.invalidateQueries(['shopDetails'])
                          shopify.toast.show(
                            t('Section.Details.clearingCacheSuccess'),
                          )
                        }
                      },
                    },
                  ]}
                />
              </div>
            </Popover>
          </InlineGrid>
        }
        secondaryActions={[
          {
            content: 'Edit',
            url: `/sections/${widget.id}/edit`,
            icon: EditIcon,
          },
        ]}
        title={widget.title}
        titleMetadata={
          <InlineStack gap={'200'}>
            <Badge tone="info-strong">
              <strong>ID:</strong> {widget.id}
            </Badge>
            <Badge tone="magic">
              <strong>Widget:</strong> {widgetName}
            </Badge>
          </InlineStack>
        }
        subtitle={
          <div
            style={{
              paddingTop: 'var(--p-space-100)',
            }}
          >
            <InlineStack gap={'200'}>
              <Badge>
                <strong>Page:</strong> {pageName}
              </Badge>
              <Badge tone="warning">
                <strong>Experience:</strong> {widget.experience.name}
              </Badge>
            </InlineStack>
          </div>
        }
      >
        <Box padding={breakpoints.smUp ? '0' : '400'}>
          <BlockStack gap={'400'}>
            <WidgetLimitReachedBanner
              plan={{
                name: updatedPlan?.name ?? '',
                price: `$${updatedPlan?.price ?? 0.0}`,
                recommendationServesBatchSize:
                  updatedPlan?.recommendationServesBatchSize ?? 0,
                recommendationServesBatchPrice: `$${updatedPlan?.recommendationServesBatchPrice ?? 0.0}`,
                recommendationsServedHardLimit:
                  updatedPlan?.recommendationsServedHardLimit ?? 0,
                maxPlanUsageCharge: 240.0,
                coveredRevenue: null,
                discountedData: {},
                slug: updatedPlan?.slug ?? '',
              }}
              showBanner={
                billingCycle.marketingRecommendationsUsage >
                0.8 * billingCycle.maximumRecummendationsServed
              }
            />
            <BlockStack>
              {page.type === PAGES.CHECKOUT.value && (
                <Banner
                  title={t('DefaultText.checkoutDeprecatedBanner')}
                  tone="info"
                />
              )}
              {!widget.enabled && (
                <SectionDisabledBanner sectionId={sectionId} />
              )}
            </BlockStack>
            <Layout>
              <AnalyticsSection
                shop={shop}
                sectionId={sectionId}
                filters={{
                  dateGte: new Date(selectedDates.start).valueOf(),
                  dateLte: new Date(selectedDates.end).valueOf(),
                }}
              />
              <DesignSection
                templateId={widget.template}
                sectionId={sectionId}
                template={
                  templates.find((template) => template.id === widget.template)
                    ?.name ?? ''
                }
                layout={
                  Object.values(WIDGET_LAYOUT).find(
                    ({ value }) => value === widget.layout,
                  ).label
                }
                onClick={() => {
                  openVisualEditor()
                }}
                widgetPreview={<></>}
                shopConfig={shopConfig}
                shopPlan={shopPlan}
              />
              <Recommendation
                shopConfig={shopConfig}
                sectionId={sectionId}
                sectionType={widget.type}
                page={widget.page}
                objects={
                  widget.type === WIDGET_LIST.PROMOTED_PRODUCTS.value
                    ? autoRecQuery.data
                    : null
                }
                slots={
                  widget.type === WIDGET_LIST.SLOTS_WIDGET.value
                    ? section?.extra?.slots ?? []
                    : null
                }
                collections={
                  widget.type === WIDGET_LIST.COLLECTIONS_WIDGET.value
                    ? section?.collections ?? []
                    : null
                }
                enabledUrls={
                  ((widgetData) => {
                    const otherPage = widgetData.pageType === PAGES.OTHER.value
                    const blogPage = widgetData.pageType === PAGES.BLOG.value
                    const promotedProductsWidget =
                      widgetData.type === WIDGET_LIST.PROMOTED_PRODUCTS.value

                    return otherPage || (blogPage && !promotedProductsWidget)
                  })({
                    pageType: page.type,
                    type: widget.type,
                  })
                    ? widget.enabledUrls
                    : null
                }
                onClick={() => {
                  redirectToLink({
                    external: false,
                    url: `/sections/${widget.id}/edit?showSection=recommendation`,
                  })
                }}
                excludedTags={
                  [
                    WIDGET_LIST.PERSONALIZED.value,
                    WIDGET_LIST.SIMILAR_PRODUCTS.value,
                    WIDGET_LIST.BOUGHT_TOGETHER.value,
                    WIDGET_LIST.CROSS_SELL.value,
                  ].includes(section.type)
                    ? widget.excludedTags
                    : null
                }
                disabledTags={
                  page.type === PAGES.PRODUCT.value &&
                  [
                    WIDGET_LIST.PERSONALIZED.value,
                    WIDGET_LIST.SIMILAR_PRODUCTS.value,
                    WIDGET_LIST.BOUGHT_TOGETHER.value,
                    WIDGET_LIST.CROSS_SELL.value,
                  ].includes(section.type)
                    ? widget.disabledTags
                    : null
                }
                whitelistedTags={
                  page.type === PAGES.PRODUCT.value &&
                  [
                    WIDGET_LIST.PERSONALIZED.value,
                    WIDGET_LIST.SIMILAR_PRODUCTS.value,
                    WIDGET_LIST.BOUGHT_TOGETHER.value,
                    WIDGET_LIST.CROSS_SELL.value,
                  ].includes(section.type)
                    ? widget.whitelistedTags
                    : null
                }
                excludedProducts={
                  [
                    WIDGET_LIST.PERSONALIZED.value,
                    WIDGET_LIST.SIMILAR_PRODUCTS.value,
                    WIDGET_LIST.BOUGHT_TOGETHER.value,
                    WIDGET_LIST.CROSS_SELL.value,
                  ].includes(section.type)
                    ? widget.excludedProducts
                    : null
                }
                disabledProducts={
                  page.type === PAGES.PRODUCT.value &&
                  [
                    WIDGET_LIST.PERSONALIZED.value,
                    WIDGET_LIST.SIMILAR_PRODUCTS.value,
                    WIDGET_LIST.BOUGHT_TOGETHER.value,
                    WIDGET_LIST.CROSS_SELL.value,
                  ].includes(section.type)
                    ? widget.disabledProducts
                    : null
                }
                whitelistedProducts={
                  page.type === PAGES.PRODUCT.value &&
                  [
                    WIDGET_LIST.PERSONALIZED.value,
                    WIDGET_LIST.SIMILAR_PRODUCTS.value,
                    WIDGET_LIST.BOUGHT_TOGETHER.value,
                    WIDGET_LIST.CROSS_SELL.value,
                  ].includes(section.type)
                    ? widget.whitelistedProducts
                    : null
                }
                recommendationOptions={['automaticEnabled']}
              />
              {widget.type === WIDGET_LIST.BOUGHT_TOGETHER.value &&
                widget.layout ===
                  WIDGET_LAYOUT.AMAZON_BOUGHT_TOGETHER.value && (
                  <BundleConfig
                    {...widget.bundleConfig}
                    onClick={() => {
                      redirectToLink({
                        external: false,
                        url: `/sections/${widget.id}/edit?showSection=bundle`,
                      })
                    }}
                  />
                )}
              <SegmentConfiguration
                segementationType={widget.segmentationType}
                onClick={() => {
                  redirectToLink({
                    external: false,
                    url: `/sections/${widget.id}/edit?showSection=segmentation`,
                  })
                }}
              />
              <AdvanceConfig
                {...widget}
                onClick={() => {
                  redirectToLink({
                    external: false,
                    url: `/sections/${widget.id}/edit?showSection=advance`,
                  })
                }}
              />
              <div
                style={{
                  padding: 'var(--p-space-1000)',
                }}
              />
            </Layout>
          </BlockStack>
        </Box>
      </Page>
      <ui-modal id="glood-delete-modal">
        <div
          style={{
            padding: 'var(--p-space-200)',
          }}
        >
          <p>{t('Section.Details.deletionText')}</p>
        </div>
        <ui-title-bar title="Attention">
          <button
            variant="primary"
            loading={deleting}
            onClick={async () => {
              setDeleting(true)
              await deleteSection(sectionId)
              setDeleting(false)
              toggleDeleteModal('close')
              await queryClient.invalidateQueries(['shopDetails'])
              redirectToLink({
                url: '/sections',
                external: false,
              })
            }}
          >
            Delete
          </button>
          <button
            onClick={() => {
              toggleDeleteModal('close')
            }}
          >
            Cancel
          </button>
        </ui-title-bar>
      </ui-modal>
      <ui-modal
        id="visual-editor-modal"
        variant="max"
        src={`/visual-editor?template=${section.template}&widget=${sectionId}&token=${token}&shop=${shopify.config.shop}`}
      >
        <ui-title-bar title="Visual Editor"></ui-title-bar>
      </ui-modal>
    </>
  )
}

export default SectionDetails

function toggleDeleteModal(state) {
  const modal = document.getElementById('glood-delete-modal')
  if (state === 'open') {
    modal.show()
  } else {
    modal.hide()
  }
}

function Skeleton() {
  return (
    <SkeletonPage fullWidth primaryAction title="Widget Detail">
      <BlockStack gap={'400'}>
        <Card>
          <BlockStack gap={'300'}>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </BlockStack>
        </Card>
        <Card>
          <BlockStack gap={'300'}>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </BlockStack>
        </Card>
      </BlockStack>
    </SkeletonPage>
  )
}

function WidgetStateChanger({ enabled, updateHandler }) {
  const [active, setActive] = useState(false)
  const { t } = useTranslation()

  const activator = (
    <span
      onClick={() => {
        setActive(!active)
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      {enabled ? (
        <Badge size="large" progress="complete" tone="success">
          {t('Section.List.enums.sectionStatus.enabled')}
        </Badge>
      ) : (
        <Badge size="large" progress="complete" tone="critical">
          {t('Section.List.enums.sectionStatus.disabled')}
        </Badge>
      )}
    </span>
  )

  return (
    <Popover activator={activator} active={active}>
      <Popover.Section>
        <Button
          onClick={() => {
            updateHandler(!enabled)
            setActive(false)
          }}
          variant="tertiary"
        >
          {!enabled
            ? t('Section.Details.enable')
            : t('Section.Details.disable')}
        </Button>
        <Box padding={'025'} />
      </Popover.Section>
    </Popover>
  )
}
