import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, EmptyState, Page } from '@shopify/polaris'
import { setUser } from '@sentry/react'

import { getShopStatus } from '../../apis/status'
import Loader from '../Loader'

export default function ShopStatusChecker({ children }) {
  const [reloadRequired, setReloadRequired] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchHandler = async () => {
    const { data, error } = await getShopStatus()
    setLoading(false)
    if (error) {
      console.log('ERROR:ShopStatusChecker', error)
      setReloadRequired(true)
      return
    } else if (!data?.shop || !data?.user || !data?.shop?.isEnabled) {
      setReloadRequired(true)
      return
    }
    window.shopify.data = data
    if (process.env.NODE_ENV !== 'development') {
      setUser({
        username: window.shopify.data.shop.myshopifyDomain,
        email: window.shopify.data.user.email,
      })
    }
  }

  useEffect(() => {
    fetchHandler()
  }, [])

  if (reloadRequired) {
    return <ReloadScreen />
  }

  if (loading) {
    return <Loader />
  }

  return children
}

function ReloadScreen() {
  const { t } = useTranslation()
  return (
    <Page>
      <Card>
        <EmptyState
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          heading={t('ReloadScreen.heading')}
          action={{
            content: t('ReloadScreen.action'),
            onAction: () => window.location.reload(),
          }}
        >
          <p>{t('ReloadScreen.description')}</p>
        </EmptyState>
      </Card>
    </Page>
  )
}
