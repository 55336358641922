import React from 'react'
import { Button, Card, ExceptionList, Link } from '@shopify/polaris'
import { AlertCircleIcon, LockIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'

function GeoTagging({ pricing, dashboardData }) {
  const { t } = useTranslation()
  const featureEnabled = areFeaturesEnabled(
    [FEATURES.GEO_TARGETING],
    dashboardData,
  )

  if (!featureEnabled) {
    return (
      <Card>
        <ExceptionList
          items={[
            {
              icon: LockIcon,
              description: t('Features.notAvailableInPlanText', {
                cta: (
                  <Button
                    loading={!featureEnabled && pricing.isLoading}
                    variant="plain"
                    onClick={() => {
                      pricing.open({
                        features: [FEATURES.GEO_TARGETING],
                      })
                    }}
                  >
                    {t('DefaultText.upgradeText')}
                  </Button>
                ),
                feature: t('Features.labels.geo_targetting'),
              }),
            },
          ]}
        />
      </Card>
    )
  }

  return (
    <Card>
      <ExceptionList
        items={[
          {
            icon: AlertCircleIcon,
            description: t('DefaultText.noAccessPage', {
              link: <Link monochrome>Chat</Link>,
            }),
          },
        ]}
      />
    </Card>
  )
}

export default GeoTagging
