import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { HubspotClient } from '../../models/hubspot'
import { areFeaturesEnabled } from '../../utils/features'
import { FEATURES } from '../../constants/features'
import { openChat } from '../ExternalScripts'
import FeedbackModal from '../FeedbackForm'

const HSClient = new HubspotClient({
  portalId: '22080564',
  formId: '1dfd6ed0-f905-42f3-95ba-be1121e0cbe5',
})

export function FloatingButtonGroup() {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const {t} = useTranslation()

  window.openFeedbackModal = () => {
    setFeedbackModalOpen(true)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 'var(--p-space-200)',
          position: 'fixed',
          right: 0,
          bottom: '50%',
          transform: 'rotate(-90deg) translateY(200%) translateX(-50%)',
        }}
      >
        <button
          onClick={() => {
            setFeedbackModalOpen(true)
          }}
          style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: 'max-content',
            paddingBottom: 'var(--p-space-200)',
          }}
          className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
        >
          {t('DefaultText.feedback')}
        </button>
        {areFeaturesEnabled([FEATURES.LIVE_SUPPORT], window.shopify.data) && (
          <button
            onClick={openChat}
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: 'max-content',
              paddingBottom: 'var(--p-space-200)',
            }}
            className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPrimary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
          >
            {t('DefaultText.support')}
          </button>
        )}
      </div>
      <FeedbackModal
        open={feedbackModalOpen}
        setOpen={setFeedbackModalOpen}
        onSubmit={feedbackHandler}
      />
    </>
  )

  async function feedbackHandler(data) {
    const payload = {
      'TICKET.hs_ticket_category': 'APP_FEEDBACK',
      email: window.shopify.data.user.email,
      'TICKET.subject': 'In App Feedback',
      'TICKET.content': data.message,
      'TICKET.feedback_rating': data.rating,
      shopify_domain: window.shopify.data.shop.myshopifyDomain,
    }
    await HSClient.sendForm(payload)
    setFeedbackModalOpen(false)
    return {
      status: 'success',
    }
  }
}
