import {
  Badge,
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Text,
} from '@shopify/polaris'
import { EditIcon } from '@shopify/polaris-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function CollectionSelection({ collections, onClick }) {
  const { t } = useTranslation()
  
  return (
    <Card>
      <BlockStack gap={'300'}>
        <InlineGrid columns={'1fr auto'} gap={'200'}>
          <BlockStack>
            <Text as="strong">
              {t('Section.Details.CollectionSelection.title')}
            </Text>
            <Text>{t('Section.Details.CollectionSelection.description')}</Text>
          </BlockStack>
          <div>
            <Button icon={EditIcon} onClick={onClick}>
              {t('Section.Details.CollectionSelection.btnText')}
            </Button>
          </div>
        </InlineGrid>
        <InlineStack gap={'200'}>
          {collections.map((collection) => (
            <Badge>
              <InlineGrid columns={'1fr auto'}>{collection}</InlineGrid>
            </Badge>
          ))}
        </InlineStack>
      </BlockStack>
    </Card>
  )
}

export default CollectionSelection
