import React from 'react'
import { usePricing } from '../components/PricingModal'
import Pricing from '../container/Pricing'
import { getQueryParams } from '../utils/router'

function PlanPage() {
  const params = getQueryParams()
  const { plans, isLoading, addPromoCode, changePlan } = usePricing({
    plan: params?.plan ?? null
  })
  return (
    <Pricing
      changePlanCB={changePlan}
      plans={plans}
      isLoading={isLoading}
      addPromoCode={addPromoCode}
    />
  )
}

export default PlanPage
