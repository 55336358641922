import React from 'react'
import {
  Banner,
  BlockStack,
  Box,
  InlineGrid,
  Text,
  InlineStack,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { PricingModal, usePricing } from '../PricingModal'
import { numberFormater } from '../../utils/formater'

function WidgetLimitReachedBanner({ plan, showBanner }) {
  const { open, isLoading, ...pricingModalProps } = usePricing()
  const { t } = useTranslation()

  if (!showBanner) return null

  return (
    <>
      <Banner
        tone="critical"
        title={t('DefaultText.servedLimitReachedText')}
        action={{
          content: t('PricingModal.PlanTemplate.buyCta'),
          onAction: () => {
            open({
              slug: plan.slug,
            })
          },
          loading: isLoading,
        }}
      >
        <Box padding={'0'}>
          <BlockStack gap={'400'}>
            <BlockStack gap={'100'}>
              <InlineGrid columns={'1fr auto'}>
                <Text as="strong" variant="headingLg">
                  {plan.name}
                </Text>
                <InlineStack blockAlign="end">
                  <Text as="strong" variant="headingLg">
                    {plan.price}
                  </Text>
                  <Text>{t('PricingModal.PlanTemplate.Widget.rate')}</Text>
                </InlineStack>
              </InlineGrid>
              <InlineGrid columns={'1fr auto'}>
                <Text>
                  {t('PricingModal.PlanTemplate.Widget.subtitle', {
                    widgetsQuantity: (
                      <strong>
                        {numberFormater(plan.recommendationsServedHardLimit)}
                      </strong>
                    ),
                  })}
                </Text>
                <Text>
                  {plan.previousPrice && (
                    <Text variant="bodyLg" textDecorationLine="line-through">
                      {plan.previousPrice}
                      {t('PricingModal.PlanTemplate.Widget.rate')}
                    </Text>
                  )}
                </Text>
              </InlineGrid>
            </BlockStack>
            <Text as="p" tone="subdued" variant="bodyMd">
              {t('PricingModal.PlanTemplate.Widget.note', {
                pricePer1000Widgets: (
                  <strong>
                    {numberFormater(plan.recommendationServesBatchSize)}
                  </strong>
                ),
                pricePerMonth: (
                  <strong>{plan.recommendationServesBatchPrice}</strong>
                ),
                maxWidgets: (
                  <strong>
                    {numberFormater(plan.recommendationsServedHardLimit)}
                  </strong>
                ),
              })}
            </Text>
          </BlockStack>
        </Box>
      </Banner>
      <PricingModal modal={pricingModalProps} />
    </>
  )
}

export default WidgetLimitReachedBanner
