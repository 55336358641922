import { useForm, useField, notEmpty } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

import {
  TEMPLATE_LAYOUTS,
  TEMPLATE_TYPES,
} from '../../../../../constants/templates'

function useMultiLangTranlationForm({
  currentValues,
  onSubmit,
  type,
  chooseLanguageField,
  templateType,
}) {
  const { t } = useTranslation()
  // for template with type other than Bundle Section
  const addToCartTextfield = useField({
    value: currentValues.addToCartText ?? '',
    validates: [notEmpty(t('template.notEmpty.addToCartText'))],
  })
  const addedToCartTextfield = useField({
    value: currentValues.addedToCartText ?? '',
    validates: [notEmpty(t('template.notEmpty.addedToCartText'))],
  })

  const addingToCartTextfield = useField({
    value: currentValues.addingToCartText ?? '',
    validates: [notEmpty(t('template.notEmpty.addingToCartText'))],
  })

  const discountLabelTextfield = useField({
    value: currentValues.discountLabelText ?? '',
    validates: [notEmpty(t('template.notEmpty.discountLabelText'))],
  })

  const goToCartTextfield = useField({
    value: currentValues.goToCartText ?? '',
    validates: [notEmpty(t('template.notEmpty.goToCartText'))],
  })

  const outOfStockTextfield = useField({
    value: currentValues.outOfStockText ?? '',
    validates: [notEmpty(t('template.notEmpty.outOfStockText'))],
  })

  const fbtAddToCartTextfield = useField({
    value: currentValues.fbtAddToCartText ?? '',
    validates: [notEmpty(t('template.notEmpty.fbtAddToCartText'))],
  })

  const thisProductTextfield = useField({
    value: currentValues.thisProductText ?? '',
    validates: [notEmpty(t('template.notEmpty.thisProductText'))],
  })

  const totalPriceTextfield = useField({
    value: currentValues.totalPriceText ?? '',
    validates: [notEmpty(t('template.notEmpty.totalPriceText'))],
  })

  // for template with type Bundle Section
  const bundleSectionTitleTextfield = useField({
    value: currentValues.bundleSectionTitle ?? '',
    validates: [notEmpty(t('template.notEmpty.bundleSectionTitleText'))],
  })

  const productComponentTitleTextField = useField({
    value: currentValues.productComponentTitle ?? '',
    validates: [notEmpty(t('template.notEmpty.productComponentTitleText'))],
  })

  let fields = {}

  if (templateType != TEMPLATE_TYPES.BUNDLE_SECTION) {
    fields = {
      chooseLanguageField,
      outOfStockTextfield,
      goToCartTextfield,
      discountLabelTextfield,
      addingToCartTextfield,
      addedToCartTextfield,
      addToCartTextfield,
    }

    if (type === TEMPLATE_LAYOUTS.FBT) {
      fields.thisProductTextfield = thisProductTextfield
      fields.fbtAddToCartTextfield = fbtAddToCartTextfield
      fields.totalPriceTextfield = totalPriceTextfield
    }
  } else if (templateType === TEMPLATE_TYPES.BUNDLE_SECTION) {
    fields = {
      chooseLanguageField,
      bundleSectionTitleTextfield,
      productComponentTitleTextField,
      totalPriceTextfield,
      addedToCartTextfield,
      addToCartTextfield,
      outOfStockTextfield
    }
  }

  return useForm({
    fields,
    onSubmit,
  })
}

export default useMultiLangTranlationForm
