import getApiClient from '.'

export async function fetchAppState() {
  const client = await getApiClient()
  try {
    const response = await client.get('/api/app/state')
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error.message,
    }
  }
}

export async function getAppEmbedState(themeId) {
  const client = await getApiClient()
  try {
    const response = await client.post('/api/app/embed-state', {
      themeId,
    })
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error.message,
    }
  }
}
