import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormLayout,
  Frame,
  FrameContext,
  Grid,
  InlineError,
  InlineGrid,
  InlineStack,
  Modal,
  RadioButton,
  Select,
  SkeletonBodyText,
  Text,
  TextField,
  Toast,
  Tooltip,
} from '@shopify/polaris'
import { useState } from 'react'

import { CHANNELS, REASONS_TO_INSTALL } from '../../constants/onboarding'
import { HubspotClient } from '../../models/hubspot'
import { useOnboardingForm } from './hooks'
import { useRedirect } from '../../hooks/useRedirect.js'
import { updateShopConfig } from '../../apis/dashbboard.js'
import queryClient from '../../utils/query.js'
import CatagoryAndSubCatagoryData from './catagory_subcatagory.json'
import { useDashboardDetails } from '../../hooks/useDashboardDetails'

const hubspotClient = new HubspotClient({
  portalId: '22080564',
  formId: '1e940aa0-7968-43f1-b8e4-d5887bdcf35f',
})

const SHOP_TYPES = Object.keys(CatagoryAndSubCatagoryData).map((key) => ({
  label: key,
  value: key,
}))

const getShopSubCatagoriesOptions = (category) => {
  if (!category) return []
  const options = CatagoryAndSubCatagoryData[category].map((subCatagory) => ({
    label: subCatagory,
    value: subCatagory,
  }))
  return [...options, { label: 'Others', value: 'others' }]
}

export default function OnboardingForm() {
  const {
    data: { shopUser = {}, config: shopConfig = {} } = {},
    isLoading: dashbboardLoading,
  } = useDashboardDetails()
  const [loading, setLoading] = useState(false)
  const { redirectToLink } = useRedirect()
  const updateHandler = async () => {
    await updateShopConfig({
      onboarding: {
        ...(shopConfig?.onboarding ?? {}),
        initInfoDone: true,
      },
    })
    await queryClient.invalidateQueries(['getDashboardDetails'])
    redirectToLink({
      external: false,
      url: '/',
    })
  }

  const { fields, submit } = useOnboardingForm({
    onSubmit: async (formData) => {
      const data = { ...formData }
      delete data.agencyDetails
      let payload = {
        ...data,
        reasonsToInstall: formData.reasonsToInstall.join(','),
        shopSubType: formData.shopSubType,
        extraStores: formData.extraStores.checked,
        isAgency: formData.isAgency.checked,
      }
      if (!formData.shopSubType.includes('other')) {
        delete payload.shopSubTypeOther
      }
      if (!formData.extraStores.checked) {
        delete payload.numberOfStores
      }
      if (formData.isAgency.checked) {
        payload = {
          ...payload,
          agencyName: formData.agencyDetails.name,
          agencyEmail: formData.agencyDetails.email,
          agencPhone: formData.agencyDetails.phone,
          agencyAddress: formData.agencyDetails.address,
          agencyClients: formData.agencyDetails.clients,
        }
      }
      setLoading(true)
      try {
        await hubspotClient.sendForm({
          ...payload,
          shop: window.shopify.data.shop.myshopifyDomain,
        })
        await updateHandler()
        shopify.toast.show('Response submitted successfully')
      } catch (error) {
        shopify.toast.show('Some error occured while submitting the form', {
          isError: true,
        })
      } finally {
        setLoading(false)
      }
      return {
        status: 'success',
      }
    },
    currentValues: {
      email: shopUser.email ?? '',
    },
  })

  const REASONS_LENGTH = REASONS_TO_INSTALL.length

  if (dashbboardLoading) {
    return <Skeleton />
  }

  return (
    <Card padding={'0'}>
      <Box background="bg-surface-tertiary" padding={'200'}>
        <InlineGrid columns={'1fr auto'} alignItems="center" gap={'200'}>
          <Text variant="headingSm" as="h5">
            Welcome to Glood AI
          </Text>
        </InlineGrid>
      </Box>
      <Divider />
      <Box padding={'400'}>
        <BlockStack gap={'300'}>
          <TextField
            type="email"
            placeholder="Enter your email"
            label="Email"
            {...fields.email}
          />
          <InlineGrid gap={'400'} columns={'1fr 1fr'}>
            <Select
              placeholder="Select shop category"
              label="Shop Category"
              options={SHOP_TYPES}
              {...fields.shopType}
            />
            {(() => {
              const content = (
                <Select
                  placeholder="Select shop sub category"
                  label="Shop Sub Category"
                  disabled={!fields.shopType.value}
                  options={getShopSubCatagoriesOptions(fields.shopType.value)}
                  {...fields.shopSubType}
                />
              )
              if (fields.shopType.value) {
                return content
              }
              return (
                <Tooltip content={'Please select Shop Category'}>
                  {content}
                </Tooltip>
              )
            })()}
          </InlineGrid>
          {fields.shopSubType.value.includes('others') && (
            <TextField
              {...fields.shopSubTypeOther}
              value={fields.shopSubTypeOther.value}
              onChange={(val) => {
                fields.shopSubTypeOther.onChange(val)
              }}
              placeholder="Please specify"
              error={fields.shopSubTypeOther.allErrors[0]}
            />
          )}
          <Box>
            <Text fontWeight="regular" variant="headingSm" as="h6">
              Reasons for installing glood.
            </Text>
            <BlockStack wrap={false} gap={'150'}>
              <Grid>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <BlockStack wrap={false}>
                    {REASONS_TO_INSTALL.slice(
                      undefined,
                      REASONS_LENGTH / 2,
                    ).map(({ label, value }) => (
                      <Checkbox
                        label={label}
                        checked={fields.reasonsToInstall.value.includes(value)}
                        onChange={(val) => {
                          const reasonsToInstall = fields.reasonsToInstall.value
                          const setValue = fields.reasonsToInstall.onChange
                          if (val) {
                            setValue([...reasonsToInstall, value])
                          } else {
                            setValue(
                              reasonsToInstall.filter(
                                (reason) => reason !== value,
                              ),
                            )
                          }
                        }}
                      />
                    ))}
                  </BlockStack>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <BlockStack wrap={false}>
                    {REASONS_TO_INSTALL.slice(REASONS_LENGTH / 2 + 1).map(
                      ({ label, value }) => (
                        <Checkbox
                          label={label}
                          checked={fields.reasonsToInstall.value.includes(
                            value,
                          )}
                          onChange={(val) => {
                            const reasonsToInstall =
                              fields.reasonsToInstall.value
                            const setValue = fields.reasonsToInstall.onChange
                            if (val) {
                              setValue([...reasonsToInstall, value])
                            } else {
                              setValue(
                                reasonsToInstall.filter(
                                  (reason) => reason !== value,
                                ),
                              )
                            }
                          }}
                        />
                      ),
                    )}
                  </BlockStack>
                </Grid.Cell>
              </Grid>
              {fields.reasonsToInstall?.allErrors?.length > 0 && (
                <InlineError message={fields.reasonsToInstall.allErrors[0]} />
              )}
              {fields.reasonsToInstall.value.includes('others') && (
                <TextField
                  {...fields.reasonsToInstallOther}
                  value={fields.reasonsToInstallOther.value}
                  onChange={(val) => {
                    fields.reasonsToInstallOther.onChange(val)
                  }}
                  placeholder="Please specify"
                  error={fields.reasonsToInstallOther.allErrors[0]}
                />
              )}
            </BlockStack>
          </Box>
          <Box>
            <BlockStack>
              <Text fontWeight="regular" variant="headingSm" as="h6">
                How did you find us?
              </Text>
              <InlineGrid
                columns={{
                  xs: 2,
                  xl: 2,
                  sm: 2,
                  lg: 2,
                  md: 2,
                }}
              >
                {CHANNELS.map((channel) => (
                  <RadioButton
                    label={channel.label}
                    checked={channel.value === fields.channel.value}
                    onChange={() => {
                      const setValue = fields.channel.onChange
                      setValue(channel.value)
                    }}
                  />
                ))}
              </InlineGrid>
              {fields.channel.allErrors.length > 0 && (
                <InlineError message={fields.channel.allErrors[0]} />
              )}
            </BlockStack>
          </Box>
          <Box>
            <BlockStack gap={'150'}>
              <Select
                label="Do you have any other store?"
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                value={fields.extraStores.checked}
                onChange={(val) => {
                  fields.extraStores.onChange(val === 'true')
                  if (!val) {
                    fields.numberOfStores.onChange(false)
                  }
                }}
              />
              {fields.extraStores.checked && (
                <TextField
                  placeholder="Number of stores"
                  type="number"
                  min={1}
                  max={100}
                  {...fields.numberOfStores}
                />
              )}
            </BlockStack>
          </Box>
          <Box>
            <BlockStack gap={'150'}>
              <Checkbox label="We are an Agency." {...fields.isAgency} />
              {fields.isAgency.checked && (
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      type="text"
                      label="Name of the agency"
                      {...fields.agencyDetails.name}
                    />
                    <TextField
                      type="text"
                      label="Country"
                      {...fields.agencyDetails.address}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      label="Phone"
                      {...fields.agencyDetails.phone}
                    />
                    <TextField
                      type="email"
                      label="Email"
                      {...fields.agencyDetails.email}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      label="Prominent Clients"
                      {...fields.agencyDetails.clients}
                    />
                  </FormLayout.Group>
                </FormLayout>
              )}
            </BlockStack>
          </Box>
          <InlineStack align="end">
            <Button variant="primary" onClick={submit} loading={loading}>
              Done
            </Button>
          </InlineStack>
        </BlockStack>
      </Box>
    </Card>
  )
}

function Skeleton() {
  const Label = ({ children }) => <Text>{children}</Text>
  return (
    <Card>
      <BlockStack>
        <FormLayout>
          <FormLayout>
            <Label>Email</Label>
            <SkeletonBodyText lines={1} />
          </FormLayout>
          <FormLayout.Group>
            <Box>
              <Label>Shop Category</Label>
              <SkeletonBodyText lines={1} />
            </Box>
            <Box>
              <Label>Shop Shop Sub Category</Label>
              <SkeletonBodyText lines={1} />
            </Box>
          </FormLayout.Group>
          <FormLayout>
            <Label>Reasons for installing glood.</Label>
            <SkeletonBodyText lines={4} />
          </FormLayout>
          <FormLayout>
            <Label>How did you find us?</Label>
            <SkeletonBodyText lines={3} />
          </FormLayout>
        </FormLayout>
      </BlockStack>
    </Card>
  )
}
