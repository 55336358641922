export const CREATION_TYPE = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
}

export const RULEBASED_OBJECT_TYPE = {
  PRODUCT: 'product',
  TAGS: 'tags',
  COLLECTION: 'collection',
  VENDOR: 'vendor',
  PRODUCT_TYPE: 'productType',
}