export function getSearchParams(params) {
  return new URLSearchParams(params).toString()
}

export function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}

export function getQueryParams() {
  const urlParams = new URLSearchParams(location.search)
  const entries = urlParams.entries()
  return paramsToObject(entries)
}

export function updateURLSearch(update = {}) {
  const current = getQueryParams()
  const updated = {
    ...current,
    ...update,
  }

  return getSearchParams(updated)
}
