import {
  Badge,
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Tag,
  Text,
  TextField,
} from '@shopify/polaris'
import { XIcon } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function CollectionSelection({ collections, onChange }) {
  const [items, setItems] = useState(collections)
  const { t } = useTranslation()

  useEffect(() => {
    onChange(items.map((item) => item?.title ?? item))
  }, [items])

  return (
    <Card>
      <BlockStack gap={'300'}>
        <InlineGrid columns={'1fr auto'} gap={'200'}>
          <BlockStack>
            <Text as="strong">
              {t(
                'Section.Edit.Sections.Recommendation.CollectionSelection.title',
              )}
            </Text>
            <Text>
              {t(
                'Section.Edit.Sections.Recommendation.CollectionSelection.description',
              )}
            </Text>
          </BlockStack>
          <div>
            <Button
              onClick={async () => {
                try {
                  const items = await shopify.resourcePicker({
                    type: 'collection',
                    multiple: 20,
                  })
                  if (items) {
                    setItems(items)
                  }
                } catch (e) {
                  window.shopify.toast.show(t('noProductUpdate'), {
                    isError: true,
                  })
                }
              }}
            >
              {t(
                'Section.Edit.Sections.Recommendation.CollectionSelection.addCollectionCTA',
              )}
            </Button>
          </div>
        </InlineGrid>
        <InlineStack gap={'200'}>
          {collections.map((collection) => (
            <Tag
              onRemove={() => {
                setItems(items.filter((item) => item !== collection))
              }}
            >
              {collection}
            </Tag>
          ))}
        </InlineStack>
      </BlockStack>
    </Card>
  )
}

export default CollectionSelection
