import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Form,
  FormLayout,
  InlineStack,
  Select,
  TextField,
} from '@shopify/polaris'
import { useForm, useField, notEmpty } from '@shopify/react-form'

import CustomModal from '../CustomModal'

function PlanDowngradeFeedbackForm({ open, setOpen, submitCallback }) {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return Array.from({ length: 6 }, (_, i) => ({
      label: t(`FeedbackForm.PlanDowngrade.reasons.reason${i + 1}`),
      value: `glood-${i}`,
    }))
  })

  const reasonField = useField({
    value: '',
    validates: [
      notEmpty(t('FeedbackForm.PlanDowngrade.validation.reasonEmpty')),
    ],
  })

  const otherReason = useField({
    value: '',
    validates: [
      notEmpty(t('FeedbackForm.PlanDowngrade.validation.otherReasonEmpty')),
    ],
  })

  const feedback = useField({
    value: '',
    validates: [
      notEmpty(t('FeedbackForm.PlanDowngrade.validation.feedbackEmpty')),
      (val) => {
        if (val.length > 500) {
          return t('FeedbackForm.PlanDowngrade.validation.feedbackMax')
        }
        if (val.length < 10) {
          return t('FeedbackForm.PlanDowngrade.validation.feedbackkMin')
        }
        return null
      },
    ],
  })
  
  const form = useForm({
    fields: {
      reason: reasonField,
      otherReason: reasonField.value === options[5].value ? otherReason : undefined,
      feedback: feedback,
    },
    onSubmit: async (values) => {
      const res = await submitCallback({
        ...values,
        reason: options.find((o) => o.value === values.reason).label,
      })
      if (res.error) {
        return {
          status: 'fail',
        }
      }
      return {
        status: 'success',
      }
    },
  })

  return (
    <CustomModal
      show={open}
      setShow={setOpen}
      variant='medium'
      overlayZIndex={999}
      title={t('FeedbackForm.PlanDowngrade.title')}>
      <Box padding={'300'}>
        <Form onSubmit={form.submit}>
          <FormLayout>
            <FormLayout>
              <Select
              placeholder={t('FeedbackForm.PlanDowngrade.placeholder.reason')}
                label={t('FeedbackForm.PlanDowngrade.labels.reason')}
                {...form.fields.reason}
                options={options}
              />
            </FormLayout>
            {reasonField.value === options[5].value && (
              <FormLayout>
                <TextField
                  label={t('FeedbackForm.PlanDowngrade.labels.otherReason')}
                  {...form.fields.otherReason}
                />
              </FormLayout>
            )}
            <FormLayout>
              <TextField
                label={t('FeedbackForm.PlanDowngrade.labels.feedback')}
                {...form.fields.feedback}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <InlineStack>
                <div>
                  <Button loading={form.submitting} submit variant='primary'>
                    {t('FeedbackForm.PlanDowngrade.submitCta')}
                  </Button>
                </div>
              </InlineStack>
            </FormLayout>
          </FormLayout>
        </Form>
      </Box>
    </CustomModal>
  )
}

export default PlanDowngradeFeedbackForm
