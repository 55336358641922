import {
  BlockStack,
  Box,
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  useBreakpoints,
} from '@shopify/polaris'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShopDetails } from '../../../hooks/useShopDetails'
import UpsertExpForm from '../../../components/UpsertExperienceForm'
import { createExperience } from '../../../apis/experience'
import { useRedirect } from '../../../hooks/useRedirect'
import queryClient from '../../../utils/query'

function CreateABExperience() {
  const { t } = useTranslation()
  const { data, isLoading } = useShopDetails()
  const { redirectToLink } = useRedirect()
  const breakpoints = useBreakpoints()

  const experiences = data?.experiences ?? []

  const submitHandler = async (val) => {
    const res = await createExperience({
      name: val.name,
      copyExperienceId: val.duplicatedFrom
        ? parseInt(val.duplicatedFrom)
        : null,
      weight: parseInt(val.weightage),
      startTime: val.startTime,
      endTime: val.endTime,
      triggerKey: Boolean(val.key) ? val.key : null,
      triggerValue: Boolean(val.value) ? val.value : null,
    })
    if (res.error) {
      return {
        error: res.error,
      }
    }
    await queryClient.invalidateQueries(['shopDetails'])
    redirectToLink({
      url: '/sections',
      external: false,
    })
    return {
      error: null,
    }
  }

  if (isLoading) {
    return <SkeletonComponent />
  }

  return (
    <Page
      fullWidth
      title={t('ABExperience.Create.title')}
      backAction={{
        url: '/sections',
      }}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <Layout>
          <Layout.AnnotatedSection
            title={t('ABExperience.Create.heading')}
            description={t('ABExperience.Create.subHeading')}
          >
            <Card>
              <UpsertExpForm
                submitHandler={submitHandler}
                experiences={experiences}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Box>
    </Page>
  )
}

export default CreateABExperience

const SkeletonComponent = () => {
  const breakpoints = useBreakpoints()
  const { t } = useTranslation()

  if (breakpoints.smDown) {
    return (
      <SkeletonPage title={t('ABExperience.Create.title')}>
        <Layout>
          <BlockStack gap={'300'}>
            <BlockStack gap={'100'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
            <Card>
              <BlockStack gap={'300'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout>
      </SkeletonPage>
    )
  }

  return (
    <SkeletonPage fullWidth title={t('ABExperience.Create.title')}>
      <Layout>
        <Layout.Section variant="oneThird">
          <BlockStack gap={'300'}>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <BlockStack gap={'300'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
