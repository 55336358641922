import {
  BlockStack,
  Box,
  Card,
  Grid,
  InlineGrid,
  InlineStack,
  Layout,
  Link,
  Page,
  Text,
} from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'

import OnboardingForm from '../../components/OnboardingForm/index.jsx'
import GloodIcon from './assets/glood-icon.svg'

import gap from './assets/GAP.png'
import baggage from './assets/baggage.png'
import polene from './assets/polene.png'
import jinc from './assets/jinc.png'

export default function Onboarding() {
  const { t } = useTranslation()
  return (
    <Page>
      <Grid
        columns={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 5,
          xl: 5,
        }}
        gap={'300'}
        alignItems="start"
      >
        <Grid.Cell
          columnSpan={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
          }}
        >
          <SideSection />
        </Grid.Cell>
        <Grid.Cell
          columnSpan={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 3,
            xl: 3,
          }}
        >
          <OnboardingForm inititallyActive />
        </Grid.Cell>
      </Grid>
    </Page>
  )
}

const ImageArray = [
  {
    src: gap,
    alt: 'Gap',
  },
  {
    src: polene,
    alt: 'Polene',
  },
  {
    src: jinc,
    alt: 'Jinc',
  },
  {
    src: baggage,
    alt: 'Baggage',
  },
]

function SideSection() {
  const { t } = useTranslation()
  return (
    <BlockStack gap={'1000'} align="start">
      <BlockStack gap={'300'} align="start">
        <img src={GloodIcon} width={114} height={40} />
        <Text as="h2" variant="headingLg">
          Welcome to Glood
        </Text>
        <p>
          Ready to unlock the power of personalized recommendations? Glood.ai
          makes it easy to understand your customers and boost sales. Our AI
          engine analyzes shopping behavior to suggest exactly what each visitor
          wants, increasing conversion rates and average order value. Let's get
          started tailoring the shopping experience for each customer and watch
          your business thrive!
        </p>
      </BlockStack>
      <InlineGrid columns={'auto auto'} gap={'400'}>
        {ImageArray.map((item, index) => (
          <div key={index} style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img
              style={{
                opacity: 0.3,
                objectFit: 'contain',
              }}
              src={item.src}
              alt={item.alt}
              width={100}
              height={40}
            />
          </div>
        ))}
      </InlineGrid>
      <BlockStack align="center">
        <InlineStack gap={'400'} align="center" alignItems="center">
          <Link monochrome>Terms and condition</Link>
          <Link monochrome>Privacy Policy</Link>
          <Link monochrome>About Us</Link>
          <Link monochrome>Contact Us</Link>
        </InlineStack>
      </BlockStack>
    </BlockStack>
  )
}
