import React from 'react'
import {
  BlockStack,
  Box,
  Button,
  Checkbox,
  Divider,
  Form,
  InlineError,
  InlineStack,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import CodeEditor from '../../../../../components/CodeEditor'

function AdvancedSection({ form }) {
  const { t } = useTranslation()

  return (
    <Form onSubmit={form.submit}>
      <Box padding={'0'}>
        <BlockStack gap={'300'}>
          {form.fields.storeData.error && (
            <InlineError message={form.fields.storeData.error} />
          )}
          <Box padding={'0'}>
            <BlockStack gap={'100'}>
              <Text as="strong">
                {t(
                  'SettingsPage.Sections.Advanced.Section.advanced.Settings.title',
                )}
              </Text>
              <Checkbox
                label={t(
                  'SettingsPage.Sections.Advanced.Section.advanced.Settings.Form.label',
                )}
                helpText={t(
                  'SettingsPage.Sections.Advanced.Section.advanced.Settings.Form.helpText',
                )}
                {...form.fields.skipParentProduct}
              />
            </BlockStack>
          </Box>
          <Divider />
          <BlockStack gap={'300'}>
            <Text as="strong">
              {t(
                'SettingsPage.Sections.Advanced.Section.advanced.StoreData.title',
              )}
            </Text>
            <Box
              padding={'100'}
              borderRadius="100"
              borderWidth="0165"
              borderColor="border"
            >
              <CodeEditor
                mode={'json'}
                value={form.fields.storeData.value}
                onChange={form.fields.storeData.onChange}
                height={'500px'}
                readOnly={form.submitting}
              />
            </Box>
            <InlineStack align="end">
              <Button loading={form.submitting} submit>
                {t('SettingsPage.Sections.Advanced.Section.advanced.btnCta')}
              </Button>
            </InlineStack>
          </BlockStack>
        </BlockStack>
      </Box>
    </Form>
  )
}

export default AdvancedSection
