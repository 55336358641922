import React, { useState } from 'react'
import {
  Button,
  Page,
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  BlockStack,
  SkeletonDisplayText,
  InlineStack,
  Badge,
  useBreakpoints,
  Box,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@shopify/polaris-icons'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProductRecommendationTable from '../../../components/ProductRecommendationTable'
import { getProductRecommendationList } from '../../../apis/recommendation'
import BulkUploadFormModal from '../../../components/BulkUploadFormModal'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  SIMILAR_PRODUCTS,
  WIDGET_TYPES,
} from '../../../constants/widgets'
export { NewProductRecommendation } from './New'
import queryClient from '../../../utils/query'
import { CREATION_TYPE } from '../../../constants/recommendations'
import useProductList from '../../../hooks/useProductList'
import { getProducts } from '../../../apis/products'
import { getQueryParams } from '../../../utils/router'
import { currencyFormatter, formatCurrency } from '../../../utils/formater'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'

const PAGE_SIZE = 10

function ProductRecommendation() {
  const { t } = useTranslation()
  const { widgetType } = useParams()
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState()
  const [searchQuery, setSearchQuery] = useState('')
  const {
    products,
    isLoading: productsLoading,
    pagination,
  } = useProductList({
    widgetType,
    searchQuery,
  })
  const params = getQueryParams()

  const breakpoint = useBreakpoints()
  const dashQuery = useDashboardDetails()
  const query = useQuery({
    queryKey: ['productRecommendation', { widgetType }, products],
    queryFn: async ({ queryKey }) => {
      const widgetType = queryKey[1].widgetType
      const products = queryKey[2]
      const productIds = products.map(({ id }) => id)
      const res = await getProductRecommendationList({
        widgetTypes: [widgetType],
        products: productIds,
      })
      if (res.error) return Promise.reject(res.error)
      const { recommendations } = res.data
      const processed = recommendations.map(({ product, recommendations }) => ({
        product: products.find(({ id }) =>
          id.toString().includes(product.toString()),
        ),
        similarProducts: (
          recommendations.filter(
            ({ recommendationType }) =>
              recommendationType === SIMILAR_PRODUCTS.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType, score }) => ({
          product:
            typeof recommendedProduct == 'string'
              ? parseInt(recommendedProduct)
              : recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
          score
        })),
        frequentlyBoughtTogether: (
          recommendations.filter(
            ({ recommendationType }) =>
              recommendationType === BOUGHT_TOGETHER.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType, score }) => ({
          product:
            typeof recommendedProduct == 'string'
              ? parseInt(recommendedProduct)
              : recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
          score
        })),
        crossSell: (
          recommendations.filter(
            ({ recommendationType }) => recommendationType === CROSS_SELL.value,
          ) ?? []
        ).map(({ recommendedProduct, creationType,score }) => ({
          product:
            typeof recommendedProduct == 'string'
              ? parseInt(recommendedProduct)
              : recommendedProduct,
          aiGenerated: creationType !== CREATION_TYPE.MANUAL,
          score
        })),
      }))
      const populated = await Promise.all(
        processed.map(async (rec) => {
          const similarProductIds = rec.similarProducts.map(({ product }) =>
            typeof product === 'string'
              ? parseInt(product.replace('gid://shopify/Product/', ''))
              : product,
          )
          const crossSellIds = rec.crossSell.map(({ product }) =>
            typeof product === 'string'
              ? parseInt(product.replace('gid://shopify/Product/', ''))
              : product,
          )
          const frequentlyBoughtTogetherIds = rec.frequentlyBoughtTogether.map(
            ({ product }) =>
              typeof product === 'string'
                ? parseInt(product.replace('gid://shopify/Product/', ''))
                : product,
          )
          const similarProducts = (
            await getProducts(similarProductIds)
          ).data.products.map((p) => ({
            id: p.id,
            images: p.image ? [p.image?.src] : [],
            title: p.title,
            vendor: p.vendor,
            handle: p.handle,
            aiGenerated: Boolean(
              rec.similarProducts.find((r) => r.product == p.id)?.aiGenerated,
            ),
            productType: p.product_type,
            price: getPriceStringFromVariants(
              p.variants,
              dashQuery.data?.config?.currencyFormat,
            ),
            score: rec.similarProducts.find((r) => r.product == p.id)?.score,
          }))
          const crossSell = (await getProducts(crossSellIds)).data.products.map(
            (p) => ({
              id: p.id,
              images: p.image ? [p.image?.src] : [],
              title: p.title,
              vendor: p.vendor,
              handle: p.handle,
              aiGenerated: Boolean(
                rec.crossSell.find((r) => r.product == p.id)?.aiGenerated,
              ),
              productType: p.product_type,
              price: getPriceStringFromVariants(
                p.variants,
                dashQuery.data?.config?.currencyFormat,
              ),
              score: rec.crossSell.find((r) => r.product == p.id)?.score,
            }),
          )
          const frequentlyBoughtTogether = (
            await getProducts(frequentlyBoughtTogetherIds)
          ).data.products.map((p) => ({
            id: p.id,
            images: p.image ? [p.image?.src] : [],
            title: p.title,
            vendor: p.vendor,
            handle: p.handle,
            aiGenerated: Boolean(
              rec.frequentlyBoughtTogether.find((r) => r.product == p.id)
                ?.aiGenerated,
            ),
            productType: p.product_type,
            price: getPriceStringFromVariants(
              p.variants,
              dashQuery.data?.config?.currencyFormat,
            ),
            score: rec.frequentlyBoughtTogether.find((r) => r.product == p.id)?.score
          }))
          return {
            product: rec.product.id,
            similarProducts,
            crossSell,
            frequentlyBoughtTogether,
          }
        }),
      )
      return populated
    },
    refetchOnMount: true,
    staleTime: 0,
    enabled: products.length > 0 || !dashQuery.isLoading,
  })

  const recommendations = query.data ?? []
  const recommendationsMap = recommendations.reduce((acc, rec) => {
    acc[rec.product] = {
      similarProducts: rec.similarProducts ?? [],
      crossSell: rec.crossSell ?? [],
      frequentlyBoughtTogether: rec.frequentlyBoughtTogether ?? [],
    }
    return acc
  }, {})

  return (
    <Page
      backAction={{
        url: params.back ?? '/settings',
      }}
      titleMetadata={
        <InlineStack gap="300">
          <Badge tone="info">{WIDGET_TYPES[widgetType].label}</Badge>
        </InlineStack>
      }
      title={t('RecommendationPage.ProductRecommendation.title')}
      subtitle={t('RecommendationPage.ProductRecommendation.description')}
      primaryAction={{
        content: t(
          'RecommendationPage.ProductRecommendation.bulkUploadBtnText',
        ),
        onAction: () => setBulkUploadModalOpen(true),
      }}
      fullWidth
    >
      <Box padding={breakpoint.mdUp ? '0' : '400'}>
        <ProductRecommendationTable
          widgetType={widgetType}
          products={products.map((product) => ({
            ...(recommendationsMap[product.id] ?? {
              similarProducts: [],
              crossSell: [],
              frequentlyBoughtTogether: [],
            }),
            product: {
              ...product,
              id: product.id.toString(),
            },
          }))}
          isLoading={productsLoading}
          pagination={pagination}
          searchQueryState={[searchQuery, setSearchQuery]}
          recFetching={
            query.isLoading || query.isRefetching || dashQuery.isLoading
          }
        />
      </Box>
      <BulkUploadFormModal
        show={bulkUploadModalOpen}
        setShow={setBulkUploadModalOpen}
      />
    </Page>
  )
}

export default ProductRecommendation

function getPriceStringFromVariants(variants, currency) {
  let arr = variants
    .map((v) => v.price)
    .map((v) => parseFloat(v))
    .sort((a, b) => a - b)
  arr = [...new Set(arr)]
  const amount = arr[0]
  return formatCurrency(currency, amount)
}
