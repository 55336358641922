import { BlockStack, Card, List, Text } from '@shopify/polaris'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

export default function CampaignSummary({ form }) {
  const { t } = useTranslation()
  return (
    <Card>
      <BlockStack gap={'100'}>
        <Text variant="headingMd">{t('CheckoutUpsell.Summary.title')}</Text>
        <Text>
          {t('CheckoutUpsell.Summary.text', {
            status: <strong>{form.fields.status.value}</strong>,
            start: (
              <strong>
                {form.fields.duration.value.start
                  ? format(
                      new Date(form.fields.duration.value.start),
                      'dd MMM, yyyy',
                    )
                  : '-'}
              </strong>
            ),
            end: (
              <strong>
                {form.fields.duration.value.end
                  ? format(
                      new Date(form.fields.duration.value.end),
                      'dd MMM, yyyy',
                    )
                  : '-'}
              </strong>
            ),
            total: (
              <strong>{`${form.fields.triggerConditions.value.length} condition(s)`}</strong>
            ),
            count: (
              <strong>{`${form.fields.products.value.length}  product(s)`}</strong>
            ),
          })}
        </Text>
      </BlockStack>
    </Card>
  )
}
