export default function StopPropogation({ children }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}>
      {children}
    </div>
  )
}
