import { useQuery } from 'react-query'

import { getDashboardDetails } from '../apis/dashbboard'
import queryClient from '../utils/query'

export function useDashboardDetails({ token } = {}) {
  const queryKey = ['getDashboardDetails']
  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const res = await getDashboardDetails(token)
      if (res.error) {
        return Promise.reject(res.error)
      }
      if (res.data?.redirectUrl) {
        return {
          redirectUrl: res.data.redirectUrl,
        }
      }
      return res.data
    },
    enabled: Boolean(token) || Boolean(shopify.idToken),
  })

  return {
    data: query.data,
    isLoading: query.isLoading,
    invalidate: () => queryClient.invalidateQueries(queryKey),
    error: query.error,
  }
}
