import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  Divider,
  SkeletonTabs,
  Spinner,
  Tabs,
} from '@shopify/polaris'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { numberFormater } from '../../../../../utils/formater'

export default function RevenueChart({
  data,
  loading,
  tabs,
  activeIdx,
  setActiveIdx,
}) {
  const { t } = useTranslation()

  useEffect(() => {
    Chart.register(CategoryScale)
  }, [])

  if (loading) {
    return (
      <Card padding={'0'}>
        <Box>
          <SkeletonTabs count={3} />
        </Box>
        <Divider />
        <div
          style={{
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 'var(--p-space-300)',
          }}
        >
          <Spinner size="small" />
        </div>
      </Card>
    )
  }

  return (
    <Card padding={'0'}>
      <Box>
        <Tabs selected={activeIdx} onSelect={setActiveIdx} tabs={tabs} />
      </Box>
      <Divider />
      <div
        style={{
          height: '300px',
          padding: 'var(--p-space-200) var(--p-space-300)',
        }}
      >
        <Line
          data={{
            labels: data.labels,
            datasets: data.datasets.map((data) => ({
              label: data.label,
              data: data.data,
              borderColor: data.borderColor,
            })),
          }}
          width={'100%'}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              line: {
                tension: 0.5,
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                align: 'end',
              },
              tooltip: {
                mode: 'x',
                backgroundColor: 'white',
                titleColor: 'rgba(26, 26, 26, 1)',
                bodyColor: 'rgba(48, 48, 48, 1)',
                borderWidth: 1,
                borderColor: 'rgba(0, 0, 0, 0.1)',
                intersect: false,
                callbacks: {
                  label: (tooltipItem) => {
                    if (typeof data.formatter === 'function') {
                      return (
                        tooltipItem.dataset.label +
                        ': ' +
                        data.formatter(tooltipItem.raw)
                      )
                    }
                    return (
                      tooltipItem.dataset.label +
                      ': ' +
                      tooltipItem.formattedValue
                    )
                  },
                },
                usePointStyle: true,
              },
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            tooltips: {
              mode: 'x',
              intersect: false,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Month',
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                  },
                },
              ],
              y: {
                ticks: {
                  callback: function (value, index, ticks) {
                    if (typeof data.formatter === 'function') {
                      return data.formatter(value)
                    }
                    return typeof value === 'number'
                      ? numberFormater(value)
                      : value
                  },
                },
              },
            },
          }}
        />
      </div>
    </Card>
  )
}
