export const DISCOUNT_TYPES = {
    // FLAT: {
    //   label: 'Flat',
    //   value: 'AMOUNT',
    // },
    PERCENTAGE: {
      label: 'Percent',
      value: 'PERCENTAGE',
    },
    AMOUNT: {
      label: 'Amount',
      value: 'AMOUNT',
    },
  }