import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Form,
  InlineGrid,
  InlineStack,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { redirectDocument, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { useListBundles } from '../hooks/useListBundles'
import useBundlesPermissible from '../hooks/useBundlesPermissible'
import BundlesUpsertForm from '../components/BundlesUpsertForm'
import {
  fetchCollection,
  fetchProduct,
  fetchProductTypes,
} from '../../../apis/shopify'
import NotFound from '../../../pages/NotFound'
import { getCommonOptions } from '../../../utils/bundles'

export default function BundlesUpsert() {
  const { allowed, loading, error: permissionError } = useBundlesPermissible()
  const { data: dashbaordData, isLoading: dashboardLoading } =
    useDashboardDetails()
  const { t } = useTranslation()
  const params = useParams()
  const bundleId = params.bundleId
  const { bundles, isLoading, error: bundleListError } = useListBundles()

  const bundle = bundles.find((bundle) => bundle.id.toString() == bundleId)
  const productVariantInBundles = Array.from(
    new Set(
      bundle?.variants
        ?.map((bundleVariant) =>
          Object.values(bundleVariant.productVariantMapping),
        )
        .flat(),
    ),
  )

  const productTypesQuery = useQuery({
    queryKey: ['productTypes'],
    queryFn: fetchProductTypes,
  })

  const productQuery = useQuery({
    queryKey: ['product', bundle?.productIds],
    queryFn: async () => {
      const productIds = bundle.productIds
      const products = await Promise.all(
        productIds.map(async (id) => {
          const { data } = await fetchProduct(id, true)
          return data
        }),
      )
      return products.map((product) => {
        product.variants = product.variants
          .map((variant) => ({
            ...variant,
            discount:
              bundle.discount?.variantDiscount?.[
                parseInt(
                  variant.id.replace('gid://shopify/ProductVariant/', ''),
                )
              ] || 0,
          }))
          .filter((variant) => {
            // filter out variants that are not in the bundle
            const variantId = parseInt(
              variant.id.replace('gid://shopify/ProductVariant/', ''),
            )
            return productVariantInBundles.find(
              (bundleVariant) => bundleVariant === variantId,
            )
          })
        return product
      })
    },
    enabled: Boolean(bundle),
  })

  const collectionQuery = useQuery({
    queryFn: async () => {
      const collectionIds = bundle.collectionIds
      const collections = await Promise.all(
        collectionIds.map(async (id) => {
          const { data } = await fetchCollection(id)
          return data
        }),
      )
      return collections.map((collection) => ({
        ...collection,
        id: 'gid://shopify/Collection/' + collection.id,
      }))
    },
    queryKey: ['collection', bundle?.collectionIds],
    enabled: Boolean(bundle),
  })

  const error =
    permissionError ||
    productQuery.error ||
    collectionQuery.error ||
    bundleListError ||
    null

  if (
    loading ||
    dashboardLoading ||
    isLoading ||
    productQuery.isLoading ||
    productTypesQuery.isLoading
  ) {
    return <Skeleton />
  }

  if (!bundle) {
    return <NotFound />
  }

  if (error) {
    return (
      <Page>
        <Card>
          <InlineGrid columns={'1fr auto 1fr'}>
            <Text>{error}</Text>
          </InlineGrid>
        </Card>
      </Page>
    )
  }

  if (!allowed) {
    return (
      <Page>
        <Card>
          <Banner title={t('Bundles.notAllowed')} tone="critical" />
        </Card>
      </Page>
    )
  }

  const productVariantIdMap = productQuery.data
    .map((product) => product.variants)
    .flat()
    .reduce((acc, variant) => {
      const id = parseInt(
        variant.id.replace('gid://shopify/ProductVariant/', ''),
      )
      acc[id] = variant
      return acc
    }, {})

  // TODO: Populate options from response.
  const options = getCommonOptions(productQuery.data)

  const currentValues = {
    title: bundle.displayName,
    description: bundle.description,
    products: productQuery.data,
    productType: bundle.productType,
    tags: bundle.tags,
    collections: collectionQuery.data,
    variantDiscount: bundle.discount?.variantDiscount || {},
    bundleDiscount: bundle.discount?.bundleDiscount || 0,
    discountType: bundle.discount?.discountType || 'noDiscount',
    configurationType: bundle.data?.bundleConfigurationType ?? null,
    bundles: bundle.variants.map((variant) => ({
      ...variant,
      products: Object.values(variant.productVariantMapping).map(
        (variantId) => ({
          ...productVariantIdMap[variantId],
          bundleVariantId: variant.id,
        }),
      ),
    })),
    options,
    id: bundle.id,
    template: '',
    shopTemplate: '',
  }

  return (
    <Page
      fullWidth
      title={t('Bundles.Details.title')}
      subtitle={t('Bundles.Details.description')}
      backAction={{
        url: '/bundles',
      }}
    >
      <BundlesUpsertForm
        disabledFields={['configurationType']}
        currentValues={currentValues}
        dashboardDetails={dashbaordData}
        productTypes={productTypesQuery.data}
        onSubmit={submitHandler}
      />
      <Box padding={'400'} />
    </Page>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth title={t('Bundles.Details.title')}>
      <Card>
        <BlockStack gap={'300'}>
          <SkeletonDisplayText />
          <SkeletonBodyText lines={3} />
        </BlockStack>
      </Card>
    </SkeletonPage>
  )
}
