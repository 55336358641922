import {
  Card,
  EmptyState,
  Page,
  Layout,
  BlockStack,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  InlineGrid,
  Banner,
  Link,
  Button,
  useBreakpoints,
  Box,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContextualSaveBar } from '@shopify/app-bridge-react'

import { useEditSection } from './hooks'
import {
  Details,
  SelectTemplate,
  BundleConfig,
  Segmentations,
  AdvanceConfig,
  Recommendation,
} from './WidgetSections'
import { useRedirect } from '../../../hooks'
import { getQueryParams, updateURLSearch } from '../../../utils/router'
import { useShopDetails } from '../../../hooks/useShopDetails'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  SIMILAR_PRODUCTS,
  WIDGET_TYPES,
} from '../../../constants/widgets'
import { updateSection } from '../../../apis/section'
import { fetchProduct } from '../../../apis/shopify'
import queryClient from '../../../utils/query'
import LocationSection from './WidgetSections/locationAndPosition'
import { AMAZON_BOUGHT_TOGETHER } from '../../../constants/layouts'
import {
  deleteAutoRecommendation,
  getAutoRecommendations,
  upsertAutoRecommendations,
} from '../../../apis/recommendation'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import DiscountConfig from './WidgetSections/discount'

export default function EditSection() {
  const saveBar = useContextualSaveBar()
  const breakpoints = useBreakpoints()
  const [token, setToken] = useState(null)
  const { t } = useTranslation()
  const { openVisualEditor = false, showSection } = getQueryParams()
  const { redirectToLink } = useRedirect()
  const { sectionId = null } = useParams()
  const [loading, setLoading] = useState(false)
  const { data = {}, isLoading, error } = useShopDetails()
  const sections = data?.sections ?? []
  const section = sections.find((s) => s.id.toString() === sectionId)
  const experience =
    (data?.experiences ?? []).find((exp) => exp.id === section?.experience) ??
    {}
  const page = data?.pages?.find((p) => p.id === section?.page) ?? {}
  const isError = error !== null
  const pricing = usePricing()
  const {
    data: dashboardDetails,
    isLoading: dashboardLoading,
    invalidate: invalidateDashbaordData,
  } = useDashboardDetails()

  const productsQuery = useQuery({
    queryKey: ['products', sectionId],
    queryFn: async () => {
      const disabledProducts = await Promise.all(
        section.disabledProducts.map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      const excludedProducts = await Promise.all(
        section.excludedProducts.map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      const whitelistedProducts = await Promise.all(
        section.whitelistedProducts.map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      return {
        disabledProducts,
        excludedProducts,
        whitelistedProducts,
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    enabled: Boolean(section?.id),
  })
  const products = productsQuery.data ?? {}
  const autoRecQuery = useQuery({
    queryKey: ['autoRecommendations', sectionId],
    queryFn: async () => {
      const { data, error } = await getAutoRecommendations({
        recommendationType: 'promoted',
        objectType: 'page',
        section: sectionId,
      })

      if (error) {
        return Promise.reject(error)
      }

      // populate products
      const objects = data.recommendations
      const populatedObjects = await Promise.all(
        objects.map(async (obj) => {
          const {
            data: { recommendations },
          } = obj
          const products = await Promise.all(
            recommendations.map(
              async ({ value }) => (await fetchProduct(value)).data,
            ),
          )
          return {
            products,
            id: obj.id,
            url: obj.objectId,
          }
        }),
      )
      return populatedObjects
    },
  })

  const currentValues = useMemo(
    () => ({
      name: section?.title ?? '',
      experience: experience?.name ?? '',
      page: page.type ?? '',
      widgetType: section?.type ?? 'bought_together',
      layout: section?.layout ?? '',
      excludedProducts: products.excludedProducts ?? [],
      disabledProducts: products.disabledProducts ?? [],
      whitelistedProducts: products.whitelistedProducts ?? [],
      disabledTags: section?.disabledTags ?? [],
      excludedTags: section?.excludedTags ?? [],
      whitelistedTags: section?.whitelistedTags ?? [],
      enableBundleDiscounts: section?.discountConfig?.enabled ?? false,
      discountType: section?.discountConfig?.type || 'PERCENTAGE',
      discountMessage: section?.discountConfig?.widgetMessage ?? '',
      cartPageMsg: section?.discountConfig?.cartMessage ?? '',
      discountValue: section?.discountConfig?.value ?? 0,
      segmentation: section?.segment ?? '',
      sectionTitle: section?.title ?? '',
      showSoldOutItems: section?.allowOutOfStock ?? false,
      enableCartProps: section?.addCartProperties ?? false,
      pricePercentageThreshold:
        section?.type === BOUGHT_TOGETHER.value
          ? section?.pricePercentageThreshold ?? 0
          : null,
      productRankingCriteria: section?.rankingCriteria ?? null,
      productFilterCriteria: section?.filterCriteria ?? '',
      fallbackCriteria: section?.fallbackCriteria ?? '',
      minPrice: parseInt(section?.minPrice ?? '0'),
      maxPrice: parseInt(section?.maxPrice ?? '0'),
      automaticEnabled: section?.automaticEnabled ?? false,
      enableRandom: section?.enableRandom ?? false,
      allowIfUnavailable: section?.allowIfUnavailable ?? false,
      template: section?.template ?? null,
      enabledUrls: section?.enabledUrls ?? [],
      location: section?.location ?? 0,
      position: section?.position ?? '',
      collections: section?.collections ?? [],
      slots: section?.extra?.slots ?? [],
      objects: autoRecQuery.data ?? [], // add objects here
      translations: section?.translations ?? {
        primary_locale: {
          title: section?.title ?? ''
        },
      },
      enableCartButton: section?.enableCartButton ?? false,
    }),
    [section, experience, page, products, autoRecQuery.data],
  )

  const saveHandler = async (data) => {
    setLoading(true)
    const {
      name,
      layout,
      widgetType,
      template,
      disabledProducts,
      excludedProducts,
      disabledTags,
      excludedTags,
      enableBundleDiscounts: { checked: enableBundleDiscounts } = {},
      segmentation,
      showSoldOutItems: { checked: showSoldOutItems },
      enableCartProps: { checked: enableCartProps },
      productRankingCriteria,
      fallbackCriteria,
      minPrice,
      maxPrice,
      allowIfUnavailable: { checked: allowIfUnavailable },
      enabledUrls,
      location,
      position,
      whitelistedProducts,
      whitelistedTags,
      collections,
      slots,
      translations,

      enableCartButton: { checked: enableCartButton } = {},
    } = data

    const ok = await upsertAutoRec({
      prevValue: currentValues.objects,
      currentValue: data.objects,
      sectionId,
    })

    if (!ok) {
      shopify.toast.show('Some Error Occured', {
        isError: true,
      })
      setLoading(false)
      return {
        status: 'error',
        message: res.error,
      }
    }

    const payload = {
      title: name,
      layout,
      collections,
      disabledProducts: disabledProducts.map((p) =>
        parseInt(p.id.replace('gid://shopify/Product/', '')),
      ),
      excludedProducts: excludedProducts.map((p) =>
        parseInt(p.id.replace('gid://shopify/Product/', '')),
      ),
      whitelistedProducts: whitelistedProducts.map((p) =>
        parseInt(p.id.replace('gid://shopify/Product/', '')),
      ),
      disabledTags,
      excludedTags,
      whitelistedTags,
      segment: segmentation,
      rankingCriteria: productRankingCriteria,
      fallbackCriteria,
      minPrice,
      maxPrice,
      allowOutOfStock: showSoldOutItems,
      allowIfUnavailable,
      enabledUrls,
      template,
      location,
      position,
      extra: {
        slots,
      },
      translations,

      enableCartButton: enableCartButton,
      addCartProperties: enableCartProps,
    }
    // for discount config
    payload.discountConfig = {
      enabled: enableBundleDiscounts,
      value: data.discountValue,
      cartMessage: data.cartPageMsg,
      widgetMessage: data.discountMessage,
      type: data.discountType,
    }

    payload.enableRandom = data?.enableRandom?.checked

    if (
      widgetType === CROSS_SELL.value ||
      widgetType === BOUGHT_TOGETHER.value ||
      widgetType === SIMILAR_PRODUCTS.value
    ) {
      payload.automaticEnabled = data.automaticEnabled.checked
    }

    if (widgetType === BOUGHT_TOGETHER.value) {
      payload.pricePercentageThreshold = data.pricePercentageThreshold
    }

    if (widgetType === SIMILAR_PRODUCTS.value) {
      payload.filterCriteria = data.filterCriteria
    }

    const res = await updateSection(sectionId, payload)
    setLoading(false)
    saveBar.hide()
    if (res.error) {
      shopify.toast.show(
        res.error?.error?.[0]?.message ?? 'Some Error Occured',
        {
          isError: true,
        },
      )
      return {
        status: 'error',
        message: res.error,
      }
    }
    await queryClient.invalidateQueries(['shopDetails'])
    await queryClient.invalidateQueries(['products', sectionId])
    await queryClient.invalidateQueries(['autoRecommendations', sectionId])
    shopify.toast.show(
      t('DefaultText.updateSuccess', {
        text: '"' + payload.title + '"',
      }),
    )
    redirectToLink({
      url: `/sections/${sectionId}`,
    })
    return {
      status: 'success',
    }
  }

  const form = useEditSection({
    onSubmit: saveHandler,
    currentValues,
  })
  const parsedFormValues = getParsedValuesFromForm(form)

  const scrollToElem = document.querySelector(`#${showSection}`) || null
  const openVisualEditorHandler = async () => {
    const token = await shopify.idToken()
    setToken(token)
    setTimeout(() => {
      document.getElementById('visual-editor-modal')?.show()
    }, 300)
  }

  const isTemplateEnabled =
    Boolean(dashboardDetails?.config?.templatesEnabled)

  useEffect(() => {
    if (form.submitErrors.length > 0) {
      shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
        duration: 5000, // 5 seconds
      })
    }
  }, [form.submitErrors])

  useEffect(() => {
    if (scrollToElem) {
      setTimeout(() => {
        scrollToElem?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 10)
    }
  }, [!!scrollToElem])

  useEffect(() => {
    if (!isLoading) {
      const isFormDirty = !compareValues(parsedFormValues, currentValues)
      if (isFormDirty) {
        saveBar.show()
      } else {
        saveBar.hide()
      }
    }
  }, [isLoading, parsedFormValues])

  useEffect(() => {
    if (openVisualEditor && !isLoading) {
      setTimeout(() => {
        openVisualEditorHandler()
        updateURLSearch({ openVisualEditor: false })
      }, 100)
    }
  }, [openVisualEditor, isLoading])

  useEffect(() => {
    function handleEvent(ev) {
      if (ev?.data === 'GAI-close-visual-editor-modal') {
        document.getElementById('visual-editor-modal')?.hide()
      }
    }
    window.addEventListener('message', handleEvent)
    saveBar.saveAction.setOptions({
      onAction: async () => {
        try {
          await form.submit()
        } catch (e) {
          console.error(e)
        }
      },
      loading: loading || form.submitting,
    })
    saveBar.discardAction.setOptions({
      onAction: () => {
        form.reset()
      },
      disabled: false,
      loading: false,
      discardConfirmationModal: true,
    })

    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [form.submitting])

  if (
    isLoading ||
    productsQuery.isLoading ||
    autoRecQuery.isLoading ||
    dashboardLoading
  ) {
    return <SkeletonComponent />
  }

  if (!section || isError) {
    return (
      <Page
        fullWidth
        backAction={{
          url: '/',
        }}
      >
        <Card>
          <EmptyState
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            heading={t('Section.NotFound.heading')}
          >
            <p>{t('Section.NotFound.description')}</p>
          </EmptyState>
        </Card>
      </Page>
    )
  }

  return (
    <>
      <Page
        fullWidth
        backAction={{
          url: '/sections',
        }}
        title={t('Section.Edit.title')}
        subtitle={t('Section.Edit.subtitle')}
      >
        <Box padding={breakpoints.mdUp ? '0' : '400'}>
          {!section.enabled && <SectionDisableBanner sectionId={section.id} />}
          <Layout>
            <Details
              page="edit"
              pricing={pricing}
              dashboardData={dashboardDetails}
              form={form}
            />
            {isTemplateEnabled && (
              <SelectTemplate
                pricing={pricing}
                dashboardData={dashboardDetails}
                form={form}
              />
            )}
            <Recommendation
              pricing={pricing}
              dashboardData={dashboardDetails}
              sectionId={sectionId}
              form={form}
            />
            <BundleConfig
              form={form}
              pricing={pricing}
              dashbaordData={dashboardDetails}
            />
            <LocationSection
              pricing={pricing}
              dashboardData={dashboardDetails}
              form={form}
            />
            <Segmentations
              pricing={pricing}
              dashboardData={dashboardDetails}
              form={form}
            />
            <AdvanceConfig
              pricing={pricing}
              dashboardData={dashboardDetails}
              showPricePercentageThreshold={
                section?.type === BOUGHT_TOGETHER.value
              }
              form={form}
            />
            <div
              style={{
                padding: 'var(--p-space-400)',
              }}
            />
          </Layout>
        </Box>
      </Page>
      <PricingModal modal={pricing} />
      <ui-modal
        id="visual-editor-modal"
        variant="max"
        src={`/visual-editor?template=${section.template}&widget=${sectionId}&token=${token}`}
      >
        <ui-title-bar title="Visual Editor"></ui-title-bar>
      </ui-modal>
    </>
  )
}

function arraysEqual(arr1, arr2) {
  // Check if the arrays are of the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort the arrays if the order of elements doesn't matter
  // arr1.sort();
  // arr2.sort();

  // Iterate over each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    const item1 = arr1[i]
    const item2 = arr2[i]

    // If both elements are arrays, recursively compare them
    if (Array.isArray(item1) && Array.isArray(item2)) {
      if (!arraysEqual(item1, item2)) {
        return false
      }
    }
    // If both elements are objects, recursively compare them
    else if (
      item1 !== null &&
      typeof item1 === 'object' &&
      item2 !== null &&
      typeof item2 === 'object'
    ) {
      if (!objectsEqual(item1, item2)) {
        return false
      }
    }
    // Otherwise, compare primitive data types directly
    else if (item1 !== item2) {
      return false
    }
  }

  // If all elements are equal, arrays are equal
  return true
}

function objectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false
  }

  // Iterate over each key in obj1
  for (let key of keys1) {
    const val1 = obj1[key]
    const val2 = obj2[key]

    // If both values are arrays, recursively compare them
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!arraysEqual(val1, val2)) {
        return false
      }
    }
    // If both values are objects, recursively compare them
    else if (
      val1 !== null &&
      typeof val1 === 'object' &&
      val2 !== null &&
      typeof val2 === 'object'
    ) {
      if (!objectsEqual(val1, val2)) {
        return false
      }
    }
    // Otherwise, compare primitive data types directly
    else if (val1 !== val2) {
      return false
    }
  }

  // If all keys and values are equal, objects are equal
  return true
}

const upsertAutoRec = async ({
  prevValue = [],
  currentValue = [],
  sectionId,
}) => {
  const areEqual = arraysEqual(currentValue, prevValue)

  if (areEqual) {
    return true
  }

  const deletedEntries = prevValue.filter(
    (prev) => !currentValue.some((curr) => curr.id === prev.id),
  )
  const idsToBeDeleted = deletedEntries.map((entry) => entry.id)

  if (idsToBeDeleted.length > 0) {
    const deleteRes = await deleteAutoRecommendation({
      ids: idsToBeDeleted,
    })

    if (deleteRes.error) {
      return false
    }
  }

  const upsertRes = await upsertAutoRecommendations(
    currentValue.map((obj) => ({
      id: obj.id,
      objectId: obj.url,
      count: 2,
      data: {
        recommendations: obj.products.map((product) => ({
          value: product.id,
        })),
      },
      recommendationType: 'promoted',
      objectType: 'page',
      section: sectionId,
    })),
  )

  if (upsertRes.error) {
    return false
  }

  return true
}

const SkeletonComponent = () => {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth primaryAction title={t('Section.Edit.title')}>
      <BlockStack gap={'300'}>
        <Layout>
          <Layout.Section variant="oneThird">
            <BlockStack gap={'300'}>
              <SkeletonDisplayText size="medium" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <BlockStack gap={'300'}>
                <SkeletonDisplayText size="small" />
                <InlineGrid columns={'1fr 1fr'} gap={'200'}>
                  <SkeletonDisplayText maxWidth="100%" size="medium" />
                  <SkeletonDisplayText maxWidth="100%" size="medium" />
                </InlineGrid>
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section variant="oneThird">
            <BlockStack gap={'300'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <BlockStack gap={'300'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section variant="oneThird">
            <BlockStack gap={'300'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <BlockStack gap={'300'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </BlockStack>
    </SkeletonPage>
  )
}

function SectionDisableBanner({ sectionId }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  return (
    <Box>
      <Banner title={t('Section.disableBanner.title')}>
        <BlockStack gap={'200'}>
          <p>
            {t('Section.disableBanner.description', {
              setupPageLink: (
                <Link url={`/sections/${sectionId}`}>
                  {t('Section.disableBanner.setupCta')}
                </Link>
              ),
            })}
          </p>
          <div>
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true)
                const res = await updateSection(sectionId, { enabled: true })
                setLoading(false)
                if (res.error) {
                  shopify.toast.show(t('Section.disableBanner.error'), {
                    isError: true,
                  })
                  return
                }
                await queryClient.invalidateQueries(['shopDetails'])
                shopify.toast.show(t('Section.disableBanner.success'))
              }}
            >
              {t('Section.disableBanner.enableCta')}
            </Button>
          </div>
        </BlockStack>
      </Banner>
      <div
        style={{
          padding: 'var(--p-space-200)',
        }}
      />
    </Box>
  )
}

function getParsedValuesFromForm(form) {
  const obj = {
    name: form.fields.name?.value ?? undefined,
    experience: form.fields.experience?.value ?? undefined,
    page: form.fields.page?.value ?? undefined,
    widgetType: form.fields.widgetType?.value ?? undefined,
    layout: form.fields.layout?.value ?? undefined,
    excludedProducts: form.fields.excludedProducts?.value ?? undefined,
    disabledProducts: form.fields.disabledProducts?.value ?? undefined,
    whitelistedProducts: form.fields.whitelistedProducts?.value ?? undefined,
    disabledTags: form.fields.disabledTags?.value ?? undefined,
    excludedTags: form.fields.excludedTags?.value ?? undefined,
    whitelistedTags: form.fields.whitelistedTags?.value ?? undefined,
    enableBundleDiscounts:
      form.fields.enableBundleDiscounts?.value ?? undefined,
    discountType: form.fields.discountType?.value ?? undefined,
    discountMessage: form.fields.discountMessage?.value ?? undefined,
    cartPageMsg: form.fields.cartPageMsg?.value ?? undefined,
    discountValue: form.fields.discountValue?.value ?? undefined,
    segmentation: form.fields.segmentation?.value ?? undefined,
    sectionTitle: form.fields.name?.value ?? undefined,
    showSoldOutItems: form.fields.showSoldOutItems?.checked ?? undefined,
    enableCartProps: form.fields.enableCartProps?.checked ?? undefined,
    pricePercentageThreshold:
      form.fields.pricePercentageThreshold?.value ?? undefined,
    productRankingCriteria:
      form.fields.productRankingCriteria?.value ?? undefined,
    productFilterCriteria:
      form.fields.productFilterCriteria?.value ?? undefined,
    fallbackCriteria: form.fields.fallbackCriteria?.value ?? undefined,
    minPrice: form.fields.minPrice?.value ?? undefined,
    maxPrice: form.fields.maxPrice?.value ?? undefined,
    automaticEnabled: form.fields.automaticEnabled?.checked ?? undefined,
    enableRandom: form.fields.enableRandom?.checked ?? undefined,
    allowIfUnavailable: form.fields.allowIfUnavailable?.checked ?? undefined,
    template: form.fields.template?.value ?? undefined,
    enabledUrls: form.fields.enabledUrls?.value ?? undefined,
    location: form.fields.location?.value ?? undefined,
    position: form.fields.position?.value ?? undefined,
    collections: form.fields.collections?.value ?? undefined,
    slots: form.fields.slots?.value ?? undefined,
    objects: form.fields.objects?.value ?? undefined,
    translations: form.fields.translations?.value ?? undefined,
    enableCartButton: form.fields.enableCartButton?.checked ?? undefined,
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})
}

function compareValues(obj1, obj2) {
  return Object.entries(obj1).every(([key, value]) => {
    return (
      (Array.isArray(value) && arraysEqual(value, obj2[key])) ||
      (typeof value === 'object' &&
        value !== null &&
        objectsEqual(value, obj2[key])) ||
      (typeof value === 'boolean' && value === obj2[key]) ||
      (typeof value === 'string' && value === obj2[key]) ||
      (typeof value === 'number' && value === obj2[key])
    )
  })
}
