import {
  Box,
  Button,
  DatePicker,
  Form,
  FormLayout,
  InlineStack,
  Popover,
  Select,
  TextField,
} from '@shopify/polaris'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { format } from 'date-fns'

function UpsertExpForm({ currentValues, experiences, submitHandler }) {
  const { t } = useTranslation()
  const [selectStartDate, setStartSelectedDates] = useState({
    start: currentValues?.startTime
      ? new Date(currentValues.startTime)
      : new Date(new Date().setMonth(new Date().getMonth() - 1)),
    end: new Date(),
  })
  const [selectEndDate, setEndSelectedDates] = useState({
    start: currentValues?.endTime
      ? new Date(currentValues.endTime)
      : new Date(),
    end: new Date(),
  })

  const [duplicatedFrom, setDuplicatedFrom] = useState(null)
  const form = useForm({
    fields: {
      name: useField({
        value: currentValues?.name || '',
        validates: [notEmpty(t('ABExperience.Create.Form.name.notEmpty'))],
      }),
      weightage: useField({
        value: currentValues?.weight || null,
        validates: [
          notEmpty(t('ABExperience.Create.Form.weightage.notEmpty')),
          (val) => {
            if (val > 100) {
              return t('ABExperience.Create.Form.weightage.max')
            }
            if (val < 0) {
              return t('ABExperience.Create.Form.weightage.min')
            }
            return null
          },
        ],
      }),
      key: useField(currentValues?.triggerKey || null),
      value: useField(currentValues?.triggerValue || null),
    },
    onSubmit: async (val) => {
      const res = await submitHandler({
        ...val,
        startTime: selectStartDate.start,
        endTime: selectEndDate.start,
        duplicatedFrom,
      })
      if (res.error) {
        shopify.toast.show(t('ABExperience.Create.errorText'), {
          isError: true,
        })
        return {
          status: 'error',
          errors: [
            {
              message: res.error,
            },
          ],
        }
      }
      shopify.toast.show(t('ABExperience.Create.successText'))
      return {
        status: 'success',
      }
    },
  })

  return (
    <Form onSubmit={form.submit}>
      <FormLayout>
        <FormLayout>
          <TextField
            {...form.fields.name}
            placeholder={t('ABExperience.Create.Form.name.placeholder')}
            label={t('ABExperience.Create.Form.name.label')}
          />
        </FormLayout>
        <FormLayout>
          <TextField
            {...form.fields.weightage}
          placeholder={t('ABExperience.Create.Form.weightage.placeholder')}
            label={t('ABExperience.Create.Form.weightage.label')}
            type="number"
          />
        </FormLayout>
        {experiences && (
          <FormLayout>
            <Select
              onChange={(val) => setDuplicatedFrom(val)}
              value={duplicatedFrom?.toString()}
              placeholder={t('ABExperience.Create.Form.duplicate.placeholder')}
              label={t('ABExperience.Create.Form.duplicate.label')}
              helpText={t('ABExperience.Create.Form.duplicate.helpText')}
              options={experiences.map(({ id, name }) => ({
                label: name,
                value: id.toString(),
                key: id.toString(),
              }))}
            />
          </FormLayout>
        )}
        <FormLayout.Group>
          <TextField
            {...form.fields.key}
            helpText={t('ABExperience.Create.Form.key.helpText')}
            placeholder={t('ABExperience.Create.Form.key.placeholder')}
            label={t('ABExperience.Create.Form.key.label')}
          />
          <TextField
            {...form.fields.value}
            placeholder={t('ABExperience.Create.Form.value.placeholder')}
            label={t('ABExperience.Create.Form.value.label')}
            helpText={t('ABExperience.Create.Form.value.helpText')}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <DatePickerPopover
            selectedDates={selectStartDate}
            setSelectedDates={setStartSelectedDates}
            label={t('ABExperience.Create.Form.startDate.label')}
            helpText={t('ABExperience.Create.Form.startDate.helpText')}
            disableDatesAfter={new Date()}
          />
          <DatePickerPopover
            selectedDates={selectEndDate}
            setSelectedDates={setEndSelectedDates}
            label={t('ABExperience.Create.Form.endDate.label')}
            helpText={t('ABExperience.Create.Form.endDate.helpText')}
            disableDatesAfter={new Date()}
            disableDatesBefore={selectStartDate.start}
          />
        </FormLayout.Group>
        <FormLayout>
          <InlineStack align="end">
            <Button loading={form.submitting} submit variant="primary">
              {t('ABExperience.Create.Form.submitCta')}
            </Button>
          </InlineStack>
        </FormLayout>
      </FormLayout>
    </Form>
  )
}

export default UpsertExpForm

function DatePickerPopover({
  selectedDates,
  setSelectedDates,
  label,
  helpText,
  disableDatesAfter,
  disableDatesBefore,
}) {
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const [popoverActive, setPopoverActive] = useState(false)
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )

  const activator = (
    <TextField
      label={label}
      onFocus={togglePopoverActive}
      helpText={helpText}
      value={format(new Date(selectedDates.start), 'dd MMM, yyyy')}
    />
  )

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      autofocusTarget="first-node"
      onClose={togglePopoverActive}
    >
      <Box padding={'200'}>
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          disableDatesAfter={disableDatesAfter}
          disableDatesBefore={disableDatesBefore}
        />
      </Box>
    </Popover>
  )
}
