import {
  useForm,
  useField,
  notEmpty,
  useChoiceField,
} from '@shopify/react-form'
import { useTranslation } from 'react-i18next'
import { BUNDLE_SETTING_TYPE } from '../../../constants/bundles'
import { useEffect } from 'react'
import { createBundleVariants } from '../../../utils/bundles'

export function useBundlesUpsertForm({ onSubmit, currentValues }) {
  const { t } = useTranslation()
  const title = useField({
    value: currentValues.title || '',
    validates: [
      notEmpty(t('BundlesUpsert.BasicDetails.form.title.notEmpty')),
      (value) => {
        if (value.length > 50) {
          return t('BundlesUpsert.BasicDetails.form.title.maxLength')
        }
        if (value.length < 3) {
          return t('BundlesUpsert.BasicDetails.form.title.minLength')
        }
        return null
      },
    ],
  })

  const products = useField({
    value: currentValues.products || [],
    validates: (val) => {
      if (val.length < 2) {
        return t('BundlesUpsert.VariantSelector.errors.atleastProducts')
      }
      if (val.length > 30) {
        return t('BundlesUpsert.VariantSelector.errors.atmostProducts')
      }
      return null
    },
  })

  const productType = useField(currentValues.productType || 'Bundle')

  const productTags = useField(currentValues.tags || [])

  const collections = useField(currentValues.collections || [])

  const images = useField(currentValues.images || [])

  const variantDiscount = useField({
    value: currentValues.variantDiscount || {},
    validates: [
      (val) => {
        // TODO: check this
        // const entries = Object.entries(val).map(([key, value]) => [
        //   key,
        //   Object.entries(value).map(([k, v]) => [k, parseFloat(v)]),
        // ])
        // let isError = false
        // const errors = entries.reduce((acc, [key, value]) => {
        //   console.log(value)
        //   acc[key] = value.reduce((acc, [k, v]) => {
        //     acc[k] = !v ? t('DefaultText.notEmpty') : null
        //     if (acc[k]) {
        //       isError = true
        //     }
        //     return acc
        //   }, {})
        //   return isError ? acc : null
        // }, {})
        // return errors
        return null
      },
    ],
  })

  const bundleDiscount = useField({
    value: currentValues.bundleDiscount || 0,
    validates: [
      (val) => {
        if (val < 0 || val > 100) {
          return t('BundlesUpsert.Discount.card.bundleDiscount.rangeError')
        }
        return null
      },
    ],
  })

  const discountType = useField(currentValues.discountType || 'noDiscount')

  const configurationType = useField(
    currentValues.configurationType || BUNDLE_SETTING_TYPE.UNIQUE,
  )

  const options = useField(currentValues.options || [])

  const bundleVariants = useField({
    value: currentValues.bundles || [],
    validates: [
      (vals) => {
        if (vals.length > 100) {
          return t('BundlesUpsert.VariantSelector.errors.variantsRange')
        }
        return null
      },
    ],
  })

  const template = useField({
    value: currentValues.template.toString() || '',
    validates: [
      notEmpty(t('BundlesUpsert.BasicDetails.form.template.notEmpty')),
    ],
  })

  const shopTemplate = useField({
    value: currentValues.shopTemplate || '',
    validates: [
      notEmpty(t('BundlesUpsert.BasicDetails.form.shopTemplate.notEmpty')),
    ],
  })

  const bundleType = useField(currentValues.bundleType)

  const status = useField(currentValues.status)

  const bundleOptions = useField({
    value: [],
    validates: [
      (vals) => {
        if (vals.length > 3) {
          return t('BundlesUpsert.VariantSelector.errors.optionsRange')
        }
        return null
      },
    ],
  })

  const publications = useField(currentValues.publicationIds || [])

  const fields = {
    title,
    products,
    tags: productTags,
    productType,
    collections,
    images,
    discountType,
    configurationType,
    options,
    bundleVariants,
    // template,
    shopTemplate,
    bundleType,
    status,
    bundleOptions,
    publications,
  }

  if (discountType.value === 'bundleDiscount') {
    fields.bundleDiscount = bundleDiscount
  }

  if (discountType.value === 'variantDiscount') {
    fields.variantDiscount = variantDiscount
  }

  return useForm({
    fields,
    onSubmit,
  })
}
