import React from 'react'

function Loader() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <svg
        style={{
          width: '80px',
        }}
        viewBox="0 0 80 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M79.724.603L54.981.521c-7.857 0-14.796 3.513-17.828 9.986C34.121 4.034 27.18.52 19.326.52 8.776.521-.127 6.85-.127 18.188c0 11.34 8.903 17.667 19.453 17.667 7.854 0 14.795-3.509 17.828-9.985 3.031 6.476 9.97 9.985 17.827 9.985 10.55 0 19.45-6.328 19.45-17.667 0-4.046-1.14-7.451-3.077-10.163l8.37-7.422zm-60.416 26.38c-5.276 0-8.903-3.617-8.903-8.795 0-5.092 3.627-8.79 8.903-8.79 5.275 0 8.887 3.697 8.887 8.79 0 5.178-3.612 8.795-8.887 8.795zm35.654 0c-5.275 0-8.902-3.617-8.902-8.795 0-5.092 3.627-8.79 8.902-8.79 5.276 0 8.9 3.709 8.9 8.79 0 5.178-3.624 8.795-8.881 8.795h-.019z"
          fill="var(--p-color-bg-inverse)"
        ></path>
      </svg>
      <svg
        style={{
          width: '40px',
          transform: 'translateX(-5px)',
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
      >
        <circle
          fill="var(--p-color-bg-inverse)"
          stroke="var(--p-color-bg-inverse)"
          strokeWidth="15"
          r="15"
          cx="40"
          cy="100"
        >
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.4"
          ></animate>
        </circle>
        <circle
          fill="var(--p-color-bg-inverse)"
          stroke="var(--p-color-bg-inverse)"
          strokeWidth="15"
          r="15"
          cx="100"
          cy="100"
        >
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.2"
          ></animate>
        </circle>
        <circle
          fill="var(--p-color-bg-inverse)"
          stroke="var(--p-color-bg-inverse)"
          strokeWidth="15"
          r="15"
          cx="160"
          cy="100"
        >
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="0"
          ></animate>
        </circle>
      </svg>
    </div>
  )
}

export default Loader
