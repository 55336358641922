import { differenceInHours, formatDistance } from 'date-fns'

export function hoursAgo(time) {
  const now = new Date()
  const compare = new Date(time)
  return differenceInHours(now, compare)
}

export function differnceInWords(time, from) {
  from = new Date() || new Date(from)
  return formatDistance(new Date(time), from)
}
