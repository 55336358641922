import {
  Avatar,
  Badge,
  Bleed,
  BlockStack,
  Button,
  Card,
  ChoiceList,
  DataTable,
  DatePicker,
  Filters,
  Icon,
  InlineGrid,
  Link,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  TextField,
} from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { UploadIcon } from '@shopify/polaris-icons'

import { useShopDetails } from '../../../../hooks/useShopDetails.js'
import { downloadAttributions, getOrders } from '../../../../apis/analytics.js'
import { useLocalSettings } from '../../../../components/LocalSettings'
import { getProducts } from '../../../../apis/products.js'
import { currencyFormatter } from '../../../../utils/formater.js'

const WINDOW_SIZE = 10

function AnalyticsOrderAttribution() {
  const { t } = useTranslation()

  const [pageNumber, setPageNumber] = useState(1)
  const [sectionFilter, setSectionFilter] = useState([])
  const [orderDownloadLoading, setOrderDownloadLoading] = useState(false)

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  const { settings, saveSettings } = useLocalSettings()

  const [durationFilter, setDurationFilter] = useState(settings.durationFilter)

  const orderAttributionQuery = useQuery({
    queryFn: async ({ queryKey }) => {
      const res = await getOrders(queryKey[1])
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: [
      'order-attribution',
      {
        dateGte: durationFilter?.start?.valueOf(),
        dateLte: durationFilter?.end?.valueOf(),
        page: pageNumber,
        count: WINDOW_SIZE,
        sections: sectionFilter.length ? sectionFilter : undefined,
      },
    ],
    refetchOnWindowFocus: false,
  })

  const shopDetailsQuery = useShopDetails()

  const productFetchQuery = useQuery({
    queryKey: [
      'order-attribution-product',
      {
        dateGte: durationFilter?.start?.valueOf(),
        dateLte: durationFilter?.end?.valueOf(),
        page: pageNumber,
        count: WINDOW_SIZE,
        sections: sectionFilter.length ? sectionFilter : undefined,
      },
    ],
    queryFn: async () => {
      const orders = orderAttributionQuery.data.orders
      const productsIds = orders
        .map((order) => order.attributedItems.map((item) => item.product.id))
        .flat()
        .map((id) => (typeof id === 'string' ? id : id.toString()))

      const productArray = (await getProducts(productsIds)).data.products.map(
        (product) => ({
          id: product.id,
          images: product.image ? [product.image?.src] : [],
          title: product.title,
          handle: product.handle,
          vendor: product.vendor,
        }),
      )

      return productArray.reduce((acc, product) => {
        if (!acc[product.id]) {
          acc[product.id] = product
        }
        return acc
      }, {})
    },
    enabled: orderAttributionQuery.isSuccess && shopDetailsQuery.isSuccess,
  })

  const sectionIdMapping = (shopDetailsQuery.data?.sections || []).reduce(
    (acc, section) => {
      acc[section.id] = section
      return acc
    },
    {},
  )
  const orders = orderAttributionQuery.data?.orders || []

  const productIdMapping = productFetchQuery.data || []

  const handleDurationFilterChange = useCallback(
    (value) => setDurationFilter(value),
    [],
  )

  const handleDurationFilterRemove = useCallback(
    () => setDurationFilter(undefined),
    [],
  )

  const handleFiltersClearAll = useCallback(() => {
    handleDurationFilterRemove()
  }, [handleDurationFilterRemove])

  const handleAttributionDownload = useCallback(async (filters) => {
    setOrderDownloadLoading(true)
    const res = await downloadAttributions(filters)
    if (res.error) {
      shopify.toast.show(`Can't download right now. Try again later.`, {
        isError: true,
      })
      return
    }
    const csvBlob = new Blob([res.data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(csvBlob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = 'order-attribution.csv'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
    setOrderDownloadLoading(false)
  }, [])

  useEffect(() => {
    saveSettings({
      ...settings,
      durationFilter,
    })
  }, [durationFilter])

  const filters = [
    {
      key: 'duration',
      label: t('Analytics.OrderAtribution.Filter.duration'),
      filter: (
        <DatePicker
          allowRange
          multiMonth
          selected={durationFilter}
          onChange={handleDurationFilterChange}
          month={month}
          year={year}
          onMonthChange={handleMonthChange}
          disableDatesAfter={new Date()}
        />
      ),
      shortcut: true,
    },
    {
      key: 'sections',
      label: t('Analytics.OrderAtribution.Filter.sections'),
      filter: (
        <ChoiceList
          title="section"
          titleHidden
          choices={Object.values(sectionIdMapping).map((section) => ({
            label: (
              <BlockStack gap={'100'}>
                <InlineGrid gap={'100'} columns={'1fr auto'}>
                  <Text variant="headingSm">{section.title}</Text>
                  <Badge tone={section.enabled ? 'success' : 'critical'}>
                    {section.enabled
                      ? t('Section.List.enums.sectionStatus.enabled')
                      : t('Section.List.enums.sectionStatus.disabled')}
                  </Badge>
                </InlineGrid>
                <Text variant="bodyXs">Section ID: {section.id}</Text>
                <Text variant="bodyXs">Exp ID: {section.experience}</Text>
              </BlockStack>
            ),
            value: section.id,
          }))}
          selected={sectionFilter}
          onChange={setSectionFilter}
          allowMultiple
        />
      ),
    },
  ]

  const appliedFilters = []

  if (durationFilter?.start && durationFilter?.end) {
    const key = 'duration'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, durationFilter),
      onRemove: handleDurationFilterRemove,
    })
  }

  if (sectionFilter.length > 0) {
    const key = 'sections'
    appliedFilters.push({
      key,
      label: sectionFilter
        .map((section) => sectionIdMapping[section].title)
        .join(', '),
      onRemove: () => setSectionFilter([]),
    })
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'duration':
        return value?.start?.toDateString() + ' - ' + value?.end?.toDateString()
      default:
        return value
    }
  }

  const loading =
    orderAttributionQuery.isLoading ||
    shopDetailsQuery.isLoading ||
    productFetchQuery.isLoading 

  return (
    <Bleed marginInline={'200'} marginBlockEnd={'200'}>
      <InlineGrid columns={'1fr auto'}>
        <Filters
          filters={filters}
          appliedFilters={appliedFilters}
          onClearAll={handleFiltersClearAll}
          hideQueryField
        />
        <div
          style={{
            paddingRight: 'var(--p-space-200)',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Button
            loading={orderDownloadLoading}
            onClick={() => {
              handleAttributionDownload({
                dateGte: durationFilter?.start?.valueOf(),
                dateLte: durationFilter?.end?.valueOf(),
                sections: sectionFilter.length ? sectionFilter : undefined,
              })
            }}
            disabled={loading}
            variant="secondary"
            size="micro"
            icon={
              <div
                style={{
                  rotate: '180deg',
                }}
              >
                <Icon source={UploadIcon} tone="base" />
              </div>
            }
          >
            {t('Analytics.OrderAtribution.CTA')}
          </Button>
        </div>
      </InlineGrid>
      {loading ? (
        <Skeleton />
      ) : (
        <ProductsTable
          pagination={{
            onNext: () => {
              setPageNumber(pageNumber + 1)
            },
            onPrevious: () => {
              setPageNumber(pageNumber - 1)
            },
            hasNext: orderAttributionQuery.data?.pages > pageNumber,
            hasPrevious: pageNumber > 1,
            page: orders.length ? pageNumber : 0,
            totalPages: orderAttributionQuery.data?.pages,
          }}
          orders={orders}
          sectionIdMapping={sectionIdMapping}
          productIdMapping={productIdMapping}
          filter={{ duration: durationFilter }}
        />
      )}
    </Bleed>
  )
}

function ProductsTable({
  orders = [],
  pagination,
  sectionIdMapping,
  productIdMapping,
}) {
  const { t } = useTranslation()

  const { page, totalPages } = pagination

  const WidgetLink = ({ type, id }) => {
    if (!type || !id) {
      return <Text>{t('unknown')}</Text>
    }

    return (
      <Link monochrome url={`/sections/${id}`}>
        {t(`Widgets.${type}.label`)}
      </Link>
    )
  }

  return (
    <>
      <DataTable
        pagination={pagination}
        headings={[
          t('Analytics.OrderAtribution.Table.heading.orderId'),
          t('Analytics.OrderAtribution.Table.heading.date'),
          t('Analytics.OrderAtribution.Table.heading.items'),
          t('Analytics.OrderAtribution.Table.heading.revenue'),
          t('Analytics.OrderAtribution.Table.heading.orderValue'),
          t('Analytics.OrderAtribution.Table.heading.section'),
        ].map((heading, index) => (
          <div key={index} style={{
            padding: '0 var(--p-space-200)',
          }}>
            <Text>{heading}</Text>
          </div>
        ))}
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
        rows={orders.map((order) => {
          const containerHeight = 3.5,
            totalHeight = 3.5 * order.attributedItems.length
          return [
            <div
              style={{
                height: `${totalHeight}rem`,
                display: 'flex',
                alignItems: 'center',
                padding: '0 var(--p-space-200)',
              }}
            >
              {order.name}
            </div>,
            <div
              style={{
                height: `${totalHeight}rem`,
                display: 'flex',
                alignItems: 'center',
                padding: '0 var(--p-space-200)',
              }}
            >
              {format(new Date(order.processedAt), 'dd LLL yyyy, hh:mm aaa')}
            </div>,
            <div style={{
              padding: '0 var(--p-space-200) 0',
            }}>
              {order.attributedItems.map((item) => (
                <ProductCard
                  price={currencyFormatter(
                    window.shopify.data.shop.currency,
                    item.attributedRevenueNative,
                  )}
                  image={productIdMapping[item.product.id]?.images?.[0]}
                  key={item.orderItemId}
                  id={item.product.id}
                  handle={productIdMapping[item.product.id]?.handle}
                  title={productIdMapping[item.product.id]?.title}
                />
              ))}
            </div>,
            <div style={{ display: 'flex', flexDirection: 'column',                 padding: '0 var(--p-space-200)', }}>
              {order.attributedItems.map(({ attributedRevenueNative: revenue }) => (
                <div
                  style={{
                    height: `${containerHeight}rem`,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {currencyFormatter(window.shopify.data.shop.currency, revenue)}
                </div>
              ))}
            </div>,
            <div
              style={{
                height: `${totalHeight}rem`,
                display: 'flex',
                alignItems: 'center',
                padding: '0 var(--p-space-200)',
              }}
            >
              {currencyFormatter(window.shopify.data.shop.currency, order.totalPriceNative)}
            </div>,
            <div
              style={{
                height: `${totalHeight}rem`,
                display: 'flex',
                alignItems: 'center',
                padding: '0 var(--p-space-200)',
              }}
            >
              <BlockStack gap={'200'}>
                {order.attributedItems.map(({ sectionId }) => (
                  <WidgetLink
                    key={sectionId}
                    id={sectionId}
                    type={sectionIdMapping[sectionId]?.type}
                  />
                ))}
              </BlockStack>
            </div>,
          ]
        })}
        footerContent={
          totalPages === 0
            ? t('tableFooter.noData')
            : t('tableFooter.showPageTemplate', {
                page,
                totalPages,
              })
        }
      />
    </>
  )
}

function ProductCard({ price, id, handle, title, image }) {
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        height: '3.5rem',
      }}
    >
      <div style={{ minWidth: '2rem' }}>
        <Avatar
          customer
          size="lg"
          source={image ?? '/assets/collection-placeholder.webp'}
        />
      </div>
      <div>
        <Text as="h3" variant="bodyMd" fontWeight="bold">
          {title}
        </Text>
        <div>
          {price} | ID: {id} | Handle: {handle}
        </div>
      </div>
    </div>
  )
}

export default AnalyticsOrderAttribution

function Skeleton() {
  return (
    <Card>
      <BlockStack gap={'300'}>
        <InlineGrid columns={'1fr auto'}>
          <div />
          <div
            style={{
              width: 100,
            }}
          >
            <SkeletonDisplayText size="medium" maxWidth={100} />
          </div>
        </InlineGrid>
        <SkeletonBodyText lines={5} />
      </BlockStack>
    </Card>
  )
}
