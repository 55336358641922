import {
  Banner,
  BlockStack,
  Button,
  Card,
  Layout,
  Select,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { SEGMENTS } from '../../../../constants/segmentation'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'
import { usePricing } from '../../../../components/PricingModal'

export default function Segmentations({ dashboardData, form, pricing }) {
  const { t } = useTranslation()
  const featureEnabled = areFeaturesEnabled([FEATURES.SEGMENTS], dashboardData)

  return (
    <Layout.AnnotatedSection
      id="segmentation"
      description={t('Section.Edit.Sections.SegmentationConfig.description')}
      title={t('Section.Edit.Sections.SegmentationConfig.title')}
    >
      <Card>
        <BlockStack gap={'300'}>
          {!featureEnabled && (
            <Banner>
              <BlockStack gap={'200'}>
                <p>
                  {t('Features.notAvailableInPlanText', {
                    feature: t('Features.labels.segments'),
                    cta: (
                      <Button
                        onClick={() => {
                          pricing.open({
                            features: [FEATURES.SEGMENTS],
                          })
                        }}
                        variant="plain"
                      >
                        {t('DefaultText.upgradeText')}
                      </Button>
                    ),
                  })}
                </p>
              </BlockStack>
            </Banner>
          )}
          <Select
            disabled={!featureEnabled}
            label={t(
              'Section.Edit.Sections.SegmentationConfig.FormFields.Selection.label',
            )}
            helpText={t(
              'Section.Edit.Sections.SegmentationConfig.FormFields.Selection.helpText',
            )}
            {...form.fields.segmentation}
            options={Object.values(SEGMENTS)}
          />
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}
