import React from 'react'
import { useChoiceField, useField, useForm } from '@shopify/react-form'

import { CHECKOUT_UPSELL_RECOMMENDATION } from '../../../../constants/checkoutUpsell'

function useConfigForm(config, onSubmit) {
  const recommendation = useField(config.recommendation ?? CHECKOUT_UPSELL_RECOMMENDATION.DISABLED)
  const products = useField(config.products ?? [])
  const excludedProducts = useField(config.excludedProducts ?? [])
  const excludedTags = useField(config.excludedTags ?? [])
  const placement = useField(config.placement ?? [])
  const overrideWithManualRec = useChoiceField(
    config.overrideWithManualRec ?? false,
  )
  return useForm({
    fields: {
      recommendation,
      products,
      excludedProducts,
      excludedTags,
      placement,
      overrideWithManualRec,
    },
    onSubmit,
  })
}

export default useConfigForm
