import getApiClient from '.'

export async function getShopDetails(token) {
  const client = await getApiClient(token)
  try {
    const res = await client.get('/api/setup/data')
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}
