const HOME = {
	value: 'home',
	label: 'Homepage',
	description:
		'Add recommendations on homepage to showcase personalized and top products. Prevent dropoffs and give a personalized experience.',
	score: 1,
	deprecated: false,
}

const PRODUCT = {
	value: 'product_page',
	label: 'Product Page',
	description:
		'Show relevant recommendations on Product Page. Show related products, up-sells, cross sells, etc.',
	score: 2,
	deprecated: false,
}

const COLLECTION = {
	value: 'collection',
	label: 'Collection Page',
	description: 'Show personalized recommendations on all collection pages.',
	score: 3,
	deprecated: false,
}

const CART = {
	value: 'cart',
	label: 'Cart Page',
	description:
		"Up-sell products on Cart page. Show recommendations based on a user's cart.",
	score: 4,
	deprecated: false,
}

const ORDER_CONFIRM = {
	value: 'order_confirm',
	label: 'Order Confirmation Page',
	description:
		'Add recommendations on Thank You page to convert one time buyers into repeat customers.',
	score: 5,
	deprecated: false,
}

const OTHER = {
	value: 'other',
	label: 'Custom Pages',
	description:
		'Add recommendations on any custom page of your store. Increase conversions by directing them to your store.',
	score: 6,
	deprecated: false,
}

const BLOG = {
	value: 'blog',
	label: 'Blogs & Articles',
	description:
		'Add relevant recommendations and Blog & Articles to attract customers to your store.',
	score: 7,
	deprecated: false,
}

const AJAX_CART = {
	value: 'ajax_cart',
	label: 'AJAX Cart',
	description:
		'Add recommendations on AJAX Cart, to increase AoV. This works only on themes that support AJAX Cart.',
	score: 8,
	deprecated: false,
}

const NOT_FOUND = {
	value: '404',
	label: '404 Not Found',
	description:
		'Add recommendations on 404 Not Found page to keep users on the store website.',
	score: 9,
	deprecated: false,
}

const CHECKOUT = {
	value: 'checkout',
	label: 'Checkout Page',
	description: 'Show personalized recommendations on the checkout page.',
	score: 10,
	deprecated: true,
}

const RETURNS = {
	value: 'returns',
	label: 'Returns Page',
	description:
		'Show Personalized Recommendations on the returns pages. Needs Return Prime integration.',
	score: 11,
	deprecated: false,
}

const PAGE_TYPES = {
	returns: RETURNS,
	checkout: CHECKOUT,
	'404': NOT_FOUND,
	ajax_cart: AJAX_CART,
	blog: BLOG,
	other: OTHER,
	order_confirm: ORDER_CONFIRM,
	cart: CART,
	collection: COLLECTION,
	product_page: PRODUCT,
	home: HOME,
}

export {
	HOME,
	PRODUCT,
	COLLECTION,
	CART,
	ORDER_CONFIRM,
	OTHER,
	BLOG,
	AJAX_CART,
	NOT_FOUND,
	CHECKOUT,
	RETURNS,
	PAGE_TYPES,
}
