import React, { useEffect } from 'react'
import { BlockStack, Box, Tabs } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import Configure from './sections/configure'
import BulkUpload from './sections/bulkUpload'
import ExcludedProducts from './sections/excludedProducts'
import DisabledProducts from './sections/disabledProducts'
import GeoTagging from './sections/geoTagging'
import SearchAPI from './sections/searchApi'
import { usePricing, PricingModal } from '../../../components/PricingModal'

function RecommendationConfig({ selectedTab, backActionUrl = null }) {
  const pricing = usePricing()
  const { t } = useTranslation()
  const selectedTabIdx = TABS.findIndex((s) => s.id === selectedTab)
  const [activeIndex, setActiveIndex] = React.useState(
    Math.max(selectedTabIdx, 0),
  )

  const ActiveComponent = ComponentTabMap[TABS[activeIndex]?.id]

  return (
    <Box padding={'0'} background="bg-fill" borderRadius="400">
      <div
        style={{
          padding: '0 var(--p-space-200) var(--p-space-400) var(--p-space-200)',
        }}
      >
        <BlockStack gap={'400'}>
          <Tabs
            tabs={TABS}
            selected={activeIndex}
            onSelect={(idx) => {
              setActiveIndex(idx)
            }}
          />
          {ActiveComponent && (
            <ActiveComponent backActionUrl={backActionUrl} pricing={pricing} />
          )}
        </BlockStack>
      </div>
      <PricingModal modal={pricing} />
    </Box>
  )
}

export default RecommendationConfig

const TABS = [
  {
    id: 'configure',
    content: 'Configure',
  },
  {
    id: 'bulk-config',
    content: 'Bulk Configure',
  },
  {
    id: 'excluded-products',
    content: 'Excluded Products',
  },
  {
    id: 'disabled-products',
    content: 'Disabled Products',
  },
  {
    id: 'geo-tagging',
    content: 'Geo Tagging',
  },
  {
    id: 'search-api',
    content: 'Search API',
  },
]

const ComponentTabMap = {
  configure: Configure,
  'bulk-config': BulkUpload,
  'excluded-products': ExcludedProducts,
  'disabled-products': DisabledProducts,
  'geo-tagging': GeoTagging,
  'search-api': SearchAPI,
}
