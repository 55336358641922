import React, { useState } from 'react'
import {
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Divider,
  InlineGrid,
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'

Chart.register(CategoryScale)

function WidgetFunnelChart({
  stats = [],
  data = [],
  initiallyCollapse = true,
  loading,
  columns = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 6,
  },
  yAxisFormatter = (val) => val,
}) {
  const { t } = useTranslation()
  const [chartCollapse, setChartCollapse] = useState(
    loading || initiallyCollapse,
  )
  const breakpoints = useBreakpoints()
  return (
    <Card padding={'0'}>
      <BlockStack>
        {/* <InlineGrid columns={'1fr auto'}> */}
        <div
          style={{
            width: '100%',
            cursor: loading ? 'progress' : 'pointer',
          }}
          onClick={() => {
            if (!loading) {
              setChartCollapse(!chartCollapse)
            }
          }}
        >
          <InlineGrid columns={'1fr auto'} alignItems="center">
            <InlineGrid columns={columns}>
              {!loading &&
                stats.map(({ label, value }, index) => (
                  <div
                    style={{
                      padding: 'var(--p-space-400)',
                      borderRight:
                        breakpoints.lgUp && index + 1 != stats.length
                          ? '1px solid var(--p-color-border)'
                          : null,
                    }}
                  >
                    <BlockStack gap={'200'}>
                      <Text as="h4" variant="bodyLg">
                        <span
                          style={{
                            textDecoration: 'underline',
                            textDecorationStyle: 'dashed',
                            textDecorationColor:
                              'var(--p-color-bg-fill-selected)',
                            textDecorationThickness: '2px',
                          }}
                        >
                          {label}
                        </span>
                      </Text>
                      <InlineStack gap={'200'}>
                        <Text variant="headingMd" as="strong">
                          {value ?? 0}
                        </Text>
                      </InlineStack>
                    </BlockStack>
                  </div>
                ))}
              {loading &&
                Array.from({ length: 3 }).map((_, i) => (
                  <div
                    style={{
                      padding: 'var(--p-space-200)',
                    }}
                  >
                    <BlockStack gap={'100'}>
                      <SkeletonDisplayText maxWidth="50px" size="small" />
                      <SkeletonBodyText lines={1} />
                    </BlockStack>
                  </div>
                ))}
            </InlineGrid>
            <div
              style={{
                padding: 'var(--p-space-200)',
              }}
            >
              <Button
                variant="monochromePlain"
                icon={!chartCollapse ? ChevronUpIcon : ChevronDownIcon}
              />
            </div>
          </InlineGrid>
          <Divider />
        </div>
        {/* </InlineGrid> */}
        {!loading && (
          <Collapsible
            open={!chartCollapse}
            id="chart-collapsible"
            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
          >
            <Box padding={'400'}>
              <div
                style={{
                  height: '300px',
                }}
              >
                <Line
                  data={{
                    labels: data[0].data.map((_) => _.key),
                    datasets: data.map((data) => ({
                      label: data.name,
                      data: data.data.map((_) => _.value),
                    })),
                  }}
                  width={'100%'}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                      line: {
                        tension: 0.5,
                      },
                    },
                    plugins: {
                      legend: {
                        position: 'bottom',
                        align: 'end',
                      },
                      tooltip: {
                        mode: 'x',
                        backgroundColor: 'white',
                        titleColor: 'rgba(26, 26, 26, 1)',
                        bodyColor: 'rgba(48, 48, 48, 1)',
                        borderWidth: 1,
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        intersect: false,
                        callbacks: {
                          title: (tooltipItem) => {
                            return tooltipItem[0].label
                          },
                          label: (tooltipItem) => {
                            return (
                              tooltipItem.dataset.label +
                              ': ' +
                              tooltipItem.formattedValue
                            )
                          },
                        },
                        usePointStyle: true,
                      },
                    },
                    hover: {
                      mode: 'nearest',
                      intersect: true,
                    },
                    tooltips: {
                      mode: 'x',
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                            labelString: 'Month',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                          }
                        },
                      ],
                      y: {
                        ticks: {
                          callback: function (value, index, ticks) {
                            return yAxisFormatter(value)
                          },
                        },
                      }
                    },
                  }}
                />
              </div>
            </Box>
          </Collapsible>
        )}
      </BlockStack>
    </Card>
  )
}

export default WidgetFunnelChart
