import { BlockStack, Card, Select, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { BUNDLE_STATUS } from '../../../../../constants/bundles'

export default function ProductStatus({ form }) {
  const { t } = useTranslation()
 
  return (
    <Card>
      <BlockStack gap={'200'}>
        <Text variant="headingSm">{t('BundlesUpsert.Heading.status')}</Text>
        <Select
          {...form.fields.status}
          options={Object.values(BUNDLE_STATUS).map((val) => ({
            label: t('BundlesUpsert.ProductStatus.' + val),
            value: val,
          }))}
        />
      </BlockStack>
    </Card>
  )
}
