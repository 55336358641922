import { Page, Text } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { createCampaign, updateCampaign } from '../../../../apis/checkoutUpsell'
import { useRedirect } from '../../../../hooks'
import CampaignUpsertForm from '../common/components/Upsert'
import CampaignSkeleton from '../common/components/Skeleton'
import { getCheckoutUpsellConfig } from '../../../../apis/checkoutUpsell'

function CampaignCreate() {
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const configQuery = useQuery({
    queryKey: ['checkoutUpsellConfig'],
    queryFn: async () => {
      const { data, error } = await getCheckoutUpsellConfig()
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const checkoutConfig = configQuery.data ?? {}

  if (configQuery.isLoading) {
    return <CampaignSkeleton />
  }

  return (
    <Page
      backAction={{
        url: '/checkout-upsell',
        content: t('CheckoutUpsell.CampaignCreate.back'),
      }}
      title={t('CheckoutUpsell.CampaignCreate.title')}
      additionalMetadata={
          t('CheckoutUpsell.CampaignDetail.additionalMetadata')
      }
    >
      <CampaignUpsertForm
        checkoutConfig={checkoutConfig}
        onSubmit={formSubmitHandler}
      />
    </Page>
  )

  async function formSubmitHandler(fieldValues, cb) {
    const { error: createError, data } = await createCampaign({
      name: fieldValues.name,
      startDate: fieldValues.duration?.start
        ? fieldValues.duration.start.valueOf() / 1000
        : null,
      endDate: fieldValues.duration?.end
        ? fieldValues.duration.end.valueOf() / 1000
        : null,
    })

    if (createError) {
      throw new Error(createError.message)
    }

    const { error: updateError } = await updateCampaign(data.id, {
      triggerType: fieldValues.triggerType,
      triggerConditionType: fieldValues.triggerConditionType,
      triggerConditions: fieldValues.triggerConditions,
      status: fieldValues.status,
      products: fieldValues.products,
    })

    if (updateError) {
      throw new Error(updateError.message)
    }
    cb()
    shopify.toast.show(
      t('CheckoutUpsell.CampaignCreate.success', {
        name: fieldValues.name,
      }),
    )
    setTimeout(() => {
      redirectToLink({
        url: `/checkout-upsell/${data.id ?? 1}`,
        external: false,
      })
    }, 200)
  }
}

export default CampaignCreate
