import { MutationCache, QueryCache, QueryClient } from 'react-query'

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  mutationCache: new MutationCache(),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default queryClient
