import {
  BlockStack,
  Button,
  Card,
  DatePicker,
  InlineGrid,
  InlineStack,
  Popover,
  SkeletonDisplayText,
  Text,
  Tooltip,
  useBreakpoints,
} from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarTimeIcon } from '@shopify/polaris-icons'
import { useQuery } from 'react-query'
import { format } from 'date-fns'

import WidgetTabChart from '../../../components/WidgetTabChart'
import WidgetFunnelChart from '../../../components/WidgetFunnelChart'
import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../utils/formater'
import { getCharts, getStats } from '../../../apis/analytics'
import { useLocalSettings } from '../../../components/LocalSettings'

function PerformanceSection({ onboardingDone, loading: dashboardLoading }) {
  const { t } = useTranslation()
  const [popoverActive, setPopoverActive] = useState(false)
  const breakpoints = useBreakpoints()
  const { saveSettings, settings } = useLocalSettings()

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  const [durationFilter, setDurationFilter] = useState(settings.durationFilter)

  const handleDurationFilterChange = useCallback(
    (value) => setDurationFilter(value),
    [],
  )

  const statQuery = useQuery({
    queryKey: ['stats', durationFilter.start, durationFilter.end],
    queryFn: async () => {
      const { data, error } = await getStats({
        dateGte: new Date(durationFilter.start).valueOf() / 1000,
        dateLte: new Date(durationFilter.end).valueOf() / 1000,
      })
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const chartsQuery = useQuery({
    queryKey: ['charts', durationFilter.start, durationFilter.end],
    queryFn: async () => {
      const { data, error } = await getCharts({
        dateGte: new Date(durationFilter.start).valueOf(),
        dateLte: new Date(durationFilter.end).valueOf(),
      })
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  useEffect(() => {
    saveSettings({
      ...settings,
      durationFilter,
    })
  }, [durationFilter])

  return (
    <BlockStack gap={'300'}>
      <InlineGrid columns={'1fr auto'}>
        <Text as="strong" variant="headingMd">
          {t('DashboardPage.PerformanceSection.title')}
        </Text>
        {breakpoints.smUp && (
          <Popover
            active={popoverActive}
            activator={
              <Button
                onClick={() => {
                  setPopoverActive(true)
                }}
                icon={CalendarTimeIcon}
              >
                {durationFilter?.start?.toDateString() +
                  ' - ' +
                  durationFilter?.end?.toDateString()}
              </Button>
            }
            autofocusTarget="first-node"
            onClose={() => {
              setPopoverActive(false)
            }}
          >
            <div
              style={{
                overflow: 'visible',
                padding: 'var(--p-space-150)',
                zIndex: 9,
              }}
            >
              <DatePicker
                allowRange
                multiMonth
                selected={durationFilter}
                onChange={handleDurationFilterChange}
                month={month}
                year={year}
                onMonthChange={handleMonthChange}
                disableDatesAfter={new Date()}
              />
            </div>
          </Popover>
        )}
      </InlineGrid>
      <WidgetTabChart
        initiallyCollapsed={onboardingDone || dashboardLoading}
        stats={[
          {
            label: t('PerformanceSection.WidgetTabChart.revenueByRk'),
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              typeof parseInt(statQuery.data?.tiles?.[0]?.nativeValue) ===
                'string'
                ? parseInt(statQuery.data?.tiles?.[0]?.nativeValue)
                : statQuery.data?.tiles?.[0]?.nativeValue,
            ),
            tooltipText: statQuery.data?.tiles?.[0]?.description,
            total: currencyFormatter(
              window.shopify.data.shop.currency,
              typeof statQuery.data?.tiles?.[0]?.nativeTotal === 'string'
                ? parseInt(statQuery.data?.tiles?.[0]?.nativeTotal)
                : statQuery.data?.tiles?.[0]?.nativeTotal,
            ),
          },
          {
            label: t('PerformanceSection.WidgetTabChart.productsSoldCountByRk'),
            value: numberFormater(
              parseInt(statQuery.data?.tiles?.[1]?.value ?? '0'),
            ),
            tooltipText: statQuery.data?.tiles?.[1]?.description,
            total: numberFormater(parseInt(statQuery.data?.tiles[1]?.total ?? 0)),
          },
          {
            label: t('PerformanceSection.WidgetTabChart.clickRate'),
            value:
              (
                parseFloat(statQuery.data?.tiles?.[2]?.value ?? '0') * 100
              ).toFixed(2) + '%',
            tooltipText: statQuery.data?.tiles?.[2]?.description,
          },
        ]}
        data={[
          {
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.nativeRevenueByRk,
              })) ?? [],
            data2:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.nativeRevenue,
              })) ?? [],
            name: t('PerformanceSection.WidgetTabChart.revenueByRk'),
            name2: 'Total Revenue',
            tooltipFormatter: (value) =>
              currencyFormatter(window.shopify.data.shop.currency, value),
            yAxisFormatter: (value) =>
              currencyFormatter(window.shopify.data.shop.currency, value),
          },
          {
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.productsSoldCountByRk,
              })) ?? [],
            data2:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.productsSoldCount,
              })) ?? [],
            name: t('PerformanceSection.WidgetTabChart.productsSoldCountByRk'),
            name2: 'Total Products Sold',
            tooltipFormatter: (value) => value,
            yAxisFormatter: (value) => numberFormater(value),
          },
          {
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.clickRate * 100,
              })) ?? [],
            name: t('PerformanceSection.WidgetTabChart.clickRate'),
            tooltipFormatter: (value) => value.toFixed(2) + '%',
            yAxisFormatter: (value) => value.toFixed(0) + '%',
          },
        ]}
        loading={chartsQuery.isLoading || dashboardLoading}
        columns={{
          xs: 2,
          sm: 3,
          md: 3,
          lg: 3,
        }}
      />
      <WidgetFunnelChart
        initiallyCollapse={onboardingDone || dashboardLoading}
        yAxisFormatter={(value) => numberFormater(value)}
        data={[
          {
            name: t('PerformanceSection.WidgetFunnelChart.pageViews'),
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.ppv,
              })) ?? [],
            tooltipFormatter: (value) => value,
          },
          {
            name: t('PerformanceSection.WidgetFunnelChart.sectionServed'),
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.served,
              })) ?? [],
            tooltipFormatter: (value) => value,
          },
          {
            name: t('PerformanceSection.WidgetFunnelChart.sectionViews'),
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.viewed,
              })) ?? [],
            tooltipFormatter: (value) => value,
          },
          {
            name: t('PerformanceSection.WidgetFunnelChart.clicks'),
            data:
              chartsQuery.data?.aggregates?.map((aggregate) => ({
                key: format(new Date(aggregate.date), 'MMM dd'),
                value: aggregate.clicked,
              })) ?? [],
            tooltipFormatter: (value) => value,
          },
        ]}
        loading={chartsQuery.isLoading || dashboardLoading}
        stats={[
          {
            label: t('PerformanceSection.WidgetFunnelChart.pageViews'),
            value: numberFormater(
              chartsQuery.data?.aggregates?.reduce(
                (prev, curr) => prev + curr.ppv,
                0,
              ),
            ),
          },
          {
            label: t('PerformanceSection.WidgetFunnelChart.sectionServed'),
            value: numberFormater(
              chartsQuery.data?.aggregates?.reduce(
                (prev, curr) => prev + curr.served,
                0,
              ),
            ),
          },
          {
            label: t('PerformanceSection.WidgetFunnelChart.sectionViews'),
            value: numberFormater(
              chartsQuery.data?.aggregates?.reduce(
                (prev, curr) => prev + curr.viewed,
                0,
              ),
            ),
          },
          {
            label: t('PerformanceSection.WidgetFunnelChart.clicks'),
            value: numberFormater(
              chartsQuery.data?.aggregates?.reduce(
                (prev, curr) => prev + curr.clicked,
                0,
              ),
            ),
          },
        ]}
        columns={{
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        }}
      />
    </BlockStack>
  )
}

export default PerformanceSection

function AttributedStatCard({
  title,
  subtitle,
  data: { total, type, currency, value } = {},
  loading = false,
}) {
  if (loading) {
    return (
      <Card>
        <BlockStack gap={'100'}>
          <span
            style={{
              bordeBbottom: '1px dotted #000',
              textDecoration: 'none',
            }}
          >
            <SkeletonDisplayText size="small" />
          </span>
          <InlineStack align="start" blockAlign="start">
            <SkeletonDisplayText size="large" maxWidth="200" />
            <SkeletonDisplayText size="small" maxWidth="200" />
          </InlineStack>
        </BlockStack>
      </Card>
    )
  }
  if (type === 'currency') {
    return (
      <Tooltip
        borderRadius="100"
        content={<Text variant="bodyXs">{subtitle}</Text>}
      >
        <Card>
          <BlockStack gap={'100'}>
            <span
              style={{
                bordeBbottom: '1px dotted #000',
                textDecoration: 'none',
              }}
            >
              <Text variant="bodyMd">{title}</Text>
            </span>
            <InlineStack align="start" blockAlign="center" gap={'100'}>
              <Text as="strong" variant="headingLg">
                {currencyFormatter(currency, value)}
              </Text>
              <Text variant="bodyMd" tone="subdued">
                / {currencyFormatter(currency, total)}
              </Text>
            </InlineStack>
          </BlockStack>
        </Card>
      </Tooltip>
    )
  }
  if (type === 'percentage') {
    return (
      <Tooltip
        borderRadius="100"
        content={<Text variant="bodyXs">{subtitle}</Text>}
      >
        <Card>
          <BlockStack gap={'100'}>
            <span
              style={{
                bordeBbottom: '1px dotted #000',
                textDecoration: 'none',
              }}
            >
              <Text variant="bodyMd">{title}</Text>
            </span>
            <InlineStack align="start" blockAlign="start">
              <Text as="strong" variant="headingLg">
                {percentageFormatter(value)}
              </Text>
            </InlineStack>
          </BlockStack>
        </Card>
      </Tooltip>
    )
  }

  return (
    <Tooltip
      borderRadius="100"
      content={<Text variant="bodyXs">{subtitle}</Text>}
    >
      <Card>
        <BlockStack gap={'100'}>
          <span
            style={{
              bordeBbottom: '1px dotted #000',
              textDecoration: 'none',
            }}
          >
            <Text variant="bodyMd">{title}</Text>
          </span>
          <InlineStack align="start" blockAlign="start">
            <Text as="strong" variant="headingLg">
              {value ? numberFormater(value) : '-'}
            </Text>
          </InlineStack>
        </BlockStack>
      </Card>
    </Tooltip>
  )
}
