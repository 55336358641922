import getApiClient from '.'

export async function getProducts(pIds) {
  if(pIds.length === 0) {
    return {
      data: {
        products: []
      },
      error: null
    }
  }
  try {
    const client = await getApiClient()
    const response = await client.post('/api/store/products', {
      productIds: pIds,
    })
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error.response.data,
    }
  }
}
