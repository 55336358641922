import {
  BlockStack,
  Box,
  Card,
  Divider,
  Layout,
  Select,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { useLocalSettings } from '../../../components/LocalSettings'
import langs from '../../../constants/languages'

export default function DashboardSettings() {
  const { t, i18n } = useTranslation()
  const { settings, saveSettings } = useLocalSettings()

  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.DashboardSettings.title')}
      description={t('SettingsPage.Sections.DashboardSettings.description')}
    >
      <Card padding={'0'}>
        <Box padding={'400'}>
          <BlockStack gap={'200'}>
            <Text as="strong">
              {t('SettingsPage.Sections.DashboardSettings.Language.label')}
            </Text>
            <Select
              helpText={t(
                'SettingsPage.Sections.DashboardSettings.Language.helpText',
              )}
              value={settings.locale}
              onChange={(val) => {
                i18n.changeLanguage(val)
                saveSettings({ ...settings, locale: val })
              }}
              options={langs.map((lang) => ({
                label: lang.label,
                value: lang.value,
              }))}
            />
          </BlockStack>
        </Box>
      </Card>
    </Layout.AnnotatedSection>
  )
}
