import React, { useState } from 'react'
import { BlockStack, Button, Page } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useNavigation } from 'react-router-dom'

import ProductSelector from '../../../../components/ProductSelector'
import ConfigureProductRecommendation from '../Configure'

export function NewProductRecommendation() {
  const { t } = useTranslation()
  const [product, setProduct] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedProducts, setSelectedProducts] = useState([])

  return (
    <Page
      backAction={{
        url: '/settings/recommendation/similar_products/product',
      }}
      fullWidth
      title={t('RecommendationPage.NewProductRecommendation.title')}
      subtitle={t('RecommendationPage.NewProductRecommendation.description')}      >
      <BlockStack gap={'400'}>
        <ProductSelector product={product} onChange={setProduct} />
        <ConfigureProductRecommendation
          selectedTab={selectedTab}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          setSelectedTab={setSelectedTab}
          product={product}
        />
      </BlockStack>
    </Page>
  )
}
