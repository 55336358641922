import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Filters,
  IndexFilters,
  IndexFiltersMode,
  IndexTable,
  InlineGrid,
  Select,
  Text,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris'
import {
  DeleteIcon,
  EditIcon,
  LockIcon,
  PlusIcon,
} from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { format } from 'date-fns'

import { getCampaigns, updateCampaign } from '../../../../apis/checkoutUpsell'
import queryClient from '../../../../utils/query'
import { currencyFormatter, numberFormater } from '../../../../utils/formater'
import { useRedirect } from '../../../../hooks/useRedirect'
import { CAMPAIGN_STATUSES } from '../../../../constants/checkoutUpsell'

const MAX_CAMPAIGNS_PER_PAGE = 10

function CampaignManager({ timeFilter, refresh }) {
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()

  const [queryValue, setQueryValue] = useState('')
  const [page, setPage] = useState(1)
  const [campaigns, setCampaigns] = useState([])

  // for filters
  const itemStrings = [
    {
      label: t('CheckoutUpsell.CampaignManager.Table.status.all'),
      value: 'all',
    },
    {
      label: t('CheckoutUpsell.CampaignManager.Table.status.active'),
      value: 'active',
    },
    {
      label: t('CheckoutUpsell.CampaignManager.Table.status.paused'),
      value: 'paused',
    },
    {
      label: t('CheckoutUpsell.CampaignManager.Table.status.draft'),
      value: 'draft',
    },
  ]
  const tabs = itemStrings.map((item, index) => ({
    content: item.label,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: [],
    value: item.value,
  }))
  const [seletedTab, setSelectedTab] = useState(0)
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Default)

  const campaignsQuery = useQuery({
    queryKey: ['checkoutUpsellCampaigns', timeFilter],
    queryFn: async () => {
      const { data, error } = await getCampaigns(timeFilter)
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const isLoading = campaignsQuery.isLoading
  const paginatedCampaigns = campaigns
    .filter((campaign) => {
      if (tabs[seletedTab].value === 'all') return true
      return campaign.status === tabs[seletedTab].value
    })
    .slice(MAX_CAMPAIGNS_PER_PAGE * (page - 1), MAX_CAMPAIGNS_PER_PAGE * page)
  const rowMarkup = paginatedCampaigns.map((campaign) => (
    <TableRowMarkup
      key={campaign.id}
      campaign={campaign}
      redirectionCallback={() => {
        redirectToLink({
          url: '/checkout-upsell/' + campaign.id,
          external: false,
        })
      }}
      refresh={refresh}
    />
  ))

  useEffect(() => {
    if (campaignsQuery.data) {
      setCampaigns(campaignsQuery.data)
    }
  }, [campaignsQuery.data])

  useEffect(() => {
    if (!queryValue) {
      setCampaigns(campaignsQuery.data ?? [])
      return
    }
    const filteredCampaigns = campaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(queryValue.toLowerCase()),
    )
    setCampaigns(filteredCampaigns)
  }, [queryValue])

  return (
    <Card padding={'0'}>
      <IndexFilters
        queryValue={queryValue}
        queryPlaceholder={t(
          'CheckoutUpsell.CampaignManager.Table.queryPlaceholder',
        )}
        onQueryChange={setQueryValue}
        onQueryClear={() => {
          setQueryValue('')
        }}
        tabs={tabs}
        selected={seletedTab}
        onSelect={(idx) => {
          setPage(1)
          setSelectedTab(idx)
        }}
        canCreateNewView={false}
        filters={[]}
        appliedFilters={[]}
        onClearAll={() => {
          setQueryValue('')
        }}
        loading={isLoading}
        mode={mode}
        setMode={setMode}
        cancelAction={{
          onAction: () => {},
          disabled: false,
          loading: false,
        }}
      />
      <IndexTable
        resourceName={{
          singular: 'Campaign',
          plural: 'Campaigns',
        }}
        headings={[
          ...Array.from({
            length: 6,
          }).map((_, index) => ({
            title: t(
              `CheckoutUpsell.CampaignManager.Table.heading.${index + 1}`,
            ),
          })),
          '',
        ]}
        columnContentTypes={Array.from({ length: 6 }).map(() => 'text')}
        pagination={{
          hasPrevious: page > 1,
          hasNext: MAX_CAMPAIGNS_PER_PAGE * page < campaigns.length,
          onPrevious: () => setPage(page - 1),
          onNext: () => setPage(page + 1),
        }}
        loading={isLoading}
        children={rowMarkup}
        itemCount={paginatedCampaigns.length}
        selectable={false}
      />
    </Card>
  )
}

export default CampaignManager

function TableRowMarkup({ campaign, redirectionCallback,refresh }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const statusOptions = [
    {
      label: t('CheckoutUpsell.CampaignManager.Table.status.active'),
      value: CAMPAIGN_STATUSES.ACTIVE,
    },
    {
      label: t('CheckoutUpsell.CampaignManager.Table.status.paused'),
      value: CAMPAIGN_STATUSES.PAUSED,
    },
  ]

  if (CAMPAIGN_STATUSES.DRAFT === campaign.status) {
    statusOptions.push({
      label: t('CheckoutUpsell.CampaignManager.Table.status.draft'),
      value: CAMPAIGN_STATUSES.DRAFT,
    })
  }

  const toggleStatus = async (
    value,
    toastText = t('CheckoutUpsell.CampaignManager.Table.status.successText', {
      name: campaign.name,
    }),
  ) => {
    setLoading(true)
    const { error } = await updateCampaign(campaign.id, {
      status: value,
    })
    await refresh()
    setLoading(false)
    if (error) {
      return shopify.toast.show(
        t('CheckoutUpsell.CampaignManager.Table.status.errorText', {
          name: campaign.name,
        }),
        {
          isError: true,
        },
      )
    }
    shopify.toast.show(toastText)
  }

  return (
    <IndexTable.Row
      key={campaign.id}
      id={campaign.id}
      onClick={redirectionCallback}
    >
      <IndexTable.Cell>
        <Text key="name">{campaign.name}</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>{getStatusBadge(campaign.status)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Text key="revenueGenerated">
          {currencyFormatter('usd', campaign.revenueGenerated)}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text key="ctr">{(campaign.ctr * 100).toFixed(0)}%</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text key={'timesShown'}>
          {numberFormater(campaign.timesShown ?? 0) ?? 0}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text key="createdOn">
          {format(new Date(campaign.createdOn), 'dd LLL, yyyy')}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Box>
          <Tooltip content="Delete">
            <Button
            loading={loading}
              icon={DeleteIcon}
              tone="critical"
              onClick={(e) => {
                e.stopPropagation()
                if (
                  confirm(
                    t('CheckoutUpsell.CampaignManager.Table.deleteConfirm', {
                      name: campaign.name,
                    }),
                  )
                ) {
                  toggleStatus(
                    CAMPAIGN_STATUSES.ARCHIVED,
                    t('CheckoutUpsell.CampaignManager.Table.deleteSuccess', {
                      name: campaign.name,
                    }),
                  )
                }
              }}
            />
          </Tooltip>
        </Box>
      </IndexTable.Cell>
    </IndexTable.Row>
  )

  function getStatusBadge(status) {
    let tone = 'success'
    switch (status) {
      case 'active':
        tone = 'success'
        break
      case 'paused':
        tone = 'info'
        break
      case 'draft':
        tone = 'subdued'
        break
    }
    return (
      <Badge tone={tone}>
        {t(`CheckoutUpsell.CampaignManager.Table.status.${status}`)}
      </Badge>
    )
  }
}
