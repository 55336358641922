import React from 'react'

import Modal from '../CustomModal'
import ProductSequenceEdit from '../ProductSequenceEdit'

function ProductSelectorModal({
  show,
  setShow,
  title,
  onSave,
  onDiscard,
  onChange,
  items,
}) {
  return (
    <Modal overlayZIndex={5} show={show} setShow={setShow} title={title} onSave={onSave} onDiscard={onDiscard}>
      <ProductSequenceEdit items={items} onChange={onChange} />
    </Modal>
  )
}

export default ProductSelectorModal
