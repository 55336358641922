import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Icon,
  Image,
  InlineGrid,
  InlineStack,
  Layout,
  Text,
  Thumbnail,
} from '@shopify/polaris'
import { IconsFilledIcon, PlusIcon, XIcon } from '@shopify/polaris-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomModal from '../../../../../components/CustomModal'

export default function MediaPicker({ form }) {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const images = form.fields.products.value
      .map((product) => product.images)
      .flat()
      .map((image) => image.originalSrc)
    form.fields.images.onChange(images)
  }, [form.fields.products.value])

  const imageOptions = form.fields.products.value
    .map((product) => product.images)
    .flat()
  
    const selectedImages = form.fields.images.value

  const onImageSelectChange = (image) => {
    if (selectedImages.includes(image)) {
      form.fields.images.onChange(selectedImages.filter((i) => i !== image))
    } else {
      form.fields.images.onChange([...selectedImages, image])
    }
  }

  return (
    <>
      <Card padding={'0'}>
        <Box padding={'400'}>
          <InlineGrid
            alignItems="start"
            gap={'300'}
            columns={{
              sm: '1fr',
              md: '1fr',
              lg: '1fr auto',
              xl: '1fr auto',
            }}
          >
            <BlockStack gap={'50'}>
              <Text variant="headingSm">
                {t('BundlesUpsert.Images.card.title')}
              </Text>
              <Text>{t('BundlesUpsert.Images.card.description')}</Text>
            </BlockStack>
            <Box>
              <Button
                icon={PlusIcon}
                onClick={() => {
                  setModalOpen(true)
                }}
                disabled={imageOptions.length === 0}
              >
                {t('BundlesUpsert.Images.card.uploadCta')}
              </Button>
            </Box>
          </InlineGrid>
        </Box>
        <Divider />
        <Box padding={'400'}>
          <InlineStack
            gap={'300'}
            align={form.fields.images.value.length == 0 ? 'center' : 'start'}
          >
            {form.fields.images.value.length === 0 && (
              <Box>
                <Text>{t('BundlesUpsert.Images.card.noImageSelected')}</Text>
              </Box>
            )}
            {form.fields.images.value.map((image, index) => (
              <div
                key={index}
                style={{
                  position: 'relative',
                }}
              >
                <Thumbnail source={image} size="large" key={index} />
                <span
                  onClick={() => {
                    form.fields.images.onChange(
                      form.fields.images.value.filter((_, i) => i !== index),
                    )
                  }}
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    transform: 'translate(50%, -50%)',
                    cursor: 'pointer',
                  }}
                >
                  <Icon source={XIcon} />
                </span>
              </div>
            ))}
          </InlineStack>
        </Box>
      </Card>
      <ImageSelectorModal
        selectedImages={selectedImages}
        images={imageOptions}
        onImageSelectChange={onImageSelectChange}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </>
  )
}

function ImageSelectorModal({
  open,
  setOpen,
  images,
  selectedImages,
  onImageSelectChange,
}) {
  const { t } = useTranslation()
  return (
    <CustomModal
      show={open}
      setShow={setOpen}
      onSave={() => {
        setOpen(false)
      }}
      title={t('selectImage')}
      variant="large"
    >
      <Box padding={'400'}>
        <InlineGrid
          columns={{
            sm: '1fr 1fr',
            md: '1fr 1fr 1fr',
            lg: '1fr 1fr 1fr 1fr',
            xl: '1fr 1fr 1fr 1fr 1fr',
          }}
          gap={'300'}
        >
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                width: '100%',
                aspectRatio: '3:4',
                borderRadius: 'var(--p-space-300)',
                overflow: 'hidden',
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              <img
                src={image.originalSrc}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  border: selectedImages.includes(image.originalSrc)
                    ? '2px solid var(--p-color-bg-inverse)'
                    : '2px solid var(--p-color-bg-fill-selected)',
                  borderRadius: 'var(--p-space-300)',
                }}
                onClick={() => {
                  onImageSelectChange(image.originalSrc)
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 'var(--p-space-200)',
                  right: 'var(--p-space-100)',
                }}
              >
                <Checkbox
                  checked={selectedImages.includes(image.originalSrc)}
                />
              </div>
            </div>
          ))}
        </InlineGrid>
      </Box>
    </CustomModal>
  )
}
