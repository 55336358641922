import React from 'react'

function ProductImage({ style, src, alt }) {
	return (
		<div
			style={{
				objectFit: 'cover',
				objectPosition: 'center',
				resizeMode: 'cover',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...style,
			}}
		>
			<img
				src={src}
				width={'100%'}
				alt={alt}
			/>
		</div>
	)
}

export default ProductImage
