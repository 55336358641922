import {
  BlockStack,
  Card,
  Divider,
  InlineStack,
  Spinner,
  Tabs,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'

Chart.register(CategoryScale)

import { useShopDetails } from '../../../../hooks/useShopDetails'
import { getCharts } from '../../../../apis/analytics'
import DateTimeRangePopover from '../../../../components/DateTimeRangePopover'
import { useLocalSettings } from '../../../../components/LocalSettings'
import { currencyFormatter, numberFormater } from '../../../../utils/formater'
import { useDashboardDetails } from '../../../../hooks/useDashboardDetails'

function AnalyticsExperience() {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const [activeIdx, setActiveIdx] = useState(0)
  const dashboardQuery = useDashboardDetails()

  const TABS = [
    {
      content: 'Revenue Attribution',
      id: 'revenue',
      yAxisFormatter: (val) =>
        currencyFormatter(dashboardQuery.data?.shop.currency, val),
    },
    {
      content: 'Click through rate',
      id: 'ctr',
      yAxisFormatter: (val) => `${val.toFixed(2)}%`,
    },
    {
      content: 'Product Sold',
      id: 'product-sold',
      yAxisFormatter: (val) => numberFormater(val),
    },
  ]

  const { data: shopData, isLoading: shopLoading } = useShopDetails()
  const experiences = shopData?.experiences || []

  const { settings, saveSettings } = useLocalSettings()

  const [durationFilter, setDurationFilter] = useState(settings.durationFilter)

  const chartsQuery = useQuery({
    queryKey: ['analytics', 'charts', experiences, durationFilter],
    queryFn: async () => {
      const aggregatedData = await Promise.all(
        experiences.map(async ({ id }) => {
          const { data } = await getCharts({
            experiences: [id],
            dateLte: new Date(durationFilter.end).valueOf(),
            dateGte: new Date(durationFilter.start).valueOf(),
          })
          return data.aggregates
        }),
      )
      const maxLenAggregate = aggregatedData.reduce((acc, curr) => {
        if (acc.length > curr.length) {
          return acc
        } else {
          return curr
        }
      }, [])

      const expAggMap = aggregatedData
        .map((agg, index) => ({
          expId: experiences[index].id,
          agg,
        }))
        .reduce((acc, curr) => {
          acc[curr.expId] = curr.agg
          return acc
        }, {})

      return {
        chartKeys: maxLenAggregate.map((_) => _.date),
        expAggMap,
      }
    },
    enabled: !shopLoading,
  })

  const handleDurationFilterChange = useCallback(
    (value) => setDurationFilter(value),
    [],
  )

  const loading =
    shopLoading || chartsQuery.isLoading || dashboardQuery.isLoading
  // const expAggMap = chartsQuery.data || {}
  // const chartData = experiences.map((exp) => ({
  //   name: getChartNames(TABS[activeIdx].id).name,
  //   data: getChartData(TABS[activeIdx].id, expAggMap[exp.id] ?? []) ?? [],
  //   tooltipFormatter: getTooltipFormatter(
  //     TABS[activeIdx].id,
  //     dashboardQuery.data,
  //   ),
  //   name2: getChartNames(TABS[activeIdx].id).name2,
  // }))[0]
  const activeYAxisFormatter = TABS[activeIdx].yAxisFormatter

  const chartsData = chartsQuery.data || {
    chartKeys: [],
    expAggMap: {},
  }

  const chartLabels = chartsData.chartKeys.map((_) =>
    format(new Date(_), 'dd LLL yy'),
  )
  
  const dataSets = Array.from({
    length: experiences.length,
  })

  ;(experiences ?? []).forEach((exp, indx) => {
    const chartData = getChartData(
      TABS[activeIdx].id,
      chartsData.expAggMap[exp.id] ?? [],
    )
    dataSets[indx] = {
      label: exp.name,
      data: chartData.map((_) => _.value),
    }
  })

  useEffect(() => {
    saveSettings({
      ...settings,
      durationFilter,
    })
  }, [durationFilter])

  return (
    <Card padding={'0'}>
      <BlockStack gap={'100'}>
        <InlineStack align="space-between">
          <Tabs
            disabled={loading}
            selected={activeIdx}
            onSelect={setActiveIdx}
            tabs={TABS}
          />
          {breakpoints.mdUp && (
            <DateTimeRangePopover
              handleDurationFilterChange={handleDurationFilterChange}
              durationFilter={durationFilter}
              loading={loading}
            />
          )}
        </InlineStack>
        <Divider />
        {loading ? (
          <div
            style={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="small" />
          </div>
        ) : (
          <div
            style={{
              height: '300px',
              padding: 'var(--p-space-200) var(--p-space-300)',
            }}
          >
            <Line
              data={{
                labels: chartLabels,
                datasets: dataSets,
              }}
              width={'100%'}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                  line: {
                    tension: 0.5,
                  },
                },
                plugins: {
                  legend: {
                    position: 'bottom',
                    align: 'end',
                  },
                  tooltip: {
                    backgroundColor: 'white',
                    titleColor: 'rgba(26, 26, 26, 1)',
                    bodyColor: 'rgba(48, 48, 48, 1)',
                    borderWidth: 1,
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    intersect: false,
                    callbacks: {
                      label: (tooltipItem) => {
                        return (
                          tooltipItem.dataset.label +
                          ': ' +
                          activeYAxisFormatter(tooltipItem.raw)
                        )
                      },
                    },
                    usePointStyle: true,
                  },
                },
                hover: {
                  mode: 'nearest',
                  intersect: true,
                },
                tooltips: {
                  mode: 'x',
                  intersect: false,
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: 'Month',
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                      },
                    },
                  ],
                  y: {
                    ticks: {
                      callback: function (value, index, ticks) {
                        return activeYAxisFormatter(value)
                      },
                    },
                  },
                },
              }}
            />
          </div>
        )}
        <div style={{ height: 'var(--p-space-200)' }} />
      </BlockStack>
    </Card>
  )
}

export default AnalyticsExperience

function getChartData(type, aggregates) {
  switch (type) {
    case 'revenue':
      return aggregates.map((agg) => ({
        key: format(new Date(agg.date), 'dd LLL yy'),
        value: agg.nativeRevenueByRk,
        total: agg.nativeTotal,
      }))
    case 'ctr':
      return aggregates.map((agg) => ({
        key: format(new Date(agg.date), 'dd LLL yy'),
        value: agg.clickRate * 100,
      }))
    case 'product-sold':
      return aggregates.map((agg) => ({
        key: format(new Date(agg.date), 'dd LLL yy'),
        value: agg.productsSoldCountByRk,
        total: agg.productsSoldCount,
      }))
    default:
      return []
  }
}

function getTooltipFormatter(type, data) {
  switch (type) {
    case 'revenue':
      return (value) => currencyFormatter(data?.shop?.currency, value)
    case 'ctr':
      return (value) => `${value.toFixed(2)}%`
    case 'product-sold':
      return (value) => `${value}`
    default:
      return (value) => numberFormater(value)
  }
}

function getChartNames(tabId) {
  switch (tabId) {
    case 'revenue':
      return {
        name: 'Revenue by Glood.AI',
        name2: 'Total Revenue',
      }
    case 'ctr':
      return {
        name: 'Click Through Rate',
      }
    case 'product-sold':
      return {
        name: 'Products Sold by Glood.AI',
        name2: 'Total Products Sold',
      }
    default:
      return {}
  }
}

function fillMissingData(...arrays) {
  // Get the reference array which we will use to fill in missing dates
  const referenceArray = arrays[0];

  // Create a new array for each input array to hold the filled data
  return arrays.map((array) => {
      const filledArray = [];

      // Create a set of all dates from the reference array for easy lookup
      const referenceDates = new Set(referenceArray.map(item => item.date));

      // Loop through each date in the reference array
      for (const refItem of referenceArray) {
          const date = refItem.date;

          // Find the corresponding item in the current array
          const currentItem = array.find(item => item.date === date);

          // If the item exists, push it to the filled array
          if (currentItem) {
              filledArray.push({ ...currentItem });
          } else {
              // If the item doesn't exist, create a new object with default values
              filledArray.push({
                  date,
                  ppv: 0,
                  served: 0,
                  viewed: 0,
                  clicked: 0,
                  revenue: 0,
                  revenueByRk: 0,
                  nativeRevenue: 0,
                  nativeRevenueByRk: 0,
                  productsSoldCountByRk: 0,
                  productsSoldCount: 0,
                  viewRate: 0,
                  clickRate: 0
              });
          }
      }

      return filledArray;
  });
}
