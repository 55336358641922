import {
  Badge,
  BlockStack,
  Box,
  Card,
  Text,
  IndexFilters,
  IndexTable,
  Page,
  ResourceList,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextField,
  useBreakpoints,
  useIndexResourceState,
  IndexFiltersMode,
  Divider,
  Icon,
  InlineStack,
  Button,
  Popover,
  Scrollable,
  InlineGrid,
  ChoiceList,
  Filters,
  Tooltip,
  EmptyState,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Fragment, useEffect, useMemo, useState } from 'react'
import OutClick from 'react-outclick'

import { fetchProduct } from '../../../apis/shopify'
import { currencyFormatter, numberFormater } from '../../../utils/formater'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import {
  ChevronDownIcon,
  DeleteIcon,
  LockIcon,
  PlusIcon,
  SearchIcon,
} from '@shopify/polaris-icons'
import { useRedirect } from '../../../hooks'
import { FEATURES } from '../../../constants/features'
import { areFeaturesEnabled } from '../../../utils/features'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import { useListBundles } from '../hooks/useListBundles'
import WidgetTabChart from '../../../components/WidgetTabChart'
import DateTimeRangePopover from '../../../components/DateTimeRangePopover'
import { useLocalSettings } from '../../../components/LocalSettings'
import { getBundleAnalytics } from '../../../apis/bundles'
import emptyPicture from './assets/empty-state.png'

const MAX_PAGE_SIZE = 10

export default function BundleListScreen() {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const [statusFilter, setStatusFilter] = useState([])
  const breakpoints = useBreakpoints()
  const [page, setPage] = useState(1)
  const pricing = usePricing()
  const { settings, saveSettings } = useLocalSettings()
  const [durationFilter, setDurationFilter] = useState(settings.durationFilter)
  const { data: dashboardData, isLoading: dashboardLoading } =
    useDashboardDetails()
  const { bundles: bundlesResponse, isLoading } = useListBundles()

  const analyticsQuery = useQuery({
    queryKey: ['bundles', 'analytics', durationFilter],
    queryFn: async () => {
      const { data, error } = await getBundleAnalytics({
        gtle: new Date(durationFilter.start),
        lte: new Date(durationFilter.end),
      })

      if (error) {
        return Promise.reject(error)
      }

      return data.aggregates
    },
  })
  const productQuery = useQuery({
    queryKey: ['bundles', 'products'],
    queryFn: async () => {
      const unfilteredPIds = bundlesResponse
        .map((bundle) => bundle.productIds)
        .reduce((prev, curr) => [...prev, ...curr], [])
      const productIds = [...new Set(unfilteredPIds)]
      const products = await Promise.all(
        productIds.map(async (id) => {
          const { data } = await fetchProduct(id)
          return data
        }),
      )
      return products
    },
    enabled: Boolean(bundlesResponse.length > 0),
  })

  const productIdMap = useMemo(() => {
    const products = productQuery.data ?? []
    return products.reduce((prev, curr) => {
      if (!curr || !curr.id) return prev
      return {
        ...prev,
        [curr.id]: curr,
      }
    }, {})
  }, [productQuery.data])

  const bundleFeatureEnabled = areFeaturesEnabled(
    [FEATURES.BUNDLES],
    window.shopify.data,
  )

  const bundles = getFilteredBundles(bundlesResponse ?? [], {
    statusFilter,
    searchQuery,
  })

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(bundles)

  const rowMarkup = bundles
    .slice((page - 1) * MAX_PAGE_SIZE, page * MAX_PAGE_SIZE)
    .map((bundle, index) => (
      <BundleEntry
        key={index}
        bundle={{
          ...bundle,
          products: bundle.productIds.map((id) => productIdMap[id]),
        }}
        utils={{
          selectedResources,
          index,
          t,
          dashboardData: window.shopify.data,
        }}
      />
    ))

  useEffect(() => {
    setPage(1)
  }, [statusFilter, searchQuery])

  useEffect(() => {
    saveSettings({
      ...settings,
      durationFilter,
    })
  }, [durationFilter])

  if (productQuery.isLoading || isLoading) {
    return <Skeleton />
  }

  const analyticsAggregates = analyticsQuery.data ?? []
  const analyticsData = {
    options: Object.keys(CHART_KEYS).map((key) => ({
      label: t(`CheckoutUpsell.Chart.${key}.title`),
      value: formateAnalyticsValue(
        CHART_KEYS[key].totalCalc(
          analyticsAggregates.map((aggregate) => aggregate[key]),
        ),
        CHART_KEYS[key].type,
      ),
      yAxisFormatter: (val) => formateAnalyticsValue(val, CHART_KEYS[key].type),
    })),
    stats: Object.keys(CHART_KEYS).map((key) => ({
      data: analyticsAggregates.map((aggregate) => ({
        key: format(new Date(aggregate.date), 'MMM dd'),
        value: aggregate[key],
      })),
      name: t(`CheckoutUpsell.Chart.${key}.title`),
      yAxisFormatter: (val) => formateAnalyticsValue(val, CHART_KEYS[key].type),
    })),
  }
  
  const updateHandler = () => {
    const activePlan = pricing.plans.find(
      (plan) => plan.id === dashboardData.shopPlan.plan,
    )
    if (
      parseFloat(activePlan.price) <
      parseFloat(dashboardData.shopPlan.price)
    ) {
      pricing.open({
        showContactCTA: true,
      })
    } else {
      pricing.open({
        features: [FEATURES.BUNDLES],
      })
    }
  }

  return (
    <Page
      fullWidth
      title={t('BundleList.title')}
      subtitle={t('BundleList.description')}
      titleMetadata={
        <div
          style={{
            height: '100%',
            display: 'grid',
            alignItems: 'center',
          }}
        >
          {!bundleFeatureEnabled && (
            <Button
              loading={dashboardLoading}
              variant="monochromePlain"
              icon={LockIcon}
              onClick={updateHandler}
            />
          )}
        </div>
      }
      primaryAction={{
        content: t('BundleList.create'),
        url: bundleFeatureEnabled && '/bundles/create/custom',
        icon: !bundleFeatureEnabled ? LockIcon : PlusIcon,
        onAction: () => {
          if (!bundleFeatureEnabled) {
            updateHandler()
          }
        },
      }}
      backAction={{
        url: '/',
      }}
    >
      <BlockStack gap={'300'}>
        <WidgetTabChart
          dateFilter={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {' '}
              <DateTimeRangePopover
                durationFilter={durationFilter}
                handleDurationFilterChange={setDurationFilter}
              />
            </div>
          }
          loading={analyticsQuery.isLoading}
          loadingBlocks={4}
          stats={analyticsData.options}
          data={analyticsData.stats}
          columns={{
            xs: 3,
            sm: 4,
            md: 5,
            lg: 5,
          }}
        />
        {bundlesResponse.length > 0 ? (
          <Card padding={'0'}>
            <Box padding={'200'}>
              <TextField
                prefix={<Icon source={SearchIcon} />}
                placeholder={t('BundleList.search.placeholder')}
                value={searchQuery}
                onChange={setSearchQuery}
              />
            </Box>
            <IndexTable
              pagination={{
                hasNext: bundles.length > page * MAX_PAGE_SIZE,
                hasPrevious: page > 1,
                onPrevious: () => {
                  setPage(page - 1)
                },
                onNext: () => {
                  setPage(page + 1)
                },
              }}
              condensed={breakpoints.smDown}
              resourceName={t('BundleList.resourceName')}
              itemCount={bundles.length}
              selectedItemsCount={
                allResourcesSelected ? 'All' : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              promotedBulkActions={[
                {
                  content: t('BundleList.delete'),
                  icon: DeleteIcon,
                },
              ]}
              headings={[
                { title: t('BundleList.table.bundle') },
                { title: t('BundleList.table.type') },
                { title: t('BundleList.table.status') },
                { title: t('BundleList.table.products') },
                { title: t('BundleList.table.revenue'), alignment: 'start' },
                { title: t('BundleList.table.purchased') },
                { title: t('BundleList.table.conversion') },
              ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
          </Card>
        ) : (
          <Card>
            <EmptyState
              action={{
                content: t('BundleList.create'),
                url: bundleFeatureEnabled && '/bundles/create/custom',
                icon: !bundleFeatureEnabled ? LockIcon : PlusIcon,
              }}
              heading={t('BundleList.empty')}
              image={emptyPicture}
            />
          </Card>
        )}
      </BlockStack>
      <PricingModal modal={pricing} />
    </Page>
  )

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0
    } else {
      return value === '' || value == null
    }
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return (
          'Status: ' +
          value
            ?.map((val) =>
              val == 'active'
                ? t('BundleList.filter.status.options.active')
                : t('BundleList.filter.status.options.inactive'),
            )
            .join(', ')
        )
      default:
        return value
    }
  }
}

function BundleEntry({
  bundle,
  utils: { selectedResources, index, t, dashboardData },
}) {
  const [open, setOpen] = useState(false)
  const {
    id,
    displayName,
    status: state,
    products,
    bundleProductId,
  } = bundle

  const { redirectToLink } = useRedirect()

  const popover = (
    <Popover
      active={open}
      activator={
        <span
          style={{
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpen(true)
          }}
        >
          <Icon source={ChevronDownIcon} />
        </span>
      }
    >
      <Scrollable
        style={{
          maxHeight: 300,
          width: 300,
        }}
      >
        <BlockStack>
          {products.map((prod, index) => (
            <Fragment key={index}>
              <Box padding={'200'}>
                <InlineGrid
                  alignItems="center"
                  gap={'200'}
                  columns={'1fr auto'}
                >
                  <Text>{prod.title}</Text>
                  {prod.images.length > 0 && (
                    <Box>
                      <img
                        src={prod.images[0]}
                        style={{
                          width: 50,
                          height: 'auto',
                        }}
                      />
                    </Box>
                  )}
                </InlineGrid>
              </Box>
              {index != products.length - 1 && <Divider />}
            </Fragment>
          ))}
        </BlockStack>
      </Scrollable>
    </Popover>
  )

  return (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources.includes(id)}
      position={index}
      onClick={() => {
        const url = `https://admin.shopify.com/store/${window.shopify.data.shop.myshopifyDomain.replace('.myshopify.com', '')}/products/${bundleProductId}`
        redirectToLink({
          url: url,
          external: true,
        })
      }}
    >
      <IndexTable.Cell>
        <BlockStack gap={'100'}>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {displayName}
          </Text>
          <Text variant="bodyXs">ID: {bundle.bundleProductId}</Text>
        </BlockStack>
      </IndexTable.Cell>
      <IndexTable.Cell>{t('BundleList.type.' + bundle.type)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Badge
          tone={
            state == 'active'
              ? 'success'
              : status === 'created'
                ? 'info'
                : undefined
          }
        >
          {t('BundleList.status.' + state)}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div
          style={{
            display: 'flex',
            gap: 'var(--p-space-200)',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          {products.map(
            ({ images, title }) =>
              images?.[0] && (
                <Tooltip content={title}>
                  <img
                    src={images[0]}
                    style={{
                      width: 50,
                      height: 'auto',
                    }}
                  />
                </Tooltip>
              ),
          )}
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {currencyFormatter(dashboardData?.shop?.currency, 0)}
      </IndexTable.Cell>
      <IndexTable.Cell>{numberFormater(0)}</IndexTable.Cell>
      <IndexTable.Cell>{(0 * 100).toFixed(0)}%</IndexTable.Cell>
    </IndexTable.Row>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth title={t('BundleList.title')}>
      <Card>
        <BlockStack gap={'200'}>
          <SkeletonDisplayText />
          <SkeletonBodyText lines={2} />
        </BlockStack>
      </Card>
    </SkeletonPage>
  )
}

function getFilteredBundles(bundles, filters = {}) {
  let filteredBundles = bundles
  if (filters.statusFilter.length) {
    filteredBundles = filteredBundles.filter((bundle) =>
      filters.statusFilter.includes(bundle.state),
    )
  }
  if (filters.searchQuery) {
    filteredBundles = filteredBundles.filter((bundle) =>
      bundle.displayName
        .toLowerCase()
        .includes(filters.searchQuery.toLowerCase()),
    )
  }
  return filteredBundles
}

const CHART_KEYS = {
  aov: {
    type: 'currency',
    totalCalc: (data) => data.reduce((acc, curr) => acc + curr, 0),
  },
  attributedRevenue: {
    type: 'currency',
    totalCalc: (data) => data.reduce((acc, curr) => acc + curr, 0),
  },
  conversion: {
    type: 'percentage',
    totalCalc: (data) => {
      const val = data.reduce((acc, curr) => acc + curr, 0) / data.length
      return Boolean(val) ? val : 0
    },
  },
  orderPlaced: {
    type: 'number',
    totalCalc: (data) => data.reduce((acc, curr) => acc + curr, 0),
  },
}

function formateAnalyticsValue(value, type) {
  switch (type) {
    case 'currency':
      return `$${value}`
    case 'percentage':
      return `${value * 100}%`
    default:
      return typeof value === 'number' ? numberFormater(value) : value
  }
}
