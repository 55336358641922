import getApiClient from '.'

export async function getBillingDetails() {
  try {
    const client = await getApiClient()
    const {data} = await client.get('/api/app/billing')
    return {
      error: null,
      data: data.billingCycle,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

