import {
  Badge,
  Button,
  Icon,
  InlineGrid,
  InlineStack,
  Text,
} from '@shopify/polaris'
import { CheckIcon, InfoIcon } from '@shopify/polaris-icons'
import { useState } from 'react'
import OnOutsideClick from 'react-outclick'
import { PricingModal, usePricing } from '../PricingModal'
import { useTranslation } from 'react-i18next'

export default function PageSelector({
  error,
  value,
  onChange,
  options,
  label,
  placeholder,
  shopPlan,
}) {
  const selected = options.find((option) => option.value === value)
  const text = selected ? selected.label : placeholder
  const [visible, setVisible] = useState(false)
  const pricingProps = usePricing()

  return (
    <OnOutsideClick
      onOutsideClick={() => {
        setVisible(false)
      }}
    >
      <div>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text">
              <span className="Polaris-Text--root Polaris-Text--bodyMd">
                {label}
              </span>
            </label>
          </div>
        </div>
        <div
          onClick={() => setVisible(true)}
          style={{
            paddingTop: '6px',
            paddingRight: '8px',
            paddingBottom: '6px',
            paddingLeft: '12px',
            fontSize: 'var(--p-font-size-325)',
            fontWeight: 'var(--p-font-weight-regular)',
            lineHeight: 'var(--p-font-line-height-500)',
            fontFamily: 'var(--p-font-family-sans)',
            border: '1px solid var(--p-color-border-tertiary)',
            borderRadius: 'var(--p-border-radius-200)',
            backgroundColor: error ? '#fde9e7' : 'transparent',
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          <div>{text}</div>
          {visible && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                transform: 'translateY(100%)',
                paddingTop: '10px',
                width: '100%',
                zIndex: 999,
              }}
            >
              <div
                style={{
                  backgroundColor: 'var(--p-color-bg-surface)',
                  borderRadius: 'var(--p-border-radius-200)',
                  boxShadow: 'var(--p-shadow-200)',
                  border: '1px solid var(--p-color-border-tertiary)',
                  maxHeight: '250px',
                  overflowY: 'scroll',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 'var(--p-space-150)',
                }}
              >
                {options.map((option, index) => (
                  <PageOption
                    setVisible={setVisible}
                    value={value}
                    option={option}
                    key={index}
                    onChange={onChange}
                    pricing={pricingProps}
                    shopPlan={shopPlan}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {error && (
          <div className="Polaris-Labelled__Error">
            <div id=":r2:Error" class="Polaris-InlineError">
              <div className="Polaris-InlineError__Icon">
                <span className="Polaris-Icon">
                  <svg
                    viewBox="0 0 20 20"
                    class="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"></path>
                    <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
                    ></path>
                  </svg>
                </span>
              </div>
              <span className="Polaris-Text--root Polaris-Text--bodyMd">
                {error}
              </span>
            </div>
          </div>
        )}
      </div>
      <PricingModal modal={pricingProps} />
    </OnOutsideClick>
  )
}

function PageOption({
  onChange,
  option,
  value,
  setVisible,
  pricing,
  shopPlan,
}) {
  const { open } = pricing
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const clickHandler = async () => {
    setLoading(true)
    await open({
      page: option.value,
      currentPlan: shopPlan,
    })
    setLoading(false)
  }

  return (
    <>
      <div
        key={option.value}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (option.upgradeRequired || option.deprecated || loading) {
            return
          }
          setVisible(false)
          onChange(option.value)
        }}
        className={'gloodSelectOption'}
        style={{
          padding: 'var(--p-space-200) var(--p-space-300)',
          cursor: option.upgradeRequired || loading || option.deprecated ? 'not-allowed' : 'pointer',
          borderRadius: 'var(--p-border-radius-200)',
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--p-space-100)',
          opacity: option.deprecated ? 0.5 : 1,
        }}
      >
        <InlineGrid columns={'auto 1fr'}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 'var(--p-space-100)',
            }}
          >
            {option.label}
            {option.shopifyPlusOnly && (
              <Badge size="small" tone="warning">
                Shopify Plus
              </Badge>
            )}
            {option.deprecated && (
              <Badge tone="warning">{t('DefaultText.deprecated')}</Badge>
            )}
          </div>
          <InlineStack gap={'300'} align="end">
            {option.value === value && (
              <div>
                <Icon source={CheckIcon} tone="base" />
              </div>
            )}
            {option.upgradeRequired && (
              <Button
                onClick={clickHandler}
                icon={
                  <svg
                    style={{
                      transform: 'translateY(2px)',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.70759 2.17732C7.73637 2.12506 7.77864 2.08148 7.83 2.05113C7.88136 2.02077 7.93993 2.00476 7.99959 2.00476C8.05925 2.00476 8.11782 2.02077 8.16918 2.05113C8.22054 2.08148 8.26282 2.12506 8.29159 2.17732L10.2596 5.91332C10.3065 5.99983 10.372 6.07488 10.4514 6.13307C10.5308 6.19126 10.622 6.23116 10.7187 6.2499C10.8153 6.26863 10.9148 6.26575 11.0102 6.24144C11.1056 6.21714 11.1944 6.17202 11.2703 6.10932L14.1216 3.66666C14.1763 3.62214 14.2438 3.59614 14.3142 3.59239C14.3847 3.58865 14.4545 3.60735 14.5137 3.64582C14.5728 3.68429 14.6182 3.74053 14.6434 3.80645C14.6685 3.87237 14.6721 3.94457 14.6536 4.01266L12.7643 10.8433C12.7257 10.9831 12.6426 11.1065 12.5276 11.1948C12.4126 11.2831 12.2719 11.3315 12.1269 11.3327H3.87292C3.72781 11.3316 3.587 11.2833 3.47185 11.195C3.35671 11.1067 3.27352 10.9832 3.23492 10.8433L1.34626 4.01332C1.32776 3.94524 1.33134 3.87304 1.35649 3.80712C1.38163 3.7412 1.42705 3.68495 1.48619 3.64649C1.54534 3.60802 1.61516 3.58931 1.68562 3.59306C1.75607 3.5968 1.82352 3.6228 1.87826 3.66732L4.72892 6.10999C4.80479 6.17268 4.8936 6.2178 4.98897 6.24211C5.08434 6.26641 5.18391 6.2693 5.28052 6.25056C5.37714 6.23183 5.46841 6.19193 5.54779 6.13374C5.62716 6.07554 5.69266 6.0005 5.73959 5.91399L7.70759 2.17732Z"
                      stroke="currentColor"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33289 14H12.6662"
                      stroke="currentColor"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                variant="primary"
                loading={loading}
              >
                Upgrade
              </Button>
            )}
          </InlineStack>
        </InlineGrid>
        {option.deprecated && (
          <Text tone="subdued" variant="bodyXs">
            {t('DefaultText.checkoutDeprecatedBanner')}
          </Text>
        )}
      </div>
    </>
  )
}
