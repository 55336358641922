import getApiClient from './index'

export async function createBundle(payload) {
  try {
    const client = await getApiClient()
    const { data } = await client.post('/api/bundles', payload)
    return {
      data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e?.response?.data || e.message,
    }
  }
}

export async function updateBundles(bId, data) {
  try {
    const client = await getApiClient()
    const { data: res } = await client.post(`/api/bundles/${bId}`, data)
    return {
      data: res,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e?.response?.data || e.message,
    }
  }
}

export async function listBundles() {
  try {
    const client = await getApiClient()
    const { data } = await client.get('/api/bundles')
    return {
      data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e?.response?.data || e.message,
    }
  }
}

export async function getBundleAnalytics(data){
  try {
    const client = await getApiClient()
    const { data: res } = await client.post('/api/bundles/analytics', data)
    return {
      data: res,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e?.response?.data || e.message,
    }
  }
}
