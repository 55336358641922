import getApiClient from '.'

export async function createPage(data) {
  const client = await getApiClient()
  try {
    const response = await client.post('/api/setup/page', data)
    return {
      error: null,
      data: response.data.page,
    }
  } catch (error) {
    return {
      error: error.response.data,
      data: null,
    }
  }
}
