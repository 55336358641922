import { Button, InlineGrid, InlineStack, Link } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { openChat } from '../ExternalScripts'

export default function ContactUsLink({
  type = 'link',
  showLiveSupport = false,
}) {
  const { t } = useTranslation()

  let contactBtn = null,
    liveSupportBtn = null

  switch (type) {
    case 'button':
      contactBtn = <Button>{t('contactUs')}</Button>
      liveSupportBtn = showLiveSupport ? (
        <Button onClick={openChat}>{t('liveSupport')}</Button>
      ) : null
      break
    case 'link':
      contactBtn = <Link>{t('contactUs')}</Link>
      liveSupportBtn = showLiveSupport ? (
        <Link onClick={openChat}>{t('liveSupport')}</Link>
      ) : null
      break
  }

  return (
    showLiveSupport ? liveSupportBtn : contactBtn
  )
}
