import { BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavigationMenu } from '@shopify/app-bridge-react'

import Routes from './Routes'
import { AppBridgeProvider, QueryProvider, PolarisProvider } from './components'
import ShopStatusChecker from './components/ShopStatusChecker'
import ExternalScripts, { openChat } from './components/ExternalScripts'
import LocalSettingsProvider from './components/LocalSettings'
import { FloatingButtonGroup } from './components/FloatingButtonGroup'

export default function App() {
  // Any .tsx or .jsx files in /pages will become a route
  // See documentation for <Routes /> for more info
  const pages = import.meta.globEager('./pages/**/!(*.test.[jt]sx)*.([jt]sx)')
  const { t } = useTranslation()

  return (
    <PolarisProvider>
      <BrowserRouter>
        <AppBridgeProvider>
          <QueryProvider>
            <ShopStatusChecker>
              <NavigationMenu
                navigationLinks={[
                  {
                    label: t('Links.sections'),
                    destination: '/sections',
                  },
                  {
                    label: t('Links.checkoutUpsell'),
                    destination: '/checkout-upsell',
                  },
                  {
                    label: t('Links.bundles'),
                    destination: '/bundles',
                  },
                  {
                    label: t('Links.analytics'),
                    destination: '/analytics',
                  },
                  {
                    label: t('Links.settings'),
                    destination: '/settings',
                  },
                  {
                    label: t('Links.pricing'),
                    destination: '/pricing',
                  },
                ]}
              />
              <LocalSettingsProvider>
                <Routes pages={pages} />
                <FloatingButtonGroup/>
              </LocalSettingsProvider>
              <ExternalScripts />
            </ShopStatusChecker>
          </QueryProvider>
        </AppBridgeProvider>
      </BrowserRouter>
    </PolarisProvider>
  )
}
