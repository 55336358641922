import {
  Button,
  Card,
  InlineError,
  Form,
  SkeletonBodyText,
  Box,
} from '@shopify/polaris'

import CodeEditor from '../../../../components/CodeEditor'
import CollapsableErrorWindow from '../../../../components/CollapsableErrorWindow'

function CSS({ config, form, dashboardData, pricing }) {
  return (
    <Form onSubmit={form.submit}>
      <div
        style={{
          padding: 'var(--p-space-400) var(--p-space-400) var(--p-space-400) 0',
          position: 'relative',
        }}
      >
        {form.fields.cssEditor.error && (
          <InlineError message={form.fields.cssEditor.error} />
        )}
        <CodeEditor
          onChange={form.fields.cssEditor.onChange}
          loading={
            <Box padding={'400'}>
              <SkeletonBodyText lines={20} />
            </Box>
          }
          value={form.fields.cssEditor.value}
          mode={'css'}
          height={500}
        />
        <div
          style={{
            paddingLeft: 'var(--p-space-400)',
          }}
        >
          <Button
            submit
            loading={form.submitting}
            variant="primary"
            size="large"
          >
            Save
          </Button>
        </div>
        <CollapsableErrorWindow error={config.cssError} />
      </div>
    </Form>
  )
}

export default CSS
