import {
  Banner,
  BlockStack,
  Box,
  Card,
  Grid,
  InlineGrid,
  Layout,
  Link,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  useBreakpoints,
} from '@shopify/polaris'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useContextualSaveBar } from '@shopify/app-bridge-react'

import ExcludedList from './sections/Exluded'
import {
  getCheckoutUpsellConfig,
  updateCheckoutUpsellConfig,
} from '../../../apis/checkoutUpsell'
import useConfigForm from './hooks/useConfigForm'
import RecommedationsSection from './sections/Recommendation'
import Placement from './sections/Placement'
import queryClient from '../../../utils/query'
import { fetchProduct } from '../../../apis/shopify'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { areFeaturesEnabled } from '../../../utils/features'
import { FEATURES } from '../../../constants/features'
import { PricingModal, usePricing } from '../../../components/PricingModal'

function CheckoutUpsellConfig() {
  const { t } = useTranslation()
  const saveBar = useContextualSaveBar()
  const breakpoints = useBreakpoints()
  const pricing = usePricing()
  const { data: dashboardData, isLoading: dashboardLoading } =
    useDashboardDetails()
  const configQuery = useQuery({
    queryKey: ['checkoutUpsellConfig'],
    queryFn: async () => {
      const { data, error } = await getCheckoutUpsellConfig()
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const productsQuery = useQuery({
    queryKey: ['products', configQuery.data?.products],
    queryFn: async () => {
      const products = configQuery.data.products
      const excludedProducts = configQuery.data.excludedProducts

      if (!products.length && !excludedProducts.length) {
        return {
          products: [],
          excludedProducts: [],
        }
      }

      const result = {
        products: await Promise.all(
          products.map(async (product) => {
            if (product.aiGenerated) {
              return product
            } else {
              const { data } = await fetchProduct(product.id)
              return data
            }
          }),
        ),
        excludedProducts: await Promise.all(
          excludedProducts.map(async (productId) => {
            const { data } = await fetchProduct(productId)
            data.id = `gid://shopify/Product/${productId}`
            return data
          }),
        ),
      }
      return result
    },
    enabled: !!configQuery.data,
  })

  const isFeatureEnabled = areFeaturesEnabled(
    [FEATURES.CHECKOUT_SECTIONS],
    dashboardData,
  )
  const config = {
    recommendation: configQuery.data?.recommendation,
    products: productsQuery.data?.products,
    excludedProducts: productsQuery.data?.excludedProducts,
    excludedTags: configQuery.data?.excludedTags,
    placement: configQuery.data?.placement,
    overrideWithManualRec: configQuery.data?.overrideWithManualRec,
  }
  const form = useConfigForm(config, async (data) => {
    data.products = data.products.map((product) => ({
      id: product.id,
      aiGenerated: product.aiGenerated ?? false,
      discount: product.discount ?? null,
    }))
    data.excludedProducts = data.excludedProducts.map((product) =>
      parseInt(product.id.replace('gid://shopify/Product/', '')),
    )
    data.overrideWithManualRec = data.overrideWithManualRec.checked
    const { error } = await updateCheckoutUpsellConfig(data)
    if (error) {
      shopify.toast.show(t('CheckoutUpsell.CheckoutUpsellConfig.saveError'), {
        isError: true,
      })
      return {
        status: 'fail',
        message: error?.message ?? '',
      }
    }
    await queryClient.invalidateQueries(['checkoutUpsellConfig'])
    await configQuery.refetch()
    shopify.toast.show(t('CheckoutUpsell.CheckoutUpsellConfig.saveSuccess'))
    return {
      status: 'success',
    }
  })

  useEffect(() => {
    saveBar.saveAction.setOptions({
      disabled: !isFeatureEnabled,
      loading: form.submitting,
      onAction: form.submit,
    })
    saveBar.discardAction.setOptions({
      disabled: false,
      loading: false,
      discardConfirmationModal: true,
      onAction: () => {
        form.reset()
        saveBar.hide()
      },
    })
  }, [form.submitting])

  useEffect(() => {
    if (form.dirty) {
      saveBar.show()
    } else {
      saveBar.hide()
    }
  }, [form.dirty])

  if (
    configQuery.isLoading ||
    productsQuery.isLoading ||
    dashboardLoading
  ) {
    return <Skeleton />
  }

  return (
    <Page
      backAction={{
        url: '/checkout-upsell',
      }}
      title={t('CheckoutUpsell.CheckoutUpsellConfig.title')}
      subtitle={t('CheckoutUpsell.CheckoutUpsellConfig.description')}
      fullWidth
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <BlockStack gap={'400'}>
          {!isFeatureEnabled && (
            <Banner tone="warning" title={t('Features.notAvailableTitle')}>
              <p>
                {t('Features.notAvailableInPlanText', {
                  feature: t('Features.labels.checkout_sections'),
                  cta: (
                    <Link
                      onClick={() => {
                        pricing.open({
                          features: [FEATURES.CHECKOUT_SECTIONS],
                        })
                      }}
                    >
                      {t('DefaultText.upgradeText')}
                    </Link>
                  ),
                })}
              </p>
            </Banner>
          )}
          <Layout>
            <RecommedationsSection
              form={form}
            />
            <ExcludedList form={form} />
            <Placement form={form} />
            <Box padding={'400'} />
          </Layout>
        </BlockStack>
      </Box>
      <PricingModal modal={pricing} />
    </Page>
  )
}

export default CheckoutUpsellConfig

function Skeleton() {
  const breakpoints = useBreakpoints()
  const { t } = useTranslation()
  return (
    <Box padding={breakpoints.smUp ? '0' : '400'}>
      <SkeletonPage
        fullWidth
        title={t('CheckoutUpsell.CheckoutUpsellConfig.title')}
      >
        <InlineGrid columns={breakpoints.smUp ? '1fr 2fr' : '1fr'} gap={'800'}>
          <Grid.Cell>
            <BlockStack gap={'200'}>
              <SkeletonDisplayText />
              <SkeletonBodyText lines={1} />
            </BlockStack>
          </Grid.Cell>
          <Grid.Cell columnSpan={2}>
            <Card>
              <BlockStack gap={'200'}>
                <SkeletonDisplayText />
                <SkeletonBodyText lines={4} />
              </BlockStack>
            </Card>
          </Grid.Cell>
        </InlineGrid>
      </SkeletonPage>
    </Box>
  )
}
