import {
  Page,
  Layout,
  Card,
  Form,
  FormLayout,
  Select,
  TextField,
  Button,
} from '@shopify/polaris'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { notEmpty, useField, useForm } from '@shopify/react-form'

import { createTemplate, getTemplates } from '../../../apis/template'
import Loader from '../../../components/Loader'
import { useRedirect } from '../../../hooks/useRedirect'
import queryClient from '../../../utils/query'
import { TEMPLATE_TYPES } from '../../../constants/templates'

function CreateTemplate() {
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const { data = {}, isLoading } = useQuery({
    queryFn: async () => {
      const res = await getTemplates()
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: 'templateList',
  })

  const templates = data?.templates ?? []

  const templateNameField = useField({
    value: '',
    validates: [notEmpty('Template Name cannot be empty')],
  })

  const sourceTemplateField = useField(null)

  const templateType = useField({
    value: '',
    validates: [notEmpty('Please select an option')],
  })

  const createForm = useForm({
    fields: { templateNameField, sourceTemplateField, templateType },
    onSubmit: async (data) => {
      const res = await createTemplate({
        name: data.templateNameField,
        copyTemplateId: data.sourceTemplateField,
        type: data.templateType,
      })
      if (res.error) {
        shopify.toast.show(t('Templates.createError'), {
          isError: true,
        })
      } else {
        await queryClient.invalidateQueries(['templates'])
        redirectToLink({
          url: `/settings/templates/${res.data.template.id}/configure`,
          external: false,
        })
        return {
          status: 'success',
        }
      }
    },
  })

  const options = templates
    .filter(({ type }) => type === templateType.value)
    .map(({ name, id }) => {
      return {
        label: name,
        value: id.toString(),
      }
    })

  useEffect(() => {
    createForm.fields.sourceTemplateField.onChange(null)
  }, [createForm.fields.templateType.value])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Page
      fullWidth
      backAction={{ url: '/settings/templates' }}
      title={t('Templates.Create.title')}
      subtitle={t('Templates.Create.subtitle')}
    >
      <Form onSubmit={createForm.submit}>
        <Layout>
          <Layout.AnnotatedSection
            variant="oneThird"
            title={t('Templates.Create.Details.title')}
            description={t('Templates.Create.Details.description')}
          >
            <Card>
              <Form>
                <FormLayout>
                  <TextField
                    {...createForm.fields.templateNameField}
                    autoComplete="text"
                    type="text"
                    label={t('Templates.Create.Form.Name.title')}
                    placeholder={t('Templates.Create.Form.Name.placeholder')}
                    helpText={t('Templates.Create.Form.Name.helpText')}
                  />
                </FormLayout>
                <div
                  style={{
                    width: '100%',
                    height: 'var(--p-height-500)',
                  }}
                />
                <FormLayout>
                  <Select
                    label={t('Templates.Create.Form.Type.title')}
                    options={Object.values(TEMPLATE_TYPES).map((type) => ({
                      label: t(`Templates.type.${type}`),
                      value: type,
                    }))}
                    {...createForm.fields.templateType}
                    placeholder={t('Templates.Create.Form.Type.placeholder')}
                  />
                </FormLayout>
                <div
                  style={{
                    width: '100%',
                    height: 'var(--p-height-500)',
                  }}
                />
                <FormLayout>
                  <Select
                    disabled={!Boolean(createForm.fields.templateType.value)}
                    onChange={(val) =>
                      createForm.fields.sourceTemplateField.onChange(
                        parseInt(val),
                      )
                    }
                    label={t('Templates.Create.Form.Source.title')}
                    value={createForm.fields.sourceTemplateField.value?.toString()}
                    options={options}
                    placeholder={t('Templates.Create.Form.Source.placeholder')}
                  />
                </FormLayout>
              </Form>
              <div
                style={{
                  width: '100%',
                  height: 'var(--p-height-500)',
                }}
              />
              <FormLayout>
                <FormLayout>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <Button
                      loading={createForm.submitting}
                      variant="primary"
                      submit
                    >
                      {t('Templates.Create.Form.buttonText')}
                    </Button>
                  </div>
                </FormLayout>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Form>
    </Page>
  )
}

export default CreateTemplate
