import React, { useState } from 'react'
import {
  Autocomplete,
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineGrid,
  InlineStack,
  Link,
  Tag,
  Text,
  TextField,
} from '@shopify/polaris'
import { SearchIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import ProductImage from '../ProductImage'
import { useRedirect } from '../../hooks'

function ProductSelector({ product, onChange, currencyFormat = null }) {
  const { t } = useTranslation()
  const productTags = product?.tags ?? []
  const productType = product?.productType
  const [showAllTags, setShowAllTags] = useState(false)
  const extraTags = Math.max(productTags.length - 3, 0)

  const productPicker = async () => {
    if (!onChange) return
    try {
      const selected = await shopify.resourcePicker({
        type: 'product',
        filter: {
          variants: false,
        },
      })
      if (!selected) {
        return
      }
      onChange(selected?.[0] ?? product)
    } catch (e) {
      window.shopify.toast.show(t('noProductUpdate'), {
        isError: true,
      })
    }
  }

  const url = `https://${window.shopify.data.shop.domain}/products/${product?.handle}`

  return (
    <Card>
      <BlockStack gap={'400'}>
        <div>
          <Text as="strong">
            {t('RecommendationPage.NewProductRecommendation.addProductTitle')}
          </Text>
          <InlineGrid gap={'400'} columns={'1fr auto'} alignItems="start">
            <Text>
              {t(
                'RecommendationPage.NewProductRecommendation.addProductDescription',
              )}
            </Text>
            <Button onClick={productPicker}>
              {t(
                'RecommendationPage.NewProductRecommendation.addProductBtnText',
              )}
            </Button>
          </InlineGrid>
        </div>
        {product && (
          <InlineGrid gap={'300'} columns={'auto 1fr'}>
            <ProductImage
              style={{
                width: 68,
                height: 80,
              }}
              src={
                product.images?.[0]?.originalSrc ??
                'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-lifestyle-1_large.png?v=1530129243'
              }
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                gap: 'var(--p-space-050)',
                height: '100%',
              }}
            >
              <InlineStack gap={'200'}>
                <Link
                  monochrome
                  onClick={() => {
                    window.open(url, '_blank')
                  }}
                >
                  <Text as="strong">{product.title}</Text>
                </Link>
                <Badge tone="magic">ID: {product.id?.replace(/\D/g, '')}</Badge>
              </InlineStack>
              <Box padding={'050'} />
              <Text variant="bodyXs" tone="subdued">
                Handle: {product.handle} | Vendor: {product.vendor} | Type:{' '}
                {productType}
              </Text>
              <Box padding={'100'} />
              <InlineStack gap={'100'}>
                {productTags
                  .slice(0, showAllTags ? productTags.length : 3)
                  .map((tag) => (
                    <Tag size="large" key={tag}>
                      {tag}
                    </Tag>
                  ))}
                {extraTags > 0 && (
                  <Button
                    variant="monochromePlain"
                    onClick={() => setShowAllTags(!showAllTags)}
                  >
                    {showAllTags
                      ? t('hideTags')
                      : t('showTags', { count: extraTags })}
                  </Button>
                )}
              </InlineStack>
            </div>
          </InlineGrid>
        )}
      </BlockStack>
    </Card>
  )
}

export default ProductSelector
