import {
  BlockStack,
  Box,
  Card,
  Divider,
  Layout,
  Text,
  Banner,
} from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourcePicker } from '@shopify/app-bridge-react'
import {
  getCommonOptions,
  prepareListOfVariants,
  prepareProductIdMap,
  prepareProductIdVariantMap,
} from '../../../../../utils/bundles'
import VariantSelector from '../components/VariantSelector'
import ProductSelect from '../components/ProductSelect'
import { BUNDLE_SETTING_TYPE } from '../../../../../constants/bundles'

export default function ProductSelector({
  form,
  dashboardDetails,
  disabledFields,
  canUpdate,
}) {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const [isResourcePickerOpen, setIsResourcePickerOpen] = useState(false)

  const error = form.fields.products.error

  // set all options as selected by default
  useEffect(() => {
    const optionsArr = []
    form.fields.products.value.forEach((product) => {
      const id = parseInt(product.id.replace('gid://shopify/Product/', ''))
      const options = product.options
      optionsArr.push({
        product: id,
        options: options.map((option) => {
          return {
            name: option.name,
            values: option.values.map((value) => value),
          }
        }),
      })
    })
    form.fields.options.onChange(optionsArr)
  }, [form.fields.products.value])

  return (
    <>
      <ResourcePicker
        showVariants={
          false
        }
        open={isResourcePickerOpen}
        resourceType="Product"
        onSelection={(vals) => {
          form.fields.products.onChange(vals.selection)
          setIsResourcePickerOpen(false)
        }}
        onCancel={() => {
          setIsResourcePickerOpen(false)
        }}
        selectMultiple={3}
        initialSelectionIds={form.fields.products.value.map((p) => ({
          id: p.id,
          variants: p.variants.map((v) => ({
            id: v.id,
          })),
        }))}
      />
      <BlockStack gap={'300'}>
        {error && <Banner title={error} tone="critical" />}
        <Card padding={'0'}>
          <Box padding={'400'}>
            <BlockStack gap={'200'}>
              <BlockStack>
                <Text variant='headingSm'>
                  {t('BundlesUpsert.ProductsSelector.selectProduct')}
                </Text>
                <Text>
                  {t('BundlesUpsert.ProductsSelector.selectProductDescription')}
                </Text>
              </BlockStack>
            </BlockStack>
          </Box>
          <Divider />
          <ProductSelect
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setIsResourcePickerOpen={setIsResourcePickerOpen}
            products={productSearch(form.fields.products.value, searchQuery)}
            onChange={form.fields.products.onChange}
            canUpdate={canUpdate}
            form={form}
          />
          {/* <Divider />
          <VariantSelector
            dashboardDetails={dashboardDetails}
            noCommonOptions={noCommonOptions}
            form={form}
            disabledFields={disabledFields}
            canUpdate={canUpdate}
          /> */}
        </Card>
      </BlockStack>
    </>
  )
}

function productSearch(products, searchQuery) {
  if (!searchQuery) {
    return products
  }
  return products.filter((product) =>
    product.title.toLowerCase().includes(searchQuery.toLowerCase()),
  )
}
