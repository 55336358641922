import { Card, EmptyState, Page } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export default function ErrorScreen() {
  const { t } = useTranslation()
  return (
    <Page>
      <Card>
        <EmptyState
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          heading={t('DefaultText.somethingWentWrongText')}
          action={{
            content: t('DefaultText.reload'),
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: t('DefaultText.contactSupport'),
            external: true,
            url: 'mailto:support@glood.ai',
          }}
        >
          <p>{t('DefaultText.wrongDescription')}</p>
        </EmptyState>
      </Card>
    </Page>
  )
}
