import { useQuery } from 'react-query'
import { fetchAppState } from '../apis/app'

function useAppState() {
  const query = useQuery({
    queryKey: 'appState',
    queryFn: async () => {
      const response = await fetchAppState()
      if (response.error) {
        return Promise.reject(response.error)
      }
      return response.data
    },
  })
  return {
    data: query.data,
    isLoading: query.isLoading,
  }
}

export default useAppState
