import getApiClient from ".";

export async function triggerProductSync(){
    try{
        const client = await getApiClient()
        const response = await client.post('/api/task',{
            task: 'product_sync'
        })
        return {
            data: response.data,
            error: null
        }
    } catch (error){
        return {
            data: null,
            error: error
        }
    }
}

export async function triggerShopifySync(){
    try{
        const client = await getApiClient()
        const response = await client.post('/api/task',{
            task: 'shopify_sync'
        })
        return {
            data: response.data,
            error: null
        }
    } catch (error){
        return {
            data: null,
            error: error
        }
    }
}