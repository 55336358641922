import { CAMPAIGN_TRIGGER_CONDITION_OPTIONS } from '../../../../../../../../constants/checkoutUpsell'

export function triggerConditionValidator(t) {
  return (conditions) => {
    const length = conditions.length
    const errorArray = Array.from({ length }, () => null)
    conditions.forEach((condition, index) => {
      if (condition.type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
        // improve condition validation
        // if (
        //   condition.data.subType === 'quantity' &&
        //   condition.data.quantity == 0
        // ) {
        //   errorArray[index] = t(
        //     'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyField',
        //   )
        // } else
        //  if (
        //   condition.data.subType === 'quantity' &&
        //   condition.data.items == 0
        // ) {
        //   errorArray[index] = t(
        //     'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyItem',
        //   )
        // } else 
        if (
          !condition.data.items ||
          condition.data.items?.length === 0
        ) {
          errorArray[index] = t(
            'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyItem',
          )
        }
      } 
      // improve cart condition validation
      // else if (
      //   condition.type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART &&
      //   condition.data.total === 0 &&
      //   condition.data.subType !== 'subscription'
      // ) {
      //   errorArray[index] = t(
      //     'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyField',
      //   )
      // } 
      else if (
        condition.type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT &&
        condition.data.discount == 0
      ) {
        errorArray[index] = t(
          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyField',
        )
      }
    })
    const anyError = errorArray.some((error) => error !== null)
    return anyError ? errorArray : null
  }
}