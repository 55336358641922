import React from 'react'
import {
  BlockStack,
  Box,
  Button,
  Form,
  InlineError,
  InlineStack,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import CodeEditor from '../../../../../components/CodeEditor'

function JSSection({ form }) {
  const { t } = useTranslation()

  return (
    <Form onSubmit={form.submit}>
      <BlockStack gap={'300'}>
        <Box>
          <Text as="strong">
            {t('SettingsPage.Sections.Advanced.Section.js.title')}
          </Text>
          <Text as="p">
            {t('SettingsPage.Sections.Advanced.Section.js.description')}
          </Text>
        </Box>
        {form.fields.js.error && <InlineError message={form.fields.js.error} />}
        <Box
          padding={'100'}
          borderRadius="100"
          borderWidth="0165"
          borderColor="border"
        >
          <CodeEditor
            mode={'javascript'}
            value={form.fields.js.value}
            onChange={form.fields.js.onChange}
            height={'500px'}
            readOnly={form.submitting}
          />
        </Box>
        <InlineStack align="end">
          <Button loading={form.submitting} submit>
            {t('SettingsPage.Sections.Advanced.Section.js.btnCta')}
          </Button>
        </InlineStack>
      </BlockStack>
    </Form>
  )
}

export default JSSection
