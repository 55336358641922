import { Page } from '@shopify/polaris'
import { PlusIcon } from '@shopify/polaris-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ABExperience() {
  const { t } = useTranslation()
  return (
    <Page
      title={t('ABExperience.title')}
      subtitle={t('ABExperience.description')}
      primaryAction={{
        content: t('ABExperience.primaryAction.content'),
        url: '/experience/create',
        icon: PlusIcon,
      }}></Page>
  )
}

export default ABExperience
