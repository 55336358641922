import { Spinner } from '@shopify/polaris'
import React from 'react'

const StoreFrame = React.memo(
  React.forwardRef((props, ref) => {
    const { onLoad, frameUrl, key, loading } = props
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <iframe
          style={{
            height: '100%',
            width: '100%',
            border: 'none',
            borderRadius: 'var(--p-space-300)',
          }}
          src={frameUrl}
          data-hj-allow-iframe=""
          ref={ref}
          onLoad={onLoad}
          sandbox="allow-same-origin allow-scripts"
          title="store-frame"
          key={key}
        />
        {loading && (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              top: 0,
              left: 0,
            }}
          >
            <Spinner size="small" />
          </div>
        )}
      </div>
    )
  }),
  (prevProps, nextProps) => {
    return (
      prevProps.loading !== nextProps.loading ||
      prevProps.frameUrl !== nextProps.frameUrl
    )
  },
)

export default StoreFrame
