import React, { useState } from 'react'
import {
  Avatar,
  BlockStack,
  Box,
  Card,
  Grid,
  IndexTable,
  Text,
  useIndexResourceState,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const WINDOW_SIZE = 6

function AnalyticsTopSelling({
  byRevenue = dummyProducts,
  topConverting = dummyProducts,
  byClickThroughRate = dummyProducts,
  byQuantity = dummyProducts,
}) {
  const { t } = useTranslation()
  return null // Remove this line when you start implementing this component
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gap: 'var(--p-space-300)',
      }}
    >
      <div>
        <Products
          products={byRevenue}
          subtitle={t('Analytics.TopSelling.Card1.subtitle')}
          title={t('Analytics.TopSelling.Card1.title')}
        />
      </div>
      <div>
        <Products
          products={topConverting}
          subtitle={t('Analytics.TopSelling.Card2.subtitle')}
          title={t('Analytics.TopSelling.Card2.title')}
        />
      </div>
      <div>
        <Products
          products={byClickThroughRate}
          subtitle={t('Analytics.TopSelling.Card3.subtitle')}
          title={t('Analytics.TopSelling.Card3.title')}
        />
      </div>
      <div>
        <Products
          products={byQuantity}
          subtitle={t('Analytics.TopSelling.Card4.subtitle')}
          title={t('Analytics.TopSelling.Card4.title')}
        />
      </div>
    </div>
  )
}

function Products({ products = [], title, subtitle }) {
  const { t } = useTranslation()
  const resourceName = {
    singular: 'product',
    plural: 'products',
  }
  const [pageIndex, setPageIndex] = useState(0)

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(products)

  const rowMarkup = products
    .slice(pageIndex * WINDOW_SIZE, pageIndex * WINDOW_SIZE + WINDOW_SIZE)
    .map(({ title, id, handle, ctr, price }, indx) => {
      return (
        <IndexTable.Row
          key={id}
          id={id}
          position={indx}
          selected={selectedResources.includes(id)}
        >
          <IndexTable.Cell>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                margin: '5px 0',
              }}
            >
              <div style={{ minWidth: '2rem' }}>
                <Avatar
                  customer
                  size="lg"
                  source="/assets/collection-placeholder.webp"
                  // name={name}
                />
              </div>
              <div>
                <Text as="h3" variant="bodyMd" fontWeight="bold">
                  {title}
                </Text>
                <div>
                  {price} | ID: {id} | Handle: {handle}
                </div>
              </div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>{ctr}%</IndexTable.Cell>
        </IndexTable.Row>
      )
    })

  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <BlockStack>
          <Text as="p" fontWeight="bold">
            {title}
          </Text>
          <Text>{subtitle}</Text>
          <Box minHeight="var(--p-space-200)" width="100%"></Box>
        </BlockStack>
      </Box>
      <IndexTable
        resourceName={resourceName}
        headings={[
          {
            title: t('Analytics.TopSelling.Table.header.products'),
          },
          {
            title: t('Analytics.TopSelling.Table.header.ctr'),
          },
        ]}
        selectable
        itemCount={products.length}
        pagination={{
          onNext: () => {
            setPageIndex(pageIndex + 1)
          },
          onPrevious: () => {
            setPageIndex(pageIndex - 1)
          },
          hasNext: products.length > (pageIndex + 1) * WINDOW_SIZE,
          hasPrevious: pageIndex > 0,
        }}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  )
}

export default AnalyticsTopSelling

const dummyProducts = [
  {
    title: '3/4 sleev Kimoono Dress',
    id: '464',
    handle: '123',
    price: '$12',
    revenue: 1145,
    ctr: 12,
  },
  {
    title: '3/4 sleev Kimoono Dress',
    id: '574',
    handle: '123',
    price: '$12',
    revenue: 1145,
    ctr: 12,
  },
  {
    title: '3/4 sleev Kimoono Dress',
    id: '232',
    handle: '123',
    price: '$12',
    revenue: 1145,
    ctr: 12,
  },
]
