import getApiClient from '.'

export async function getDashboardDetails(token) {
  const client = await getApiClient(token)
  try {
    const response = await client.get('/api/app/dashboard')
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error.response.data,
    }
  }
}

export async function getShopConfig() {
  const { data, error } = await getDashboardDetails()
  if (error) {
    return { data: null, error }
  }
  return {
    data: data.config,
    error: null,
  }
}

export async function updateShopConfig(data = {}) {
  const client = await getApiClient()
  try {
    const response = await client.post('/api/app/config', data)
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error.response.data,
    }
  }
}
