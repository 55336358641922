export function areFeaturesEnabled(features = [], dashboardData = {}) {
  const { shopPlan: { featuresEnabled = [] } = {} } = dashboardData
  if(!featuresEnabled.length) return false

  return features.every((feature) => {
    return featuresEnabled.includes(feature)
  })
}

export function getFeaturesPlans(features = [], plans = [], dashboardDetails) {
  const { shopPlan: { onAnnual = false } = {} } = dashboardDetails

  if(features.length === 0) return plans

  const filteredPlans = plans
    .filter((plan) => onAnnual ? Boolean(plan.annualPrice) : Boolean(plan.price))
    .sort((a, b) => {
      const aPrice = onAnnual ? a.annualPrice : a.price
      const bPrice = onAnnual ? b.annualPrice : b.price
      return parseFloat(aPrice) - parseFloat(bPrice)
    })

  const suggestedPlans = filteredPlans.filter((plan) =>
    features.every((f) => plan.featuresEnabled.includes(f)),
  )

  return suggestedPlans
}
