export function saveToStorage(shop,data){
    localStorage.setItem(shop+'_setting', JSON.stringify(data))
}

export function getFromStorage(shop){
    const data = localStorage.getItem(shop+'_setting')
    if(data){
        return JSON.parse(data)
    }
    return {}
}