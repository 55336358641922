import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import HomePage from './container/Homepage'
import Plans from './pages/plans'
import Sections from './pages/sections'
import VisualEditor from './pages/visual-editor'
import SectionCreate from './container/Section/Create'
import SectionDetails from './container/Section/Detail'
import EditSection from './container/Section/Edit'
import ProductRecommendation, {
  NewProductRecommendation,
} from './container/Recommendation/ProductRecommendation'
import GlobalRecommendation from './container/Recommendation/GlobalRecommendation'
import RuleBasedRecommendation from './container/Recommendation/RuleBasedRecommendation'
import ProductRecommendationEdit from './container/Recommendation/ProductRecommendation/Details'
import RecommendationDataset from './container/Recommendation/Dataset'
import Templates from './container/Templates/Details'
import CreateTemplate from './container/Templates/Create'
import ConfigureTemplate from './container/Templates/Configure'
import Analytics from './container/Analytics'
import Settings from './container/Settings'
import ABExperience from './container/ABExperience'
import CreateABExperience from './container/ABExperience/Create'
import ConfirmCharge from './container/Charge'
import Onboarding from './container/Onboarding'
import CheckoutUpsell from './container/CheckoutUpsell'
import CampaignCreate from './container/CheckoutUpsell/Campaign/Create'
import CampaignDetails from './container/CheckoutUpsell/Campaign/Detail'
import CheckoutUpsellConfig from './container/CheckoutUpsell/Config'
import Loader from './components/Loader'
import NotFound from './pages/NotFound'
import {
  UpsertBundlesScreen,
  CreateBundle,
  BundlesList,
} from './container/Bundles'
import ErrorScreen from './container/Error'
import PlanPage from './pages/plans'
import { Recommendation } from './container/Recommendation'

// Below are the dynamuic imports for the routes. Change this later.
// const UpsertBundlesScreen = lazy(() =>
//   import('./container/Bundles').then((module) => ({
//     default: module.UpsertBundlesScreen,
//   })),
// )
// const CreateBundle = lazy(() =>
//   import('./container/Bundles').then((module) => ({
//     default: module.CreateBundle,
//   })),
// )
// const BundlesList = lazy(() =>
//   import('./container/Bundles').then((module) => ({
//     default: module.BundlesList,
//   })),
// )
// const SectionCreate = lazy(() =>
//   import('./container/Section/Create').then((module) => ({
//     default: module.default,
//   })),
// )
// const SectionDetails = lazy(() =>
//   import('./container/Section/Detail').then((module) => ({
//     default: module.default,
//   })),
// )
// const GlobalRecommendation = lazy(() =>
//   import('./container/Recommendation/GlobalRecommendation').then((module) => ({
//     default: module.default,
//   })),
// )
// const ProductRecommendationEdit = lazy(() =>
//   import('./container/Recommendation/ProductRecommendation/Details').then(
//     (module) => ({
//       default: module.default,
//     }),
//   ),
// )
// const RuleBasedRecommendation = lazy(() =>
//   import('./container/Recommendation/RuleBasedRecommendation/index.jsx').then(
//     (module) => ({
//       default: module.default,
//     }),
//   ),
// )
// const RecommendationDataset = lazy(() =>
//   import('./container/Recommendation/Dataset/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const Templates = lazy(() =>
//   import('./container/Templates/Details/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const Analytics = lazy(() =>
//   import('./container/Analytics/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const CreateTemplate = lazy(() =>
//   import('./container/Templates/Create/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const ConfigureTemplate = lazy(() =>
//   import('./container/Templates/Configure/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const Settings = lazy(() =>
//   import('./container/Settings/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const ABExperience = lazy(() =>
//   import('./container/ABExperience/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const CreateABExperience = lazy(() =>
//   import('./container/ABExperience/Create/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const ConfirmCharge = lazy(() =>
//   import('./container/Charge/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const Onboarding = lazy(() =>
//   import('./container/Onboarding/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const PlanPage = lazy(() =>
//   import('./pages/plans.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const DashboardBootWrapper = lazy(() =>
//   import('./components/BootWrapper/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const ErrorScreen = lazy(() =>
//   import('./container/Error/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const CheckoutUpsell = lazy(() =>
//   import('./container/CheckoutUpsell/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const CampaignCreate = lazy(() =>
//   import('./container/CheckoutUpsell/Campaign/Create/index.jsx').then(
//     (module) => ({
//       default: module.default,
//     }),
//   ),
// )
// const CampaignDetails = lazy(() =>
//   import('./container/CheckoutUpsell/Campaign/Detail/index.jsx').then(
//     (module) => ({
//       default: module.default,
//     }),
//   ),
// )
// const CheckoutUpsellConfig = lazy(() =>
//   import('./container/CheckoutUpsell/Config/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )
// const Loader = lazy(() =>
//   import('./components/Loader/index.jsx').then((module) => ({
//     default: module.default,
//   })),
// )

// const EditSection = lazy(() =>
//   import('./container/Section/Edit').then((module) => ({
//     default: module.default,
//   })),
// )
// const ProductRecommendation = lazy(() =>
//   import('./container/Recommendation/ProductRecommendation').then((module) => ({
//     default: module.default,
//   })),
// )
// const NewProductRecommendation = lazy(() =>
//   import('./container/Recommendation/ProductRecommendation').then((module) => ({
//     default: module.NewProductRecommendation,
//   })),
// )
// const HomePage = lazy(() =>
//   import('./container/Homepage').then((module) => ({
//     default: module.default,
//   })),
// )
// const NotFound = lazy(() =>
//   import('./pages/NotFound').then((module) => ({
//     default: module.default,
//   })),
// )
// const Plans = lazy(() =>
//   import('./pages/plans').then((module) => ({
//     default: module.default,
//   })),
// )
// const Sections = lazy(() =>
//   import('./pages/sections').then((module) => ({
//     default: module.default,
//   })),
// )
// const VisualEditor = lazy(() =>
//   import('./pages/visual-editor').then((module) => ({
//     default: module.default,
//   })),
// )

export default function Routes({ pages }) {
  return (
    <ReactRouterRoutes>
      <Route path="/error" element={<ErrorScreen />} />
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path="/plans"
        element={
          <Suspense fallback={<Loader />}>
            <Plans />
          </Suspense>
        }
      />
      <Route
        path="/sections"
        element={
          <Suspense fallback={<Loader />}>
            <Sections />
          </Suspense>
        }
      />
      <Route
        path="/visual-editor"
        element={
          <Suspense fallback={<Loader />}>
            <VisualEditor />
          </Suspense>
        }
      />
      <Route path="/recommendation" element={<Recommendation />} />
      <Route
        path="/sections/create"
        element={
          <Suspense fallback={<Loader />}>
            <SectionCreate />
          </Suspense>
        }
      />
      <Route
        path="/sections/:sectionId"
        element={
          <Suspense fallback={<Loader />}>
            <SectionDetails />
          </Suspense>
        }
      />
      <Route
        path="/sections/:sectionId/edit"
        element={
          <Suspense fallback={<Loader />}>
            <EditSection />
          </Suspense>
        }
      />
      <Route
        path="/settings/recommendation/:widgetType/product"
        element={
          <Suspense fallback={<Loader />}>
            <ProductRecommendation />
          </Suspense>
        }
      />
      <Route
        path="/settings/recommendation/:widgetType/global"
        element={
          <Suspense fallback={<Loader />}>
            <GlobalRecommendation />
          </Suspense>
        }
      />
      <Route
        path="/settings/recommendation/:widgetType/rule"
        element={
          <Suspense fallback={<Loader />}>
            <RuleBasedRecommendation />
          </Suspense>
        }
      />
      <Route
        path="/settings/recommendation/:widgetType/product/new"
        element={
          <Suspense fallback={<Loader />}>
            <NewProductRecommendation />
          </Suspense>
        }
      />
      <Route
        path="/settings/recommendation/:widgetType/product/:productId"
        element={
          <Suspense fallback={<Loader />}>
            <ProductRecommendationEdit />
          </Suspense>
        }
      />
      <Route
        path="/settings/recommendation/dataset/:datasetId"
        element={
          <Suspense fallback={<Loader />}>
            <RecommendationDataset />
          </Suspense>
        }
      />
      <Route
        path="/settings/templates"
        element={
          <Suspense fallback={<Loader />}>
            <Templates />
          </Suspense>
        }
      />
      <Route
        path="/settings/templates/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateTemplate />
          </Suspense>
        }
      />
      <Route
        path="/settings/templates/:templateId/configure"
        element={
          <Suspense fallback={<Loader />}>
            <ConfigureTemplate />
          </Suspense>
        }
      />
      <Route
        path="/analytics"
        element={
          <Suspense fallback={<Loader />}>
            <Analytics />
          </Suspense>
        }
      />
      <Route
        path="/analytics/:activeAnalyticsSection"
        element={
          <Suspense fallback={<Loader />}>
            <Analytics />
          </Suspense>
        }
      />
      <Route
        path="/bundles"
        element={
          <Suspense fallback={<Loader />}>
            <BundlesList />
          </Suspense>
        }
      />
      <Route
        path="/bundles/create/:bundleType"
        element={
          <Suspense fallback={<Loader />}>
            <CreateBundle />
          </Suspense>
        }
      />
      <Route
        path="/bundles/:bundleId"
        element={
          <Suspense fallback={<Loader />}>
            <UpsertBundlesScreen />
          </Suspense>
        }
      />
      <Route
        path="/pricing"
        element={
          <Suspense fallback={<Loader />}>
            <PlanPage />
          </Suspense>
        }
      />
      <Route
        path="/settings"
        element={
          <Suspense fallback={<Loader />}>
            <Settings />
          </Suspense>
        }
      />
      <Route
        path="/experience"
        element={
          <Suspense fallback={<Loader />}>
            <ABExperience />
          </Suspense>
        }
      />
      <Route
        path="/experience/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateABExperience />
          </Suspense>
        }
      />
      <Route
        path="/charge/confirm"
        element={
          <Suspense fallback={<Loader />}>
            <ConfirmCharge />
          </Suspense>
        }
      />
      <Route
        path="/onboarding"
        element={
          <Suspense fallback={<Loader />}>
            <Onboarding />
          </Suspense>
        }
      />
      <Route
        path="/checkout-upsell"
        element={
          <Suspense fallback={<Loader />}>
            <CheckoutUpsell />
          </Suspense>
        }
      />
      <Route
        path="/checkout-upsell/create"
        element={
          <Suspense fallback={<Loader />}>
            <CampaignCreate />
          </Suspense>
        }
      />
      <Route
        path="/checkout-upsell/configure"
        element={
          <Suspense fallback={<Loader />}>
            <CheckoutUpsellConfig />
          </Suspense>
        }
      />
      <Route
        path="/checkout-upsell/:campaignId"
        element={
          <Suspense fallback={<Loader />}>
            <CampaignDetails />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<Loader />}>
            <NotFound />
          </Suspense>
        }
      />
    </ReactRouterRoutes>
  )
}
