import React, { useCallback, useState } from 'react'
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InlineGrid,
  InlineStack,
  Layout,
  MediaCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  Tabs,
  Text,
  Thumbnail,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'

import { getIntegrations, updateIntegration } from '../../../apis/integrations'
import queryClient from '../../../utils/query'

function IntegrationsSection() {
  const { data = [], isLoading } = useQuery({
    queryKey: ['integrations'],
    queryFn: async () => {
      const { data, error } = await getIntegrations()
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })
  const breakPoints = useBreakpoints()
  const { t } = useTranslation()
  const [activeIdx, setActiveIdx] = useState(0)
  const [collapsed, setCollapsed] = useState(true)

  const handleTabChange = useCallback((selectedTabIndex) => {
    setActiveIdx(selectedTabIndex)
  }, [])

  const TABS = [
    {
      content: t('SettingsPage.Sections.Integration.Tabs.mobile'),
      id: 'mobile',
    },
    {
      content: t('SettingsPage.Sections.Integration.Tabs.thank_you_page'),
      id: 'thank_you_page',
    },
    {
      content: t('SettingsPage.Sections.Integration.Tabs.product_reviews'),
      id: 'product_reviews',
    },
    {
      content: t('SettingsPage.Sections.Integration.Tabs.swatch'),
      id: 'swatch',
    },
    {
      content: t('SettingsPage.Sections.Integration.Tabs.page_builder'),
      id: 'page_builder',
    },
    {
      content: t('SettingsPage.Sections.Integration.Tabs.returns'),
      id: 'returns',
    },
  ]

  const activeGrp = TABS[activeIdx].id

  const cardsMarkup = data
    .filter((item) => item.group === activeGrp)
    .map((item) => (
      <ProductCard
        key={item.id}
        title={item.name}
        description={item.description}
        imgSrc={item.logo}
        isRecommended={item.isRecommended}
        onUpdateIntegration={async (data) => {
          const { error } = await updateIntegration(data)
          if (error) {
            shopify.toast.show(
              t('SettingsPage.Sections.Integration.tryAgain'),
              {
                isError: true,
              },
            )
          } else {
            await queryClient.invalidateQueries(['integrations'])
            shopify.toast.show(
              t('SettingsPage.Sections.Integration.successMessage', {
                name: item.name,
                status: data.isEnabled
                  ? t('SettingsPage.Sections.Integration.enableStatus')
                  : t('SettingsPage.Sections.Integration.disableStatus'),
              }),
            )
          }
        }}
        isEnabled={item.isEnabled}
        installedOnShop={item.installedOnShop}
        comingSoon={item.comingSoon}
        installLink={item.installLink}
        slug={item.slug}
      />
    ))

  const minCards = breakPoints.lgUp ? 3 : 2

  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Integration.title')}
      description={t('SettingsPage.Sections.Integration.description')}
    >
      <Card padding={'0'}>
        <Box padding={'0'}>
          <Tabs
            disabled={isLoading}
            tabs={TABS}
            selected={activeIdx}
            onSelect={handleTabChange}
          />
        </Box>
        <Divider />
        <Box padding={'400'}>
          <BlockStack gap={'300'} align="start">
            <Grid
              gap={'100'}
              columns={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
              }}
            >
              {isLoading &&
                Array.from({ length: minCards }).map((_, idx) => (
                  <SkeletonCard key={idx} />
                ))}
              {cardsMarkup.length > minCards
                ? cardsMarkup.slice(0, collapsed ? minCards : data.length)
                : cardsMarkup}
              {cardsMarkup.length === 0 && (
                <Grid.Cell
                  columnSpan={{
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 3,
                  }}
                >
                  <Text>
                    {t('SettingsPage.Sections.Integration.noIntegrations')}
                  </Text>
                </Grid.Cell>
              )}
            </Grid>
            {!isLoading && cardsMarkup.length > minCards && (
              <Box>
                <Button
                  size="slim"
                  variant="plain"
                  icon={collapsed ? ChevronDownIcon : ChevronUpIcon}
                  onClick={() => {
                    setCollapsed(!collapsed)
                  }}
                >
                  {t(
                    `SettingsPage.Sections.Integration.${
                      collapsed ? 'showMoreText' : 'showLessText'
                    }`,
                  )}
                </Button>
              </Box>
            )}
          </BlockStack>
        </Box>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default IntegrationsSection

function ProductCard({
  title,
  description,
  onUpdateIntegration,
  imgSrc,
  isRecommended,
  isEnabled,
  installedOnShop,
  installLink,
  comingSoon,
  slug,
}) {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  let actionButton
  
  if (installedOnShop === false) {
    actionButton = (
      <Button url={installLink} target="_blank">
        {t('SettingsPage.Sections.Integration.installBtnCta')}
      </Button>
    )
  } else if (comingSoon) {
    actionButton = (
      <Button href={installLink} target="_blank">
        Coming Soon
      </Button>
    )
  } else {
    actionButton = (
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true)
          await onUpdateIntegration({
            client: slug,
            isEnabled: !isEnabled,
            usageLimit: 5000,
          })
          setLoading(false)
        }}
      >
        {t(
          `SettingsPage.Sections.Integration.${
            !isEnabled ? 'enableBtnCta' : 'disableBtnCta'
          }`,
        )}
      </Button>
    )
  }

  return (
    <Grid.Cell>
      <div
        style={{
          borderRadius: 'var(--p-border-radius-400)',
          border: 'var(--p-border-width-025) solid var(--p-color-border)',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          position: 'relative',
        }}
      >
        <div
        >
          <Box padding={'400'} color="avatar-text-on-bg-fill">
            <InlineGrid columns={'1fr auto 1fr'}>
              <div />
              <Thumbnail source={imgSrc} size="large" />
              <div />
            </InlineGrid>
          </Box>
          <div
            style={{
              position: 'absolute',
              top: 'var(--p-space-200)',
              right: 'var(--p-space-200)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 'var(--p-space-200)',
            }}
          >
            {isRecommended && (
              <Badge size="small" tone="info">
                {t('SettingsPage.Sections.Integration.recommended')}
              </Badge>
            )}
            {comingSoon && (
              <Badge size="small" tone="magic">
                {t('SettingsPage.Sections.Integration.comingSoon')}
              </Badge>
            )}
          </div>
        </div>
        <div
          style={{
            padding: 'var(--p-space-200)',
            display: 'flex',
            flex: 1,
            gap: 'var(--p-space-200)',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <Text as="strong">{title}</Text>
            <Text as="p">{description}</Text>
          </div>
          <InlineGrid columns={'1fr auto'}>
            <div />
            <div>{actionButton}</div>
          </InlineGrid>
        </div>
      </div>
    </Grid.Cell>
  )
}

function SkeletonCard() {
  return (
    <Grid.Cell>
      <Card>
        <BlockStack gap={'400'}>
          <Box padding={'400'}>
            <InlineGrid columns={'1fr auto 1fr'}>
              <div />
              <SkeletonThumbnail size="large" />
              <div />
            </InlineGrid>
          </Box>
          <SkeletonBodyText lines={2} />
        </BlockStack>
      </Card>
    </Grid.Cell>
  )
}
