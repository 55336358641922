import { Button, DatePicker, Popover } from '@shopify/polaris'
import { CalendarTimeIcon } from '@shopify/polaris-icons'
import { useCallback, useState } from 'react'
import { format } from 'date-fns'

export default function DateTimeRangePopover({
  loading = false,
  handleDurationFilterChange,
  durationFilter,
}) {
  const [active, setActive] = useState(false)
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  return (
    <Popover
      active={active}
      activator={
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 'var(--p-space-150)',
          }}
        >
          <Button
            loading={loading}
            onClick={() => {
              setActive(true)
            }}
            icon={CalendarTimeIcon}
          >
            {format(
              new Date(durationFilter?.start ?? new Date()),
              'dd LLL, yy',
            )}
            {' - '}
            {format(
              new Date(durationFilter?.end ?? new Date()),
              'dd LLL, yy',
            )}
          </Button>
        </div>
      }
      autofocusTarget="first-node"
      onClose={() => {
        setActive(false)
      }}
    >
      <div style={{ overflow: 'visible', padding: 'var(--p-space-150)' }}>
        <DatePicker
          allowRange
          multiMonth
          selected={durationFilter}
          onChange={handleDurationFilterChange}
          month={month}
          year={year}
          onMonthChange={handleMonthChange}
          disableDatesAfter={new Date()}
        />
      </div>
    </Popover>
  )
}
