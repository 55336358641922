import { Layout } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ProductTagList from '../../../../components/ProductsList'

function ExcludedList({ form }) {
  const [products, tags] = [
    form.fields.excludedProducts.value ?? [],
    form.fields.excludedTags.value,
  ]
  const [setProducts, setTags] = [
    form.fields.excludedProducts.onChange,
    form.fields.excludedTags.onChange,
  ]
  const {t} = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.title')}
      description={t(
        'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.description',
      )}
    >
      <ProductTagList
        productSelectionTitle={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.productTitle',
        )}
        productSelectionButtonCta={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.productCta',
        )}
        productSelectionDescription={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.productDescription',
        )}
        products={products}
        onProductSelectionChange={(products) => {
          setProducts(products)
        }}
        tagsTitle={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.tagsTitle',
        )}
        tagsDescription={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.tagsDescription',
        )}
        tags={tags}
        onTagChange={(tags) => {
          setTags(tags)
        }}
        queryPlaceholder={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.queryPlaceholder',
        )}
        tagBtnCta={t(
          'CheckoutUpsell.CheckoutUpsellConfig.ExcludedList.tagsCta',
        )}
      />
    </Layout.AnnotatedSection>
  )
}

export default ExcludedList
