import { Layout } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { RecommendationConfig } from '../../Recommendation'

export default function Recommendation({ selectedTab }) {
  const { t } = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('RecommendationPage.title')}
      description={t('RecommendationPage.description')}
    >
      <RecommendationConfig selectedTab={selectedTab} />
    </Layout.AnnotatedSection>
  )
}
