import getApiClient from '.'

export async function getPlans(filter = {}) {
  const client = await getApiClient()
  try {
    const res = await client.get('/api/app/plans', {
      params: {
        promo: filter?.promo,
        plan: filter?.plan,
      },
    })
    let plans = res.data.plans.sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price),
    )
    if (filter.page) {
      plans = plans.filter((plan) => plan.pagesEnabled.includes(filter.page))
    }
    if (filter.recommendation) {
      plans = plans.filter((plan) =>
        plan.recommendationsEnabled.includes(filter.recommendation),
      )
    }
    if (filter.currentPlan) {
      plans = plans.filter(
        (plan) => parseFloat(plan.price) > parseFloat(filter.currentPlan.price),
      )
    }
    if (filter.slug) {
      plans = plans.filter((plan) => plan.slug === filter.slug)
    }
    if (filter.id) {
      plans = plans.filter((plan) => plan.id === filter.id)
    }
    if (filter.abovePlans) {
      plans = plans.filter(
        (plan) => parseFloat(plan.price) > parseFloat(filter.abovePlans.price),
      )
    }
    if (filter.plans) {
      plans = plans.filter((plan) => filter.plans.find((p) => p.id === plan.id))
    }
    return {
      error: null,
      data: plans,
    }
  } catch (e) {
    return {
      data: null,
      error: e,
    }
  }
}

export async function changePlan({ plan, promo = '', onAnnual } = {}) {
  const client = await getApiClient()
  try {
    const res = await client.post('/api/app/plans/change', {
      plan,
      promo,
      onAnnual,
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function confirmPlan(chargeId) {
  const client = await getApiClient()
  try {
    const res = await client.post('/api/app/plans/confirm', {
      chargeId,
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}
