import React from 'react'
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Form,
  InlineError,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import CodeEditor from '../../../../../components/CodeEditor'
import { areFeaturesEnabled } from '../../../../../utils/features'
import { FEATURES } from '../../../../../constants/features'
import { LockIcon } from '@shopify/polaris-icons'

function CSSSection({ form, pricing, loading }) {
  const { t } = useTranslation()
  const featureEnabled = areFeaturesEnabled(
    [FEATURES.CUSTOM_CSS],
    window.shopify.data,
  )

  const onChange = (value) => {
    if (featureEnabled) {
      form.fields.css.onChange(value)
    }
  }

  return (
    <BlockStack gap={'300'}>
      {!featureEnabled && (
        <Banner
          title={t('Features.notAvailableInPlanText', {
            feature: <strong>{t('Features.labels.custom_css')}</strong>,
            cta: (
              <Link
                onClick={() => {
                  pricing.open({
                    features: [FEATURES.CUSTOM_CSS],
                  })
                }}
              >
                {t('DefaultText.upgradeText')}
              </Link>
            ),
          })}
        />
      )}
      <Box>
        <Text as="strong">
          {t('SettingsPage.Sections.Advanced.Section.css.title')}
        </Text>
        <Text as="p">
          {t('SettingsPage.Sections.Advanced.Section.css.description')}
        </Text>
      </Box>
      {form.fields?.css?.error && (
        <InlineError message={form.fields.css.error} />
      )}
      <Box
        padding={'100'}
        borderRadius="100"
        borderWidth="0165"
        borderColor="border"
      >
        <CodeEditor
          mode={'css'}
          value={form.fields?.css?.value ?? ''}
          onChange={onChange}
          height={'500px'}
          readOnly={form.submitting || !featureEnabled || loading}
        />
      </Box>
      <InlineStack align="end">
        <Button
          icon={!featureEnabled && LockIcon}
          loading={form.submitting}
          onClick={form.submit}
        >
          {t('SettingsPage.Sections.Advanced.Section.css.btnCta')}
        </Button>
      </InlineStack>
    </BlockStack>
  )
}

export default CSSSection
