import {
  Badge,
  BlockStack,
  Button,
  Card,
  Collapsible,
  Icon,
  InlineGrid,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  RefreshIcon,
} from '@shopify/polaris-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAppState from '../../hooks/useAppState'
import styles from './styles.module.css'

function SyncCard({ initiallyCollapsed = true }) {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(initiallyCollapsed)
  const { data = {}, isLoading } = useAppState()

  const productSync = data.products?.lastProductSyncedAt
  const widgetSynced = data?.trendingGeneratedAt && data?.bestsellerGeneratedAt
  const orderSync = data.orders?.lastOrderSyncedAt

  const isDone = Boolean(productSync && orderSync && widgetSynced)

  return (
    <Card padding={'200'}>
      <div
        style={{
          cursor: 'pointer',
          padding: 'var(--p-space-200) 0',
        }}
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        <InlineGrid gap={'200'} columns={'auto auto auto 1fr auto'}>
          <Icon source={RefreshIcon} />
          {isLoading ? (
            <div
              style={{
                width: 100,
              }}
            >
              <SkeletonDisplayText />
            </div>
          ) : (
            <Text variant="headingSm">{t('SyncCard.title')}</Text>
          )}
          <Badge size="small" tone={isDone ? 'success' : 'warning'}>
            {isDone
              ? t('SyncCard.badge.complete')
              : `${t('SyncCard.badge.inProgress')}: ${getLabelSequence({ t, sequence: [productSync, orderSync, widgetSynced] })}`}
          </Badge>
          <div />
          <Button
            variant="plain"
            size="slim"
            disabled={isLoading}
            icon={collapsed ? ChevronDownIcon : ChevronUpIcon}
          />
        </InlineGrid>
        <Collapsible open={!collapsed}>
          <div
            style={{
              height: 'var(--p-space-300)',
            }}
          />
          <BlockStack gap={'100'}>
            <InlineGrid columns={'auto 1fr'} gap={'200'}>
              {!productSync ? <LoadingIcon /> : <Icon source={CheckIcon} />}
              <Text>{t('SyncCard.labels.productSync')}</Text>
            </InlineGrid>
            <InlineGrid columns={'auto 1fr'} gap={'200'}>
              {!orderSync ? <LoadingIcon /> : <Icon source={CheckIcon} />}
              <Text>{t('SyncCard.labels.orderSync')}</Text>
            </InlineGrid>
            <InlineGrid columns={'auto 1fr'} gap={'200'}>
              {!widgetSynced ? <LoadingIcon /> : <Icon source={CheckIcon} />}
              <Text>{t('SyncCard.labels.similarProductSync')}</Text>
            </InlineGrid>
          </BlockStack>
        </Collapsible>
      </div>
    </Card>
  )
}

export default SyncCard

const LoadingIcon = () => (
  <svg
    className={styles.spin}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <circle
      cx="9"
      cy="9"
      r="8.25"
      stroke="#898A8A"
      strokeWidth="1.5"
      stroke-dasharray="3 3"
    />
  </svg>
)

function getLabelSequence({ t, sequence = [] }) {
  if (!sequence[0]) return t('SyncCard.labels.productSync')
  if (!sequence[1]) return t('SyncCard.labels.orderSync')
  return t('SyncCard.labels.similarProductSync')
}
