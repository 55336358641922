import { Spinner } from '@shopify/polaris'
import React, { Suspense } from 'react'
const Editor = React.lazy(() => import('@monaco-editor/react'))

export default function CodeEditor({
  value,
  onChange,
  loading,
  height,
  mode,
  readOnly = false,
}) {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100%',
            height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner size="small" />
        </div>
      }
    >
      <Editor
        height={height}
        language={mode}
        value={value}
        options={{
          fontSize: 14,
          readOnly: readOnly,
          minimap: {
            enabled: false,
          },
        }}
        loading={loading}
        onChange={onChange}
        onMount={(editor, monaco) => {
          registerLiquid(monaco)
        }}
      />
    </Suspense>
  )
}

export const registerLiquid = (monaco) => {
  monaco.languages.register({ id: 'liquid' })

  monaco.languages.setMonarchTokensProvider('liquid', {
    tokenizer: {
      root: [
        [
          /\{\{/,
          { token: '@rematch', switchTo: '@handlebarsInSimpleState.root' },
        ],
        [/<!DOCTYPE/, 'metatag.html', '@doctype'],
        [/<!--/, 'comment.html', '@comment'],
        [/(<)(\w+)(\/>)/, ['delimiter.html', 'tag.html', 'delimiter.html']],
        [
          /(<)(script)/,
          ['delimiter.html', { token: 'tag.html', next: '@script' }],
        ],
        [
          /(<)(style)/,
          ['delimiter.html', { token: 'tag.html', next: '@style' }],
        ],
        [
          /(<)([:\w]+)/,
          ['delimiter.html', { token: 'tag.html', next: '@otherTag' }],
        ],
        [
          /(<\/)(\w+)/,
          ['delimiter.html', { token: 'tag.html', next: '@otherTag' }],
        ],
        [/</, 'delimiter.html'],
        [/\{%/, 'delimiter.html'],
        [/[^<{]+/], // text
      ],

      doctype: [
        [
          /\{\{/,
          { token: '@rematch', switchTo: '@handlebarsInSimpleState.comment' },
        ],
        [/[^>]+/, 'metatag.content.html'],
        [/>/, 'metatag.html', '@pop'],
      ],

      comment: [
        [
          /\{\{/,
          { token: '@rematch', switchTo: '@handlebarsInSimpleState.comment' },
        ],
        [/-->/, 'comment.html', '@pop'],
        [/[^-]+/, 'comment.content.html'],
        [/./, 'comment.content.html'],
      ],

      otherTag: [
        [
          /\{\{/,
          { token: '@rematch', switchTo: '@handlebarsInSimpleState.otherTag' },
        ],
        [/\/?>/, 'delimiter.html', '@pop'],
        [/"([^"]*)"/, 'attribute.value'],
        [/'([^']*)'/, 'attribute.value'],
        [/[\w\-]+/, 'attribute.name'],
        [/=/, 'delimiter'],
        [/[ \t\r\n]+/], // whitespace
      ],

      // -- BEGIN <script> tags handling

      // After <script
      script: [
        [
          /\{\{/,
          { token: '@rematch', switchTo: '@handlebarsInSimpleState.script' },
        ],
        [/type/, 'attribute.name', '@scriptAfterType'],
        [/"([^"]*)"/, 'attribute.value'],
        [/'([^']*)'/, 'attribute.value'],
        [/[\w\-]+/, 'attribute.name'],
        [/=/, 'delimiter'],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@scriptEmbedded.text/javascript',
            nextEmbedded: 'text/javascript',
          },
        ],
        [/[ \t\r\n]+/], // whitespace
        [
          /(<\/)(script\s*)(>)/,
          [
            'delimiter.html',
            'tag.html',
            { token: 'delimiter.html', next: '@pop' },
          ],
        ],
      ],

      // After <script ... type
      scriptAfterType: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInSimpleState.scriptAfterType',
          },
        ],
        [/=/, 'delimiter', '@scriptAfterTypeEquals'],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@scriptEmbedded.text/javascript',
            nextEmbedded: 'text/javascript',
          },
        ], // cover invalid e.g. <script type>
        [/[ \t\r\n]+/], // whitespace
        [/<\/script\s*>/, { token: '@rematch', next: '@pop' }],
      ],

      // After <script ... type =
      scriptAfterTypeEquals: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInSimpleState.scriptAfterTypeEquals',
          },
        ],
        [
          /"([^"]*)"/,
          { token: 'attribute.value', switchTo: '@scriptWithCustomType.$1' },
        ],
        [
          /'([^']*)'/,
          { token: 'attribute.value', switchTo: '@scriptWithCustomType.$1' },
        ],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@scriptEmbedded.text/javascript',
            nextEmbedded: 'text/javascript',
          },
        ], // cover invalid e.g. <script type=>
        [/[ \t\r\n]+/], // whitespace
        [/<\/script\s*>/, { token: '@rematch', next: '@pop' }],
      ],

      // After <script ... type = $S2
      scriptWithCustomType: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInSimpleState.scriptWithCustomType.$S2',
          },
        ],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@scriptEmbedded.$S2',
            nextEmbedded: '$S2',
          },
        ],
        [/"([^"]*)"/, 'attribute.value'],
        [/'([^']*)'/, 'attribute.value'],
        [/[\w\-]+/, 'attribute.name'],
        [/=/, 'delimiter'],
        [/[ \t\r\n]+/], // whitespace
        [/<\/script\s*>/, { token: '@rematch', next: '@pop' }],
      ],

      scriptEmbedded: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInEmbeddedState.scriptEmbedded.$S2',
            nextEmbedded: '@pop',
          },
        ],
        [
          /<\/script/,
          { token: '@rematch', next: '@pop', nextEmbedded: '@pop' },
        ],
      ],

      // -- END <script> tags handling

      // -- BEGIN <style> tags handling

      // After <style
      style: [
        [
          /\{\{/,
          { token: '@rematch', switchTo: '@handlebarsInSimpleState.style' },
        ],
        [/type/, 'attribute.name', '@styleAfterType'],
        [/"([^"]*)"/, 'attribute.value'],
        [/'([^']*)'/, 'attribute.value'],
        [/[\w\-]+/, 'attribute.name'],
        [/=/, 'delimiter'],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@styleEmbedded.text/css',
            nextEmbedded: 'text/css',
          },
        ],
        [/[ \t\r\n]+/], // whitespace
        [
          /(<\/)(style\s*)(>)/,
          [
            'delimiter.html',
            'tag.html',
            { token: 'delimiter.html', next: '@pop' },
          ],
        ],
      ],

      // After <style ... type
      styleAfterType: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInSimpleState.styleAfterType',
          },
        ],
        [/=/, 'delimiter', '@styleAfterTypeEquals'],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@styleEmbedded.text/css',
            nextEmbedded: 'text/css',
          },
        ], // cover invalid e.g. <style type>
        [/[ \t\r\n]+/], // whitespace
        [/<\/style\s*>/, { token: '@rematch', next: '@pop' }],
      ],

      // After <style ... type =
      styleAfterTypeEquals: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInSimpleState.styleAfterTypeEquals',
          },
        ],
        [
          /"([^"]*)"/,
          { token: 'attribute.value', switchTo: '@styleWithCustomType.$1' },
        ],
        [
          /'([^']*)'/,
          { token: 'attribute.value', switchTo: '@styleWithCustomType.$1' },
        ],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@styleEmbedded.text/css',
            nextEmbedded: 'text/css',
          },
        ], // cover invalid e.g. <style type=>
        [/[ \t\r\n]+/], // whitespace
        [/<\/style\s*>/, { token: '@rematch', next: '@pop' }],
      ],

      // After <style ... type = $S2
      styleWithCustomType: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInSimpleState.styleWithCustomType.$S2',
          },
        ],
        [
          />/,
          {
            token: 'delimiter.html',
            next: '@styleEmbedded.$S2',
            nextEmbedded: '$S2',
          },
        ],
        [/"([^"]*)"/, 'attribute.value'],
        [/'([^']*)'/, 'attribute.value'],
        [/[\w\-]+/, 'attribute.name'],
        [/=/, 'delimiter'],
        [/[ \t\r\n]+/], // whitespace
        [/<\/style\s*>/, { token: '@rematch', next: '@pop' }],
      ],

      styleEmbedded: [
        [
          /\{\{/,
          {
            token: '@rematch',
            switchTo: '@handlebarsInEmbeddedState.styleEmbedded.$S2',
            nextEmbedded: '@pop',
          },
        ],
        [/<\/style/, { token: '@rematch', next: '@pop', nextEmbedded: '@pop' }],
      ],

      // -- END <style> tags handling

      handlebarsInSimpleState: [
        [/\{\{?/, 'delimiter.handlebars'],
        [/\}\}?/, { token: 'delimiter.handlebars', switchTo: '@$S2.$S3' }],
        { include: 'handlebarsRoot' },
      ],

      handlebarsInEmbeddedState: [
        [/\{\{?/, 'delimiter.handlebars'],
        [
          /\}\}?/,
          {
            token: 'delimiter.handlebars',
            switchTo: '@$S2.$S3',
            nextEmbedded: '$S3',
          },
        ],
        { include: 'handlebarsRoot' },
      ],

      handlebarsRoot: [
        [/"[^"]*"/, 'string.handlebars'],
        [/[#/][^\s}]+/, 'keyword.helper.handlebars'],
        [/else\b/, 'keyword.helper.handlebars'],
        [/[\s]+/],
        [/[^}]/, 'variable.parameter.handlebars'],
      ],
    },
  })

  monaco.languages.registerCompletionItemProvider('liquid', {
    provideCompletionItems: () => {
      const keywords = [
        'assign',
        'capture',
        'endcapture',
        'increment',
        'decrement',
        'if',
        'else',
        'elsif',
        'endif',
        'for',
        'endfor',
        'break',
        'continue',
        'limit',
        'offset',
        'range',
        'reversed',
        'cols',
        'case',
        'endcase',
        'when',
        'block',
        'endblock',
        'true',
        'false',
        'in',
        'unless',
        'endunless',
        'cycle',
        'tablerow',
        'endtablerow',
        'contains',
        'startswith',
        'endswith',
        'comment',
        'endcomment',
        'raw',
        'endraw',
        'editable',
        'endentitylist',
        'endentityview',
        'endinclude',
        'endmarker',
        'entitylist',
        'entityview',
        'forloop',
        'image',
        'include',
        'marker',
        'outputcache',
        'plugin',
        'style',
        'text',
        'widget',
        'abs',
        'append',
        'at_least',
        'at_most',
        'capitalize',
        'ceil',
        'compact',
        'concat',
        'date',
        'default',
        'divided_by',
        'downcase',
        'escape',
        'escape_once',
        'first',
        'floor',
        'join',
        'last',
        'lstrip',
        'map',
        'minus',
        'modulo',
        'newline_to_br',
        'plus',
        'prepend',
        'remove',
        'remove_first',
        'replace',
        'replace_first',
        'reverse',
        'round',
        'rstrip',
        'size',
        'slice',
        'sort',
        'sort_natural',
        'split',
        'strip',
        'strip_html',
        'strip_newlines',
        'times',
        'truncate',
        'truncatewords',
        'uniq',
        'upcase',
        'url_decode',
        'url_encode',
      ]

      return {
        suggestions: keywords.map((keyword) => ({
          label: keyword,
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: keyword,
        })),
      }
    },
  })
}
