import {
  Badge,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Divider,
  InlineGrid,
  InlineStack,
  Select,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from '@shopify/react-form'
import { DesktopIcon, ExitIcon, MobileIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import humps from 'humps'
import merge from 'lodash.merge'

import { useFieldsObject, usePrepareFieldsFromSchema } from './hooks'
import InputColor from '../InputColor'
import { VisualEditorSchema } from '../../constants/visualEditor'
import {
  AMAZON_BOUGHT_TOGETHER,
  CAROUSEL,
  HORIZONTAL_GRID,
} from '../../constants/layouts'

export default function VisualEditorForm({
  storeFrameRef,
  layout,
  setLayout,
  handleSave,
  children,
  widget,
  template,
  setScreenType,
  screenType,
  isAmazonBoughtTogetherEnabled,
  setKey,
  shop,
}) {
  const { t } = useTranslation()
  const layoutCompMap = {
    [HORIZONTAL_GRID.value]: LayoutForm(HORIZONTAL_GRID.value),
    [CAROUSEL.value]: LayoutForm(CAROUSEL.value),
    [AMAZON_BOUGHT_TOGETHER.value]: LayoutForm(AMAZON_BOUGHT_TOGETHER.value),
  }
  const EditorForm = layoutCompMap[layout] ?? Fragment

  return (
    <>
      <EditorForm
        {...{
          schema: VisualEditorSchema(t),
          handleSave,
          template,
          widget,
          storeFrameRef,
          setKey,
          shop,
          topBarProps: {
            layout,
            setLayout,
            screenType,
            setScreenType,
            isAmazonBoughtTogetherEnabled,
          },
        }}
      >
        {children}
      </EditorForm>
    </>
  )
}

const LayoutForm =
  (layout) =>
  ({
    schema,
    handleSave,
    widget,
    template,
    storeFrameRef,
    setKey,
    shop,
    children,
    topBarProps,
  }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(null)
    const schemaToBerendered = schema.tabs.filter((tab) =>
      tab.layout.some((l) => l === layout),
    )
    const editorFields = schema.tabs
      .filter((tab) => tab.layout.some((l) => l === layout))
      .flatMap((tab) => {
        return tab.sections.flatMap((section) =>
          section.rows.flatMap((row) => {
            return row.fields
          }),
        )
      })

    const formFields = usePrepareFieldsFromSchema(
      useFieldsObject(
        editorFields,
        humps.camelizeKeys({ template, widget }),
        () => {
          setKey((key) => key + 1)
        },
      ),
      schema.tabs.filter((tab) => tab.layout.some((l) => l === layout)),
      layout,
    )

    const { fields, submit, submitting, submitErrors } = useForm({
      fields: {
        // since layout is not a part of the schema, we need to remove it and then pass it to the useForm otherwise it will throw an error
        ...(() => {
          const copy = { ...formFields }
          delete copy.layout
          return copy
        })(),
      },
      onSubmit: async (vals) => {
        const params = getParsedObjects(
          {
            ...fields,
            layout: {
              value: layout,
            },
          },
          widget,
        )
        const success = await handleSave(params)
        if (!success) {
          return { status: 'fail' }
        }
        setTimeout(() => {
          window.opener.postMessage('GAI-close-visual-editor-modal')
        }, 100)
        return { status: 'success' }
      },
    })

    useEffect(() => {
      const { settings, translations } = getParsedObjects(
        {
          ...fields,
          layout: {
            value: layout,
          },
        },
        widget,
      )

      if (!storeFrameRef.current || !template || !fields) {
        return
      }

      const templateSettingCopy = { ...template.settings }

      const payload = {
        widgetId: widget.id,
        params: {
          shop: shop,
        },
        layout: layout,
        title: fields.title.value,
        settings: merge(templateSettingCopy, humps.decamelizeKeys(settings)),
        translations: translations['primary_locale'],
        maxContent: parseInt(fields.maxContent.value ?? '0'),
      }

      storeFrameRef.current.contentWindow.postMessage(
        {
          type: 'rk:settings:update',
          payload,
        },
        '*',
      )
    }, [fields, layout])

    useEffect(() => {
      if (submitErrors.length > 0) {
        shopify.toast.show(submitErrors[0].message, {
          duration: 3000,
          isError: true,
        })
      }
    }, [submitErrors])

    return (
      <>
        {/* This one is for rendering the left column */}
        <TopBar
          {...topBarProps}
          saveBtnLoading={submitting}
          onSubmit={submit}
          widget={widget}
          template={template}
        />
        <InlineGrid columns={'auto 1fr'}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {activeTabIndex === null && (
              <div
                style={{
                  height: '100%',
                  borderRight: '1px solid var(--p-color-border)',
                  paddingTop: 'var(--p-space-300)',
                }}
              >
                <BlockStack>
                  {schemaToBerendered.map((setting, indx) => (
                    <MenuItem setting={setting} indx={indx} key={indx} />
                  ))}
                </BlockStack>
              </div>
            )}
            {/* This one is for rendering the right column */}
            {activeTabIndex !== null && (
              <div
                style={{
                  height: 'calc(100vh - 55px)',
                  flex: 1,
                  width: '30%',
                  borderRight: '1px solid var(--p-color-border)',
                  overflowY: 'scroll',
                }}
              >
                {schemaToBerendered.map((setting, indx) => {
                  if (indx !== activeTabIndex) {
                    return null
                  }
                  return (
                    <Tab
                      onExit={() => {
                        setActiveTabIndex(null)
                      }}
                      fields={fields}
                      setting={setting}
                      key={indx}
                    />
                  )
                })}
              </div>
            )}
          </div>
          {children}
        </InlineGrid>
      </>
    )

    function MenuItem({ setting, indx }) {
      return (
        <span
          key={indx}
          style={{
            cursor: 'pointer',
            height: '100%',
            width: '20rem',
            backgroundColor:
              activeTabIndex == indx
                ? 'var(--p-color-bg-surface-active)'
                : undefined,
            padding: '0 var(--p-space-300)',
            display: 'block',
            transition: 'background-color 0.1s ease',
          }}
          onClick={() => {
            setActiveTabIndex(indx)
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor =
              'var(--p-color-bg-surface-active)'
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor =
              activeTabIndex == indx
                ? 'var(--p-color-bg-surface-active)'
                : 'transparent'
          }}
        >
          <Box paddingBlock={'200'} paddingInline={'300'}>
            <Text as="p" fontWeight="bold">
              {setting.title}
            </Text>
          </Box>
        </span>
      )
    }
  }

function Tab({ setting, fields, onExit }) {
  return (
    <section
      style={{
        width: '20rem',
      }}
    >
      <BlockStack>
        <Box padding={'300'}>
          <InlineGrid columns={'auto 1fr'} gap={'200'}>
            <Button onClick={onExit} variant="tertiary" icon={ExitIcon} />
            <Text variant="headingSm">{setting.title}</Text>
          </InlineGrid>
        </Box>
        <Divider />
        <Box padding={'300'}>
          <BlockStack gap="400" id={setting.title.replaceAll(' ', '')}>
            {setting.sections.map((section, index) => (
              <>
                {section.rows.map((row, rowIndx) => {
                  if (row.dependency) {
                    let show = true
                    Object.keys(row.dependency).map((key) => {
                      show = show && fields[key].value === row.dependency[key]
                    })
                    if (!show) {
                      return null
                    }
                  }
                  return (
                    <Fragment key={rowIndx}>
                      {row.fields.map((field, index) => (
                        <Box key={index}>
                          {field.type == 'text' ? (
                            <TextField
                              label={field.label}
                              // onChange={() => {}}
                              {...fields[field.name]}
                              autoComplete="off"
                              helpText={field.helpText}
                              suffix={field.suffix ?? undefined}
                            />
                          ) : field.type == 'number' ? (
                            <TextField
                              label={field.label}
                              type="number"
                              //   value={value}
                              //   onChange={handleChange}
                              {...fields[field.name]}
                              min={field.min ?? undefined}
                              max={field.max ?? undefined}
                              suffix={field.suffix ?? undefined}
                              autoComplete="off"
                              helpText={field.helpText}
                            />
                          ) : field.type == 'select' ? (
                            <Select
                              label={field.label}
                              options={field.options}
                              placeholder="Select a store category"
                              {...fields[field.name]}
                              helpText={field.helpText}
                            />
                          ) : field.type == 'color' ? (
                            <InputColor
                              label={field.label}
                              {...fields[field.name]}
                            />
                          ) : null}
                        </Box>
                      ))}
                    </Fragment>
                  )
                })}
              </>
            ))}
          </BlockStack>
        </Box>
      </BlockStack>
    </section>
  )
}

function TopBar({
  layout,
  setLayout,
  screenType,
  setScreenType,
  onSubmit,
  saveBtnLoading,
  isAmazonBoughtTogetherEnabled,
  widget,
  template,
}) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        width: '100%',
        padding: 'var(--p-space-300) var(--p-space-400)',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--p-color-border)',
      }}
    >
      <InlineStack align="center" gap="400">
        <Text variant="headingLg" as="h4">
          {widget.title}
        </Text>
        <Badge tone="info" size="small">
          Template: {template.name}
        </Badge>
      </InlineStack>
      <InlineStack gap="400">
        <ButtonGroup variant="segmented">
          <Button
            pressed={layout == HORIZONTAL_GRID.value}
            onClick={() => setLayout(HORIZONTAL_GRID.value)}
          >
            {t('VisualEditorForm.cta.grid')}
          </Button>
          <Button
            pressed={layout == CAROUSEL.value}
            onClick={() => setLayout(CAROUSEL.value)}
          >
            {t('VisualEditorForm.cta.carousel')}
          </Button>
          {isAmazonBoughtTogetherEnabled && (
            <Button
              pressed={layout == AMAZON_BOUGHT_TOGETHER.value}
              onClick={() => setLayout(AMAZON_BOUGHT_TOGETHER.value)}
            >
              {t('VisualEditorForm.cta.bought_together')}
            </Button>
          )}
        </ButtonGroup>
        <ButtonGroup>
          <Tooltip content="Desktop">
            <Button
              onClick={() => setScreenType('desktop')}
              pressed={screenType == 'desktop'}
              icon={DesktopIcon}
            ></Button>
          </Tooltip>
          <Tooltip content="Mobile">
            <Button
              onClick={() => setScreenType('mobile')}
              pressed={screenType == 'mobile'}
              icon={MobileIcon}
            ></Button>
          </Tooltip>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            variant="primary"
            disabled={saveBtnLoading}
            loading={saveBtnLoading}
            onClick={onSubmit}
          >
            {t('VisualEditorForm.cta.save')}
          </Button>
        </ButtonGroup>
      </InlineStack>
    </div>
  )
}

function getParsedObjects(fields, section) {
  const parsedFields = parseFieldsObject(fields)
  const settings = {
    widgetTitleAlignment: parsedFields.widgetTitleAlignment,
    widgetTitleFontSize: `${parsedFields.widgetTitleFontSize}px`,
    widgetFontFamily: parsedFields.widgetFontFamily,
    amazonBoughtTogether: {},
    productCard: {},
    carousel: {},
    horizontalGrid: {},
    maxContent: parseInt(parsedFields.maxContent),
  }
  const translations = {
    primaryLocale: {},
  }
  if (parsedFields.layout === AMAZON_BOUGHT_TOGETHER.value) {
    settings.amazonBoughtTogether = {
      ...settings.amazonBoughtTogether,
      imageObjectFit: parsedFields.imageObjectFit,
      imageAspectRatio: parsedFields.imageAspectRatio,
    }
    settings.productCard = {}
  } else {
    settings.productCard = {
      ...settings.productCard,
      imageObjectFit: parsedFields.imageObjectFit,
      imageAspectRatio: parsedFields.imageAspectRatio,
      imageHoverMode: parsedFields.imageHoverMode,
      gutter: parseInt(parsedFields.gutter),
      gutterMobile: parseInt(parsedFields.gutterMobile),
    }
    settings.amazonBoughtTogether = {}
  }
  if (parsedFields.layout === AMAZON_BOUGHT_TOGETHER.value) {
    settings.amazonBoughtTogether = {
      ...settings.amazonBoughtTogether,
      buttonBackgroundColor: parsedFields.buttonBackgroundColor,
      buttonTextColor: parsedFields.buttonTextColor,
      totalPriceColor: parsedFields.totalPriceColor,
      productPriceColor: parsedFields.productPriceColor,
    }
    translations.primaryLocale = {
      ...translations.primaryLocale,
      thisProductText: parsedFields.thisProductText,
      totalPriceText: parsedFields.totalPriceText,
      fbtAddToCartText: parsedFields.fbtCartButtonText,
    }
  }
  if (
    parsedFields.layout === CAROUSEL.value ||
    parsedFields.layout === HORIZONTAL_GRID.value
  ) {
    settings.productCard = {
      ...settings.productCard,
      titleFontSize: `${parsedFields.titleFontSize}px`,
      titleColor: parsedFields.titleColor,
      titleAlign: parsedFields.titleAlign,
      maxTitleCharacters: parseInt(parsedFields.maxTitleCharacters),
      vendorPosition: parsedFields.vendorPosition,
      vendorColor: parsedFields.vendorColor,
      vendorFontSize: `${parsedFields.vendorFontSize}px`,
      maxTitleRows: parseInt(parsedFields.maxTitleRows),
      productTitleFontFamily: parsedFields.productTitleFontFamily,
      priceColor: parsedFields.priceColor,
      priceColorOriginal: parsedFields.priceColorOriginal,
      priceColorCompare: parsedFields.priceColorCompare,
      priceFontSize: `${parsedFields.priceFontSize}px`,
      priceCompareAtMode: parsedFields.priceCompareAtMode,
      priceFontFamily: parsedFields.priceFontFamily,
      comparePriceVisibilityMobile: parsedFields.comparePriceVisibilityMobile,
      addToCartMode: parsedFields.addToCartMode,
      addToCartBackground: parsedFields.addToCartBackground,
      addToCartColor: parsedFields.addToCartColor,
      discountLabelVisible:
        parsedFields.discountLabelVisible === 'true' ? true : false,
      discountLabelPosition: parsedFields.discountLabelPosition,
      discountLabelBackground: parsedFields.discountLabelBackground,
      discountLabelColor: parsedFields.discountLabelColor,
    }
    translations.primaryLocale = {
      ...translations.primaryLocale,
      addToCartText: parsedFields.cartButtonText,
      addedToCartText: parsedFields.addedToCartText,
      discountLabelText: parsedFields.discountLabelText,
    }
  }
  if (parsedFields.layout === CAROUSEL.value) {
    if (parsedFields.navigationMode !== 'bullet') {
      settings.carousel = {
        navigationMode: parsedFields.navigationMode,
        arrowFill: parsedFields.arrowFill,
        arrowStyle: parsedFields.arrowStyle,
        arrowBackground: parsedFields.arrowBackground,
        arrowTransition: parsedFields.arrowTransition,
        glideConfig: {
          type: parsedFields.type,
        },
      }
    } else {
      settings.carousel = {
        navigationMode: parsedFields.navigationMode,
        bulletBackground: parsedFields.bulletBackground,
        bulletActiveBackground: parsedFields.bulletActiveBackground,
        bulletSize: parseInt(parsedFields.bulletSize),
        glideConfig: {
          type: parsedFields.type,
        },
      }
    }
  }
  if (
    parsedFields.layout === HORIZONTAL_GRID.value ||
    parsedFields.layout === CAROUSEL.value
  ) {
    const temp = {
      perRowDesktop: parseInt(parsedFields.perRowDesktop),
      perRowMobile: parseInt(parsedFields.perRowMobile),
      perRowWidescreen: parseInt(parsedFields.perRowWidescreen),
    }
    if (section.layout === HORIZONTAL_GRID.value) {
      settings.horizontalGrid = {
        ...settings.horizontalGrid,
        ...temp,
      }
    } else if (section.layout === CAROUSEL.value) {
      settings.carousel = {
        ...settings.carousel,
        ...temp,
      }
    } else {
      settings.productCard = {
        ...settings.productCard,
        ...temp,
      }
    }
  }
  return {
    settings: humps.decamelizeKeys(settings),
    translations: humps.decamelizeKeys(translations),
    widgetParams: {
      title: parsedFields.title,
    },
  }
}

function parseFieldsObject(fields) {
  const parsed = {}
  Object.entries(fields).forEach(([key, value]) => {
    parsed[key] = value.value
  })
  return parsed
}
