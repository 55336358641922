import {
  ButtonGroup,
  Page,
  Button,
  VideoThumbnail,
  Card,
  Tabs,
  BlockStack,
  Divider,
  Text,
  SkeletonPage,
  SkeletonTabs,
  InlineGrid,
} from '@shopify/polaris'
import humps from 'humps'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MultiLanguageTranslations from './sections/multiLanguageTrans'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DeleteIcon } from '@shopify/polaris-icons'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { merge } from 'lodash-es'

import {
  deleteTemplate,
  getTemplateDetails,
  updateTemplate,
} from '../../../apis/template'
import CSS from './sections/css'
import Liquid from './sections/liquid'
import Javascript from './sections/javascript'
import AdvancedSettings from './sections/advancedSettings'
import queryClient from '../../../utils/query'
import { useRedirect } from '../../../hooks'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { openChat } from '../../../components/ExternalScripts'

function ViewTutorialModal() {
  return (
    <ui-modal id="glood-view-tutorial-modal">
      <div
        style={{
          padding: 'var(--p-space-200)',
        }}
      >
        <VideoThumbnail
          videoLength={80}
          thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
          onClick={() => console.log('clicked')}
        />
      </div>
      <ui-title-bar title="Select Products to exclude">
        <button
          variant="primary"
          onClick={() => {
            console.log('View documentation')
          }}
        >
          View Documentation
        </button>
        <button
          onClick={() => {
            console.log('Need Assistance')
          }}
        >
          Need Assistance
        </button>
      </ui-title-bar>
    </ui-modal>
  )
}

function toggleViewTutorialModal(showModal) {
  const modal = document.getElementById('glood-view-tutorial-modal')
  if (showModal) {
    modal.show()
  } else {
    modal.hide()
  }
}

function ConfigureTemplate() {
  const { templateId } = useParams()
  const { redirectToLink } = useRedirect()
  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const res = await getTemplateDetails(templateId)
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: ['templates', templateId],
  })
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const config = data?.template ?? {}
  const activeTabId = TABS[selected].id
  const ActiveComponent = ComponentTabMap[activeTabId]
  const pricing = usePricing()
  const { data: dashboardData, isLoading: dashboardLoading } =
    useDashboardDetails()

  const jsonEditor = useField({
    value: JSON.stringify(humps.camelizeKeys(config.settings), null, 2),
    validates: [
      notEmpty(
        `${t('Templates.Configure.AdvancedSettings.validation.emptyJson')}`,
      ),
      (value) => {
        try {
          JSON.parse(value)
          return undefined
        } catch (e) {
          return t(
            'Templates.Configure.AdvancedSettings.validation.inValidJson',
          )
        }
      },
    ],
  })

  const cssEditor = useField(config.cssTemplate)

  const jsEditor = useField(config.jsTemplate)

  const liquidEditor = useField({
    value: config.template,
    validates: [notEmpty(`${t('Templates.Configure.Liquid.validation')}`)],
  })

  const form = useForm({
    fields: { jsonEditor, cssEditor, jsEditor, liquidEditor },
    onSubmit: async (data) => {
      const settings = config.settings
      merge(settings, JSON.parse(data.jsonEditor))
      const payload = {
        settings: humps.decamelizeKeys(settings),
        cssTemplate: data.cssEditor,
        jsTemplate: data.jsEditor,
        template: data.liquidEditor,
      }
      const res = await updateTemplate(config.id, payload)
      if (res.error) {
        shopify.toast.show('Some Error Occured!', {
          isError: true,
        })
        return {
          status: 'error',
          message: res.error,
        }
      }
      await queryClient.invalidateQueries(['templates', config.id])
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      return {
        status: 'success',
      }
    },
  })

  const deleteHandler = async () => {
    if (!confirm(t('Templates.deleteConfirmation'))) {
      return
    }
    const res = await deleteTemplate(templateId)
    if (res.error?.error === 'template.errors.inUse') {
      shopify.toast.show(t('Templates.tempalteInUseError'), {
        isError: true,
      })
      return
    }
    await queryClient.invalidateQueries(['templates'])
    redirectToLink({
      url: '/settings/templates',
      external: false,
    })
  }

  useEffect(() => {
    if (form.submitErrors.length) {
      shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
      })
    }
  }, [form.submitErrors])

  if (isLoading || dashboardLoading) {
    return <Skeleton />
  }

  return (
    <>
      <Page
        fullWidth
        backAction={{ url: '/settings/templates' }}
        title={`${t('Templates.Configure.title')} ${config.name}`}
        subtitle={t('Templates.Configure.subtitle')}
        primaryAction={
          <div
            style={{
              display: 'flex',
              gap: 'var(--p-space-200)',
              alignItems: 'center',
            }}
          >
            {/* <Button
              onClick={() => {
                setViewTutorial(true)
                toggleViewTutorialModal(viewTutorial)
              }}
            >
              View Tutorial
            </Button> */}
            <Button variant="primary" onClick={openChat}>
              Need Assistance
            </Button>
          </div>
        }
        secondaryActions={[
          {
            content: t('Templates.deleteCta'),
            destructive: true,
            icon: DeleteIcon,
            onAction: deleteHandler,
          },
        ]}
      >
        <Card padding="0">
          <BlockStack>
            <Tabs tabs={TABS} selected={selected} onSelect={setSelected} />
            <Divider />
            <ActiveComponent
              dashboardData={dashboardData}
              pricing={pricing}
              form={form}
              config={config}
            />
          </BlockStack>
        </Card>
      </Page>
      <ViewTutorialModal />
      <PricingModal modal={pricing} />
    </>
  )
}

export default ConfigureTemplate

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth title={t('Templates.Configure.title')}>
      <Card>
        <SkeletonTabs count={5} />
      </Card>
    </SkeletonPage>
  )
}

const TABS = [
  {
    id: 'liquid',
    content: 'Liquid',
    editorCompatible: true,
    misc: {
      language: 'html',
      errors: 'htmlError',
      value_identifier: 'template',
    },
  },
  {
    id: 'javascript',
    content: 'Javascript',
    editorCompatible: true,
    misc: {
      language: 'javascript',
      errors: 'jsError',
      value_identifier: 'jsTemplate',
    },
  },
  {
    id: 'css',
    content: 'CSS',
    editorCompatible: true,
    misc: {
      language: 'css',
      errors: 'cssError',
      value_identifier: 'cssTemplate',
    },
  },
  {
    id: 'multi-language-translations',
    content: 'Multi Language Translations',
    editorCompatible: false,
  },
  {
    id: 'advanced-settings',
    content: 'Advanced Settings',
    editorCompatible: false,
    misc: {
      language: 'json',
      errors: '',
      value_identifier: 'settings',
    },
  },
]

const ComponentTabMap = {
  liquid: Liquid,
  javascript: Javascript,
  css: CSS,
  'multi-language-translations': MultiLanguageTranslations,
  'advanced-settings': AdvancedSettings,
}
