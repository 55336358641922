import { Card, EmptyState, Link, Page } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ErrorScreen() {
  const { t } = useTranslation()
  return (
    <Page>
      <Card>
        <EmptyState
          heading={t('ErrorScreen.heading')}
          footerContent={
            <p>
              {t('ErrorScreen.footer', {
                contact: <Link>{t('ErrorScreen.contactCta')}</Link>,
              })}
            </p>
          }
          image='https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'>
          <p>{t('ErrorScreen.description')}</p>
        </EmptyState>
      </Card>
    </Page>
  )
}

export default ErrorScreen
