import {
  useForm,
  useField,
  useChoiceField,
  notEmpty,
  useList,
  useDynamicList,
} from '@shopify/react-form'

import titleToLanguageMap from '../../../../constants/widgets/defaultTitle'

export function useSectionCreateForm({ onSubmit, currentValues }) {
  const locale = shopify.config.locale
  const name = useField({
    value: titleToLanguageMap[locale],
    validates: [notEmpty('Please enter a title')],
  })
  const experience = useField({
    value: currentValues.experience,
    validates: [notEmpty('Please select an experience')],
  })
  const page = useField({
    value: '',
    validates: [notEmpty('Please select a page')],
  })
  const recommendations = useField({
    value: null,
    validates: [notEmpty('Please select atleast one recommendation')],
  })

  return useForm({
    fields: {
      name,
      experience,
      page,
      recommendations,
    },
    onSubmit,
  })
}
