import React, { Fragment, useState } from 'react'
import {
  BlockStack,
  Button,
  Card,
  Collapsible,
  Divider,
  InlineGrid,
  Text,
  Tooltip,
  useBreakpoints,
} from '@shopify/polaris'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LockIcon,
  SettingsIcon,
} from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import * as WIDGETS from '../../../../constants/widgets'
import { FEATURES } from '../../../../constants/features'
import { areFeaturesEnabled } from '../../../../utils/features'

function Configure({ pricing, backActionUrl }) {
  const { t } = useTranslation()
  return (
    <BlockStack gap={'200'}>
      {WIDGET_TYPES.map((widget) => (
        <RecommendationCard
          backActionUrl={backActionUrl}
          key={widget.value}
          title={t(`Widgets.${widget.value}.label`)}
          description={t(`Widgets.${widget.value}.description`)}
          icon={widget.icon}
          widgetType={widget.value}
          pricing={pricing}
        />
      ))}
    </BlockStack>
  )
}

export default Configure

function RecommendationCard({
  title,
  description,
  icon,
  widgetType,
  pricing,
  backActionUrl,
}) {
  const [collapsed, setCollapsed] = useState(true)
  const breakpoints = useBreakpoints()
  return (
    <Card padding={'0'}>
      <BlockStack>
        <div
          onClick={() => {
            setCollapsed(!collapsed)
          }}
          style={{
            width: '100%',
            padding: 'var(--p-space-400)',
            cursor: 'pointer',
          }}
        >
          <InlineGrid columns={'1fr auto'}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <img src={icon} width={40} height={40} alt="icon" />
              <div
                style={{
                  display: 'flex',
                  flex: '1 1 0%',
                  paddingLeft: 'var(--p-space-200)',
                }}
              >
                <BlockStack>
                  <Text as="h6" fontWeight="semibold">
                    {title}
                  </Text>
                  <Text as="p">{description}</Text>
                </BlockStack>
              </div>
            </div>
            <Button
              variant="plain"
              icon={collapsed ? ChevronDownIcon : ChevronUpIcon}
            />
          </InlineGrid>
        </div>
        <Divider />
        <Collapsible open={!collapsed}>
          <div
            style={{
              width: '100%',
              padding: 'var(--p-space-400)',
            }}
          >
            <InlineGrid gap={'400'} columns={breakpoints.mdUp ? 3 : 1}>
              {ALL_SETTINGS.sort((a, b) => {
                const isFirstEnabled =
                  WidgetToSettingMap[widgetType].indexOf(a) !== -1
                const isSecondEnabled =
                  WidgetToSettingMap[widgetType].indexOf(b) !== -1
                if (isFirstEnabled && !isSecondEnabled) {
                  return -1
                }
                if (!isFirstEnabled && isSecondEnabled) {
                  return 1
                }
                return 0
              }).map((setting) => (
                <RecommendationSettingCard
                  disabled={
                    WidgetToSettingMap[widgetType].indexOf(setting) === -1
                  }
                  backActionUrl={backActionUrl}
                  type={setting}
                  widgetType={widgetType}
                  pricing={pricing}
                />
              ))}
            </InlineGrid>
          </div>
        </Collapsible>
      </BlockStack>
    </Card>
  )
}

function RecommendationSettingCard({
  disabled,
  type,
  widgetType,
  pricing,
  backActionUrl,
}) {
  const { t } = useTranslation()
  const featureEnabled =
    type !== 'ruleBasedRecommendation' ||
    areFeaturesEnabled([FEATURES.RECOMMENDATION_RULES], window.shopify.data)
  const Wrapper = disabled || !featureEnabled ? Tooltip : Fragment

  const title = t(`Recommendation.${type}.title`)
  const description = t(`Recommendation.${type}.description`)
  const url = `/settings/recommendation/${widgetType}/${SettingToUrlSlug[type]}`
  const btnText = t(`Recommendation.btnText`)

  return (
    <div
      style={{
        padding: 'var(--p-space-400)',
        borderRadius: 'var(--p-border-radius-400)',
        border: 'var(--p-border-width-025) solid var(--p-color-border)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 'var(--p-space-200)',
          flex: 1,
        }}
      >
        <Text as="strong">{title}</Text>
        <Text>{description}</Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Wrapper
          {...(disabled || !featureEnabled
            ? {
                content:
                  (disabled && t('DefaultText.settingNotAvailable')) ||
                  (!featureEnabled &&
                    t('Features.notAvailableInPlanText', {
                      feature: t('Features.labels.recommednation_rules'),
                      cta: '',
                    })),
              }
            : {})}
        >
          <Button
            loading={pricing.isLoading && !featureEnabled}
            url={featureEnabled && `${url}${backActionUrl ? `?back=${backActionUrl}` : ''}`}
            disabled={disabled}
            icon={featureEnabled ? SettingsIcon : LockIcon}
            onClick={() => {
              if (featureEnabled) {
                return
              }
              pricing.open({
                features: [FEATURES.RECOMMENDATION_RULES],
              })
            }}
          >
            {btnText}
          </Button>
        </Wrapper>
      </div>
    </div>
  )
}

const WIDGET_TYPES = [
  WIDGETS.SIMILAR_PRODUCTS,
  WIDGETS.BOUGHT_TOGETHER,
  WIDGETS.CROSS_SELL,
  WIDGETS.TRENDING,
  WIDGETS.BESTSELLERS,
]

const WidgetToSettingMap = {
  [WIDGETS.SIMILAR_PRODUCTS.value]: [
    'manualRecommendation',
    'globalRecommendation',
  ],
  [WIDGETS.BOUGHT_TOGETHER.value]: [
    'manualRecommendation',
    'ruleBasedRecommendation',
    'globalRecommendation',
  ],
  [WIDGETS.CROSS_SELL.value]: [
    'manualRecommendation',
    'ruleBasedRecommendation',
    'globalRecommendation',
  ],
  [WIDGETS.TRENDING.value]: ['globalRecommendation'],
  [WIDGETS.BESTSELLERS.value]: ['globalRecommendation'],
}

const SettingToUrlSlug = {
  manualRecommendation: 'product',
  ruleBasedRecommendation: 'rule',
  globalRecommendation: 'global',
}

const ALL_SETTINGS = [
  'manualRecommendation',
  'ruleBasedRecommendation',
  'globalRecommendation',
]
