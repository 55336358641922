import humps from 'humps'
import getApiClient from '.'

export async function getTemplates() {
  try {
    const client = await getApiClient()
    const res = await client.get('/api/setup/template')
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function getTemplateDetails(templateId,token) {
  try {
    const client = await getApiClient(token)
    const res = await client.get(`/api/setup/template/${templateId}`)
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function updateTemplate(templateId, data, token=null) {
  try {
    const client = await getApiClient(token)
    const res = await client.post(`/api/setup/template/${templateId}`, data)
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function deleteTemplate(templateId) {
  try {
    const client = await getApiClient()
    const res = await client.delete(`/api/setup/template/${templateId}`)
    return {
      data: humps.camelizeKeys(res.data),
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function createTemplate(data) {
  try {
    const client = await getApiClient()
    const res = await client.post(`/api/setup/template`, data)
    return {
      data: res.data,
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}
