import {
  Badge,
  BlockStack,
  Box,
  Card,
  Divider,
  InlineStack,
  List,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export default function Components({ form }) {
  const { t } = useTranslation()
  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <BlockStack gap={'100'}>
          <Text variant="headingSm">
            {t('BundlesUpsert.Heading.components')}
          </Text>
          <Text>{t('BundlesUpsert.Description.components')}</Text>
          <List>
            <List.Item>{t('BundlesUpsert.Text.bundledProducts',{
              products: form.fields.products.value.length,
            })}</List.Item>
            <List.Item>
              {t('BundlesUpsert.Text.options', {
                options: form.fields.options.value.length,
              })}
            </List.Item>
            <List.Item>
              {t('BundlesUpsert.Text.variants', {
                variants: form.fields.bundleVariants.value.length,
              })}
            </List.Item>
          </List>
        </BlockStack>
      </Box>
      <Divider />
      <Box padding={'400'}>
        <BlockStack gap={'300'}>
              <BlockStack gap={'100'}>
              <Text variant="headingSm">{t('BundlesUpsert.Heading.options')}</Text>
              <Text>{t('BundlesUpsert.Description.options')}</Text>
              </BlockStack>
          <BlockStack gap={'300'}>
            {form.fields.products.value.map((product) => {
              const title = product.title,
                variants = product.variants
              const productOption = form.fields.options.value.find(
                (option) =>
                  option.product ===
                  parseInt(product.id.replace('gid://shopify/Product/', '')),
              )
              const list = getSelectedOptionsList(
                variants,
                productOption?.options ?? [],
              )

              return (
                <BlockStack gap={'200'}>
                  <Text variant='headingSm'>{title}</Text>
                  <InlineStack gap={'200'}>
                    {list.map((item, index) => (
                      <Badge key={index}>{item}</Badge>
                    ))}
                  </InlineStack>
                </BlockStack>
              )
            })}
          </BlockStack>
        </BlockStack>
      </Box>
    </Card>
  )
}

function getSelectedOptionsList(variants, options) {
  const optionValues = options.map((option) => option.values).flat()
  const optionsValueReverseMapping = options.reduce((acc, option) => {
    option.values.forEach((value) => {
      acc[value] = option.name
    })
    return acc
  }, {})

  const selectedVariants = variants.filter((variant) => {
    const vOptions = variant.selectedOptions.map((option) => option.value)
    return vOptions.every((value) => optionValues.includes(value))
  })

  return selectedVariants.map((variant) => {
    const selectedVals = variant.selectedOptions.map((option) => option.value)
    return selectedVals
      .map((value) => `${optionsValueReverseMapping[value]}: ${value}`)
      .join(', ')
  })
}
