import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  Badge,
  BlockStack,
  Box,
  Card,
  DataTable,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris'
import { useQuery } from 'react-query'
import { format, parse } from 'date-fns'
import numeral from 'numeral'

import CustomModal from '../CustomModal'
import { getBillingDetails } from '../../apis/billing'

function BillingModal({ open, setOpen }) {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const res = await getBillingDetails()
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: 'billingDetails',
  })

  const rows = (data ?? [])?.map((item) => [
    format(new Date(item.startedOn), 'dd LLL, yyyy'),
    format(new Date(item.endedOn ?? item.endingOn), 'dd LLL, yyyy'),
    <BlockStack gap={'100'}>
      <Text>
        <strong>{t('BillingModal.monthlyCharge')}: </strong>$
        {item.shopPlan.price}
      </Text>
      <Text>
        <strong>{t('BillingModal.usageCharge')}: </strong>${item.billingPrice}
      </Text>
      <Text>
        <strong>{t('BillingModal.total')}: </strong>$
        {numeral(
          parseFloat(item.shopPlan.price) + parseFloat(item.billingPrice),
        ).format('0.00')}
      </Text>
      <Text>
        <strong>{t('BillingModal.totalSectionServed')}:</strong>{' '}
        {numeral(item.currentUsage).format('0,0')}
      </Text>
    </BlockStack>,
    item.endedOn ? (
      <Badge tone="warning" size="medium">
        {item.isTrialPeriod
          ? t('BillingModal.trial')
          : t('BillingModal.charged')}
      </Badge>
    ) : (
      <Badge tone="success" size="medium">
        {item.isTrialPeriod
          ? t('BillingModal.trial')
          : t('BillingModal.pending')}
      </Badge>
    ),
  ])

  return (
    <CustomModal
      show={open}
      setShow={setOpen}
      title={t('BillingModal.title')}
      variant="large"
      overlayZIndex={9999}
    >
      <Box background='bg-fill' padding={'0'}>
        <Table
          headings={[
            t('BillingModal.table.heading1'),
            t('BillingModal.table.heading2'),
            t('BillingModal.table.heading3'),
            t('BillingModal.table.heading4'),
          ].map((heading) => (
            <strong>{heading}</strong>
          ))}
          rows={
            isLoading
              ? Array.from({
                  length: 3,
                }).map((_, index) => [
                  <SkeletonDisplayText size="small" />,
                  <SkeletonDisplayText size="small" />,
                  <SkeletonDisplayText size="small" />,
                  <SkeletonDisplayText size="small" />,
                ])
              : rows
          }
        />
        {/* <DataTable
          stickyHeader
          columnContentTypes={[
            'text',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
          ]}
          headings={}

        /> */}
      </Box>
    </CustomModal>
  )
}

export default BillingModal

const Table = ({ headings, rows }) => {
  return (
    <div style={{ overflowY: 'auto', maxHeight: '80vh' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead
          style={{
            backgroundColor: '#f9f9f9',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <tr>
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{ padding: '8px', border: '1px solid #ddd' }}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{ padding: '8px', border: '1px solid #ddd' }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
