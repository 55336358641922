import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  InlineGrid,
  Layout,
  Popover,
  Scrollable,
  Text,
} from '@shopify/polaris'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutClick from 'react-outclick'

import { CHECKOUT_UPSELL_SECTION_POSTIONS } from '../../../../constants/checkoutUpsell'
import { useRedirect } from '../../../../hooks'
import { openChat } from '../../../../components/ExternalScripts'

function Placement({ form }) {
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()

  return (
    <Layout.AnnotatedSection
      description={t(
        'CheckoutUpsell.CheckoutUpsellConfig.Placement.description',
      )}
      title={t('CheckoutUpsell.CheckoutUpsellConfig.Placement.title')}
    >
      <Card padding={'0'}>
        <Box padding={'400'}>
          <InlineGrid columns={'1fr auto auto'} gap={'200'} alignItems="start">
            <BlockStack gap={'100'}>
              <Text as="strong">
                {t('CheckoutUpsell.CheckoutUpsellConfig.Placement.card.title')}
              </Text>
              <Text>
                {t(
                  'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.description',
                )}
              </Text>
            </BlockStack>
            <div>
              <Button
                onClick={() => {
                  redirectToLink({
                    url: `https://admin.shopify.com/store/${shopify.config.shop.replace('.myshopify.com', '')}/settings/checkout/editor`,
                    external: true,
                  })
                }}
              >
                {t('CheckoutUpsell.CheckoutUpsellConfig.Placement.card.cta')}
              </Button>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  document.getElementById('ins-modal').show()
                }}
              >
                {t(
                  'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.instructions',
                )}
              </Button>
            </div>
          </InlineGrid>
        </Box>
      </Card>
      <ui-modal id="ins-modal" variant="large">
        <div
          style={{
            padding: 'var(--p-space-400)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Instructions video will be uploaded soon here. For now, You can
          contact our support team for any help.
        </div>
        <ui-title-bar
          title={t(
            'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.insModalTitle',
          )}
        ></ui-title-bar>
      </ui-modal>
    </Layout.AnnotatedSection>
  )
}

export default Placement
