import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  DataTable,
  ExceptionList,
  Form,
  FormLayout,
  InlineError,
  InlineStack,
  Layout,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextField,
  Tooltip,
  useBreakpoints,
} from '@shopify/polaris'
import { InfoIcon } from '@shopify/polaris-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Pages from '../../../constants/page'
import * as WidgetsList from '../../../constants/widgets'
import { useRedirect } from '../../../hooks'
import { useSectionCreateForm } from './hooks'
import { createSection } from '../../../apis/section'
import PageSelector from '../../../components/PageSelector'
import { getQueryParams } from '../../../utils/router'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { createPage } from '../../../apis/page'
import { useShopDetails } from '../../../hooks/useShopDetails'
import { PricingModal, usePricing } from '../../../components/PricingModal'

import './style.css'

export default function SectionCreate() {
  const { data: { shopPlan = {} } = {}, isLoading: dashboardDetailsLoading } =
    useDashboardDetails()
  const breakpoints = useBreakpoints()
  const { recommendationsEnabled = [], pagesEnabled = [] } = shopPlan
  const { data: shopDetails, isLoading: shopDetailsLoading } = useShopDetails()
  const [experiences, shopPages, shopSections] = [
    shopDetails?.experiences ?? [],
    shopDetails?.pages ?? [],
    shopDetails?.sections ?? [],
  ]
  const { experienceId = null } = getQueryParams()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { redirectToLink } = useRedirect()
  const pricing = usePricing()

  const form = useSectionCreateForm({
    onSubmit: async (data) => {
      setLoading(true)
      let page = shopPages.find((page) => page.type === data.page)
      if (!page) {
        const { data: createdPageResponse, error } = await createPage({
          type: data.page,
        })
        if (error) {
          shopify.toast.show(t('Section.Create.sectionCreateErrorText'), {
            isError: true,
          })
          return
        }
        page = createdPageResponse
      }
      const res = await createSection({
        page: page.id,
        type: data.recommendations,
        title: data.name,
        experience: parseInt(data.experience),
      })
      setLoading(false)
      if (res.error) {
        console.log('error while creating widget', res.error)
        shopify.toast.show(t('Section.Create.sectionCreateErrorText'), {
          isError: true,
        })
        return {
          status: 'error',
          errors: [
            {
              message: res.error,
            },
          ],
        }
      } else {
        shopify.toast.show(t('Section.Create.sectionCreateSuccessText'))
        redirectToLink({
          external: false,
          url: `/sections/${res.data.section.id}/edit?showSave=1&backUrl=/sections`,
        })
        return {
          status: 'success',
        }
      }
    },
    currentValues: {
      experience: (() => {
        if (experiences.length === 0) return ''
        let parsedId = null
        try {
          parsedId = parseInt(experienceId)
        } catch (e) {}
        const experience = experiences.find(({ id }) => id === parsedId)?.id
        if (experience) return parsedId
        const defaultExperience = experiences.find(({ isDefault }) =>
          Boolean(isDefault),
        )
        if (defaultExperience) return defaultExperience.id
        return experiences[0].id
      })(),
    },
  })

  const widgetTypes = Object.values(WidgetsList)
    .filter((w) => Boolean(w.value))
    .sort((a, b) => {
      const isFirstAvailable =
        a.allowedOnPages?.find(
          ({ value }) => value === form.fields.page.value,
        ) && recommendationsEnabled.find((value) => value === a.value)
      const isSecondAvailable =
        b.allowedOnPages?.find(
          ({ value }) => value === form.fields.page.value,
        ) && recommendationsEnabled.find((value) => value === a.value)
      if (isFirstAvailable && isSecondAvailable) return a.score - b.score
      if (isFirstAvailable) return -10
      if (isSecondAvailable) return 10
      return -20
    })
    .map((widget) => ({
      value: widget.value,
      title: widget.label,
      subtitle: widget.description,
      icon: widget.icon,
    }))

  const shopifyPageList = Object.values(Pages)
    .filter(({ value }) => Boolean(value))
    .sort((a, b) => a.score - b.score)
    .map((page) => ({
      value: page.value,
      label: page.label,
      upgradeRequired: !pagesEnabled.includes(page.value),
      shopifyPlusOnly: page?.shopifyPlusOnly ?? false,
      deprecated: page.deprecated,
    }))

  const shopExperienceList =
    experiences.map(({ name, id }) => ({
      label: name,
      value: id.toString(),
    })) ?? []

  if (dashboardDetailsLoading || shopDetailsLoading) {
    return <SkeletonComponent />
  }

  return (
    <>
      <Page
        fullWidth
        backAction={{
          url: '/sections',
        }}
        title={t('Section.Create.title')}
      >
        <Box padding={breakpoints.smUp ? '0' : '400'}>
          <Layout>
            <Layout.AnnotatedSection
              variant="oneThird"
              title={t('Section.Create.Details.title')}
              description={t('Section.Create.Details.description')}
            >
              <Card>
                <Form onSubmit={form.submit}>
                  <FormLayout>
                    <FormLayout>
                      <TextField
                        autoComplete="text"
                        type="text"
                        {...form.fields.name}
                        label={t('Section.Create.Form.Name.title')}
                        placeholder={t('Section.Create.Form.Name.placeholder')}
                        helpText={t('Section.Create.Form.Name.helpText')}
                      />
                    </FormLayout>
                    <FormLayout.Group>
                      <Select
                        {...form.fields.experience}
                        value={form.fields.experience.value.toString()}
                        options={shopExperienceList}
                        label={t('Section.Create.Form.Experience.title')}
                      />
                      <PageSelector
                        options={shopifyPageList}
                        label={t('Section.Create.Form.Page.title')}
                        placeholder={t('Section.Create.Form.Page.placeholder')}
                        value={form.fields.page.value}
                        onChange={form.fields.page.onChange}
                        error={form.fields.page.allErrors?.[0]}
                        shopPlan={shopPlan}
                      />
                    </FormLayout.Group>
                    <FormLayout>
                      <Card padding={'0'}>
                        <DataTable
                          footerContent={
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'start',
                              }}
                            >
                              <Button
                                onClick={() => setExpanded(!expanded)}
                                variant="plain"
                                disclosure={expanded ? 'up' : 'down'}
                              >
                                {expanded ? 'Show Less' : 'Show More'}
                              </Button>
                            </div>
                          }
                          columnContentTypes={[
                            'text',
                            'numeric',
                            'numeric',
                            'numeric',
                            'numeric',
                          ]}
                          headings={[
                            t('Section.Create.Form.Recommendation.title'),
                          ]}
                          rows={widgetTypes
                            .slice(0, expanded ? undefined : 3)
                            .map((recommendation) => {
                              const widget = Object.values(WidgetsList).find(
                                ({ value }) => value === recommendation.value,
                              )
                              const canCreateOnSelectedPage =
                                canCreateMoreWidgets(
                                  widget.value,
                                  form.fields.page.value,
                                  shopSections.map((section) => ({
                                    ...section,
                                    page: shopPages.find(
                                      (page) => page.id === section.page,
                                    ).type,
                                  })),
                                )
                              const isAvailableOnCurrentPage =
                                widget.allowedOnPages.find(
                                  ({ value }) =>
                                    value === form.fields.page.value,
                                )
                              const isRecommended =
                                widget.recommendedPages.find(
                                  ({ value }) =>
                                    value === form.fields.page.value,
                                )
                              const { value, icon } = recommendation
                              const isPageDeprecated = shopifyPageList.find(
                                (page) => page.value === form.fields.page.value,
                              )?.deprecated
                              let toolTipText = ''
                              const recommendationEnabled =
                                recommendationsEnabled.find(
                                  (value) => value === recommendation.value,
                                )
                              if (!form.fields.page.value) {
                                toolTipText = t(
                                  'Widgets.ToolTip.selectPageText',
                                )
                              } else if (!recommendationEnabled) {
                                toolTipText = t(
                                  'Widgets.ToolTip.widgetNotEnabled',
                                )
                              } else if (!isAvailableOnCurrentPage) {
                                toolTipText = t(
                                  'Widgets.ToolTip.notAvailableOnPageText',
                                )
                              } else if (!canCreateOnSelectedPage) {
                                toolTipText = t(
                                  'Section.Create.maximumWidgetLimitReached',
                                )
                              } else if (
                                isRecommended &&
                                widget.recommendedPageDescriptions[
                                  form.fields.page.value
                                ]
                              ) {
                                toolTipText = t(
                                  `Widgets.${value}.page.${form.fields.page.value}`,
                                )
                              } else {
                                toolTipText = t(`Widgets.${value}.description`)
                              }
                              return [
                                <RecommendationTypeCard
                                  toolTipText={toolTipText}
                                  disabled={
                                    !isAvailableOnCurrentPage ||
                                    !recommendationEnabled ||
                                    !canCreateOnSelectedPage ||
                                    isPageDeprecated
                                  }
                                  upgradeHandler={() =>
                                    pricing.open({
                                      recommendation: recommendation.value,
                                      currentPlan: shopPlan,
                                    })
                                  }
                                  upgradeRequired={!recommendationEnabled}
                                  isRecommended={isRecommended}
                                  key={recommendation.value}
                                  value={value}
                                  icon={icon}
                                  title={t(`Widgets.${value}.label`)}
                                  subtitle={t(`Widgets.${value}.description`)}
                                  checked={
                                    form.fields.recommendations.value ===
                                    recommendation.value
                                  }
                                  onClick={(val) => {
                                    const setValue =
                                      form.fields.recommendations.onChange
                                    setValue(recommendation.value)
                                  }}
                                />,
                              ]
                            })}
                        />
                      </Card>
                    </FormLayout>
                    {form.fields.recommendations.allErrors.length > 0 && (
                      <InlineError
                        message={form.fields.recommendations.allErrors[0]}
                      />
                    )}
                    <FormLayout>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: 'var(--p-space-400)',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'var(--p-space-200)',
                          }}
                        >
                          <ExceptionList
                            items={[
                              {
                                icon: InfoIcon,
                                description: t('Section.Create.Form.infoText'),
                              },
                            ]}
                          />
                        </div>
                        <Button loading={loading} variant="primary" submit>
                          {t('Section.Create.Form.buttonText')}
                        </Button>
                      </div>
                    </FormLayout>
                  </FormLayout>
                </Form>
              </Card>
              <div
                style={{
                  width: '100%',
                  height: 'var(--p-space-800)',
                }}
              />
            </Layout.AnnotatedSection>
          </Layout>
        </Box>
      </Page>
      <PricingModal modal={pricing} />
    </>
  )
}

const RecommendationTypeCard = ({
  title,
  subtitle,
  checked,
  onClick,
  icon,
  disabled = false,
  inBeta = false,
  isRecommended = false,
  upgradeRequired = false,
  toolTipText,
  upgradeHandler,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Tooltip width="wide" content={toolTipText} preferredPosition="mostSpace">
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            opacity: disabled ? 0.75 : 1,
            width: '100%',
            position: 'relative',
          }}
        >
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onClick}
            label={
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <img src={icon} width={32} height={32} alt="icon" />
                  <div
                    style={{
                      display: 'flex',
                      flex: '1 1 0%',
                      paddingLeft: 'var(--p-space-200)',
                    }}
                  >
                    <BlockStack>
                      <InlineStack gap={'200'}>
                        <Text as="h6" fontWeight="semibold">
                          {title}
                        </Text>
                        {inBeta && <Badge tone="warning">Beta</Badge>}
                        {isRecommended && (
                          <Badge tone="attention">Recommended</Badge>
                        )}
                      </InlineStack>
                      <Text as="p">{subtitle}</Text>
                    </BlockStack>
                  </div>
                </div>
              </>
            }
          />
          {upgradeRequired && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                size="large"
                onClick={async () => {
                  setLoading(true)
                  await upgradeHandler()
                  setLoading(false)
                }}
              >
                Upgrade
              </Button>
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  )
}

const SkeletonComponent = () => {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth title={t('Section.Create.title')}>
      <Layout>
        <Layout.Section variant="oneThird">
          <BlockStack gap={'300'}>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <BlockStack gap={'300'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

function canCreateMoreWidgets(widgetType, pageType, widgets) {
  if (!pageType || !widgetType) return false

  let widgetList = widgets.filter(
    (widget) => widget.type === widgetType && widget.page === pageType,
  )

  const WIDGET = Object.values(WidgetsList)
    .filter((w) => Boolean(w?.value))
    .find(({ value }) => value === widgetType)

  const maxAllowed = WIDGET.maxAllowed[pageType] ?? WIDGET.maxAllowed.default

  return widgetList.length < maxAllowed
}
