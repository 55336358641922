import { useAppBridge } from '@shopify/app-bridge-react'
import {
  Page,
  BlockStack,
  Box,
  InlineGrid,
  Card,
  Text,
  useBreakpoints,
  Bleed,
  Icon,
  Divider,
  Collapsible,
  Link,
  List,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  Button,
  Banner,
  CalloutCard,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'
import { Fragment, useEffect, useState } from 'react'

import PerformanceSection from './sections/perfomance'
import ProductTour from '../../components/ProductTour/index.jsx'
import FeedbackModal from '../../components/FeedbackForm/index.jsx'
import AppNotEnabledBanner from '../../components/AppNotEnabledBanner/index.jsx'
import OnboardingSteps from '../../components/OnboardingSteps/index.jsx'
import { useDashboardDetails } from '../../hooks/useDashboardDetails.js'
import { useRedirect } from '../../hooks/useRedirect.js'
import UsageSurpassedBanner from '../../components/UsageSurpassedBanner/index.jsx'
import {
  PricingModal,
  usePricing,
} from '../../components/PricingModal/index.js'
import { HubspotClient } from '../../models/hubspot.js'
import { openChat } from '../../components/ExternalScripts/index.jsx'
import { useAppEmbed } from '../../hooks/useAppEmbed.js'
import SyncCard from '../../components/SyncCard'
import { areFeaturesEnabled } from '../../utils/features.js'
import { FEATURES } from '../../constants/features.js'

const SURPASS_THRESHOLD = 0.8
const TOTAL_ONBOARDINGSTEPS = 6

const HSClient = new HubspotClient({
  portalId: '22080564',
  formId: '1dfd6ed0-f905-42f3-95ba-be1121e0cbe5',
})

export default function HomePage() {
  const pricing = usePricing()
  const { t } = useTranslation()
  const { data: dashboardData, isLoading: dashboardLoading } =
    useDashboardDetails()
  const [showTutModal, setTutModal] = useState(false)

  const { redirectToLink } = useRedirect()
  const breakpoints = useBreakpoints()
  const quickLinks = getQuickLinks({ t })

  const bannerData = {
    usageSurpassed:
      dashboardData?.billingCycle?.currentUsage >=
      dashboardData?.billingCycle?.maxWidgetServeHardLimit,
    usageAboutToSurpass:
      dashboardData?.billingCycle?.currentUsage /
        dashboardData?.billingCycle?.maxWidgetServeHardLimit >
        SURPASS_THRESHOLD &&
      dashboardData?.billingCycle?.currentUsage <
        dashboardData?.billingCycle?.maxWidgetServeHardLimit,
  }
  const { appEmbedUrl, isAppEmbedded, isLoading: appLoading } = useAppEmbed()
  const [showOnboardingStepsBanner, setShowOnboardingStepsBanner] = useState(
    window.shopify.data.onboarding.openOnboardingStepsBanner,
  )
  const appEnableTutCompleted =
    dashboardData?.config?.onboarding?.appEnableTutCompleted ?? false
  const onboardingSteps =
    dashboardData?.config?.onboarding?.onboardingSteps ?? {}

  useEffect(() => {
    if (dashboardData) {
      if (
        !Boolean(dashboardData.config.onboarding.completed) &&
        !Boolean(dashboardData.config.onboarding.hideOnboardingStepsBanner)
      ) {
        setShowOnboardingStepsBanner(true)
      }
      if (
        !dashboardData.config.onboarding.completed &&
        !dashboardData.config.onboarding.initInfoDone
      ) {
        redirectToLink({
          url: '/onboarding',
          external: false,
        })
      }
    }
  }, [dashboardData])

  const Wrapper = ({ children }) => {
    return (
      <section
        style={{
          width: '100%',
          height: '100vh',
          display: 'grid',
          gridTemplateColumns: breakpoints.lgUp ? '1fr 300px' : '1fr',
          overflowX: 'hidden',
        }}
      >
        <Bleed marginInlineStart="0">{children}</Bleed>
        {breakpoints.lgUp && (
          <>
            <Bleed marginInlineEnd={'0'}>
              <div>
                <QuickLinks quickLinks={quickLinks} />
              </div>
            </Bleed>
          </>
        )}
      </section>
    )
  }

  const numberOfCreatedSections =
    dashboardData?.setup?.createdSectionsCount ?? 0

  return (
    <Wrapper>
      <Page
        fullWidth
        title={showOnboardingStepsBanner ? null : t('DashboardPage.title')}
        subtitle={
          showOnboardingStepsBanner || appEnableTutCompleted
            ? null
            : t('DashboardPage.subtitle')
        }
      >
        {/* Point to be remembered: all keys must be same as that of locale file */}
        {/* All props must follow this format otherwise perfomance section won't work */}
        <div
          style={{
            paddingLeft: breakpoints.smDown ? 'var(--p-space-400)' : null,
            paddingRight: breakpoints.smDown ? 'var(--p-space-400)' : null,
          }}
        >
          <BlockStack gap={'400'}>
            <CalloutCard
              title={t('Welcome.title')}
              illustration="/assets/glood.svg"
              primaryAction={{
                content: t('Welcome.cta'),
                onAction: window.openFeedbackModal,
              }}
            >
              <p>{t('Welcome.description')}</p>
            </CalloutCard>
            <BlockStack>
              <UsageSurpassedBanner
                dashboardData={dashboardData}
                show={!dashboardLoading && bannerData.usageSurpassed}
                onUpgrade={() => {
                  redirectToLink({
                    url: '/pricing',
                    external: false,
                  })
                }}
                cta={t('SettingsPage.Sections.Account.viewPricing')}
              />
              {!dashboardLoading && bannerData.usageAboutToSurpass && (
                <Banner tone="warning">
                  <p>
                    {t('SettingsPage.Sections.Account.usageAboutToSurpass', {
                      cta:
                        dashboardData?.shopPlan?.onAnnual ||
                        dashboardData?.shopPlan?.isExtendedUsageEnabled ? (
                          <Link url="/pricing">
                            {' '}
                            {t('SettingsPage.Sections.Account.upgradeBtnCta')}
                          </Link>
                        ) : (
                          <Link>{t('contactUs')}</Link>
                        ),
                    })}
                  </p>
                </Banner>
              )}
            </BlockStack>
            {showOnboardingStepsBanner && !dashboardLoading && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <OnboardingSteps
                  props={{
                    setFeedbackModalOpen: window.openFeedbackModal,
                    onboarding: {
                      ...(dashboardData?.config?.onboarding ?? {}),
                      onboardingSteps: {
                        ...(dashboardData?.config?.onboarding
                          ?.onboardingSteps ?? {}),
                        'onboarding-step-1':
                          numberOfCreatedSections > 0 ||
                          onboardingSteps['onboarding-step-1']
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-2':
                          numberOfCreatedSections ||
                          onboardingSteps['onboarding-step-2']
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-3':
                          numberOfCreatedSections > 0 ||
                          onboardingSteps['onboarding-step-3']
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-4':
                          isAppEmbedded || onboardingSteps['onboarding-step-4']
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-5':
                          parseInt(dashboardData?.shopPlan?.price ?? 0) > 0 ||
                          onboardingSteps['onboarding-step-5']
                            ? 'completed'
                            : 'incomplete',
                      },
                    },
                  }}
                  onClose={() => {
                    setShowOnboardingStepsBanner(false)
                  }}
                  loading={dashboardLoading}
                />
              </div>
            )}
            <AppNotEnabledBanner
              appEmbedUrl={appEmbedUrl}
              appEnabled={isAppEmbedded}
              loading={appLoading}
            />
            <SyncCard />
            <PerformanceSection
              onboardingDone={showOnboardingStepsBanner}
              loading={dashboardLoading}
            />
            {/* <OtherStatistics /> */}
            {breakpoints.lgDown && <QuickLinks quickLinks={quickLinks} />}
            <FeedbackModal
              skipModal={true}
              onSubmit={async function feedbackHandler(data) {
                const payload = {
                  'TICKET.hs_ticket_category': 'APP_FEEDBACK',
                  email: window.shopify.data.user.email,
                  'TICKET.subject': 'In App Feedback',
                  'TICKET.content': data.message,
                  'TICKET.feedback_rating': data.rating,
                  shopify_domain: window.shopify.data.shop.myshopifyDomain,
                }
                await HSClient.sendForm(payload)
                return {
                  status: 'success',
                }
              }}
            />
            <Box padding={'1000'} />
          </BlockStack>
        </div>
        <Box padding={'300'} />
      </Page>
      <ProductTour
        onClose={() => {
          setTutModal(false)
        }}
        initiallyOpen={showTutModal}
      />
      <PricingModal modal={pricing} />
      <ui-modal id="tut-modal" variant="large">
        <div
          style={{
            padding: 'var(--p-space-400)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Instructions video will be uploaded soon here. For now, You can
          contact our support team for any help.
        </div>
        <ui-title-bar
          title={t(
            'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.insModalTitle',
          )}
        ></ui-title-bar>
      </ui-modal>
    </Wrapper>
  )
}

function QuickLinks({ quickLinks }) {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  return (
    <div
      style={{
        paddingRight: breakpoints.lgUp ? 'var(--p-space-800)' : 0,
        width: breakpoints.lgUp ? '300px' : '100%',
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
        }}
      >
        <Card padding={'0'}>
          <Box padding={'300'}>
            <Text as="strong">{t('QuickLinks.title')}</Text>
          </Box>
          <Divider />
          {quickLinks.map(({ title, links }, index) => (
            <Fragment key={index}>
              <QuickLinkAccordion title={title} links={links} />
              {index !== quickLinks.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Card>
      </div>
    </div>
  )
}

function QuickLinkAccordion({ title, links, initiallyOpened = false }) {
  const [open, setOpen] = useState(initiallyOpened)
  return (
    <>
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <Box padding={'300'} background="bg-fill-hover">
          <InlineGrid columns={'1fr auto'} gap={'300'}>
            <Text>{title}</Text>
            <Icon source={open ? ChevronUpIcon : ChevronDownIcon} />
          </InlineGrid>
        </Box>
        <Collapsible open={open}>
          <div
            style={{
              padding:
                'var(--p-space-200) var(--p-space-100) var(--p-space-200) var(--p-space-100)',
            }}
          >
            <List type="bullet" gap="loose">
              {links.map(({ label, url }, index) => (
                <List.Item key={index}>
                  <Link key={index} url={url} monochrome>
                    {label}
                  </Link>
                </List.Item>
              ))}
            </List>
          </div>
        </Collapsible>
      </div>
    </>
  )
}

const getQuickLinks = ({ t }) => [
  {
    title: t('QuickLinks.section.title'),
    links: [
      {
        label: t('QuickLinks.section.view'),
        url: '/sections',
      },
      {
        label: t('QuickLinks.section.create'),
        url: '/sections/create',
      },
    ],
  },
  {
    title: t('QuickLinks.abexperience.title'),
    links: [
      {
        label: t('QuickLinks.abexperience.create'),
        url: '/experience/create',
      },
    ],
  },
  {
    title: t('QuickLinks.recommendations.title'),
    links: [
      {
        label: t('QuickLinks.recommendations.view'),
        url: '/settings',
      },
      {
        label: t('QuickLinks.recommendations.bulk'),
        url: '/settings?recSection=bulk-config',
      },
      {
        label: t('QuickLinks.recommendations.excludedProducts'),
        url: '/settings?recSection=excluded-products',
      },
      {
        label: t('QuickLinks.recommendations.disabledProducts'),
        url: '/settings?recSection=disabled-products',
      },
    ],
  },
  {
    title: t('QuickLinks.templates.title'),
    links: [
      {
        label: t('QuickLinks.templates.view'),
        url: '/settings/templates',
      },
      {
        label: t('QuickLinks.templates.create'),
        url: '/settings/templates/create',
      },
    ],
  },
  {
    title: t('QuickLinks.analytics.title'),
    links: [
      {
        label: t('QuickLinks.analytics.sections'),
        url: '/analytics/section',
      },
      {
        label: t('QuickLinks.analytics.attributedOrders'),
        url: '/analytics/order-attribution',
      },
      {
        label: t('QuickLinks.analytics.experience'),
        url: '/analytics/experience',
      },
    ],
  },
  {
    title: t('QuickLinks.setting.title'),
    links: [
      {
        label: t('QuickLinks.setting.view'),
        url: '/settings',
      },
    ],
  },
]
