export class HubspotClient {
  constructor({ portalId, formId }) {
    this.PORTAL_ID = portalId
    this.FORM_ID = formId
    this.END_POINT = `https://api.hsforms.com/submissions/v3/integration/submit/${this.PORTAL_ID}/${this.FORM_ID}`
    this.apiClient = (body) =>
      fetch(this.END_POINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(async (response) => await response.json())
        .catch((error) => {
          throw new Error(error)
        })
  }

  /**
   * Sends data to Hubspot
   * @param {data} fields - Object containing the fields to send to Hubspot.
   */
  sendForm(data) {
    const fields = []
    for (const key in data) {
      fields.push({ name: key, value: data[key], objectTypeId: '0-1' })
    }
    return this.apiClient({
      submittedAt: new Date().getTime(),
      fields,
    })
  }
}
