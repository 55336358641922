import { BlockStack, Grid, Layout, useBreakpoints } from '@shopify/polaris'
import { useEffect } from 'react'
import { useContextualSaveBar } from '@shopify/app-bridge-react'

import { useBundlesUpsertForm } from '../../hooks/useBundleUpsertForm'
import BasicDetailsSection from './sections/BasicDetails'
import ProductSelector from './sections/ProductSelector'
import MediaPicker from './sections/MediaSelection'
import DiscountSection from './sections/Discount'
import ProductStatus from './sections/Status'
import ProductOperations from './sections/ProductOperations'
import Components from './sections/Components'
import { createBundleVariants, generateBundleOptions } from '../../../../utils/bundles'
import { useRedirect } from '../../../../hooks'
import Publications from './sections/Publications'
import { useTemplates } from '../../../../hooks/useTemplates'
import { TEMPLATE_TYPES } from '../../../../constants/templates'

export default function BundlesUpsertForm({
  currentValues = {},
  dashboardDetails,
  disabledFields,
  productTypes,
  onSubmit,
  bundleType, // use this later
  publications
}) {
  const saveBar = useContextualSaveBar()
  const breakpoints = useBreakpoints()
  const {redirectToLink} = useRedirect()
  const { templates, templateLoading } = useTemplates()

  const templateOptions = templates
    .filter((template) => template.type === TEMPLATE_TYPES.BUNDLE_SECTION)
    .map((template) => ({
      label: template.name,
      value: template.id.toString(),
    }))

  const form = useBundlesUpsertForm({
    onSubmit: async (data)=>{
      const { error, data: res } = await onSubmit(data)
      if (error) {
        return {
          status: 'failed',
        }
      } else {
        saveBar.hide()
        redirectToLink({
          url: `https://admin.shopify.com/store/${window.shopify.config.shop.replace('.myshopify.com', '')}/products/${res.productId}`,
          external: true,
        })
        return {
          status: 'success',
        }
      }
    },
    currentValues: {
      ...currentValues,
      shopTemplate: currentValues.shopTemplate || templateOptions[0]?.value,
    },
  })

  useEffect(() => {
    saveBar.saveAction.setOptions({
      onAction: () => {
        form.submit()
      },
      loading: form.submitting,
    })
    saveBar.discardAction.setOptions({
      onAction: form.reset,
    })
  }, [form.submitting])

  useEffect(() => {
    if (form.dirty) {
      saveBar.show()
    } else {
      saveBar.hide()
    }
  }, [form.dirty])

  // Generate bundle options based on the selected products and options
  useEffect(() => {
    const bundleOptions = generateBundleOptions(form, bundleType)
    form.fields.bundleOptions.onChange(bundleOptions)

    const bundleVariants = createBundleVariants(form.fields.products.value, form.fields.options.value)
    form.fields.bundleVariants.onChange(bundleVariants)
  }, [form.fields.products.value, form.fields.options.value, bundleType])

  useEffect(()=>{
    if(form.submitErrors.length > 0){
      shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
      })
    }
  },[form.submitErrors])

  const commonProps = {
    canUpdate: true,
    productTypes: productTypes,
    disabledFields: disabledFields,
    form: form,
    dashboardDetails: dashboardDetails,
    publications: publications,
    templateOptions: templateOptions,
    templateLoading: templateLoading,
  }

  return (
    <Grid
      columns={{
        xs: 2,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 3,
      }}
    >
      <Grid.Cell
        columnSpan={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
        }}
      >
        <BlockStack gap={'400'}>
          <BasicDetailsSection {...commonProps} />
          {(breakpoints.mdDown) && (
            <>
              <ProductStatus {...commonProps} />
              <Publications {...commonProps}/>
              <ProductOperations {...commonProps} />
            </>
          )}
          <ProductSelector {...commonProps} />
          <DiscountSection {...commonProps} />
          <MediaPicker {...commonProps} />
        </BlockStack>
      </Grid.Cell>
      {breakpoints.mdUp && (
        <Grid.Cell
          columnSpan={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
          }}
        >
          <BlockStack gap={'400'}>
            <ProductStatus {...commonProps} />
            <Publications {...commonProps}/>
            <Components {...commonProps} />
            <ProductOperations {...commonProps} />
          </BlockStack>
        </Grid.Cell>
      )}
    </Grid>
  )
}